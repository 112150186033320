import React, { useEffect, useState } from "react";

import ScrollAnimation from "react-animate-on-scroll";
import "../../../styles/animate.css";

import "../../../styles/home.css";
import screenShot1 from "../../../assets/home/screenShot1Home.svg";
import screenShot1Mobile from "../../../assets/home/Group 682.png";

import screenShot2 from "../../../assets/home/screenShot2Home.svg";
import screenShot2Mobile from "../../../assets/home/Group 681.png";

import homeRightColImg from "../../../assets/home/Group 230.svg";
import brand1 from "../../../assets/home/homeBrand1.svg";
import brand2 from "../../../assets/home/homeBrand2.svg";
import brand3 from "../../../assets/home/homeBrand3.svg";
import brand4 from "../../../assets/home/homeBrand4.svg";
import brand5 from "../../../assets/home/homeBrand5.svg";
import brand6 from "../../../assets/home/homeBrand6.svg";
import sectionTwoBoy from "../../../assets/home/Group 549.svg";
import card1Img from "../../../assets/home/Group.svg";
import card2Img from "../../../assets/home/Vector.svg";
import card3Img from "../../../assets/home/Vector (1).svg";
import HomeSection2 from "./HomeSection2";
import sectionTwoGirl from "../../../assets/home/Group 680.svg";
import homeSection5Img from "../../../assets/home/Mask Group.png";
import homeSecition5item1 from "../../../assets/home/Group 625.svg";
import homeSecition5item2 from "../../../assets/home/Group 624.svg";
import homeSecition5item3 from "../../../assets/home/001-pillar 1.svg";
import Section5BtnGroup from "./Section5BtnGroup";
import section5ArrowImg from "../../../assets/home/Group 681.svg";
import axios from "axios";
import HomeSection2Mobile from "./HomeSection2Mobile";
import HomeSection4MentorsDesktop from "./HomeSection4MentorsDesktop";
import HomeSection4MentorsMobile from "./HomeSection4MentorsMobile";
import homeSection5ImgMobile from "../../../assets/home/Mask Group-2.png";
import { Link } from "react-router-dom";
import PopUp from "../popup/PopUp";
import { useMobilePopup } from "../../../hooks/useMobilePopup";
import { mentors } from "../../../helper/dummyData";
import CardoButton from "../../common/btn/CardoButton";
const Home = () => {
  const [isSectionTwoBoy, setIsSectionTwoBoy] = useState(true);
  const [mentorData, setMentorData] = useState([]);
  useEffect(() => {
    setMentorData({ mentors });
  }, []);

  const onBubbleClick = (e) => {
    setIsSectionTwoBoy(!isSectionTwoBoy);
    if (
      typeof e.target.parentNode.parentNode.parentNode.childNodes[3] !==
      "undefined"
    ) {
      let img =
        e.target.parentNode.parentNode.parentNode.childNodes[2].childNodes[0];
      let rectangleRightTop =
        e.target.parentNode.parentNode.parentNode.childNodes[1].childNodes[0]
          .childNodes[0];
      let rectangleRightBottom =
        e.target.parentNode.parentNode.parentNode.childNodes[3].childNodes[0]
          .childNodes[0];
      let rectangleLeftTop =
        e.target.parentNode.parentNode.parentNode.childNodes[1].childNodes[1]
          .childNodes[0];
      let rectangleLeftBottom =
        e.target.parentNode.parentNode.parentNode.childNodes[3].childNodes[1]
          .childNodes[0];

      if (img.classList.contains("home-section-two-toggle")) {
        img.classList.remove("home-section-two-toggle");
        rectangleRightTop.classList.remove("rectangle-active");
        rectangleRightBottom.classList.remove("rectangle-active");
        rectangleLeftTop.classList.remove("rectangle-active");
        rectangleLeftBottom.classList.remove("rectangle-active");
      }
      setTimeout(() => {
        img.classList.add("home-section-two-toggle");
        rectangleRightTop.classList.add("rectangle-active");
        rectangleRightBottom.classList.add("rectangle-active");
        rectangleLeftTop.classList.add("rectangle-active");
        rectangleLeftBottom.classList.add("rectangle-active");
      }, 300);

      if (!isSectionTwoBoy) {
        setTimeout(() => {
          img.style.backgroundImage = `url('${sectionTwoBoy}')`;
        }, 250);
      } else {
        setTimeout(() => {
          img.style.backgroundImage = `url('${sectionTwoGirl}')`;
        }, 250);
      }
    }
  };
  const { openMobilePopup } = useMobilePopup("mobile-modal");
  return (
    <div>
      {openMobilePopup}
      {console.log(mentorData)}
      <section className="home-header">
        <div className="container_wide home-header-container">
          <div className="row home-header_row">
            <div className="col-4 home-header_leftCol">
              <div>
                <ScrollAnimation animateIn="fadeInDown" offset={0}>
                  <h1 className="desktop-heading">
                    Making career
                    <br /> mentorship
                    <br /> accessible for all
                  </h1>
                </ScrollAnimation>
                <ScrollAnimation animateIn="fadeIn" offset={0}>
                  <p className="desktop-content">
                    Cardo is a 501(c)(3) non-profit organization that provides
                    free, industry-specific career guidance to underserved and
                    underrepresented communities
                  </p>{" "}
                </ScrollAnimation>
                <ScrollAnimation animateIn="fadeInUp" offset={0}>
                  <div className="btn-group">
                    <Link to="/find-mentor" className="text-decoration-none">
                      <button className="pink-btn find-mentor-btn homebtn-pink">
                        Find your mentor
                      </button>
                    </Link>
                    <Link to="/become-mentor" className="text-decoration-none">
                      <button className="blue-btn become-mentor-btn">
                        Become a mentor
                      </button>
                    </Link>
                  </div>
                  <div className="strike-text">
                    OUR MENTORS COME FROM WORLD CLASS COMPANIES
                  </div>
                  <div className="brands">
                    <div>
                      <img src={brand1} alt="" className="brands-img" />
                    </div>
                    <div>
                      <img src={brand2} alt="" className="brands-img" />
                    </div>
                    <div>
                      <img src={brand3} alt="" className="brands-img" />
                    </div>
                    <div>
                      <img src={brand4} alt="" className="brands-img" />
                    </div>
                    <div>
                      <img src={brand5} alt="" className="brands-img" />
                    </div>
                    <div>
                      <img src={brand6} alt="" className="brands-img" />
                    </div>
                    {/* <img src={brandsImg} alt="" /> */}
                  </div>
                </ScrollAnimation>
              </div>
            </div>
            <div className="col-8 home-header_rightCol">
              <ScrollAnimation offset={30} animateIn="fadeIn">
                <img src={homeRightColImg} alt="" className="homeMainImage" />
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </section>

      <section className="home-section-one">
        <div className="home-section-one-container">
          <div className="home-section-one-row">
            <div className="home-section-one-row-one-leftCol">
              <div>
                <ScrollAnimation offset={30} animateIn="fadeInLeft">
                  <div
                    className="box-shape"
                    style={{ backgroundColor: "#FAC4DC" }}
                  >
                    <img
                      src={screenShot1}
                      style={{ width: "92%" }}
                      alt=""
                      className="img_desk"
                    />
                    <img
                      src={screenShot1Mobile}
                      style={{ width: "92%" }}
                      alt=""
                      className="img_mobile"
                    />
                  </div>
                </ScrollAnimation>
              </div>
            </div>
            <div className="home-section-one-row-one-rightCol">
              <div className="home-section-one-row-one-rightCol-container">
                <ScrollAnimation offset={30} animateIn="scaleLeft">
                  <span className="strike-text-desktop">
                    FOR MENTEES: NO MORE TWISTS AND TURNS
                  </span>
                  <span className="strike-text-mobile">
                    FOR MENTEES: NO MORE TWISTS AND TURNS
                  </span>
                </ScrollAnimation>
                <ScrollAnimation offset={30} animateIn="fadeInDown" delay={300}>
                  <h2>
                    Get the guidance you <br className="mobile_br" /> need,
                    without the
                    <br className="mobile_br" /> roadblocks
                  </h2>
                </ScrollAnimation>
                <ScrollAnimation offset={30} animateIn="fadeIn" delay={320}>
                  <p>
                    Finding a mentor shouldn't be so hard. Get access to a{" "}
                    <br className="br_desk" />
                    network of professionals in your target career path.{" "}
                    <br className="br_desk" />
                    Search, select, and communicate all in one place.
                  </p>
                </ScrollAnimation>
                <ScrollAnimation offset={30} animateIn="fadeInUp" delay={340}>
                  <a href="/find-mentor" className="text-decoration-none">
                    <button className="pink-btn find-mentor-btn homebtn-pink">
                      Find your mentor
                    </button>
                  </a>
                </ScrollAnimation>
              </div>
            </div>
          </div>
          <div className="home-section-one-row-two">
            <div className="home-section-one-row-two-leftCol">
              <div className="home-section-one-row-two-leftCol-container">
                <ScrollAnimation offset={30} animateIn="scaleLeft">
                  <span className="strike-text-desktop">
                    FOR MENTORS: LIFT AS YOU CLIMB
                  </span>
                  <span className="strike-text-mobile">
                    FOR MENTORS: LIFT AS YOU CLIMB
                  </span>
                </ScrollAnimation>
                <ScrollAnimation offset={30} animateIn="fadeInDown" delay={300}>
                  <h2>
                    Easily make your knowledge and experience accessible to
                    those who need it most
                  </h2>
                </ScrollAnimation>
                <ScrollAnimation offset={30} animateIn="fadeIn" delay={320}>
                  <p>
                    We know your time is valuable. Coach and
                    <br className="br_mobile" /> empower people who{" "}
                    <br className="br_desk" />
                    can benefit from your help
                    <br className="br_mobile" /> the most. Manage your schedule,
                    track <br className="br_desk" />
                    your impact,
                    <br className="br_mobile" /> and communicate directly
                    through our platform.
                  </p>
                </ScrollAnimation>
                <ScrollAnimation offset={30} animateIn="fadeInUp" delay={340}>
                  <a href="/become-mentor" className="text-decoration-none">
                    <button className="blue-btn become-mentor-btn">
                      Become a mentor
                    </button>
                  </a>
                </ScrollAnimation>
              </div>
            </div>
            <div className="home-section-one-row-two-rightCol">
              <div>
                <ScrollAnimation offset={30} animateIn="fadeInRight">
                  <div
                    className="box-shape"
                    style={{ backgroundColor: "#6565FC" }}
                  >
                    <img
                      src={screenShot2}
                      style={{ width: "92%" }}
                      alt=""
                      className="img_desk"
                    />
                    <img
                      src={screenShot2Mobile}
                      style={{ width: "92%" }}
                      alt=""
                      className="img_mobile"
                    />
                  </div>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="home-section-two">
        <div className="home-section-two-container">
          <ScrollAnimation offset={30} animateIn="fadeIn">
            <h2 className="heading">Why Cardo</h2>
          </ScrollAnimation>
          <div className="home-section2-desktop">
            {isSectionTwoBoy ? (
              <HomeSection2
                imagePosition="-20px"
                onClick={onBubbleClick}
                bubbleClass={{
                  mentor: "mentor-bubble-man",
                  mentee: "mentee-bubble-man",
                }}
                topLeftTitle={"Get past low response rates"}
                topLeftDesc={
                  "No need for multiple cold reachouts on LinkedIn. Find mentors who are ready and willing to help."
                }
                topRightTitle={"Don’t pay a dime"}
                topRightDesc={
                  "We don’t believe mentorship should cost $200/hr. All of our services are free."
                }
                bottomLeftTitle={"Have more authentic conversations"}
                bottomLeftDesc={
                  "Bring your authentic self and get the raw advice you need. No judgement, no expectations."
                }
                bottomRightTitle={"Eliminate scheduling nightmares"}
                bottomRightDesc={
                  "No more back and forth. Easily see mentors’ availability and book a session."
                }
              />
            ) : (
              <HomeSection2
                imagePosition="-20px"
                onClick={onBubbleClick}
                bubbleClass={{
                  mentor: "mentor-bubble-girl",
                  mentee: "mentee-bubble-girl",
                }}
                topLeftTitle={"Mentorship made easy"}
                topLeftDesc={
                  "Integrate multiple calendars, set availability parameters, and join sessions in a few easy clicks."
                }
                topRightTitle={"Track your impact"}
                topRightDesc={
                  "Quantify your mentorship contributions and earn credit from our 501(c)(3) registered non-profit."
                }
                bottomLeftTitle={"Don’t waste your time"}
                bottomLeftDesc={
                  "All mentees go through a screening process and set clear agendas prior to sessions."
                }
                bottomRightTitle={"Access to underserved mentees"}
                bottomRightDesc={
                  "Reach people who need your help the most, even if they are not in your network."
                }
              />
            )}
          </div>
          <div className="home-section2-mobile">
            {isSectionTwoBoy ? (
              <HomeSection2Mobile
                mentorbg={"#E1E1E1"}
                menteebg={"#FAC4DC"}
                onClick={onBubbleClick}
                topLeftTitle={"Get past low response rates"}
                topLeftDesc={
                  "No need for multiple cold reachouts on LinkedIn. Find mentors who are ready and willing to help."
                }
                topRightTitle={"Don’t pay a dime"}
                topRightDesc={
                  " We don’t believe mentorship should cost $200/hr. All of our services are free."
                }
                bottomLeftTitle={"Have more authentic conversations"}
                bottomLeftDesc={
                  "Bring your authentic self and get the raw advice you need. No judgement, no expectations."
                }
                bottomRightTitle={"Eliminate scheduling nightmares"}
                bottomRightDesc={
                  "No more back and forth. Easily see mentors’ availability and book a session."
                }
              />
            ) : (
              <HomeSection2Mobile
                mentorbg={"#5555DB"}
                menteebg={"#E1E1E1"}
                onClick={onBubbleClick}
                topLeftTitle={"Mentorship made easy"}
                topLeftDesc={
                  "Integrate multiple calendars, set availability parameters, and join sessions in a few easy clicks."
                }
                topRightTitle={"Track your impact"}
                topRightDesc={
                  "Quantify your mentorship contributions and earn credit from our 501(c)(3) registered non-profit."
                }
                bottomLeftTitle={"Don’t waste your time"}
                bottomLeftDesc={
                  "All mentees go through a screening process and set clear agendas prior to sessions."
                }
                bottomRightTitle={"Access to underserved mentees"}
                bottomRightDesc={
                  "Reach people who need your help the most, even if they are not in your network."
                }
              />
            )}
          </div>
        </div>
      </section>
      <section className="home-section-three">
        <div className="container_wide home-section-three-container">
          <ScrollAnimation offset={30} animateIn="fadeInDown">
            <h2 className="heading">How it works</h2>
          </ScrollAnimation>
        </div>
        <div className="home-section-three-body">
          <div className="card-container">
            <div className="middle_line"></div>

            <ScrollAnimation offset={30} animateIn="fadeInDown">
              <div className="card" id="card1">
                <div className="card-inner">
                  <div className="icon-container">
                    <img src={card1Img} alt="" />
                  </div>
                  <div className="card-heading">Step 1</div>
                  <div className="card-content">
                    Apply to Cardo <br />
                    and select a<br />
                    target industry <br />
                  </div>
                </div>
              </div>
            </ScrollAnimation>
            <ScrollAnimation offset={30} animateIn="fadeInDown" delay={100}>
              <div className="card">
                <div className="card-inner">
                  <div className="icon-container">
                    <img src={card2Img} alt="" />
                  </div>
                  <div className="card-heading">Step 2</div>
                  <div className="card-content">
                    Cardo reviews all
                    <br />
                    applications and
                    <br />
                    onboards mentors and
                    <br />
                    mentees
                  </div>
                </div>
              </div>
            </ScrollAnimation>
            <ScrollAnimation offset={30} animateIn="fadeInDown" delay={200}>
              <div className="card">
                <div className="card-inner">
                  <div className="icon-container">
                    <img src={card3Img} alt="" />
                  </div>
                  <div className="card-heading">Step 3</div>
                  <div className="card-content">
                    Use Cardo’s built-in
                    <br />
                    features to have virtual
                    <br />
                    1:1 career mentorship
                    <br />
                    conversations
                  </div>
                </div>
              </div>
            </ScrollAnimation>
          </div>
        </div>
      </section>
      <section className="home-section-four">
        <div className="home-section-four-container">
          <ScrollAnimation offset={30} animateIn="fadeInDown">
            <h2 className="heading">Our mentors</h2>
          </ScrollAnimation>
          <div className="home-section-four-body">
            <Section5BtnGroup />
            <div className="mentor-desktop">
              <HomeSection4MentorsDesktop mentorData={mentorData} />
            </div>
            <div className="mentor-mobile">
              <HomeSection4MentorsMobile mentorData={mentorData} />
            </div>
          </div>
        </div>
      </section>
      <section className="home-section-five">
        <div className="home-section-five-container">
          <div className="home-section-five-body">
            <div className="home-section-five-leftCol">
              <ScrollAnimation offset={30} animateIn="fadeInLeft">
                <img
                  style={{
                    objectFit: "cover",
                    objectPosition: "0 30%",
                    width: "100%",
                    height: "680px",
                  }}
                  src={homeSection5Img}
                  alt=""
                  className="homeSection5desktop"
                />
                <img
                  src={homeSection5ImgMobile}
                  alt=""
                  className="homeSection5mobile"
                />
              </ScrollAnimation>
            </div>
            <div className="home-section-five-rightCol">
              <ScrollAnimation offset={30} animateIn="scaleLeft">
                <div className="desktop-heading">WHY IT MATTERS</div>
                <div className="mobile-heading">WHY IT MATTERS</div>
              </ScrollAnimation>
              <ScrollAnimation offset={30} animateIn="fadeInDown" delay={300}>
                <h1 className="heading">How we make a difference</h1>
              </ScrollAnimation>
              <ScrollAnimation offset={30} animateIn="fadeIn" delay={350}>
                <div className="home-section-five-item">
                  <div className="item-image-container">
                    <img src={homeSecition5item1} alt="" />
                  </div>
                  <div>
                    <h3 className="item-heading">Support diverse hiring</h3>
                    <p className="item-desc">
                      Workplace diversity is more than just a hot topic.
                      Empowering our mentees can help bring its proven and
                      tangible benefits to the table.
                    </p>
                  </div>
                </div>
              </ScrollAnimation>
              <ScrollAnimation offset={30} animateIn="fadeIn" delay={350}>
                <div className="home-section-five-item">
                  <div className="item-image-container">
                    <img src={homeSecition5item2} alt="" />
                  </div>
                  <div>
                    <h3 className="item-heading">
                      Empower communities through knowledge
                    </h3>
                    <p className="item-desc">
                      We provide the platform, our mentees unlock its potential.
                      We believe in the network effects of career advice and
                      accessibility.
                    </p>
                  </div>
                </div>
              </ScrollAnimation>
              <ScrollAnimation offset={30} animateIn="fadeIn" delay={350}>
                <div className="home-section-five-item">
                  <div className="item-image-container">
                    <img src={homeSecition5item3} alt="" />
                  </div>
                  <div>
                    <h3 className="item-heading">Break traditional barriers</h3>
                    <p className="item-desc">
                      Let's admit it - privilege plays a part in accessing
                      opportunity. We fill in the gaps with career guidance and
                      support where needed.
                    </p>
                  </div>
                </div>
              </ScrollAnimation>
              <ScrollAnimation offset={30} animateIn="fadeIn">
                <div className="home-section-five-item-mobile">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <div className="item-image-container">
                      <img src={homeSecition5item1} alt="" />
                    </div>
                    <h3 className="item-heading">Support diverse hiring</h3>
                  </div>
                  <p className="item-desc">
                    Workplace diversity is more than just a hot topic.
                    Empowering our mentees can help bring its proven and
                    tangible benefits to the table.
                  </p>
                </div>
              </ScrollAnimation>
              <ScrollAnimation offset={30} animateIn="fadeIn" delay={50}>
                <div className="home-section-five-item-mobile">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <div className="item-image-container">
                      <img src={homeSecition5item2} alt="" />
                    </div>
                    <h3 className="item-heading">
                      Empower communities <br /> through knowledge
                    </h3>
                  </div>

                  <p className="item-desc">
                    We provide the platform, our mentees unlock its potential.
                    We believe in the network effects of career advice and
                    accessibility.
                  </p>
                </div>
              </ScrollAnimation>
              <ScrollAnimation offset={30} animateIn="fadeIn" delay={100}>
                <div className="home-section-five-item-mobile">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <div className="item-image-container">
                      <img src={homeSecition5item3} alt="" />
                    </div>
                    <h3 className="item-heading">Break traditional barriers</h3>
                  </div>

                  <p className="item-desc">
                    Let's admit it - privilege plays a part in accessing
                    opportunity. We fill in the gaps with career guidance and
                    support where needed.
                  </p>
                </div>
              </ScrollAnimation>
              <div
                style={{ marginTop: "100px", position: "relative" }}
                className="learnmore"
              >
                <ScrollAnimation offset={30} animateIn="fadeInDown">
                  <Link to="/about-us" className="text-decoration-none">
                    <button className="blue-btn btn">Learn More</button>
                  </Link>
                </ScrollAnimation>
                <div className="arrow-img-container">
                  <ScrollAnimation
                    offset={30}
                    animateIn="fadeInRight"
                    delay={350}
                  >
                    <img src={section5ArrowImg} alt="" />
                  </ScrollAnimation>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div style={{ position: "relative" }}>
        <div className="support-our-mission">
          <div className="suport-out-mission-container">
            <div>
              <h3 className="heading">Support our mission</h3>
              <p className="desc">
                While we would love your time and knowledge, we understand you
                may not be able to join as a mentor at this time. If our mission
                matters to you, please consider helping us scale our impact
                through a donation. As a non-profit organization, all proceeds
                are dedicated to furthering our mission.{" "}
              </p>
            </div>
            <Link to="/donate" className="text-decoration-none">
              <button className="blue-btn donate-btn">Donate</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
