import React from "react";
import "../../../../../styles/dashboard/mentee-dashboard/mentee-dashboard-my-sessions-popup.css";
import moment from "moment-timezone";

const MentorDashboardBookingDetails = (props) => {
  const { modalMentorDetails } = props;

  const { mentor, mentee } = modalMentorDetails;

  function closeModal(e) {
    e.stopPropagation();
    props.closeModal();
  }

  return (
    <div className="mentee-dashboard-find-mentor-modal" onClick={closeModal}>
      <div
        className="mentee-dashboard-find-mentor-modal-content"
        style={{ height: "720px" }}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="position-relative">
          <div className="mentee-dashboard-find-mentor-modal-header">
            <div className="mentee-dashboard-find-mentor-modal-header-container">
              <div className="display-flex align-items-center">
                <div
                  className="mentee-dashboard-find-mentor-modal-header-mentor-avatar mr-25"
                  style={{
                    backgroundImage: `url('${mentee.img_url}')`,
                  }}
                />
                <div>
                  <div>
                    <h3 className="mentee-dashboard-find-mentor-modal-header-mentor-name">
                      {mentee.first_name} {mentee.last_name.substr(0, 1)}.
                    </h3>
                  </div>
                  {mentee.profile_info?.preferred_trail && (
                    <div className="font-Neometric-Regular mentee-dashboard-find-mentor-modal-header-mentor-industry">
                      {mentee?.profile_info?.preferred_industry}

                      <i
                        className="fas fa-caret-right"
                        style={{ margin: "0px 5px" }}
                      ></i>
                      {mentee?.profile_info?.preferred_trail}
                    </div>
                  )}
                </div>
              </div>

              <div style={{ display: "flex" }}>
                <div
                  className="mentee-dashboard-find-mentor-modal-header-mentor-credits"
                  style={{ width: "183px", height: "87px" }}
                >
                  Session Date and Time
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-evenly",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{ fontSize: "12px" }}
                      className="mentee-dashboard-find-mentor-modal-header-mentor-credits-value"
                    >
                      {moment(modalMentorDetails.start_time)
                        .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
                        .format("MM/DD/YY")}
                    </div>
                    <div
                      style={{ fontSize: "12px" }}
                      className="mentee-dashboard-find-mentor-modal-header-mentor-credits-value"
                    >
                      {moment(modalMentorDetails.start_time)
                        .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
                        .format("LT")}
                    </div>
                  </div>
                </div>

                <div
                  className="font-Neometric-Regular"
                  style={{
                    marginLeft: "15px",
                    height: "87px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  {mentee.status !== "deleted" && (
                    <a
                      target="_blank"
                      href={modalMentorDetails.reschedule_url}
                      className="font-Neometric-Regular"
                      style={{
                        textDecoration: "none",
                        width: "142px",
                        height: "40px",
                        background: "#4D4DC6",
                        borderRadius: "7px",
                        color: "white",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "14px",
                      }}
                    >
                      Reschedule
                    </a>
                  )}
                  <a
                    target="_blank"
                    href={modalMentorDetails.cancel_url}
                    style={{
                      textDecoration: "none",
                      width: "142px",
                      height: "40px",
                      background: "#4D4DC6",
                      borderRadius: "7px",
                      color: "white",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "14px",
                    }}
                  >
                    Cancel
                  </a>
                </div>
              </div>
              <div
                className="mentee-dashboard-find-mentor-modal-close"
                onClick={closeModal}
              >
                &times;
              </div>
            </div>
          </div>

          {/* Content */}
          <div className="mentee-dashboard-sessions-detail-modal-popup-content">
            <div className="mentee-dashboard-find-mentor-modal-mentor-info mb-25">
              <div
                className="display-flex flex-direction-column"
                style={{ marginRight: "10px", width: "calc(50% - 40px)" }}
              >
                <div
                  className="mentee-dashboard-find-mentor-modal-mentor-info-about-me"
                  style={{ minHeight: "217px" }}
                >
                  <div
                    className=""
                    style={{
                      wordBreak: "break-word",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "center",
                      paddingTop: "20px",
                      paddingLeft: "30px",
                      paddingRight: "30px",
                    }}
                  >
                    <div className="font-Neometric-Medium line-height">
                      About Me
                    </div>
                    <br />
                    <p className="line-height" style={{ fontSize: "14px" }}>
                      {mentee.profile_info?.my_story}
                    </p>
                  </div>
                </div>
                <div
                  className="mentee-dashboard-find-mentor-modal-mentor-info-about-me"
                  style={{ minHeight: "138px", marginTop: "10px" }}
                >
                  <div
                    className=""
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "center",
                      paddingTop: "10px",
                      paddingLeft: "30px",
                      paddingRight: "30px",
                    }}
                  >
                    <div className="my-sessions-details-popup-qacol">
                      Stage:{" "}
                      <span className="font-Neometric-Regular">
                        {mentee.profile_info?.stage}
                      </span>
                    </div>
                    <div className="my-sessions-details-popup-qacol">
                      Education:{" "}
                      <span className="font-Neometric-Regular">
                        {mentee.profile_info?.education}
                      </span>
                    </div>
                    <div className="my-sessions-details-popup-qacol">
                      GPA:{" "}
                      <span className="font-Neometric-Regular">
                        {mentee.profile_info?.gpa}
                      </span>
                    </div>
                    <div className="my-sessions-details-popup-qacol">
                      Seeking:{" "}
                      <span className="font-Neometric-Regular">
                        {mentee.profile_info?.interested_in}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="display-flex flex-direction-column">
                  <div
                    style={{ minHeight: "100px" }}
                    className="mentee-dashboard-find-mentor-modal-mentor-info-uniqueness mentor-dashboard-find-mentor-modal-mentor-info-uniqueness"
                  >
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "flex-start",
                      }}
                    >
                      <div className="font-Neometric-Medium ">
                        What Makes Me Unique
                      </div>
                      <div
                        className="mentee-dashboard-find-mentor-modal-mentor-info-mentorship-focus-help-tags-container"
                        style={{ width: "340px" }}
                      >
                        {mentee.profile_info?.what_makes_me_unique?.map(
                          (whatMakesMeUniqueItem, index) => (
                            <div
                              key={index}
                              className="mentee-dashboard-find-mentor-modal-mentor-info-mentorship-focus-help-tags"
                            >
                              {whatMakesMeUniqueItem}
                            </div>
                          )
                        )}
                      </div>
                      <div className="mentor-dashboard-find-mentor-modal-interview-btn mt-15">
                        <a
                          href={mentee.profile_info?.resume}
                          target="_blank"
                          style={{ textDecoration: "none" }}
                          className="display-flex resume-download-btn"
                        >
                          Resume
                          <i className="fas fa-arrow-down ml-5" />
                        </a>
                        {/*<Link to={{pathname: 'https://www.linkedin.com/company/hellocardo/'}} target="_blank" rel="noopener noreferrer">*/}
                        {/*<button className='display-flex resume-download-btn '>*/}
                        {/*    LinkedIn*/}
                        {/*    <i className="fas fa-external-link-alt ml-5" />*/}
                        {/*</button>*/}
                        {/*</Link>*/}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  marginLeft: "10px",
                  // marginBottom: "30px",
                  background: "#F5F7F9",
                  borderRadius: "10px",
                  display: "flex",
                  height: "515px",
                }}
              >
                <div
                  className="m-25"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                  }}
                >
                  <div className=" my-sessions-details-popup-qacol">
                    Session Agenda
                  </div>
                  <div className="my-sessions-details-popup-qacol-answers">
                    {modalMentorDetails.interviewTitle}
                  </div>
                  {modalMentorDetails.questions_and_answers.map(
                    (questions_and_answer) => {
                      return (
                        <div>
                          <div className="my-sessions-details-popup-qacol">
                            {questions_and_answer.question}
                          </div>
                          <div className="my-sessions-details-popup-qacol-answers">
                            {questions_and_answer.answer}
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            </div>

            {/* ContentEnd Here */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MentorDashboardBookingDetails;
