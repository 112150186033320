import React from "react";

const test = (e) => {
  let eClass = e.target.classList;
  let eClassParent = e.target.parentNode.classList;
  let eClassParent2 = e.target.parentNode.parentNode.classList;

  let eIndex = e.target.getAttribute("index");

  let industryCardItemHeaderMobile = document.getElementsByClassName(
    "industry-card-item-header-mobile"
  );
  let comingSoon = document.getElementsByClassName("comingSoon");
  let section5content = document.getElementsByClassName(
    "industry-card-item-content-mobile-container"
  );
  let arrow = document.getElementsByClassName("arrow");
  if (eIndex != 0) {
    if (
      eClass.contains("head") ||
      eClassParent.contains("head") ||
      eClass.contains("industry-card-item-header-mobile") ||
      eClassParent2.contains("head")
    ) {
      industryCardItemHeaderMobile[eIndex].classList.toggle("head-active");
      section5content[eIndex].classList.toggle(
        "industry-card-item-content-mobile-container-active"
      );
      arrow[eIndex].classList.toggle("arrow-active");
    }
  }
  if (eIndex == 0) {
    var loc = document.location.toString().split("#")[0];
    document.location = loc + "#step2";
  }
};

const FindMentorSection5Mobile = () => {
  return (
    <div className="industry-card-container-mobile">
      <div
        className="industry-card-item-header-mobile head-active"
        index="0"
        onClick={test.bind(this)}
      >
        <div className="head" index="0">
          <h3 index="0">
            <i className="fa fa-angle-right arrow arrow-active"></i>
            Consulting
          </h3>
          <p className="select" index="0">
            Select
          </p>
        </div>
        <div
          className="industry-card-item-content-mobile-container industry-card-item-content-mobile-container-active"
          index="0"
        >
          <div className="industry-card-item-content-mobile">
            <span>Strategy</span>
            <span>Operations</span>
            <span>Human Capital</span>
            <span>Digital</span>
            <span>Technology</span>
          </div>
        </div>
      </div>

      <div
        className="industry-card-item-header-mobile"
        onClick={test.bind(this)}
        index="1"
      >
        <div className="head" index="1">
          <h3 index="1" className="disabled-heading">
            <i className="fa fa-angle-right arrow"></i>
            Finance
          </h3>
          <p index="1" className="comingSoon">
            Coming Soon
          </p>
        </div>
        <div className="industry-card-item-content-mobile-container" index="1">
          <div className="industry-card-item-content-mobile">
            <span>Investment Banking</span>
            <span>Private Equity</span>
            <span>Venture Capital</span>
            <span>Corporate Finance</span>
            <span>FP&A</span>
          </div>
        </div>
      </div>

      <div
        className="industry-card-item-header-mobile"
        onClick={test.bind(this)}
        index="2"
      >
        <div className="head" index="2">
          <h3 index="2" className="disabled-heading">
            <i className="fa fa-angle-right arrow disabled-arrow"></i>
            Product
          </h3>
          <p index="2" className="comingSoon">
            Coming Soon
          </p>
        </div>
        <div className="industry-card-item-content-mobile-container" index="2">
          <div className="industry-card-item-content-mobile">
            <span> Product Management</span>
            <span> Product Marketing</span>
            <span> Product Design</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FindMentorSection5Mobile;
