import { CLEAR_ERRORS, GET_ERRORS } from "../types/types";

export const throwCustomError = (errorName, status, msg) => (dispatch) => {
  dispatch({
    type: GET_ERRORS,
    payload: {
      errorStatus: status,
      [errorName]: msg,
    },
  });
};
export const clearErrors = () => (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
    payload: {},
  });
};
