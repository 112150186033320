import React from "react";
import Slider from "react-slick";
import section1cardIcon1 from "../../../assets/Become a mentee/Group 641.svg";
import section1cardIcon2 from "../../../assets/Become a mentee/Vector.svg";
import section1cardIcon3 from "../../../assets/Become a mentee/Group 560.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const FindMentorSection2Mobile = () => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    arrows: false,
    variableWidth: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div>
      <Slider {...settings}>
        <div>
          <div className="card-mobile">
            <div style={{ display: "flex", alignItems: "center" }}>
              <div className="card-icon-mobile">
                <img src={section1cardIcon1} alt="" />
              </div>
              <div className="card-heading-mobile">Hold the cash </div>
            </div>
            <p className="card-desc-mobile">
              Don’t waste hundreds of dollars paying for career mentorship.
              We’ve got you covered, for free.
            </p>
          </div>
        </div>
        <div>
          <div className="card-mobile">
            <div style={{ display: "flex", alignItems: "center" }}>
              <div className="card-icon-mobile">
                <img src={section1cardIcon2} alt="" />
              </div>
              <div className="card-heading-mobile">
                People who actually care{" "}
              </div>
            </div>

            <p className="card-desc-mobile">
              Say goodbye to unanswered messages. Mentors on Cardo are here to
              help you achieve your career goals.
            </p>
          </div>
        </div>
        <div>
          <div className="card-mobile">
            <div style={{ display: "flex", alignItems: "center" }}>
              <div className="card-icon-mobile">
                <img src={section1cardIcon3} alt="" />
              </div>
              <div className="card-heading-mobile">Skip the formalities </div>
            </div>

            <p className="card-desc-mobile">
              Drop the buzzwords. Have authentic and unbiased conversations that
              matter.
            </p>
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default FindMentorSection2Mobile;
