// Get All Errors Type
export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

// LOADERS
export const START_LOADING = "START_LOADING";
export const STOP_LOADING = "STOP_LOADING";

// Handle Success

export const SUCCESS = "SUCCESS";
export const CLEAN_SUCCESS = "CLEAN_SUCCESS";

// Mentor|Mentee Form Types
export const GET_FORM_MENTOR = "GET_FORM_MENTOR";
export const FORM_LOADING = "GET_FORM_LOADING";
export const GET_CONDITIOS_MENTOR = "GET_CONDITIOS_MENTOR";
export const GET_FORM_MENTEE = "GET_FORM_MENTEE";
// Current User
export const GET_CURRENT_USER = "GET_CURRENT_USER";
export const REMOVE_CURRENT_USER = "REMOVE_CURRENT_USER";
export const CURRENT_USER_LOADING = "CURRENT_USER_LOADING";
// Admin Types
export const GET_ALL_USERS = "GET_ALL_USERS";
export const USERS_LOADING = "USERS_LOADING";
export const ERROR_401 = "ERROR_401";
export const SAVE_LINK = "SAVE_LINK";
export const GET_LINKS = "GET_LINK";
export const GET_CALENDLY_TAB = "GET_CALENDLY_TAB";
export const ACCEPT_USER = "ACCEPT_USER";
export const REJECT_USER = "REJECT_USER";
export const GET_ALL_NEW_USERS = "GET_ALL_NEW_USERS";
export const POST_ACCEPT = "POST_ACCEPT"

//Mentee Dashboard
export const MENTEE_DASHBOARD_HOME = "MENTEE_DASHBOARD_HOME";
export const MENTEE_DASHBOARD_FIND_MENTOR = "MENTEE_DASHBOARD_FIND_MENTOR";
export const MENTEE_DASHBOARD_EDIT_PROFILE = "MENTEE_DASHBOARD_EDIT_PROFILE";
export const MENTEE_DASHBOARD_MY_SESSIONS = "MENTEE_DASHBOARD_MY_SESSIONS";
export const MENTEE_DASHBOARD_EVENT_SCHEDULED =
  "MENTEE_DASHBOARD_EVENT_SCHEDULED";
export const MENTEE_DASHBOARD_NEW_IMAGE = "MENTEE_DASHBOARD_NEW_IMAGE";
export const MENTEE_DASHBOARD_MENTEE_DELETED =
  "MENTEE_DASHBOARD_MENTEE_DELETED";
export const MENTOR_DASHBOARD_POST_EMAIL_INVITATION =
  "MENTOR_DASHBOARD_POST_EMAIL_INVITATION";

//Calendly
export const GET_SUPPORT_AREAS = "GET_SUPPORT_AREAS";

//Mentor Dashboard
export const MENTOR_DASHBOARD_HOME = "MENTOR_DASHBOARD_HOME";
export const MENTOR_DASHBOARD_FIND_MENTOR = "MENTOR_DASHBOARD_FIND_MENTOR";
export const MENTOR_DASHBOARD_EDIT_PROFILE = "MENTOR_DASHBOARD_EDIT_PROFILE";
export const MENTOR_DASHBOARD_MY_SESSIONS = "MENTOR_DASHBOARD_MY_SESSIONS";
export const MENTOR_DASHBOARD_EVENT_SCHEDULED =
  "MENTOR_DASHBOARD_EVENT_SCHEDULED";
export const MENTOR_DASHBOARD_NEW_IMAGE = "MENTOR_DASHBOARD_NEW_IMAGE";
export const MENTOR_DASHBOARD_MENTOR_DELETED =
  "MENTOR_DASHBOARD_MENTOR_DELETED";
export const MENTOR_DASHBOARD_MENTOR_EDIT_MENTORSHIP =
  "MENTOR_DASHBOARD_MENTOR_EDIT_MENTORSHIP";
export const MENTOR_DASHBOARD_NOTIFICATIONS = "MENTOR_DASHBOARD_NOTIFICATIONS";
export const MENTOR_DASHBOARD_GET_INVITATION_STATUS =
  "MENTOR_DASHBOARD_GET_INVITATION_STATUS";
export const MENTOR_PROFILE_TOGGLE = "MENTOR_PROFILE_TOGGLE";
export const MENTOR_VIDEOCALL_TOKEN = "MENTOR_VIDEOCALL_TOKEN";
export const MENTOR_VIDEOCALL_TOKEN_ERROR = "MENTOR_VIDEOCALL_TOKEN_ERROR";
export const RESET_MENTOR_VIDEOCALL_TOKEN = "RESET_MENTOR_VIDEOCALL_TOKEN";
export const MENTOR_SINGLE_SESSION = "MENTOR_SINGLE_SESSION";
export const POST_REVIEW_DATA = "POST_DATA";
export const POST_ONBOARDING_FIRST = "POST_ONBOARDING_FIRST"
export const POST_ONBOARDING_FIRST_TEMP = "POST_ONBOARDING_FIRST_TEMP"


//admin
export const PUT_REVOKE = "PUT_REVOKE";
export const PUT_INVITE = "PUT_INVITE";
export const LOGIN = "LOGIN"
export const CHECK = "CHECK"

export const GET_SEATS_INFO = "GET_SEATS_NO";
