import React from "react";
import Footer from "../layout/Footer";
import Navbar from "../layout/Navbar";
import "../../../styles/mobile-popup.css";

import ModalImage from "../../../assets/modal-content-not-available-on-mobile.svg";

const NotAccessibleForMobile = () => {
  return (
    <div>
      <Navbar loginColor="#2C205B" />

      <div
        style={{
          height: "740px",
          paddingTop: "0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          margin: "0 20px",
        }}
      >
        <div className="mobile-modal-content-text">
          <h2 className="modal-content-title">Oops!</h2>
          <p className="modal-first-paragragh">
            Cardo is not available on mobile. Please log in or apply via your
            web browser on your Mac or PC.
          </p>
          <img
            className="modal_image"
            src={ModalImage}
            alt="not-available-on-mobile"
          />
          <p className="modal-second-paragragh">
            We want to make sure our mentors and mentees have the best
            experience possible. We’ve found that mobile communication is often
            filled with distractions, so we encourage mentorship in environments
            with fewer distractions!
          </p>
        </div>
      </div>
      <Footer
        wavebg={"transparent"}
        bgcolor={"#2c205b"}
        copywritebg={"#211845"}
      />
    </div>
  );
};

export default NotAccessibleForMobile;
