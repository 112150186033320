import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

const MenteeDashboardFindMentorFilter = (props) => {
  const { mentorFilter, handleMentorFilter } = props;
  const [isAvailabilityOpen, setIsAvailabilityOpen] = useState(false);
  const [isSessionType, setIsSessionType] = useState(false);
  const toggleAvailabilityOpen = () =>
    setIsAvailabilityOpen(!isAvailabilityOpen);
  const toggleSessionType = () => setIsSessionType(!isSessionType);
  const [filter, setFilter] = useState("");

  const filterChange = (e) => {
    setFilter(e.target.value);
    let filters = document.getElementsByClassName("filterSkill");
    let search = e.target.value;

    for (var i = 0; i < filters.length; i++) {
      let txtValue = filters[i].textContent || filters[i].innerText;
      if (
        txtValue.substr(0, search.length).toUpperCase() ===
          search.toUpperCase() &&
        search.length > 0
      ) {
        filters[i].style.display = "flex";
      } else {
        filters[i].style.display = "none";
      }
      if (search.length <= 0) {
        filters[i].style.display = "flex";
      }
    }
  };
  const filterMentors = (e) => {
    let mentorSkillsArray = mentorFilter.skillFilter;

    let mentorSkills = document.getElementsByClassName(
      "mentee-dashboard-find-mentor-card-item-skill"
    );
    let innerText = e.target.innerText;
    // pushing to array

    if (mentorFilter.skillFilter.includes(innerText.trim())) {
      e.target.style.background = "transparent";
      e.target.style.border = "1px solid #C4C4C4";
      e.target.style.color = "#C4C4C4";
      const skillindex = mentorSkillsArray.indexOf(innerText);
      mentorSkillsArray.splice(skillindex, 1);
    } else {
      e.target.style.background = "#EEEEFB";
      e.target.style.border = "1px solid #EEEEFB";
      e.target.style.color = "#5555DB";

      mentorSkillsArray.push(innerText);
    }
    handleMentorFilter(mentorSkillsArray);
  };

  return (
    <div className="display-flex justify-content-between align-items-center m-25">
      <div
        className="display-flex font-Neometric-Medium"
        style={{ marginLeft: "20px" }}
      >
        <span style={{ lineHeight: "1" }}>Consulting </span>
        <i
          className="fas fa-caret-right"
          style={{
            color: "#C8C8C8",
            margin: "0px 5px",
            display: "flex",
            alignItems: "center",
            paddingBottom: "2px",
          }}
        ></i>
        <span style={{ lineHeight: "1" }}>
          {" "}
          {props.menteeDashboardProfileInfo?.preferred_trail}
        </span>
        <div className="tooltip">
          ?
          <div className="tooltiptext">
            You have applied for the{" "}
            {props.menteeDashboardProfileInfo?.preferred_trail} trail. If you
            believe this is a mistake or you would like to change your trail,
            please reach out to our support team. Please note that mentees can
            only access one trail at a time.
          </div>
        </div>
      </div>
      <div className="mentee-dashboard-find-mentor-filter-container">
        <div>
          <div
            className="mentee-dashboard-find-mentor-filter"
            onClick={toggleSessionType}
          >
            Filter by session type <i className="fas fa-angle-down ml-10"></i>
          </div>
          <div
            style={{ width: "310px", padding: "15px" }}
            className={`mentee-dashboard-find-mentor-filter-session-type-dropdown-body ${
              isSessionType && "open"
            }`}
          >
            <div>
              <div
                style={{
                  borderBottom: "1px solid #C5C5C5",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  color: "#C4C4C4",
                  padding: "7px 0",
                }}
              >
                <i
                  style={{
                    fontSize: "12px",
                  }}
                  className="fas fa-search "
                ></i>
                <input
                  className="filterInput"
                  style={{
                    paddingLeft: "8px",
                    border: "0",
                    borderBottom: "0",
                    fontSize: "12px",
                    width: "296px",
                  }}
                  type="text"
                  id="filterText"
                  placeholder="Search"
                  onChange={filterChange}
                  value={filter}
                ></input>
              </div>
              <div className="filterSkill-container">
                <div key="1" onClick={filterMentors} className="filterSkill">
                  Case Interview
                </div>

                <div key="2" onClick={filterMentors} className="filterSkill">
                  Industry Overview
                </div>

                <div key="3" onClick={filterMentors} className="filterSkill">
                  Fit Interview
                </div>

                <div key="4" onClick={filterMentors} className="filterSkill">
                  Resume Review
                </div>

                <div key="5" onClick={filterMentors} className="filterSkill">
                  LinkedIn Tips
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps)(MenteeDashboardFindMentorFilter);
