import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import currencyIcon from "../../../assets/dashboard/header/Vector-7.svg";
import notificationIcon from "../../../assets/dashboard/header/Vector-8.svg";
import { logoutUser } from "../../../state-management/actions/authActions";
import { getCurrentUser } from "../../../state-management/actions/dashboard/mentee-dashboard/dashboardActions";
import ProfileNotification from "../profile/profile-components/ProfileNotification";

const DashboardHeader = (props) => {
  const { currentUser, handleCurrentLink } = props;
  const profileLink = `/${currentUser.type}/profile`;
  const [isOpen, setOpen] = useState(false);

  const toggleDropdown = () => setOpen(!isOpen);
  useEffect(() => {
    props.getCurrentUser();
  }, []);
  const onLogout = () => {
    props.logoutUser();
    props.history.push("/");
  };

  return (
    <div className="dashboard-header">
      <div className="dashboard-header-container">
        {currentUser.type !== "mentor" && (
          <div className="dashboard-header-currency">
            <span className="mr-5">
              <img src={currencyIcon} alt="" />
            </span>
            {currentUser.points}
          </div>
        )}

        <ProfileNotification
          currentUser={currentUser}
          handleCurrentLink={handleCurrentLink}
        />

        <div
          className="dashboard-header-current-user-dropdown"
          onClick={toggleDropdown}
        >
          <div
            className="dashboard-header-current-user"
            style={{ background: isOpen ? "#fafafa" : "white" }}
          >
            <div
              className="dashboard-header-user-avatar mr-10"
              style={{
                backgroundImage: `url(${currentUser.imgUrl})`,
              }}
            />
            <span className="font-Neometric-Regular">
              {currentUser.lastName
                ? `${currentUser.firstName} ${currentUser.lastName
                    .substring(0, 1)
                    .toUpperCase()}.`
                : ""}
            </span>
            <i className="fas fa-angle-down ml-10"></i>
          </div>
          <div
            className={`dashboard-header-current-user-dropdown-body ${
              isOpen && "open"
            }`}
          >
            <Link
              to={profileLink}
              style={{ textDecoration: "none", color: "black" }}
            >
              <div className="dashboard-header-current-user-dropdown-item">
                <div className="dashboard-header-current-user-dropdown-item-before"></div>
                <div>Edit Profile</div>
              </div>
            </Link>
            <div
              className="dashboard-header-current-user-dropdown-item"
              onClick={onLogout}
            >
              <div className="dashboard-header-current-user-dropdown-item-before"></div>
              <div>Logout</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  currentUser: state.auth.currentUser,
});
export default connect(mapStateToProps, { logoutUser, getCurrentUser })(
  withRouter(DashboardHeader)
);
