import React, { useState } from "react";
import validate from "../../../../helper/validate";
import MenteeSignupStep1Welcome from "./steps/MenteeSignupStep1Welcome";
import MenteeSignupStep2 from "./steps/MenteeSignupStep2";
import MenteeSteps from "./steps/MenteeSteps";
import { countries } from "../../../../helper/countries";
import ReactHtmlParser from "react-html-parser";
import MenteeSignupStep3OurGoals from "./steps/MenteeSignupStep3OurGoals";
import MenteeSignupStep4YourBackground from "./steps/MenteeSignupStep4YourBackground";
import MenteeSignupStep5OurMission from "./steps/MenteeSignupStep5OurMission";
import MenteeSignupStep6Video from "./steps/MenteeSignupStep6Video";
import MenteeSignupStep7Verify from "./steps/MenteeSignupStep7Verify";
import LinkedInLogin from "../login-with-linkedin/LinkedInLogin";
import { helper } from "../../../../helper/helper";
import { connect } from "react-redux";
import { postSignupMenteeJotform } from "../../../../state-management/actions/authActions";

const MenteeSignupForm = (props) => {
  const { menteeForm } = props;
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const handleLoading = (loadingValue) => {
    setLoading(loadingValue);
  };
  const initialFormState = {
    stepZero: {
      q73_ltpgtthanksFor: {
        name: `q${menteeForm[3].qid}_${menteeForm[3].name}`,
        description: menteeForm[3].description,

        required: false,
        text: menteeForm[3].text,
        type: "control_text",
      },
    },
    stepOne: {
      q131_input131: {
        name: `q${menteeForm[5].qid}_${menteeForm[5].name}`,
        description: menteeForm[5].description,

        required: false,
        text: menteeForm[5].text,
        type: "control_text",
      },
    },
    stepTwo: {
      q68_whichCountry: {
        name: `q${menteeForm[8].qid}_${menteeForm[8].name}`,
        value: menteeForm[8].selected,
        required: true,
        description: menteeForm[8].description,
        type: "select",
        text: menteeForm[8].text,
        selected: menteeForm[8].selected,
        choices: ReactHtmlParser(countries),
      },

      q12_whichIndustry: {
        name: `q${menteeForm[10].qid}_${menteeForm[10].name}`,
        description: menteeForm[10].description,
        text: menteeForm[10].text,
        value: "Consulting",
        inputValues: [
          "Consulting",
          "Finance (Coming Soon)",
          "Product (Coming Soon)",
        ],
        required: true,
        type: "radio",
        checked: true,
      },
      q13_trail: {
        name: `q${menteeForm[11].qid}_${menteeForm[11].name}`,
        required: true,
        description: menteeForm[11].description,

        value: "",
        text: menteeForm[11].text,
        inputValues: menteeForm[11].options.split("|"),
        type: "radio",
        checked: false,
      },
      q79_whatType: {
        name: `q${menteeForm[12].qid}_${menteeForm[12].name}`,
        required: true,
        value: "",
        description: menteeForm[12].description,

        text: menteeForm[12].text,
        inputValues: menteeForm[12].options.split("|"),
        type: "radio",
        checked: false,
      },
      q77_whichAreas: {
        name: `q${menteeForm[13].qid}_${menteeForm[13].name}`,
        required: true,
        value: [],
        description: menteeForm[13].description,

        displayValues: [
          "Industry Overview",
          "Resume Review",
          "LinkedIn Tips",
          "Fit Interview",
          "Case Interview",
        ],
        checked: false,
        text: menteeForm[13].text,
        inputValues: menteeForm[13].options.split("|"),
        type: "checkbox",
      },
      q24_whereAre24: {
        name: `q${menteeForm[14].qid}_${menteeForm[14].name}`,
        description: menteeForm[14].description,

        checked: false,
        required: true,
        value: "",
        text: menteeForm[14].text,
        inputValues: menteeForm[14].options.split("|"),
        type: "radio",
      },
      q25_pleaseAttach: {
        name: `q${menteeForm[15].qid}_${menteeForm[15].name}`,
        description: menteeForm[15].description,
        value: {},
        required: true,
        file: true,
        text: menteeForm[15].text,
        fileName: "No file chosen",
        type: "file",
        buttonText: menteeForm[15].buttonText,
        allowedTypes: ["pdf", "doc", "docx"],
        maxFileSize: 5000,
      },
    },
    stepThree: {
      q23_whichOf: {
        name: `q${menteeForm[18].qid}_${menteeForm[18].name}`,
        description: menteeForm[18].description,
        checked: false,
        required: true,
        value: "",
        text: menteeForm[18].text,
        inputValues: menteeForm[18].options.split("|"),
        type: "radio",
      },
      q111_wereYou: {
        name: `q${menteeForm[19].qid}_${menteeForm[19].name}`,
        description: menteeForm[19].description,
        checked: false,
        value: "",
        text: menteeForm[19].text,
        inputValues: menteeForm[19].options.split("|"),
        type: "radio",
      },
      q96_whoIs: {
        name: `q${menteeForm[20].qid}_${menteeForm[20].name}`,
        description: menteeForm[20].description,
        value: "",
        required: false,
        type: "text",
        placeholder: "",
        text: menteeForm[20].text,
      },
      q114_whereDid: {
        name: `q${menteeForm[21].qid}_${menteeForm[21].name}`,
        description: menteeForm[21].description,
        value: "",
        required: true,
        type: "text",
        placeholder: "",
        text: menteeForm[21].text,
      },
      q28_whatIswas: {
        name: `q${menteeForm[22].qid}_${menteeForm[22].name}`,
        description: menteeForm[22].description,
        value: "",
        type: "number",
        maxValue: menteeForm[22].maxValue + 1,
        minValue: menteeForm[22].minValue,
        hint: menteeForm[22].hint,
        text: menteeForm[22].text,
        stepIncrement: "0.1",
      },
    },
    stepFour: {
      q80_areYou80: {
        name: `q${menteeForm[25].qid}_${menteeForm[25].name}`,
        description: menteeForm[25].description,
        checked: false,
        required: true,
        value: "",
        text: menteeForm[25].text,
        inputValues: menteeForm[25].options.split("|"),
        type: "radio",
      },
      q81_doYou81: {
        name: `q${menteeForm[26].qid}_${menteeForm[26].name}`,
        description: menteeForm[26].description,
        checked: false,
        required: true,
        value: "",
        text: menteeForm[26].text,
        inputValues: menteeForm[26].options.split("|"),
        type: "radio",
      },
      q116_areYou116: {
        name: `q${menteeForm[27].qid}_${menteeForm[27].name}`,
        description: menteeForm[27].description,
        checked: false,
        required: true,
        value: "",
        text: menteeForm[27].text,
        inputValues: menteeForm[27].options.split("|"),
        type: "radio",
      },
      q117_areYou117: {
        name: `q${menteeForm[28].qid}_${menteeForm[28].name}`,
        description: menteeForm[28].description,
        checked: false,
        required: true,
        value: "",
        text: menteeForm[28].text,
        inputValues: menteeForm[28].options.split("|"),
        type: "radio",
      },
      q115_doYou115: {
        name: `q${menteeForm[29].qid}_${menteeForm[29].name}`,
        description: menteeForm[29].description,

        checked: false,
        required: true,
        value: "",
        text: menteeForm[29].text,
        inputValues: menteeForm[29].options.split("|"),
        type: "radio",
      },
    },
    stepFive: {
      q90_input90: {
        name: `q${menteeForm[32].qid}_${menteeForm[32].name}`,
        description: menteeForm[32].description,
        text: menteeForm[32].text,
        type: "control_text",
      },
      q6_uploadYour: {
        name: `q${menteeForm[33].qid}_${menteeForm[33].name}`,
        description: menteeForm[33].description,
        value: {},
        required: true,
        file: true,
        text: menteeForm[33].text,
        fileName: "No file chosen",
        type: "file",
        buttonText: menteeForm[33].buttonText,
        allowedTypes: ["mp4", "wma", "mpg", "flv", "avi"],
        // maxFileSize: 5000,
      },
    },
    // stepSix: {
    //   q84_input84: {
    //     name: `q${menteeForm[36].qid}_${menteeForm[36].name}`,
    //     text: menteeForm[36].text,
    //     type: "control_text",
    //   },
    //   q64_areYou: {
    //     name: `q${menteeForm[37].qid}_${menteeForm[37].name}`,
    //     checked: false,
    //     required: true,
    //     value: "",
    //     text: menteeForm[37].text,
    //     inputValues: menteeForm[37].options.split("|"),
    //     type: "radio",
    //   },
    // },
  };
  const [formData, setFormData] = useState(initialFormState);
  const [errors, setErrors] = useState({});

  const radioChange = (step, e, val) => {
    setFormData((prev) => ({
      ...prev,
      ...(prev[step][e.target.name].checked = true),
    }));
    e.persist();
    setFormData((prev) => ({
      ...prev,
      [step]: {
        ...prev[step],

        [e.target.name]: {
          ...prev[step][e.target.name],
          value: e.target.value,
        },
      },
    }));
  };
  const onCheckboxChange = (step, e, val) => {
    setFormData((prev) => ({
      ...prev,
      ...(prev[step][e.target.name].checked = true),
    }));
    e.persist();
    let elm = e.target.value;
    let arr = [...formData[step][e.target.name].value];

    if (arr.includes(elm)) {
      let index = arr.indexOf(elm);
      arr.splice(index, 1);
    } else {
      arr.push(elm);
    }
    setFormData((prev) => ({
      ...prev,
      [step]: {
        ...prev[step],
        [e.target.name]: {
          ...prev[step][e.target.name],
          value: arr,
        },
      },
    }));
  };

  const handleRequired = (step, inputsRequired, inputsNotRequired) => {
    inputsRequired.forEach((input) => {
      setFormData((prev) => ({
        ...prev,
        [step]: {
          ...prev[step],
          [input.name]: {
            ...prev[step][input.name],
            required: true,
          },
        },
      }));
    });
    inputsNotRequired.forEach((input) => {
      setFormData((prev) => ({
        ...prev,
        [step]: {
          ...prev[step],
          [input.name]: {
            ...prev[step][input.name],
            required: false,
            value: "",
          },
        },
      }));
    });
  };

  const changeHandler = (step, e) => {
    e.persist();

    setFormData((prev) => ({
      ...prev,
      [step]: {
        ...prev[step],
        [e.target.name]: {
          ...prev[step][e.target.name],
          value: e.target.value,
        },
      },
    }));
  };

  const fileChangeHandler = (e, name, file, step) => {
    var file = file;

    var reader = new FileReader();

    reader.onloadend = () => {
      var media = new Audio(reader.result);
      media.onloadedmetadata = function () {
        // console.log(media.duration); //this would give duration of the video/audio file
      };
    };

    setFormData((prev) => ({
      ...prev,
      [step]: {
        ...prev[step],
        [name]: {
          ...prev[step][name],
          value: file,
          fileName: file.name ? file.name : "No file chosen",
        },
      },
    }));
  };
  const resetForm = () => {
    setFormData({ ...initialFormState });
  };
  const iconClickHandle = (stepNo, e) => {
    // e.preventDefault();
    // setStep(stepNo);
  };
  const stepChangeHandler = (values, e) => {
    e.preventDefault();
    window.scrollTo(0, 0);

    const newErrors = validate(values);
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      setStep(step + 1);
    }
  };
  const onPrevStep = (step) => {
    window.scrollTo(0, 0);
    setStep(step);
  };

  const [linkedinStatus, setLinkedinStatus] = useState({
    error: "",
    code: "",
  });

  const handleLinkedInSuccess = (data) => {
    setLinkedinStatus({
      code: data.code,
      error: "",
    });
    submitHandler(data.code);
  };
  const customLinkedInError = (msg) => {
    setLinkedinStatus({
      code: "",
      error: msg,
    });
  };
  const handleLinkedInFailure = (error) => {
    setLinkedinStatus({
      code: "",
      error: error.errorMessage,
    });
  };
  const submitHandler = (code) => {
    // e.preventDefault();
    handleLoading(true);

    // const { code } = linkedinStatus;
    if (code !== "") {
      const data = new FormData();

      data.append("q34_whatAre", "Mentee");
      data.append(
        "q73_ltpgtthanksFor",
        formData.stepZero.q73_ltpgtthanksFor.value
      );
      data.append("q131_input131", formData.stepOne.q131_input131.value);
      data.append("q68_whichCountry", formData.stepTwo.q68_whichCountry.value);
      data.append(
        "q12_whichIndustry",
        formData.stepTwo.q12_whichIndustry.value
      );
      data.append("q13_trail", formData.stepTwo.q13_trail.value);
      data.append("q79_whatType", formData.stepTwo.q79_whatType.value);
      for (let i = 0; i < formData.stepTwo.q77_whichAreas.value.length; i++) {
        data.append(
          "q77_whichAreas[]",
          formData.stepTwo.q77_whichAreas.value[i]
        );
      }

      data.append("q24_whereAre24", formData.stepTwo.q24_whereAre24.value);
      data.append("q25_pleaseAttach", formData.stepTwo.q25_pleaseAttach.value);
      data.append("q23_whichOf", formData.stepThree.q23_whichOf.value);
      data.append("q111_wereYou", formData.stepThree.q111_wereYou.value);
      data.append("q96_whoIs", formData.stepThree.q96_whoIs.value);
      data.append("q114_whereDid", formData.stepThree.q114_whereDid.value);
      data.append("q28_whatIswas", formData.stepThree.q28_whatIswas.value);
      data.append("q80_areYou80", formData.stepFour.q80_areYou80.value);
      data.append("q81_doYou81", formData.stepFour.q81_doYou81.value);
      data.append("q116_areYou116", formData.stepFour.q116_areYou116.value);
      data.append("q117_areYou117", formData.stepFour.q117_areYou117.value);

      data.append("q115_doYou115", formData.stepFour.q115_doYou115.value);

      data.append("q6_uploadYour", formData.stepFive.q6_uploadYour.value);

      // data.append("q84_input84", formData.stepSix.q84_input84.value);
      // data.append("q64_areYou", formData.stepSix.q64_areYou.value);
      props.postSignupMenteeJotform(
        data,
        code,
        handleLoading,
        formData.stepTwo.q25_pleaseAttach.value,
        formData
      );
    } else {
      setLinkedinStatus({
        code: "",
        error: "Something went wrong, please refresh and try again",
      });
    }
    // In here you can send data to some api
    // For example if you have some redux action: sendData(data)
  };

  return (
    <div>
      <form
        onSubmit={submitHandler}
        id={`form_id_${helper.menteeFormId}`}
        encType="multipart/form-data"
        name={`form_${helper.menteeFormId}`}
      >
        <MenteeSteps
          step={step}
          iconClickHandle={iconClickHandle}
          data={formData}
        >
          {step === 0 && (
            <MenteeSignupStep1Welcome
              data={formData.stepZero}
              onChange={changeHandler}
              onRadioChange={radioChange}
              onCheckboxChange={onCheckboxChange}
              onStepChange={stepChangeHandler}
              errors={errors}
              stepKey="stepZero"
              title={"Welcome"}
              step={0}
            />
          )}
          {step === 1 && (
            <MenteeSignupStep2
              data={formData.stepOne}
              onChange={changeHandler}
              onRadioChange={radioChange}
              onCheckboxChange={onCheckboxChange}
              onStepChange={stepChangeHandler}
              onPrevStep={onPrevStep}
              errors={errors}
              stepKey="stepOne"
              title={""}
              step={1}
            />
          )}
          {step === 2 && (
            <MenteeSignupStep3OurGoals
              data={formData.stepTwo}
              onChange={changeHandler}
              onRadioChange={radioChange}
              onCheckboxChange={onCheckboxChange}
              onFileChange={fileChangeHandler}
              onStepChange={stepChangeHandler}
              onPrevStep={onPrevStep}
              errors={errors}
              stepKey="stepTwo"
              title={"Your Goals"}
              step={2}
            />
          )}
          {step === 3 && (
            <MenteeSignupStep4YourBackground
              data={formData.stepThree}
              onChange={changeHandler}
              onRadioChange={radioChange}
              onCheckboxChange={onCheckboxChange}
              onFileChange={fileChangeHandler}
              onStepChange={stepChangeHandler}
              handleRequired={handleRequired}
              onPrevStep={onPrevStep}
              errors={errors}
              stepKey="stepThree"
              title={"Your Background"}
              step={3}
            />
          )}
          {step === 4 && (
            <MenteeSignupStep5OurMission
              data={formData.stepFour}
              onChange={changeHandler}
              onRadioChange={radioChange}
              onCheckboxChange={onCheckboxChange}
              onFileChange={fileChangeHandler}
              onStepChange={stepChangeHandler}
              onPrevStep={onPrevStep}
              errors={errors}
              stepKey="stepFour"
              title={"Our Mission"}
              step={4}
            />
          )}
          {step === 5 && (
            <MenteeSignupStep6Video
              data={formData.stepFive}
              onChange={changeHandler}
              onRadioChange={radioChange}
              onCheckboxChange={onCheckboxChange}
              onFileChange={fileChangeHandler}
              onStepChange={stepChangeHandler}
              onPrevStep={onPrevStep}
              errors={errors}
              stepKey="stepFive"
              title={"Video"}
              step={5}
            />
          )}
          {/* {step === 6 && (
            <MenteeSignupStep7Verify
              data={formData.stepSix}
              onChange={changeHandler}
              onRadioChange={radioChange}
              onCheckboxChange={onCheckboxChange}
              onFileChange={fileChangeHandler}
              onStepChange={stepChangeHandler}
              onPrevStep={onPrevStep}
              errors={errors}
              stepKey="stepSix"
              title={"Verify"}
              step={6}
            />
          )} */}
          {step === 6 && (
            <LinkedInLogin
              onPrevStep={onPrevStep}
              linkedinStatus={linkedinStatus}
              handleLinkedInSuccess={handleLinkedInSuccess}
              handleLinkedInFailure={handleLinkedInFailure}
              customLinkedInError={customLinkedInError}
              loading={loading}
              title={"Verify"}
              step={7}
            />
          )}
        </MenteeSteps>
      </form>
    </div>
  );
};

export default connect(null, { postSignupMenteeJotform })(MenteeSignupForm);
