import React from "react";
import leftColImg from "../../../../assets/sign-up/status/Group 708.svg";
import "../../../../styles/signup/sign-up-status.css";
import lightLogo from "../../../../assets/sign-up/Group 396.svg";
import { Link } from "react-router-dom";

const SignupStatusPending = () => {
  return (
    <div className="sign-up-status-pending">
      <div className="position-relative">
        <div className="signup-logo-container">
          <Link to="/">
            <img src={lightLogo} alt="" />
          </Link>
        </div>
      </div>
      <div className="row sign-up-status-row">
        <div className="col-6 sign-up-status-leftCol">
          <div className="sign-up-status-leftCol-container">
            <img src={leftColImg} alt="" />
          </div>
        </div>
        <div className="col-6 sign-up-status-rightCol">
          <div className="sign-up-status-rightCol-container">
            <div className="sign-up-status-desc-container">
              <div className="sign-up-status-desc">
                <h3 className="sign-up-status-rightCol-title">
                  Thank you for applying to Cardo!
                </h3>
                <br /> <br /> <br />
                <p className="sign-up-status-rightCol-desc">
                  Your application has been received and is in review. If you’re
                  a good fit for Cardo, we'll be in touch shortly to discuss
                  next steps. <br /> <br /> In the meantime, check out Find Your
                  Mentor and Become a Mentor for more insights on how Cardo
                  works! <br /> <br /> Feel free to reach out to us at
                  support@hellocardo.org if you have any questions!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupStatusPending;
