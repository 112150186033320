import React from 'react';
import FileDownloadIcon from "../../../../../../icons/FileDownloadIcon";

export function formatFileSize(bytes, suffixIndex = 0) {
    const suffixes = ['bytes', 'KB', 'MB', 'GB'];
    if (bytes < 1000) return +bytes.toFixed(2) + ' ' + suffixes[suffixIndex];
    return formatFileSize(bytes / 1024, suffixIndex + 1);
}

export default function MediaMessage({media}) {
    const handleClick = () => {
        media.getContentTemporaryUrl().then(url => {
            const anchorEl = document.createElement('a');

            anchorEl.href = url;
            anchorEl.target = '_blank';
            anchorEl.rel = 'noopener';

            // setTimeout is needed in order to open files in iOS Safari.
            setTimeout(() => {
                anchorEl.click();
            });
        });
    };

    return (
        <div onClick={handleClick}>
            <div>
                <FileDownloadIcon/>
            </div>
            <div>
                <p>{media.filename}</p>
                <p>{formatFileSize(media.size)} - Click to open</p>
            </div>
        </div>
    );
}
