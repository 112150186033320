import React from "react";
import "../../../../../styles/dashboard/admin-dasboard/calendly-tab/calendly-seats.css";

const CalendlySeats = ({ seatsInUse, remainingSeats }) => {
  return (
    <div className="calendly_top_cards_wrapper">
      <div className="calendly_seats_card_second_container">
        <div className="calendly_seats_card_second">
          <h1 className="calendly_seats_wrapper calendly_seats_seatsInUse">
            {+seatsInUse - 1}
          </h1>
          <p className="calendly_seats_wrapper">Seats In Use</p>
        </div>
        <div className="calendly_seats_card_second">
          <h1 className="calendly_seats_wrapper calendly_seats_remainingSeats">
            {remainingSeats}
          </h1>
          <p className="calendly_seats_wrapper">Remaining Seats</p>
        </div>
      </div>
    </div>
  );
};

export default CalendlySeats;
