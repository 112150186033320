import axios from "axios";
import { helper } from "../../../../helper/helper";
import {
  MENTOR_DASHBOARD_HOME,
  GET_CURRENT_USER,
  MENTOR_DASHBOARD_MY_SESSIONS,
  MENTOR_DASHBOARD_NOTIFICATIONS,
  MENTOR_VIDEOCALL_TOKEN,
  POST_REVIEW_DATA,
  RESET_MENTOR_VIDEOCALL_TOKEN,
  MENTOR_SINGLE_SESSION,
  MENTOR_VIDEOCALL_TOKEN_ERROR,
  POST_ONBOARDING_FIRST,
  POST_ONBOARDING_FIRST_TEMP
} from "../../../types/types";
import { throwCustomError } from "../../errorActions";

export const getCurrentUser = () => async (dispatch) => {
  await axios
    .get(`${helper.baseUrl}/users/mentee/dashboard/getUserInfo`, {
      withCredentials: true,
    })
    .then((res) => {
      dispatch({
        type: GET_CURRENT_USER,
        payload: res.data,
      });
    })
    .catch((err) => console.log(err.response));
};
export const getMentorDashboardHomeData = () => (dispatch) => {
  axios
    .get(
      `${helper.baseUrl}/users/mentor/dashboard/getMentorDashboardHomePage`,
      { withCredentials: true }
    )
    .then((res) =>
      dispatch({
        type: MENTOR_DASHBOARD_HOME,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch(
        throwCustomError(
          "somethingWentWrong",
          err.response.status,
          err.response.data
        )
      )
    );
};

export const getMentorDashboardMySessionsData = () => (dispatch) => {
  axios
    .get(
      `${helper.baseUrl}/users/mentor/dashboard/getMentorDashboardMySessionsPage`,
      { withCredentials: true }
    )
    .then((res) => {
      dispatch({
        type: MENTOR_DASHBOARD_MY_SESSIONS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(
        throwCustomError(
          "somethingWentWrong",
          err.response.status,
          err.response.data
        )
      );
    });
};

export const getNotifications = () => (dispatch) => {
  axios
    .get(`${helper.baseUrl}/users/mentor/dashboard/getUserNotfications`, {
      withCredentials: true,
    })
    .then((res) => {
      dispatch({
        type: MENTOR_DASHBOARD_NOTIFICATIONS,
        payload: res.data,
      });
    })
    .catch((err) => console.log(err.response));
};

export const readNotifications = (notficationsIds) => (dispatch) => {
  axios
    .put(
      `${helper.baseUrl}/users/putReadNotfications`,
      { notficationsIds },
      { withCredentials: true }
    )
    .then((res) => { })
    .catch((err) => console.log(err.response));
};



export const getSingleSession = (sessionId) => (dispatch) => {
  axios
    .get(`${helper.baseUrl}/users/getSessionById`, {
      withCredentials: true,
      params: { sessionId },
    })
    .then((res) => {
      {
        dispatch({
          type: MENTOR_SINGLE_SESSION,
          payload: res.data,
        });
      }
    })
    .catch((err) => console.log(err.response));
};
export const getVideoCallToken = (sessionId) => dispatch => {
  let url = `${helper.baseUrl}/videoChat/getTwilioToken`;
  axios.get(url,
    { withCredentials: true, params: { sessionId } })
    .then(res => {
      {
        dispatch({
          type: MENTOR_VIDEOCALL_TOKEN,
          payload: res.data
        })
      }
    })
    .catch(err => {
      if (err.response) {
        if (err.response.status !== 200) {
          dispatch({
            type: MENTOR_VIDEOCALL_TOKEN_ERROR,
            payload: err.response.data.msg
          })
        }
      }
    })
}

export const postOnboardingFirstTime = () => dispatch => {
  axios.post(`${helper.baseUrl}/users/mentor/dashboard/postOnboardingFirstTime`, null,
    { withCredentials: true })
    .then(res => {
      {
        dispatch({
          type: POST_ONBOARDING_FIRST,
          payload: res.data
        })
      }
    })
    .catch(err => console.log(err.response))
}

export const postOnboardingFirstTimeTemp = () => dispatch => {
  axios.post(`${helper.baseUrl}/users/mentor/dashboard/postOnboardingFirstTimeTemp`, null,
    { withCredentials: true })
    .then(res => {
      {
        dispatch({
          type: POST_ONBOARDING_FIRST_TEMP,
          payload: res.data
        })
      }
    })
    .catch(err => console.log(err.response))
}


export const postData = (data) => dispatch => {
  axios.post(`${helper.baseUrl}/videoChat/postUserVideoCallSession`, data,
    { withCredentials: true })
    .then(res => {
      {
        dispatch({
          type: POST_REVIEW_DATA,
          payload: res.data
        })
      }
    })
    .catch(err => console.log(err.response))
}

export const resetVideoCallToken = () => (dispatch) => {
  dispatch({
    type: RESET_MENTOR_VIDEOCALL_TOKEN,
  });
};

export const sendInviteRequest = (data, handleSuccess, emailState, setDisableBtn, setBtnLoading) => (dispatch) => {
  axios
    .post(`${helper.baseUrl}/users/mentor/dashboard/postInviteFriend`, data, {
      withCredentials: true,
    })
    .then((res) => {
      console.log(res)
      if (res.status === 200) {
        handleSuccess(res.data.msg)
        setBtnLoading(false)
        emailState("")
      }
    })
    .catch((err) => {


      setDisableBtn(false)
      setBtnLoading(false)
      dispatch(
        throwCustomError(
          "somethingWentWrong",
          err.response.status,
          err.response.data
        )
      )

    }
    );
};
