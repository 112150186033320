import React from "react";
import moment from "moment-timezone";
const FirstSession = (props) => {
  const { upcomingSession, selectModalDetails, handleModalMentorDetails } =
    props;
  const handleDetailsClick = () => {
    selectModalDetails();
    handleModalMentorDetails(upcomingSession);
  };
  return (
    <div style={{ display: "flex", marginBottom: "15px" }}>
      <div style={{ width: "40px", marginTop: "5px" }}>
        <div className="font-Neometric-Medium" style={{ fontSize: "18px" }}>
          {moment(upcomingSession.start_time).format("DD")}
        </div>
        <div className="font-Neometric-Regular" style={{ fontSize: "14px" }}>
          {moment(upcomingSession.start_time).format("MMM")}
        </div>
      </div>
      <div
        style={{
          width: "860px",
          height: "120px",
          backgroundColor: "#FAC4DC",
          borderRadius: "10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ width: "785px", height: "82px", display: "flex" }}>
          <div style={{ width: "90px", display: "flex", alignItems: "center" }}>
            <div
              style={{
                width: "64px",
                height: "64px",
                borderRadius: "100%",
                backgroundSize: "cover",
                backgroundImage: `url(${upcomingSession.mentee.img_url})`,
              }}
            />
          </div>
          <div
            style={{
              color: "black",
              width: "210px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div>
              <div
                style={{ color: "black", marginBottom: "4px" }}
                className="font-Neometric-Medium"
              >
                {upcomingSession.mentee.first_name}{" "}
                {upcomingSession.mentee.last_name.substr(0, 1)}.
                {upcomingSession.mentee.status === "deleted" && "(Deleted)"}
              </div>
              <div
                style={{ color: "black", fontSize: "14px" }}
                className="font-eina"
              >
                {upcomingSession.mentee?.profile_info?.preferred_industry}
                <i
                  className="fas fa-caret-right"
                  style={{ margin: "0px 5px" }}
                ></i>
                {upcomingSession.mentee?.profile_info?.preferred_trail}
              </div>
            </div>
          </div>

          <div
            style={{
              width: "270px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div>
              {upcomingSession.mentee.status !== "deleted" ? (
                <>
                  <div
                    style={{
                      color: "black",
                      fontSize: "14px",
                      marginBottom: "8px",
                    }}
                    className="font-Neometric-Medium"
                  >
                    Time
                  </div>
                  <div
                    style={{ color: "black", fontSize: "16px" }}
                    className="font-Neometric-Regular"
                  >
                    {moment(upcomingSession.start_time)
                      .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
                      .format("LT")}{" "}
                    →{" "}
                    {moment(upcomingSession.end_time)
                      .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
                      .format("LT")}
                  </div>
                </>
              ) : (
                <div
                  style={{ color: "black", fontSize: "16px" }}
                  className="font-Neometric-Regular"
                >
                  Mentee has been deleted, you can cancel this session from your
                  calendly.
                </div>
              )}
            </div>
          </div>
          <div
            style={{
              width: "217px",
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              justifyContent:
                upcomingSession.mentee.status === "deleted"
                  ? "flex-end"
                  : "space-between",
              paddingTop: "15px",
            }}
          >
            <div>
              {upcomingSession.mentee.status !== "deleted" && (
                <a
                  href={upcomingSession.reschedule_url}
                  target="_blank"
                  className="font-Neometric-Regular"
                  style={{
                    fontSize: "14px",
                    color: "black",
                    borderRadius: "7px",
                    width: "117px",
                    height: "46px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#FFD2E6",
                    textDecoration: "none",
                  }}
                >
                  Reschedule
                </a>
              )}
              <a
                href={upcomingSession.cancel_url}
                target="_blank"
                className="font-Neometric-Regular"
                style={{
                  fontSize: "12px",
                  color:
                    upcomingSession.mentee.status === "deleted"
                      ? "black"
                      : "#CC92AC",
                  width: "117px",
                  height: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textDecoration: "underline",
                  marginTop: "12px",
                }}
              >
                Cancel
              </a>
            </div>
            {upcomingSession.mentee.status !== "deleted" && (
              <div
                className="font-Neometric-Regular"
                style={{
                  fontSize: "14px",
                  color: "black",
                  borderRadius: "7px",
                  width: "84px",
                  height: "46px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "white",
                  cursor: "pointer",
                }}
                onClick={handleDetailsClick}
              >
                Details
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FirstSession;
