import React, { useState } from "react";
import { usePopup } from "../../../../../hooks/usePopup";
import RadioBtnGroup from "../../profile-form-components/RadioBtnGroup";
import DeleteAccountConfirmationPopUp from "./DeleteAccountConfirmationPopUp";

const MenteeAccountTab = (props) => {
  const { accountData, handleRadioChange } = props;
  const [loading, setLoading] = useState(false);
  const handleLoading = (val) => {
    setLoading(val);
  };
  const { openPopupClick, openPopup } = usePopup(
    DeleteAccountConfirmationPopUp,
    "delete-account-confirmation-popup"
  );
  const { industry, trail } = accountData;
  return (
    <div className="mentee-profile-account">
      <h2>Mentorship Focus</h2>
      <div className="mentee-profile-form-wrapper">
        <div className="form-line">
          <RadioBtnGroup
            id="industry"
            label="Industry"
            name="industry"
            value={industry.value}
            inputValues={industry.inputValues}
            onChange={handleRadioChange}
            disabled
            toolTip={
              <div>
                We are currently only servicing the Consulting industry. Stay on
                the lookout for us to launch new industries!
              </div>
            }
          />
        </div>
        <div className="form-line">
          <RadioBtnGroup
            id="trail"
            label="Trail"
            name="trail"
            value={trail.value}
            inputValues={trail.inputValues}
            onChange={handleRadioChange}
            disabled
            toolTip={
              <div>
                Mentees are aligned to a trail based on their application. If
                you’d like to edit your Trail, please reach out to our support
                team.
              </div>
            }
          />
        </div>
      </div>
      <div className="mt-25">
        <div className="mt-25 mb-25">
          <h2>Account</h2>
        </div>
        <div className="mt-25 mb-25">
          <p className="delete-account-text">
            If you would like to delete your account, you can do so here.
            Account deletions are <br /> permanent and cannot be reversed.{" "}
            <br /> <br /> If you prefer to turn your profile off, you can do so
            via the toggle above
          </p>
        </div>
        <div>
          <button
            className="cardo-btn delete-account-btn"
            onClick={() => props?.handleDeletePopup()}
          >
            Delete Account
          </button>
        </div>
      </div>
    </div>
  );
};

export default MenteeAccountTab;
