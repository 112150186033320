import React from "react";
import "../../../styles/alert.css";
import { usePopup } from "../../../hooks/usePopup";
import { Link } from "react-router-dom";
import { useMobilePopup } from "../../../hooks/useMobilePopup";
import { useIsDesktop } from "../../../helper/useIsDesktop";

const Alert = (props) => {
  const { isDesktop } = useIsDesktop();
  const { openMobilePopupClick } = useMobilePopup("mobile-modal");

  const { openPopupClick } = usePopup();
  return (
    <div className="alert">
      <div className="alert-container">
        The wait is over!{" "}
        <Link
          className="cardo-link"
          style={{
            color: "white",
            fontWeight: "700",
            textDecoration: "underline",
          }}
          to={isDesktop ? "/sign-up" : "#"}
        >
          <span onClick={isDesktop ? () => {} : openMobilePopupClick}>
            Apply Now
          </span>
        </Link>{" "}
        for a chance to be a part of our public beta.
      </div>
    </div>
  );
};

export default Alert;
