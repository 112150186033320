import React, { Fragment, useEffect, useState } from "react";

const EditableRadio = (props) => {
  const {
    type = "radio",
    name,
    inputValue,
    onRadioChange,
    error,
    id,
    value,
    disabled,
    label,
    inputValues,
    stepKey,
  } = props;
  const [editableRadioValue, setEditableRadioValue] = useState(
    !inputValues.includes(value) ? value : ""
  );
  useEffect(() => {
    document.getElementById("q84_whereDid_radio_3").checked === false &&
      setEditableRadioValue("");
  }, [value]);
  return (
    <Fragment>
      <span className="form-radio-item">
        <span className="dragger-item"></span>{" "}
        <input
          id={id}
          className={error ? "radio is-danger" : "radio"}
          type={type}
          name={name}
          value={inputValue}
          checked={!inputValues.includes(value) && value !== ""}
          onChange={(e) => {
            onRadioChange(stepKey, e, value);
          }}
          disabled={disabled}
          onClick={(e) => {
            document.getElementById("q84_whereDid_radio_3_value").focus();
          }}
        />
        <label className={error ? "radio is-danger" : "radio"} htmlFor={id}>
          <span>
            <input
              id="q84_whereDid_radio_3_value"
              type="text"
              className="editable-form-radio"
              name={name}
              value={editableRadioValue}
              onChange={(e) => {
                setEditableRadioValue(e.target.value);
                onRadioChange(stepKey, e, value);
              }}
              onClick={(e) => {
                document.getElementById("q84_whereDid_radio_3").checked = true;
              }}
              placeholder="Other"
            />
          </span>
        </label>
        <div className="check"></div>
      </span>
    </Fragment>
  );
};

export default EditableRadio;
