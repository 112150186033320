import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import MentorProfile from "../../MentorProfile";
import ProfileBodyProgress from "../../profile-components/ProfileBodyProgress";
import {
  postEmailInvitation,
  getUserInvitationStatus,
  editMentorProfile,
} from "../../../../../state-management/actions/dashboard/mentor-dashboard/profileActions";

function MentorCalendar(props) {
  const [steps, setSteps] = useState({
    step1: false,
    step2: false,
    step3: false,
    step4: false,
  });

  const [email, setEmail] = useState(props.currentUser.email);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState();

  const handleImgPopup = (e) => {
    props.setImagepopup(true);
    props.setImgClass(
      e?.currentTarget?.classList[e.currentTarget.classList.length - 1]
    );
  };

  const onChange = (e) => {
    setEmail(e.target.value);
  };

  const submitEmail = (e) => {
    if (!loading) {
      setLoading(true);
      props.postEmailInvitation(email, setLoading, setSteps);
    }
  };
  useEffect(() => {
    props.getUserInvitationStatus(setLoading, setStatus);
  }, []);
  useEffect(() => {
    if (props?.mentorEmailInvitation?.status) {
      setStatus(props.mentorEmailInvitation.status);
    }

    if (props?.calendar?.status) {
      setEmail(props.calendar.email);
      setStatus(props.calendar.status);
      setSteps((prev) => {
        return { ...prev, step1: true };
      });
      if (props?.calendar?.status === "accepted") {
        setSteps((prev) => {
          return { ...prev, step2: true };
        });
      }
    }

    if (props?.invitationStatus?.status) {
      setStatus(props.invitationStatus.status);
    }
  }, [props]);

  useEffect(() => {
    if (status === "accepted") {
      props.editMentorProfile();
      props.getUserInvitationStatus(setLoading, setStatus);
    }
  }, [status]);
  return (
    <>
      <div className="calendar-connection" style={{ width: "870px" }}>
        <h2>Calendar Connection</h2>
        <p className="delete-account-text mt-10">
          Connect your calendar(s) via Calendly to let Cardo handle scheduling
          and avoid double bookings.
        </p>
        <div className="calendar-connection-details mentee-profile-form-wrapper">
          <div
            className="mentee-dashboard-home-accordian"
            style={{ position: "relative", left: "-60px" }}
          >
            <div className="display-flex calender-step">
              <div className="mentee-dashboard-home-title calendar-connection-icon">
                <i
                  className={`${
                    steps["step1"] ? "fas" : "far"
                  } fa-check-circle`}
                />
              </div>
              <div
                className="mentee-dashboard-home-content mentee-dashboard-home-content-open"
                style={{ paddingBottom: "40px" }}
              >
                <div className="mentee-dashboard-home-content-text mentee-dashboard-home-content-text-open">
                  <h3>Step 1: Connect A Calendly Account with Cardo</h3>
                  <p className="delete-account-text mt-10 mb-25">
                    Cardo provides a free Calendly Pro account to all of our
                    mentors, allowing you to easily <br className="deskBr" />{" "}
                    connect multiple calendars and ensure you never have a
                    double booking again.
                  </p>
                  <div>
                    <input
                      class="calendar_email_input"
                      disabled={props?.calendar?.email ? true : false}
                      value={email}
                      onChange={(e) => onChange(e)}
                      placeholder="Email"
                      style={{
                        border: "1px solid #5555DB",
                        width: "416px",
                        height: "50px",
                        padding: "0 12px",
                        // background: "#EEEEFB",
                        borderRadius: "10px",
                        // textAlign: "center",
                      }}
                    ></input>
                    <div
                      onClick={
                        props?.calendar?.email || steps.step1
                          ? () => {}
                          : (e) => submitEmail(e)
                      }
                      className={
                        props?.calendar?.email || steps.step1
                          ? "edit-profile-button cursor-disable"
                          : "edit-profile-button"
                      }
                    >
                      {loading
                        ? "Loading"
                        : props?.calendar?.email || steps.step1
                        ? "Already sent"
                        : "Send Invitation"}
                    </div>
                    {status && (
                      <div
                        style={{
                          fontFamily: "Neometric Regular",
                          marginTop: "10px",
                        }}
                      >
                        <span style={{ fontFamily: "Neometric Medium" }}>
                          Status
                        </span>
                        :
                        <span
                          style={{
                            marginLeft: "5px",
                            color: status === "accepted" ? "green" : "black",
                          }}
                        >
                          {status[0].toUpperCase() + status.slice(1)}
                        </span>
                      </div>
                    )}
                    {props?.errors?.msg && <div>{props.errors.msg}</div>}
                  </div>
                </div>
              </div>
            </div>
            <div className="display-flex calender-step">
              <div className="mentee-dashboard-home-title calendar-connection-icon">
                <i
                  className={`${
                    steps["step2"] ? "fas" : "far"
                  } fa-check-circle`}
                />
              </div>
              <div
                className="mentee-dashboard-home-content mentee-dashboard-home-content-open"
                style={{ paddingBottom: "40px" }}
              >
                <div className="mentee-dashboard-home-content-text mentee-dashboard-home-content-text-open">
                  <h3>
                    Step 2: Check your Email and Accept Your Calendly Invitation
                  </h3>
                  <div>
                    <div style={{ paddingRight: "10px" }}>
                      <p className="delete-account-text mt-10 mb-15">
                        Accept the invitation sent to the e-mail provided above
                        and follow the brief setup prompts to get your account
                        synced.
                      </p>
                      <div
                        onClick={(e) => {
                          handleImgPopup(e);
                        }}
                        className="edit-profile-checkbox calendar-connection-img-box new_calendly_accept_invite-bg"
                        style={{ width: "438px", height: "253px" }}
                      ></div>
                    </div>
                  </div>

                  {status === "pending" && (
                    <div
                      style={{
                        fontFamily: "Neometric Regular",
                        lineHeight: "20px",
                        marginTop: "10px",
                      }}
                    >
                      <span style={{ fontFamily: "Neometric Medium" }}>
                        Status
                      </span>
                      :
                      <span
                        style={{
                          marginLeft: "5px",
                          color: status === "accepted" ? "green" : "black",
                        }}
                      >
                        {status[0].toUpperCase() + status.slice(1)}
                      </span>
                      <div style={{ fontStyle: "italic" }}>
                        Still says “Pending”?{" "}
                        <span
                          style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          onClick={() => window.location.reload()}
                        >
                          Click here to refresh.
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="display-flex calender-step">
              <div className="mentee-dashboard-home-title calendar-connection-icon">
                <i
                  className={`${
                    steps["step3"] ? "fas" : "far"
                  } fa-check-circle`}
                />
              </div>
              <div
                className="mentee-dashboard-home-content mentee-dashboard-home-content-open"
                style={{ paddingBottom: "40px" }}
              >
                <div className="mentee-dashboard-home-content-text mentee-dashboard-home-content-text-open">
                  <h3>Step 3: Provide your availability</h3>
                  <p className="delete-account-text mt-10 mb-15">
                    Once you’ve been granted access to a Pro account with Cardo,
                    connect calendars and update availability
                  </p>
                  <div
                    style={{
                      border: "1px solid #D3D3D3",
                      display: "flex",
                      width: "95%",
                      borderRadius: "10px",
                      padding: "10px 18px",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        width: "42%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <h5
                          style={{
                            fontSize: "14px",
                            marginTop: "15px",
                            color: "black",
                            fontFamily: "Neometric Medium",
                          }}
                        >
                          Connect up to 6 Calendars
                        </h5>
                        <p className="calender-column-text mt-10 mb-15">
                          With Calendly Pro, you can connect multiple calendars
                          to reference your real-time availability. Choose a
                          default calendar and connect up to 5 more.
                        </p>
                      </div>
                      <div
                        onClick={(e) => {
                          handleImgPopup(e);
                        }}
                        className="edit-profile-checkbox calendar-connection-img-box connect_calendars_bg"
                      ></div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",

                        position: "relative",
                      }}
                    >
                      <div
                        className="font-Neometric-Bold"
                        style={{
                          position: "absolute",
                          bottom: "100px",
                          color: "#5555DB",
                          fontSize: "22px",
                          fontWeight: "600",
                        }}
                      >
                        AND
                      </div>
                    </div>
                    <div
                      style={{
                        width: "42%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <h5
                          style={{
                            fontSize: "14px",
                            marginTop: "15px",
                            color: "black",
                            fontFamily: "Neometric Medium",
                          }}
                        >
                          Verify Your Availability and Paramenters
                        </h5>
                        <p className="calender-column-text mt-10 mb-15">
                          Set your ideal availability by day and time. This
                          availability will be taken into consideration in
                          tandem with real-time calendar availability.
                        </p>
                      </div>
                      <div
                        onClick={(e) => {
                          handleImgPopup(e);
                        }}
                        className="edit-profile-checkbox calendar-connection-img-box edit_availability_bg"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="display-flex calender-step">
              <div className="mentee-dashboard-home-title calendar-connection-icon calendar-connection-icon-before-none">
                <i
                  className={`${
                    steps["step4"] ? "fas" : "far"
                  } fa-check-circle`}
                />
              </div>
              <div
                className="mentee-dashboard-home-content mentee-dashboard-home-content-open"
                style={{ paddingBottom: "40px" }}
              >
                <div className="mentee-dashboard-home-content-text mentee-dashboard-home-content-text-open">
                  <h3>
                    Step 4: Turn “On” Your Cardo Profile {"&"} Start Mentoring!
                  </h3>
                  <p className="delete-account-text mt-10 mb-15">
                    Make sure your Cardo profile is complete and turned “On” to
                    be shown to mentors. Happy mentoring! If you have any
                    questions, reach out to us at support@hellocardo.org.
                  </p>
                  <div
                    style={{
                      border: "1px solid #D3D3D3",
                      display: "flex",
                      width: "95%",
                      borderRadius: "10px",
                      padding: "10px 18px",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        width: "42%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <h5
                          style={{
                            fontSize: "14px",
                            marginTop: "15px",
                            color: "black",
                            fontFamily: "Neometric Medium",
                          }}
                        >
                          Complete Your Cardo Bio
                        </h5>
                        <p className="calender-column-text mt-10 mb-15">
                          Write a few words to let mentees know who you are,
                          what’s your story, and how you’d like to help.
                        </p>
                      </div>
                      <div
                        onClick={(e) => {
                          handleImgPopup(e);
                        }}
                        className="edit-profile-checkbox calendar-connection-img-box bio_and_save_bg"
                      ></div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",

                        position: "relative",
                      }}
                    >
                      <div
                        className="font-Neometric-Bold"
                        style={{
                          position: "absolute",
                          bottom: "100px",
                          color: "#5555DB",
                          fontSize: "22px",
                          fontWeight: "600",
                        }}
                      >
                        AND
                      </div>
                    </div>
                    <div
                      style={{
                        width: "42%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <h5
                          style={{
                            fontSize: "14px",
                            marginTop: "15px",
                            color: "black",
                            fontFamily: "Neometric Medium",
                          }}
                        >
                          Turn Your Profile “On”
                        </h5>
                        <p className="calender-column-text mt-10 mb-15">
                          Switch your profile on to mentees. If you ever want to
                          take a break from mentoring, you can turn your profile
                          off.
                        </p>
                      </div>
                      <div
                        onClick={(e) => {
                          handleImgPopup(e);
                        }}
                        className="edit-profile-checkbox calendar-connection-img-box turn_profile_on_bg"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div></div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  errors: state.errors,
  mentorEmailInvitation: state.mentorDashboard.mentorEmailInvitation,
  invitationStatus: state.mentorDashboard.invitationStatus,
});

export default connect(mapStateToProps, {
  postEmailInvitation,
  getUserInvitationStatus,
  editMentorProfile,
})(MentorCalendar);
