import React from "react";
import warning from "../../../../../assets/mentee-dashboard-profile-edit/warning.svg";
import { postDeleteAccount } from "../../../../../state-management/actions/dashboard/mentee-dashboard/profileActions";

const DeleteAccountConfirmationPopUp = () => {
  function closeModal(e) {
    e.stopPropagation();
    document.getElementsByClassName(
      "delete-account-confirmation-popup"
    )[0].style.display = "none";
  }

  function deleteAccound() {
    postDeleteAccount(() => {});
  }

  return (
    <div>
      <div className="delete-account-confirmation-popup" onClick={closeModal}>
        <div
          className="delete-account-confirmation-popup-content"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="font-Neometric-Medium" style={{ color: "red" }}>
            {" "}
            <img
              src={warning}
              alt="warning"
              style={{ marginRight: "5px" }}
            />{" "}
            Warning
          </div>
          <div className="font-eina text-align-center line-height">
            Are you sure you would like you permanently delete <br /> your
            account?
          </div>
          <div className="display-flex">
            <button
              style={{
                width: "125px",
                height: "47px",
              }}
              className="cardo-btn cardo-btn-sm btn-bg-lightindigo"
              onClick={closeModal}
            >
              Nevermind
            </button>
            <button
              style={{
                width: "125px",
                height: "47px",
              }}
              className="cardo-btn cardo-btn-sm btn-bg-lightblue"
              onClick={deleteAccound}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteAccountConfirmationPopUp;
