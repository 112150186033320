import React from "react";
import '../../../../../../styles/dashboard/mentor-dashboard/pre-join.css';
import VideoInputList from "./VideoInputList";
import AudioInputList from "./AudioInputList";
import AudioOutputList from "./AudioOutputList";

function Settings(props) {

    const closeModel = () => {
        props.closeModel();
    }
    return (
        <div
            className="mentee-dashboard-find-mentor-modal"
            style={{display: "block", position: "absolute", backgroundColor: "transparent", overflow: 'unset', top: '125%', left: '0'}}
        >
            <div style={{width: '300px', height: "auto", position: 'unset', transform: 'unset', marginRight: '0'}}
                 className="mentee-dashboard-find-mentor-modal-content"
                 onClick={(e) => e.stopPropagation()}
            >
                <div
                    className="position-relative"
                    style={{
                        paddingTop: '15px'
                    }}
                >
                    <div className='join-session-step' style={{width: '80%'}}>
                        <VideoInputList localTracks={props.localTracks} closeModel={() => closeModel()}/>
                        <AudioInputList localTracks={props.localTracks} closeModel={() => closeModel()}/>
                        <AudioOutputList closeModel={() => closeModel()}/>
                    </div>
                </div>
                <div style={{
                    display: "flex",
                    justifyContent: 'center',
                    alignItems: 'center',
                    bottom: 0,
                    right: 0,
                    left: 0
                }}>
                </div>
            </div>
        </div>
    );
}

export default Settings;
