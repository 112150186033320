import CalendlySeats from "./CalendlySeats";
import CalendlySeatsInput from "./CalendlySeatsInput";
import "../../../../../styles/dashboard/admin-dasboard/calendly-tab/calendlycard-container.css";
import CalendlyMonthlyExpenseCard from "./CalendlyMonthlyExpenseCard";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  getCalendlySeatsInfo,
  putCalendlySeatsInfo,
} from "../../../../../state-management/actions/dashboard/mentee-dashboard/calendlyActions";

const CalendlyCardContainer = (props) => {
  const [noOfSeats, setNoOfSeats] = useState();
  const [pricePerSeat, setPricePerSeat] = useState(null);
  const [seatsInUse, setSeatsInUse] = useState();

  const handlePricePerSeatChange = (e) => {
    setPricePerSeat(e.target.value);
  };

  const handleTotalSeatsChange = (e) => {
    setNoOfSeats(e.target.value);
  };

  useEffect(() => {
    //on succes (revoke or invite) fire this function.
    props.getCalendlySeatsInfo();
  }, []);

  useEffect(() => {
    if (props?.getCalendlySeats) {
      setNoOfSeats(props?.getCalendlySeats?.info[0]?.totalOfSeats);
      setPricePerSeat(props?.getCalendlySeats?.info[0]?.pricePerSeat);
      setSeatsInUse(props?.getCalendlySeats?.seats?.collection?.length);
    }
  }, [props?.getCalendlySeats]);

  return (
    <div className="calendly_card_contaner">
      <CalendlySeatsInput
        noOfSeats={noOfSeats}
        handleTotalSeatsChange={handleTotalSeatsChange}
        pricePerSeat={pricePerSeat}
        handlePricePerSeatChange={handlePricePerSeatChange}
      />
      <CalendlySeats
        seatsInUse={seatsInUse}
        remainingSeats={noOfSeats - seatsInUse}
      />
      <CalendlyMonthlyExpenseCard expense={pricePerSeat * noOfSeats} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  getCalendlySeats: state.admin.seatsInfo,
});
export default connect(mapStateToProps, {
  getCalendlySeatsInfo,
  putCalendlySeatsInfo,
})(withRouter(CalendlyCardContainer));
