import React, { useEffect, useState } from "react";
import { InlineWidget, CalendlyEventListener } from "react-calendly";
import { postEventScheduled } from "../../../../../state-management/actions/dashboard/mentee-dashboard/calendlyActions";
import FindMentorPopUpFirstScreen from "./mentee-dashboard-find-mentor-popup-components/FindMentorPopUpFirstScreen";
import "../../../../../styles/dashboard/mentee-dashboard/mentee-dashboard-find-mentor-pop-up.css";
import FindMentorPopUpSecondScreen from "./mentee-dashboard-find-mentor-popup-components/FindMentorPopUpSecondScreen";
import MenteeDashboardConfirmationPopup from "../mentee-dashboard-booking-details/MenteeDashboardConfirmationPopup";

import coinsIcon from "../../../../../assets/mentee-dashboard/popup/coinsIcon.svg";
import { connect } from "react-redux";

const MenteeDashboardFindMentorPopup = (props) => {
  const [popupSteps, setPopupSteps] = useState(1);
  const [calendlySlug, setCalendlySlug] = useState("");
  const [interViewType, setInterViewType] = useState({});
  const [popupHeight, setPopupHeight] = useState("730px");
  const {
    closeModalSchedule,
    mentor,
    currentUser,
    postEventScheduled,
    closeModalDone,
    menteeDashboardProfileInfo,
  } = props;

  const onEventScheduled = (event) => {
    if (event.data.event === "calendly.event_scheduled") {
      closeModalSchedule();
      closeModalDone();
      postHandler(event.data, props.mentor, props.currentUser, interViewType);
    }
  };

  const postHandler = (data, mentor, mentee, interViewType) => {
    postEventScheduled({ data, mentor, mentee, interViewType });
  };

  function closeModalPopup(e) {
    e.stopPropagation();
    closeModalSchedule();
  }

  const nextStep = (slug, choosenInterviewType, height) => {
    if (height) {
      setPopupHeight(height);
    }
    if (slug) {
      setCalendlySlug(slug);
      setInterViewType(choosenInterviewType);
    }

    setPopupSteps((prevState) => prevState + 1);
  };
  const prevStep = (height) => {
    setPopupHeight(height);
    setPopupSteps((prevState) => prevState - 1);
  };
  const popContent = () => {
    switch (popupSteps) {
      case 1:
        return (
          <FindMentorPopUpFirstScreen mentor={mentor} nextStep={nextStep} />
        );
      case 2:
        return (
          <FindMentorPopUpSecondScreen
            prevStep={prevStep}
            nextStep={nextStep}
            mentor={props.mentor}
          />
        );
      case 3:
        return (
          <CalendlyEventListener onEventScheduled={onEventScheduled}>
            <InlineWidget
              url={
                calendlySlug +
                "?hide_gdpr_banner=1&hide_event_type_details=1&hide_landing_page_details=1&name=" +
                currentUser.firstName +
                " " +
                currentUser.lastName +
                "&email=" +
                currentUser.email
              }
              style={{
                minWidth: "320px",

                height: "560px",
              }}
            />
            <div
              className="display-flex justify-content-center"
              style={{ alignItems: "center", marginBottom: "30px" }}
            >
              <button
                style={{ fontFamily: "Neometric Medium" }}
                className="cardo-btn btn-bg-lightindigo popupBtn"
                onClick={() => prevStep("649px")}
              >
                Back
              </button>
              <button
                className="cardo-btn btn-bg-lightindigo popupBtn"
                disabled
              >
                Next
              </button>
            </div>
          </CalendlyEventListener>
        );

      default:
        break;
    }
  };
  return (
    <div
      className="mentee-dashboard-find-mentor-modal"
      onClick={closeModalSchedule}
    >
      <div
        className="mentee-dashboard-find-mentor-modal-content"
        style={{ height: popupHeight }}
        onClick={(e) => e.stopPropagation()}
      >
        <div
          className="position-relative mentee-dashboard-find-mentor-modal-container-wrapper"
          style={{ height: "100%" }}
        >
          <div className="mentee-dashboard-find-mentor-modal-header">
            <div className="mentee-dashboard-find-mentor-modal-header-container">
              <div className="display-flex align-items-center">
                <div
                  className="mentee-dashboard-find-mentor-modal-header-mentor-avatar mr-25"
                  style={{
                    backgroundImage: `url('${mentor.img_url}')`,
                  }}
                />
                <div>
                  <div>
                    <h3 className="mentee-dashboard-find-mentor-modal-header-mentor-name mb-5 mt-5">
                      {mentor.first_name} {mentor.last_name.substr(0, 1)}.
                    </h3>
                  </div>
                  <div className="font-eina font-size-14 mt-5 mb-5 mentee-dashboard-find-mentor-modal-header-mentor-industry">
                    {menteeDashboardProfileInfo?.preferred_trail}
                    <i
                      className="fas fa-caret-right"
                      style={{ margin: "0px 5px" }}
                    ></i>{" "}
                    {mentor.profile_info.consulting_firm}{" "}
                  </div>
                </div>
              </div>

              <div className="mentee-dashboard-find-mentor-modal-header-mentor-credits">
                Credits
                <div className="mentee-dashboard-find-mentor-modal-header-mentor-credits-value">
                  <img
                    style={{ marginRight: "5px" }}
                    src={coinsIcon}
                    alt="coins"
                  />
                  {currentUser.points}
                </div>
              </div>
              <div
                className="mentee-dashboard-find-mentor-modal-close"
                onClick={closeModalPopup}
              >
                &times;
              </div>
            </div>
          </div>
          <div className="mentee-dashboard-find-mentor-modal-popup-content">
            {popContent()}
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  menteeDashboardEventScheduled:
    state.menteeDashboard.menteeDashboardEventScheduled,
});
export default connect(mapStateToProps, { postEventScheduled })(
  MenteeDashboardFindMentorPopup
);
