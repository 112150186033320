import React, { useState, useEffect } from "react";
import { Loader } from "rsuite";
import "../../../../../../../styles/rsuite.css";
const SendEmailButton = (props) => {
  const { componentClass, btnDisabled, loading } = props;

  return (
    <button
      disabled={btnDisabled}
      type={btnDisabled ? "button" : "submit"}
      className={componentClass}
      style={{
        margin: "15px 0 0 0",
        fontSize: "16px",
        width: "180px",
        cursor: btnDisabled ? "not-allowed" : "pointer",
      }}
    >
      {loading ? <Loader /> : "Share"}
    </button>
  );
};
export default SendEmailButton;
