import React from 'react';
import useDevices from "../../../../../../hooks/useDevices";
import useMediaStreamTrack from "../../../../../../hooks/useMediaStreamTrack";
import {SELECTED_AUDIO_INPUT_KEY} from "../../../../../../helper/videoCall";
import AudioLevelIndicator from "./AudioLevelIndicator";
import Select from "../../../../../auth/form-component/Select";

function AudioInputList(props) {
    const {audioInputDevices} = useDevices();

    const localAudioTrack = props.localTracks.find(track => track.kind === 'audio');
    const mediaStreamTrack = useMediaStreamTrack(localAudioTrack);
    const localAudioInputDeviceId = mediaStreamTrack?.getSettings().deviceId;

    function replaceTrack(newDeviceId) {
        window.localStorage.setItem(SELECTED_AUDIO_INPUT_KEY, newDeviceId);
        localAudioTrack?.restart({deviceId: {exact: newDeviceId}});
        if (props.closeModel) {
            props.closeModel();
        }
    }

    return (
        <div>
            {audioInputDevices.length > 0 ? (
                    <Select
                        name="audioinput"
                        text="Microphone"
                        value={localAudioInputDeviceId || ''}
                        onChange={(e) => replaceTrack(e.target.value)}
                        error={''}
                        choices={
                            audioInputDevices.map(device => (
                                <option value={device.deviceId} key={device.deviceId}>
                                    {device.label}
                                </option>
                            ))
                        }
                    />
            ) : (
                <div className="audiovideoinput">
                    {localAudioTrack?.mediaStreamTrack.label || 'No Local Audio'}
                </div>
            )}
            {/*<AudioLevelIndicator audioTrack={localAudioTrack} color="black"/>*/}
        </div>
    );
}

export default AudioInputList
