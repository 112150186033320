export const adminDashboardCalendly = [
  {
    trail: "Digital",
    first: "Najam",
    last: "Uddin",
    company: "Test Company",
    email: "najam.uddin583371@gmail.com",
    hours: "5h", //ms
    last_login: new Date(Date.now() - 86400000), //new Date('')
    last_call: new Date(Date.now() - 106400000), // new Date('')
    "#_sessions": 6,
    status: "revoke",
    rating: "4/5",
  },
  {
    trail: "Digital",
    first: "Asmin",
    last: "Mubi",
    company: "Test Company",
    email: "test@gmail.com",
    hours: "5h",
    last_login: new Date(Date.now() - 86400000), //new Date('')
    last_call: new Date(Date.now() - 106400000), // new Date('')
    "#_sessions": 2,
    status: "revoke",
    rating: "4/5",
  },
  {
    trail: "Digital",
    first: "Asmin",
    last: "Mubi",
    company: "Test Company",
    email: "test@gmail.com",
    hours: "5h",
    last_login: new Date(Date.now() - 86400000), //new Date('')
    last_call: new Date(Date.now() - 106400000), // new Date('')
    "#_sessions": 3,
    status: "revoke",
    rating: "4/5",
  },
  {
    trail: "Digital",
    first: "Asmin",
    last: "Mubi",
    company: "Test Company",
    email: "test@gmail.com",
    hours: "5h",
    last_login: new Date(Date.now() - 86400000), //new Date('')
    last_call: new Date(Date.now() - 106400000), // new Date('')
    "#_sessions": 8,
    rating: "4/5",
    status: "active",
  },
  {
    trail: "Digital",
    first: "Asmin",
    last: "Mubi",
    company: "Test Company",
    email: "test@gmail.com",
    hours: "5h",
    last_login: new Date(Date.now() - 86400000), //new Date('')
    last_call: new Date(Date.now() - 106400000), // new Date('')
    "#_sessions": 8,
    rating: "4/5",
    status: "active",
  },
];
