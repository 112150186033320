export const mentors = [
  {
    name: "Ethan L.",
    area: "Strategy",
    company: "Bain & Co.",
    skills: "Case Interview, Industry Insights, Fit Interview",
    imageUrl:
      "https://mentors-imgs.s3.us-east-2.amazonaws.com/Strategy/Ethan+L.png",
    aboutMe:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse suscipit vel arcu sit amet gravida. Fusce in eros in nunc pulvinar ullamcorper rutrum at mauris. In et suscipit augue. Fusce id velit ut mauris egestas placerat sit amet posuere lorem. Mauris eu pellentesque nunc, et gravida dolor.",
    whatMakesMeUnique: "BIPOC, LGBTQ+, Latinx",
  },
  {
    name: "Javier S.",
    area: "Operations",
    company: "Accenture",
    skills: "Industry Insights, Resume Review, LinkedIn Review, Case Interview",
    imageUrl:
      "https://mentors-imgs.s3.us-east-2.amazonaws.com/Operations/Javier+S.png",
    aboutMe:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse suscipit vel arcu sit amet gravida. Fusce in eros in nunc pulvinar ullamcorper rutrum at mauris. In et suscipit augue. Fusce id velit ut mauris egestas placerat sit amet posuere lorem. Mauris eu pellentesque nunc, et gravida dolor.",
    whatMakesMeUnique: "BIPOC, LGBTQ+, Latinx",
  },
  {
    name: "Temisan S.",
    area: "Technology",
    company: "Deloitte",
    skills: "Cover Letter Review, Resume Review, Fit Interview, Case Interview",
    imageUrl:
      "https://mentors-imgs.s3.us-east-2.amazonaws.com/Technology/Temisan+S.png",
    aboutMe:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse suscipit vel arcu sit amet gravida. Fusce in eros in nunc pulvinar ullamcorper rutrum at mauris. In et suscipit augue. Fusce id velit ut mauris egestas placerat sit amet posuere lorem. Mauris eu pellentesque nunc, et gravida dolor.",
    whatMakesMeUnique: "BIPOC, LGBTQ+, Latinx",
  },
];

export const team = [
  {
    name: "Raghav Bansal",
    title: "Co-Founder & Executive Director",
    bio: `Raghav is the Co-Founder and Executive Director of Cardo. After graduating from Virginia Tech, he started his career at Accenture. Initially joining the Operations Consulting division of Accenture, Raghav navigated his way to the Strategy practice, advising internet and SaaS businesses. This journey, possible only with the help of many mentors along the way, led Raghav to starting Cardo. Today, Raghav is a VC Associate at Fractal, a venture studio focused on building and investing in vertical SaaS companies. In his free time, Raghav volunteers as a mentor on Cardo and is always looking for new things to build. He is also an avid traveler and a sneaker enthusiast.`,
    imageUrl: "/raghav.jpg",
    linkedIn: "https://www.linkedin.com/in/rbansal1/",
  },
  {
    name: "Shayan Tabatabai",
    title: "Co-Founder and VP of Operations",
    bio: `Shayan Tabatabai is the Co-Founder and VP of Operations of Cardo. Shayan graduated from Virginia Tech in 2016 with a degree in Business Information Technology. Following Virginia Tech, Shayan began working at Deloitte Consulting and continues his consulting career there to this day. In his free time, Shayan loves to spend time outdoors including hiking, biking, and skiing whenever his schedule permits.`,
    imageUrl: "/Shayan_.png",
    linkedIn: "https://www.linkedin.com/in/shayan-tabatabai-4789b7a3/",
  },
  {
    name: "Traci Hirokawa",
    title: "VP of Marketing & Content",
    bio: `Traci Hirokawa is the VP of Marketing at Cardo. Traci graduated from Duke University in 2019 with a degree in Economics and Spanish. Following Duke, Traci worked at Accenture in San Francisco for the strategy consulting practice for two years. While at Accenture, Traci met Raghav and joined the Cardo team to help lead all things marketing and content. Today, Traci is on the strategy team at an artifical intelligence startup called C3.ai and is also a proud dog mom to Kona the mini aussiedoodle.`,
    imageUrl: "/traci.jpg",
    linkedIn: "https://www.linkedin.com/in/tracihirokawa/",
  },

  {
    name: "Nick Young",
    title: "Product Advisor",
    bio: ``,
    imageUrl: "/nick.jpg",
    linkedIn: "https://www.linkedin.com/in/nickyoung3/",
  },
  /* {
    name: "Forrest Colyer",
    title: "Product Advisor",
    bio: `Aly Murray is the Co-Founder and Executive Director of UPchieve. She’s also a proud Latina, math nerd, and community college grad. After earning an associate’s degree, Aly transferred to the University of Pennsylvania where she graduated summa cum laude with a degree in Mathematics. Following Penn, Aly worked on the trading floor at J.P. Morgan for two years before leaving to commit to UPchieve full-time. For her work on UPchieve, Aly has been featured on the Forbes 30 Under 30 list in Education (2021) and honored as a Roddenberry Fellow (2021).

    Aly’s personal experience as a low-income student drives her to fight for educational equity and work towards a world in which all students have an equal opportunity to achieve upward mobility. In her free time, Aly volunteers as a tutor on UPchieve and as a college coach with Breakthrough New York to help other low-income students succeed. She’s also an avid gamer and proud dog parent to a pup named Moose.`,
    imageUrl: "",
    linkedIn: "https://www.linkedin.com/in/forrestcolyer/",
  }, */
];
