import React from "react";
import { Link, withRouter } from "react-router-dom";
import faqBaloon from "../../../../assets/dashboard/sidebar/faqBaloon.svg";
const SidebarLinks = (props) => {
  const { title, link } = props;
  return (
    <div className="dashboard-sidebar-faq-link-container">
      <div className="sidebar-faq-link">
        <img src={faqBaloon} alt="" className="sidebar-faq-img" />
        <div className="sidebar-faq-link-conatiner">
          <p className="font-size-14 faq-text">Have a question?</p>
          <a href={link} target="_blank">
            <button className="cardo-btn btn-bg-lightblue faq-btn">
              {title}
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default withRouter(SidebarLinks);
