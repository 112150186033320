import React from "react";

const MenteeMentorBtnContainer = (props) => {
  const { activeBtn, handleActiveBtn, setActiveInnerTab } = props;

  return (
    <div className="mentee_mentor_btn_container">
      <div
        className={`mentee_mentor_swith_btn ${
          activeBtn === "mentee" && "mentee_mentor_swith_btn_active"
        }`}
        onClick={() => {
          handleActiveBtn("mentee");
          if (setActiveInnerTab) {
            setActiveInnerTab("pipeline");
          }
        }}
      >
        Mentee
      </div>
      <div
        className={`mentee_mentor_swith_btn ${
          activeBtn === "mentor" && "mentee_mentor_swith_btn_active"
        }`}
        onClick={() => {
          handleActiveBtn("mentor");
          if (setActiveInnerTab) {
            setActiveInnerTab("pipeline");
          }
        }}
      >
        Mentor
      </div>
    </div>
  );
};

export default MenteeMentorBtnContainer;
