import React, { useEffect, useState } from "react";
import Group438 from "../../../assets/About us/Group 438 (1).svg";
import Group from "../../../assets/About us/Group.svg";
import Group1 from "../../../assets/About us/Group (1).svg";
import Group2 from "../../../assets/About us/Group (2).svg";
import Group313 from "../../../assets/About us/Group 313.svg";
import Group429 from "../../../assets/About us/Group 429.svg";
import "../../../styles/about-us.css";
import { team } from "../../../helper/dummyData";
import ScrollAnimation from "react-animate-on-scroll";
import Section5BtnGroup from "../home/Section5BtnGroup";

import TeamCardsDesktop from "./about-us-components/TeamCardsDesktop";
import TeamCardsMobile from "./about-us-components/TeamCardsMobile";
import TeamDetailPopup from "./about-us-components/TeamDetailPopup";

const AboutUs = () => {
  const [teamData, setTeamData] = useState([]);
  const [teamPopup, setTeamPopup] = useState(false);
  const [teamMemeber, setTeamMemeber] = useState();
  useEffect(() => {
    // axios
    //   .get("https://hellocardo.org:5000/mentors/getMentorsHomePage")
    //   .then((res) => {
    //     setMentorData(res.data);
    //   })
    //   .catch((err) => console.log(err));
    setTeamData({ team });
  }, []);
  const handleTeamPopup = () => {
    setTeamPopup(!teamPopup);
  };
  const handleTeamMemeber = (teamMemberDetails) => {
    setTeamMemeber(teamMemberDetails);
  };
  return (
    <div>
      {teamPopup && (
        <TeamDetailPopup
          handleTeamPopup={handleTeamPopup}
          teamPopup={teamPopup}
          teamMemeber={teamMemeber}
        />
      )}
      {/* About Us header */}
      <section className="aboutus-header">
        <div className="container_wide aboutus-header_container">
          <div className="row aboutus-header_row">
            <div className="col-6 aboutus-header_leftCol">
              <div className="aboutus-header-img-container">
                <img src={Group438} alt="" />
              </div>
            </div>
            <div className="col-6 aboutus-header_rightCol">
              <div className="aboutus-header_rightCol_text">
                <h1>
                  Share knowledge.
                  <br /> Empower others.
                  <br /> Lift as you climb.
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Section 1 'Our mission' */}
      <section className="aboutus-section_one">
        <div className="container_narrow aboutus-section_one_container">
          <div className="row aboutus-section_one_row">
            <div className="col-5 row aboutus-section_one_leftCol">
              <h2>Our mission</h2>
            </div>
            <div className="col-7 aboutus-section_one_rightCol">
              <p>
                We equalize professional growth by <br className="br_mobile" />
                making it simpler to seek and offer
                <br className="br_mobile" /> career <br className="desk_br" />
                mentorship for
                <br className="br_mobile" /> hyper-competitive industries.
                <br />
                <br />
                Using technology, we connect
                <br className="br_mobile" /> underserved and underrepresented
                <br className="br_mobile" /> communities{" "}
                <br className="desk_br" />
                to mentorship
                <br className="br_mobile" /> opportunities that will help coach,
                <br className="br_mobile" /> empower, and guide the next
                <br className="br_mobile" />
                <br className="desk_br" />
                generation of business leaders.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Section 2 'Our values'  */}
      <section className="aboutus-section_two">
        <div className="container_narrow aboutus-section_two_container">
          <div className="row aboutus-section_two_row">
            <div className="col-5 aboutus-section_two_leftCol">
              <h2>Our values</h2>
              <p>
                Our core values reflect our <br className="desk_br" />
                commitment to
                <br className="br_mobile" /> positive impact{" "}
                <br className="desk_br" />
                and act as the cornerstones for
                <br className="br_mobile" />
                <br className="desk_br" />
                how we operate.
              </p>
            </div>
            <div className="col-7 aboutus-section_two_rightCol">
              {/* Section two right col first row */}
              <div className="aboutus-section_two_rightCol_row">
                <div className="aboutus-section_two_rightCol_img">
                  <img src={Group} alt="" />
                </div>
                <div className="aboutus-section_two_rightCol_text">
                  <h3>Authenticity</h3>
                  <p>
                    Open and honest conversations are key to{" "}
                    <br className="br_mobile" />
                    <br className="desk_br" />
                    successful mentorship. We prefer to leave
                    <br className="br_mobile" />
                    <br className="desk_br" />
                    formalities behind and focus on results.
                  </p>
                </div>
              </div>
              {/* Section two right col second row  */}
              <div className="aboutus-section_two_rightCol_row">
                <div className="aboutus-section_two_rightCol_img">
                  <img src={Group1} alt="" />
                </div>
                <div className="aboutus-section_two_rightCol_text">
                  <h3>Abundance</h3>
                  <p>
                    Opportunity should not be limited to a few.
                    <br className="desk_br" /> <br className="br_mobile" />
                    Anyone willing to work hard has the right{" "}
                    <br className="desk_br" />
                    to <br className="br_mobile" />
                    find career success and growth.
                  </p>
                </div>
              </div>
              {/*  Section two right col third row  */}
              <div className="aboutus-section_two_rightCol_row">
                <div className="aboutus-section_two_rightCol_img">
                  <img src={Group2} alt="" />
                </div>
                <div className="aboutus-section_two_rightCol_text">
                  <h3>Down-to-earth</h3>
                  <p>
                    Pedigree is not an indicator of your worth.{" "}
                    <br className="br_mobile" />
                    <br className="desk_br" />
                    Regardless of background, everyone is{" "}
                    <br className="br_mobile" />
                    <br className="desk_br" />
                    treated with the same respect.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Section 3 'Who we serve'  */}
      <section className="aboutus-section_three">
        <div className="container_narrow aboutus-section_three_container">
          <img
            className="aboutus-section_three_ballon_image"
            src={Group313}
            alt=""
          />
          <div className="row aboutus-section_three_row">
            <div className="col-6 aboutus-section_three_leftCol">
              <h2>Who we serve</h2>
              <p>
                We prioritize individuals who don’t have the
                <br className="br_mobile" /> upper hand.
                <br />
                <br />
                To equalize professional growth in industries that are{" "}
                <br className="desk_br" />
                especially difficult to break into, we focus on underserved{" "}
                <br className="desk_br" />
                and underrepresented <br className="br_mobile" />
                communities.
                <br />
                <br />
                Some examples of who we prioritize:
              </p>
              <div className="row aboutus-section_three_row_bubbles">
                <div className="col-3 aboutus-section_three_col_bubbles multipul_bubbles">
                  <div className="aboutus-section_three_bubble_multi">
                    BIPOC
                  </div>
                  <div className="aboutus-section_three_bubble_multi">
                    Latinx
                  </div>
                  <div className="aboutus-section_three_bubble_multi">
                    Women
                  </div>
                </div>
                <div className="col-3 aboutus-section_three_col_bubbles single_bubble">
                  <div className="aboutus-section_three_bubble">
                    Low-income
                    <br />
                    or single-parent
                    <br />
                    households
                  </div>
                </div>
                <div className="col-3 aboutus-section_three_col_bubbles single_bubble">
                  <div className="aboutus-section_three_bubble">
                    First-gen
                    <br />
                    college
                    <br />
                    students
                  </div>
                </div>
                <div className="col-3 aboutus-section_three_col_bubbles single_bubble">
                  <div className="aboutus-section_three_bubble">
                    Non-target
                    <br />
                    school <br />
                    graduates
                  </div>
                </div>
              </div>
              <p>
                All prospective applicants undergo a screening process in{" "}
                <br className="desk_br" />
                order to join our platform.
              </p>
            </div>
            <div className="col-6 aboutus-section_three_rightCol">
              <img src={Group429} alt="" />
            </div>
          </div>
        </div>
      </section>
      <section className="home-section-four">
        <div className="home-section-four-container">
          <ScrollAnimation offset={30} animateIn="fadeInDown">
            <h2 className="heading">Our Team</h2>
          </ScrollAnimation>
          <div className="home-section-four-body">
            {/* <Section5BtnGroup /> */}
            <div className="mentor-desktop">
              <TeamCardsDesktop
                teamData={teamData}
                handleTeamMemeber={handleTeamMemeber}
                handleTeamPopup={handleTeamPopup}
              />
            </div>
            <div className="mentor-mobile">
              <TeamCardsMobile
                teamData={teamData}
                handleTeamMemeber={handleTeamMemeber}
                handleTeamPopup={handleTeamPopup}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutUs;
