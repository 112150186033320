import { SUCCESS, CLEAN_SUCCESS } from "../types/types";

const initialState = {
  success: false,
};

const successReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUCCESS: {
      return { ...state, success: true };
    }
    case CLEAN_SUCCESS: {
      return { ...state, success: false };
    }
    default:
      return state;
  }
};
export default successReducer;
