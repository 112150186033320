import { SUCCESS, CLEAN_SUCCESS } from "../types/types";

export const handleSuccess = () => {
  return {
    type: SUCCESS,
  };
};
export const cleanSuccess = () => {
  return {
    type: CLEAN_SUCCESS,
  };
};
