import React, { useEffect, useState } from "react";
import ProfileHeader from "./profile-components/ProfileHeader";
import "../../../styles/dashboard/profile/profile.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import ProfileBodyHeader from "./profile-components/ProfileBodyHeader";
import ProfileBodyProgress from "./profile-components/ProfileBodyProgress";
import MenteeEditProfile from "./mentee-profile-components/MenteeEditProfile";
import { getCurrentUser } from "../../../state-management/actions/dashboard/mentee-dashboard/dashboardActions";
import MessagePopup from "./mentor-profile-components/mentor-form/MessagePopup";
import { postDeleteAccount } from "../../../state-management/actions/dashboard/mentee-dashboard/profileActions";
const MenteeProfile = (props) => {
  const [screen, setScreen] = useState("Information");
  const [deletePopup, setDeletePopup] = useState(false);
  const { currentUser, menteeDashboardEditProfile } = props;
  useEffect(() => {
    props.getCurrentUser();
  }, []);

  const [userImg, setUserImg] = useState();
  useEffect(() => {
    if (!userImg) {
      setUserImg(currentUser.imgUrl);
    }
    return;
  }, [userImg, currentUser]);

  const changeScreen = (screen) => {
    setScreen(screen);
  };
  const handleDeletePopup = () => {
    setDeletePopup(!deletePopup);
  };
  return (
    <div className="profile">
      {deletePopup && (
        <MessagePopup
          type="delete"
          msg={
            <div>
              Are you sure you would like you permanently delete <br /> your
              account?
            </div>
          }
          title="Warning"
          handlePopup={handleDeletePopup}
          deleteHandler={postDeleteAccount}
        />
      )}
      <ProfileHeader currentUser={currentUser} userImg={userImg} />
      <div className="profile-body">
        <div>
          <Link
            to="/mentee/dashboard"
            className="profile-back-to-dashboard-btn text-decoration-none"
          >
            <button className="cardo-btn btn-bg-lightblue ">
              Back To Dashboard
            </button>
          </Link>
          <div className="profile-body-container">
            <ProfileBodyHeader
              currentUser={currentUser}
              setUserImg={setUserImg}
              userImg={userImg}
            />
            {screen === "Information" ? (
              <ProfileBodyProgress
                menteeDashboardEditProfile={menteeDashboardEditProfile}
              />
            ) : (
              ""
            )}
            <MenteeEditProfile
              changeScreen={changeScreen}
              currentUser={currentUser}
              handleDeletePopup={handleDeletePopup}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  currentUser: state.auth.currentUser,
  menteeDashboardEditProfile: state.menteeDashboard.menteeDashboardEditProfile,
});
export default connect(mapStateToProps, { getCurrentUser })(MenteeProfile);
