import React from "react";
import { Link } from "react-router-dom";
import brand1 from "../../../assets/Become-a-Mentor/Vector-1.svg";
import brand2 from "../../../assets/Become-a-Mentor/Vector-2.svg";
import brand3 from "../../../assets/Become-a-Mentor/Vector-3.svg";
import brand4 from "../../../assets/Become-a-Mentor/Group 577.svg";
import brand5 from "../../../assets/Become-a-Mentor/Group 591.svg";
import brand6 from "../../../assets/Become-a-Mentor/Group 593.svg";

import firstScreenShotBg from "../../../assets/Become-a-Mentor/Group 688.svg";
import firstScreenShot from "../../../assets/Become-a-Mentor/Group 703.svg";
import firstScreenShotMobile from "../../../assets/Become-a-Mentor/Group 702.png";

import secondScreenShotBg from "../../../assets/Become-a-Mentor/Group 689.svg";
import secondScreenShot from "../../../assets/Become-a-Mentor/Group 683.svg";
import secondScreenShotMobile from "../../../assets/Become-a-Mentor/Group 683.png";

import thirdScreenShotBg from "../../../assets/Become-a-Mentor/Group 690.svg";
import thirdScreenShot from "../../../assets/Become-a-Mentor/Group 681.svg";
import thirdScreenShotMobile from "../../../assets/Become-a-Mentor/Group 681.png";

import section2step1 from "../../../assets/Become-a-Mentor/Group.svg";
import section2step2 from "../../../assets/Become-a-Mentor/Group 533.svg";
import section2step3 from "../../../assets/Become-a-Mentor/Group 571.svg";
import section2step4 from "../../../assets/Become-a-Mentor/Group 531.svg";
import section2step5 from "../../../assets/Become-a-Mentor/Group 534.svg";
import section2step6 from "../../../assets/Become-a-Mentor/Group 532.svg";

import section3rightCol from "../../../assets/Group 558.png";
import becomeMentorSection5 from "../../../assets/Become-a-Mentor/becomeMentorSection5.svg";

import "../../../styles/become-mentor.css";
import Accordian from "./Accordian";
import BecomeMentorSection1Desktop from "./BecomeMentorSection1Desktop";
import BecomeMentorSection1Mobile from "./BecomeMentorSection1Mobile";
import { useMobilePopup } from "../../../hooks/useMobilePopup";
import { useIsDesktop } from "../../../helper/useIsDesktop";
const BecomeMentor = () => {
  const { openMobilePopup, openMobilePopupClick } =
    useMobilePopup("mobile-modal");
  const { isDesktop } = useIsDesktop();
  return (
    <div>
      {openMobilePopup}

      <section className="become-mentor-header">
        <div className="become-mentor-header_container">
          <div className="become-mentor-text">Become a mentor</div>
          <h1 className="pay-it-forward-text">
            Pay it forward while making an impact
          </h1>
          <p className="help-underserved-text">
            Help underserved and underrepresented individuals gain the
            confidence and skills <br className="br_mobile" /> to succeed in
            hyper-competitive careers
          </p>
          <div className="apply-now-container">
            <Link
              to={isDesktop ? "/sign-up" : "#"}
              className="pink-btn apply_now_btn font-Mulish become-mentor-btn"
              onClick={openMobilePopupClick}
            >
              Apply Now
            </Link>
          </div>
          <br className="br_mobile" />
          <div className="brands-container">
            <div className="brands">
              <div>
                <img src={brand1} alt="" className="brands-img" />
              </div>
              <div>
                <img src={brand2} alt="" className="brands-img" />
              </div>
              <div>
                <img src={brand3} alt="" className="brands-img" />
              </div>
              <div>
                <img src={brand4} alt="" className="brands-img" />
              </div>
              <div>
                <img src={brand5} alt="" className="brands-img" />
              </div>
              <div>
                <img src={brand6} alt="" className="brands-img" />
              </div>
              {/* <img src={brandsImgDesk} alt="" className="img_desk" />
              <img src={brandsImgMob} alt="" className="img_mobile" /> */}
            </div>
          </div>
        </div>
      </section>
      <section className="become-mentor-section-one">
        <div className="become-mentor-section_one_container">
          <h2 className="become-mentor-section-one-heading">
            What makes us different
          </h2>
          <div className="become-mentor-section-body">
            <div className="become-mentor-section-body-desktop">
              <BecomeMentorSection1Desktop />
            </div>
            <div className="become-mentor-section-body-mobile">
              <BecomeMentorSection1Mobile />
            </div>
            <div></div>
          </div>
        </div>
      </section>
      <section className="become-mentor-section-two">
        <div className="container_narrow become-mentor-section_two_container">
          <h2 className="heading">How it works</h2>
          <div className="become-mentor-section-two-body">
            <div className="steps-container">
              <div className="steps">
                <div className="step">
                  <div className="step-icon">
                    <img src={section2step1} alt="" />
                  </div>
                  <div className="step-content">
                    <div className="step-no">Step 1</div>
                    <div className="step-desc">
                      Apply to be a mentor for your selected industry
                    </div>
                  </div>
                </div>
              </div>
              <div className="steps">
                <div className="step">
                  <div className="step-icon">
                    <img src={section2step2} alt="" />
                  </div>
                  <div className="step-content">
                    <div className="step-no">Step 2</div>
                    <div className="step-desc">
                      Decide on preferred support areas (e.g., resume review)
                      and complete your profile{" "}
                    </div>
                  </div>
                </div>
              </div>
              <div className="steps">
                <div className="step">
                  <div className="step-icon">
                    <img src={section2step3} alt="" />
                  </div>
                  <div className="step-content">
                    <div className="step-no">Step 3</div>
                    <div className="step-desc">
                      Sync your calendar(s), enter availability preferences, and
                      leave scheduling to us{" "}
                    </div>
                  </div>
                </div>
              </div>
              <div className="steps">
                <div className="step">
                  <div className="step-icon">
                    <img src={section2step4} alt="" />
                  </div>
                  <div className="step-content">
                    <div className="step-no">Step 4</div>
                    <div className="step-desc">
                      View upcoming sessions with clear preset agendas in your
                      dashboard{" "}
                    </div>
                  </div>
                </div>
              </div>
              <div className="steps">
                <div className="step">
                  <div className="step-icon">
                    <img src={section2step5} alt="" />
                  </div>
                  <div className="step-content">
                    <div className="step-no">Step 5</div>
                    <div className="step-desc">
                      Attend session, coach your mentee, and leave feedback on
                      the platform{" "}
                    </div>
                  </div>
                </div>
              </div>
              <div className="steps">
                <div className="step">
                  <div className="step-icon">
                    <img src={section2step6} alt="" />
                  </div>
                  <div className="step-content">
                    <div className="step-no">Step 6</div>
                    <div className="step-desc">
                      Track your session history and earn community service
                      hours for your contributions{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="become-mentor-section-three">
        <div className="container_narrow become-mentor-section_three_container">
          <div className="row become-mentor-section-three-row">
            <div className="col-7 become-mentor-section-three-leftCol">
              <div className="strike-heading">OUR FOCUS</div>
              <div className="heading">
                Professional growth for <br className="br_mobile" />
                <br className="br_desk" /> the underserved and
                <br className="br_mobile" /> underrepresented
              </div>
              <p className="desc">
                We are dedicated to people whose social,
                <br className="br_mobile" /> educational,
                <br className="br_desk" /> economic, or familial background
                <br className="br_mobile" /> didn’t offer the conditions to{" "}
                <br className="br_desk" />
                break into
                <br className="br_mobile" /> traditionally hyper-selective
                career paths. <br /> <br /> Some examples of who we prioritize:
              </p>
              <div className="examples-container">
                <div className="example-item example-pill-container">
                  <span>BIPOC</span>
                  <span>Latinx</span>
                  <span>Women</span>
                </div>
                <div className="example-item">
                  Low-income or single-parent households
                </div>
                <div className="example-item">First-gen college students</div>
                <div className="example-item">Non-target school graduates</div>
              </div>
              <p className="desc">
                Given COVID-19, we are also temporarily serving those who have
                lost employment as a result of the pandemic. <br /> <br /> Do
                you share our purpose?
              </p>
            </div>

            <div className="col-5 become-mentor-section-three-rightCol">
              <img src={section3rightCol} alt="" />
            </div>
          </div>
        </div>
      </section>
      <section className="become-mentor-section-four">
        <div className="become-mentor-section_four_container">
          <h2 className="heading">We know your time is valuable</h2>
          <p className="desc">
            Cardo’s built-in features make paying it
            <br className="br_mobile" /> forward hassle-free
          </p>
          <div className="become-mentor-section-four-row-container">
            <div className="become-mentor-section-four-row-blue">
              <div
                className="become-mentor-section-four-box"
                style={{ backgroundImage: `url('${firstScreenShotBg}')` }}
              >
                <img src={firstScreenShot} alt="" className="img_desk" />
                <img
                  src={firstScreenShotMobile}
                  alt=""
                  className="img_mobile "
                />
              </div>
              <div className="become-mentor-section-four-row-blue-col-two">
                <div className="col-strike-text">GIVE BACK ON YOUR TIME</div>
                <h2 className="col-heading">
                  Easily set your
                  <br className="br_desk" /> availability
                  <br className="br_mobile" /> and manage
                  <br className="br_desk" /> your
                  <br className="br_mobile" /> schedule
                </h2>
                <p className="col-desc">
                  With just a few clicks, connect multiple calendars{" "}
                  <br className="br_mobile" /> and
                  <br className="br_desk" /> set availability parameters to
                  manage your
                  <br className="br_mobile" /> schedule in
                  <br className="br_desk" /> real-time. Never worry about
                  <br className="br_mobile" /> a double-booking again.
                </p>
              </div>
            </div>

            <div className="become-mentor-section-four-row-pink">
              <div className="become-mentor-section-four-row-pink-col-one">
                <div className="col-strike-text">help drive results</div>
                <h2 className="col-heading">
                  Go into every
                  <br className="br_mobile" /> conversation feeling{" "}
                  <br className="br_mobile" />
                  informed{" "}
                </h2>
                <p className="col-desc">
                  View your mentee’s background and the session{" "}
                  <br className="br_mobile" /> agenda ahead of time. Based on
                  your preferences, <br className="br_mobile" /> mentees can
                  book sessions related to:
                </p>
                <div className="col-pill-container">
                  <span>LinkedIn Profile Support</span>
                  <span>Case Interview Preparation</span>

                  <span>Cover Letter Support</span>

                  <span>Behavioral Interview Preparation</span>

                  <span>Resume Support</span>

                  <span>Industry Overview & Insights</span>
                </div>
              </div>
              <div
                className="become-mentor-section-four-box"
                style={{ backgroundImage: `url('${secondScreenShotBg}')` }}
              >
                <img src={secondScreenShot} alt="" className="img_desk" />
                <img
                  src={secondScreenShotMobile}
                  alt=""
                  className="img_mobile"
                />
              </div>
            </div>
            <div className="become-mentor-section-four-row-blue">
              <div
                className="become-mentor-section-four-box"
                style={{ background: `url('${thirdScreenShotBg}')` }}
              >
                <img src={thirdScreenShot} alt="" className="img_desk" />
                <img
                  src={thirdScreenShotMobile}
                  alt=""
                  className="img_mobile"
                />
              </div>
              <div className="become-mentor-section-four-row-blue-col-two">
                <div className="col-strike-text">See your impact</div>
                <h2 className="col-heading">
                  Seamlessly keep track
                  <br className="br_desk" /> of
                  <br className="br_mobile" /> your mentorship
                  <br className="br_mobile" />
                  <br className="br_desk" /> contributions{" "}
                </h2>
                <p className="col-desc">
                  Take the guess work out of your contributions.
                  <br className="br_mobile" />
                  <br className="br_desk" /> Easily view your impact on your
                  custom
                  <br className="br_mobile" /> dashboard
                  <br className="br_desk" /> driven by our built-in analytics
                  features.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="become-mentor-section-five">
        <div className="become-mentor-section_five_container">
          <div className="row become-mentor-section-five-row">
            <div className="col-6 become-mentor-section-five-leftCol">
              <img src={becomeMentorSection5} alt="" />
            </div>
            <div className="col-6 become-mentor-section-five-rightCol">
              <div>
                <h2 className="section-five-heading">
                  Frequently asked questions
                </h2>

                <Accordian
                  data={[
                    {
                      title: (
                        <h3 className="title-text">
                          Is there a required time commitment?
                        </h3>
                      ),
                      content: (
                        <p>
                          No. However, we recommend that our{" "}
                          <br className="br_mobile" /> mentors contribute{" "}
                          <br className="br_desk" />a minimum of 2{" "}
                          <br className="br_mobile" /> hours per month.
                        </p>
                      ),
                    },
                    {
                      title: (
                        <h3 className="title-text">
                          What if I have to cancel or reschedule?
                        </h3>
                      ),
                      content: (
                        <p>
                          Not a problem. Easily view, cancel, or reschedule
                          upcoming <br className="br_desk" /> sessions directly
                          <br className="br_mobile" /> from your dashboard.
                        </p>
                      ),
                    },
                    {
                      title: (
                        <h3 className="title-text">
                          How is my mentorship contribution <br /> tracked?
                        </h3>
                      ),
                      content: (
                        <p>
                          Our platform auto-tracks the sessions{" "}
                          <br className="br_mobile" /> you book and{" "}
                          <br className="br_desk" />
                          attend, so that you can <br className="br_mobile" />{" "}
                          view your impact in real-time.
                        </p>
                      ),
                    },
                    {
                      title: (
                        <h3 className="title-text">How are mentees vetted?</h3>
                      ),
                      content: (
                        <p>
                          Each mentee goes through a thorough application and{" "}
                          <br className="br_desk" />
                          screening process to ensure your time will be well
                          spent.{" "}
                        </p>
                      ),
                    },
                    {
                      title: (
                        <h3 className="title-text">
                          Can I join from my phone?
                        </h3>
                      ),
                      content: (
                        <p>
                          No. Currently, we only support access to the platform
                          via <br className="br_desk" /> desktop. We want to{" "}
                          <br className="br_mobile" />
                          make sure each session is intentful.{" "}
                        </p>
                      ),
                    },
                  ]}
                />
                <div className="apply-now-container">
                  <Link to={isDesktop ? "/sign-up" : "#"}>
                    <button
                      className="pink-btn apply_now_btn font-Mulish become-mentor-btn"
                      onClick={openMobilePopupClick}
                    >
                      Apply Now
                    </button>
                  </Link>
                </div>
                <p
                  style={{
                    fontWeight: "300",
                    lineHeight: "22px",
                    fontFamily: "Neometric Regular",
                  }}
                  className="contact-for-help-text"
                >
                  Still have questions? Reach out to us{" "}
                  <a href="mailto:support@hellocardo.org">support@hellocardo.org</a>, we’d be happy to help!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default BecomeMentor;
