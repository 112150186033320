import React, { useEffect, useState } from "react";
import lightLogo from "../../../assets/sign-up/Group 396.svg";
import leftColMainImg from "../../../assets/sign-in/Group 739.svg";
import "../../../styles/signin/sign-in.css";
import { LinkedIn } from "react-linkedin-login-oauth2";
import { helper } from "../../../helper/helper";
import { Link } from "react-router-dom";
import {
  signin,
  clearErrors,
} from "../../../state-management/actions/authActions";
import { connect } from "react-redux";
import { getCsurfToken } from "../../../state-management/actions/getCsrfToken";
const SignIn = (props) => {
  useEffect(() => {
    if (props?.errors?.userNotExists) {
      customLinkedInError(
        <div>
          <div className="signup-form-validation">
            Oops! We didn’t find an account with those credentials. <br />{" "}
          </div>
          <div className="sign-in-user-exists-error">
            Please apply to create an account. <br /> <br /> If you believe this
            message is an error, reach out <br /> at{" "}
            <a href="mailto:support@hellocardo.org" className="sign-in-link">
              support@hellocardo.org
            </a>
            .
          </div>
        </div>
      );
    }
    if (props?.errors?.userRejected) {
      customLinkedInError(
        <div>
          <div className="signup-form-validation">
            This user already exists. <br />{" "}
          </div>
          <div className="sign-in-user-exists-error">
            Please contact <br />
            <a href="mailto:support@hellocardo.org" className="sign-in-link">
              support@hellocardo.org
            </a>
            .
          </div>
        </div>
      );
    }
  }, [props?.errors]);
  useEffect(() => {
    props.clearErrors();
    setLinkedinStatus({
      error: "",
      code: "",
    });
  }, []);
  const [linkedinStatus, setLinkedinStatus] = useState({
    error: "",
    code: "",
  });

  const handleLinkedInSuccess = (data) => {
    setLinkedinStatus({
      code: data.code,
      error: "",
    });
    props.signin(data.code);
  };
  const customLinkedInError = (msg) => {
    setLinkedinStatus({
      code: "",
      error: msg,
    });
  };
  const handleLinkedInFailure = (error) => {
    setLinkedinStatus({
      code: "",
      error: "Please Login To Submit Form",
    });
  };
  const { error, code } = linkedinStatus;

  return (
    <div className="row sign-in-row">
      <div className="col-6 sign-in-leftCol">
        <div className="sign-in-leftCol-container">
          <div className="sign-in-logo-container ">
            <Link to="/">
              <img src={lightLogo} alt="" />
            </Link>
          </div>
          <div className="leftCol-mainImg-container">
            <img src={leftColMainImg} alt="" />
          </div>
        </div>
      </div>
      <div className="col-6 sign-in-rightCol">
        <div className="sign-in-rightCol-container">
          <h1 className="sign-in-rightCol-heading">Welcome Back</h1>
          <p className="font-Neometric-Regular sign-in-rightCol-desc">
            Cardo makes career mentorship accessible for all. <br /> Sign in to
            provide or access mentorship for free.
          </p>
          <div className="sign-in-rightCol-linkedin-btn-container">
            <LinkedIn
              clientId={helper.linkedinClientId}
              onFailure={handleLinkedInFailure}
              onSuccess={handleLinkedInSuccess}
              redirectUri={helper.linkedinRedirectUrl}
              scope="r_liteprofile r_emailaddress"
            >
              <div className="login-with-linkedin" />
            </LinkedIn>
            <div className="sign-in-rightCol-linkedin-btn-container-error">
              {error && <div>{error}</div>}
            </div>
          </div>
          <div className="font-eina sign-in-dont-have-account-text">
            Don't have an account?{" "}
            <Link className="sign-in-link" to="sign-up">
              Apply now
            </Link>
            .
          </div>
          <div className="font-eina sign-in-terms-of-agreement">
            By using Cardo, you agree to our{" "}
            <Link className="sign-in-link" to="terms-of-service">
              Terms of Use
            </Link>{" "}
            and <br />
            <Link className="sign-in-link" to="privacy-policy">
              Privacy Policy
            </Link>
            .
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  errors: state.errors,
});
export default connect(mapStateToProps, { signin, getCsurfToken, clearErrors })(
  SignIn
);
