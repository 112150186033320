import React, { useEffect, useState } from "react";
import { adminDashboardContent } from "../../../../helper/admin/adminDashboardContent";
import ContentTabCard from "./content-tab-components/ContentTabCard";
import "../../../../styles/dashboard/admin-dasboard/content-tab/content-tab.css";
import {
  saveLink,
  getLinks,
} from "../../../../state-management/actions/admin/adminDashboard";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import MenteeMentorBtnContainer from "../ru-admin-components/MenteeMentorBtnContainer";
const ContentTab = (props) => {
  const [contentData, setContentData] = useState();
  const [save, setSave] = useState();
  const [activeBtn, setActiveBtn] = useState("mentee");

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    props.getLinks();
  }, []);
  useEffect(() => {
    if (props.links) {
      setContentData(props.links);
    }
    if (props?.savedLink) {
      setLoading(false);
      setSave(props.savedLink.title);
    }
  }, [props]);

  const onSaveClick = (e, index) => {
    e.preventDefault();
    setLoading(true);
    const data = contentData[index];
    data.title = e.target.id;

    props.saveLink(data);
  };
  const handleContentInput = (key, keyIndex, e) => {
    const { value, name } = e.target;

    let updateColumns = contentData[key].columns;

    updateColumns[keyIndex].title = value;
    setContentData((prev) =>
      prev.map((item, index) => {
        if (index === key) {
          return { ...item, columns: updateColumns };
        } else {
          return item;
        }
      })
    );
  };

  const handleContentSubInput = (key, index1, index2, e, n) => {
    //...prev, prev[area].columns[index1].column[index2].link = value
    const { name, value } = e.target;
    let subProperty = name.split("-")[0];
    let updateColumns = contentData[key].columns;
    updateColumns[index1].column[index2][subProperty] = value;

    setContentData((prev) =>
      prev.map((item, index) => {
        if (index === key) {
          return { ...item, columns: updateColumns };
        } else {
          return item;
        }
      })
    );
  };

  let renderContentTabContentData = (area, index) => {
    if (area !== "info") {
      let cardContent = contentData[index]?.columns;

      while (
        contentData[index]?.columns?.length <
        contentData[contentData.length - 1]?.limit
      ) {
        cardContent.push({
          title: "",
          column: [
            { link_title: "", link: "" },
            { link_title: "", link: "" },
          ],
        });
      }
      // cardContent?.push.apply(cardContent, {
      //   length: contentData?.info?.limit - cardContent?.length,
      // });
      //
      return (
        <div key={area}>
          <div className="admin_dashboard_content_area_container">
            <h2 className="admin_dashboard_content_area_title">
              {area.replace(/_/g, " ")}
            </h2>
            <div
              className="cardo-btn btn-bg-lightblue admin_dashboard_save_btn"
              onClick={(e) => onSaveClick(e, index)}
              id={area}
            >
              Save{" "}
            </div>
            {!loading &&
              save &&
              save === area.replace(/_/g, " ").toLowerCase() &&
              "✓"}
            {loading && "moment..."}
          </div>
          <ContentTabCard
            area={area}
            index={index}
            cardContent={cardContent}
            handleContentInput={handleContentInput}
            handleContentSubInput={handleContentSubInput}
          />
        </div>
      );
    }
  };
  let renderContentTabContent = () => {
    let arr = [];
    contentData.map((area, index) => {
      arr.push(renderContentTabContentData(area.title, index));
    });

    return arr;
  };
  return (
    <div>
      <MenteeMentorBtnContainer
        activeBtn={activeBtn}
        handleActiveBtn={setActiveBtn}
      />
      {contentData && renderContentTabContent()}
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: state.loading,
  links: state.admin.links,
  savedLink: state.admin.saveLink,
});
export default connect(mapStateToProps, { saveLink, getLinks })(
  withRouter(ContentTab)
);
