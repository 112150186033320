import axios from 'axios';
import { helper } from '../../../../helper/helper';
import {
    MENTOR_DASHBOARD_EDIT_PROFILE,
    MENTOR_DASHBOARD_MENTOR_EDIT_MENTORSHIP,
    MENTOR_DASHBOARD_NEW_IMAGE,
    MENTOR_DASHBOARD_POST_EMAIL_INVITATION,
    MENTOR_DASHBOARD_GET_INVITATION_STATUS,
    MENTOR_PROFILE_TOGGLE,
    GET_ERRORS
} from '../../../types/types'
import Cookies from 'universal-cookie';
const cookies = new Cookies();

export const editMentorProfile = (data) => dispatch => {
    axios.get(`${helper.baseUrl}/users/mentor/dashboard/getMentorDashboardEditProfile`, { withCredentials: true })
        .then(res => {
            dispatch({
                type: MENTOR_DASHBOARD_EDIT_PROFILE,
                payload: res.data
            })
        })
        .catch(err => console.log(err.response))
}

export const putMentorProfileMentorship = (data, handleLoading) => dispatch => {
    axios.put(`${helper.baseUrl}/users/mentor/dashboard/putMentorDashboardEditMentorship`, data, { withCredentials: true })
        .then(res => {
            handleLoading(false);
            dispatch({
                type: MENTOR_DASHBOARD_MENTOR_EDIT_MENTORSHIP,
                payload: res.data
            })
        })
        .catch(err => console.log(err.response))
}

export const putEditMentorProfile = (data, handleLoading) => dispatch => {

    axios.put(`${helper.baseUrl}/users/mentor/dashboard/putMentorDashboardEditProfile`,
        data,
        { withCredentials: true })
        .then(res => {
            handleLoading(false)
            dispatch({
                type: MENTOR_DASHBOARD_EDIT_PROFILE,
                payload: res.data
            })
        })
        .catch(err => console.log(err.response))
}

export const putNewImage = (data, handleLoading) => dispatch => {

    axios.post(`${helper.baseUrl}/users/mentor/dashboard/putNewImage`,
        data,


        { withCredentials: true })
        .then(res => {
            handleLoading(false)
            dispatch({
                type: MENTOR_DASHBOARD_NEW_IMAGE,
                payload: res.data
            })
        })
        .catch(err => console.log(err.response))
}


export const postDeleteAccount = (handleLoading) => {

    axios.post(`${helper.baseUrl}/users/mentor/dashboard/postDeleteAccount`,
        {
            headers: { 'x-csrf-token': cookies.get('XSRF-TOKEN') }
        },
        { withCredentials: true })
        .then(res => {
            handleLoading(false)
            if (res.status === 200) {
                window.location.href = '/'
            }
        })
        .catch(err => console.log(err.response))
}


export const postEmailInvitation = (data, handleLoading, setSteps) => dispatch => {

    axios.post(`${helper.baseUrl}/calendly/postInvitation`,
        { email: data },


        { withCredentials: true })
        .then(res => {
            setSteps((prev) => {
                return { ...prev, step1: true };
            });
            handleLoading(false)
            dispatch({
                type: MENTOR_DASHBOARD_POST_EMAIL_INVITATION,
                payload: res.data
            })
        })
        .catch(err => {
            handleLoading(false)
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        })
}

export const getUserInvitationStatus = (handleLoading, setStatus) => dispatch => {
    axios.get(`${helper.baseUrl}/calendly/getUserInvitationStatus`,
        { withCredentials: true })
        .then(res => {
            setStatus(res.data.status)
            handleLoading(false)
            dispatch({
                type: MENTOR_DASHBOARD_GET_INVITATION_STATUS,
                payload: res.data
            })
        })
        .catch(err => {
            handleLoading(false)
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        })
}

export const getProfileToggleState = () => dispatch => {

    axios.get(`${helper.baseUrl}/users/mentor/dashboard/getProfileToggleState`,

        { withCredentials: true })
        .then(res => {

            dispatch({
                type: MENTOR_PROFILE_TOGGLE,
                payload: res.data
            })
        })
        .catch(err => console.log(err.response))
}
export const putProfileToggle = (data, handleLoading, setToggle) => dispatch => {

    axios.put(`${helper.baseUrl}/users/mentor/dashboard/putProfileToggle`,
        data,
        { withCredentials: true })
        .then(res => {
            setToggle(res.data.isProfileActive)
            return handleLoading(false)
            // dispatch({
            //     type: MENTOR_PROFILE_TOGGLE,
            //     payload: res.data
            // })
        })
        .catch(err => console.log(err.response))
}
