import React, { useEffect, useState } from "react";
import Checkbox from "../../profile-form-components/Checkbox";
import InlineField from "../../profile-form-components/InlineField";
import TextArea from "../../profile-form-components/TextArea";
import TextField from "../../profile-form-components/TextField";

import aprooved from "../../../../../assets/mentee-dashboard-profile-edit/aprooved.png";

import { putEditMenteeProfile } from "../../../../../state-management/actions/dashboard/mentee-dashboard/profileActions";
import { connect } from "react-redux";
import NumberField from "../../profile-form-components/NumberField";
import RadioBtnGroup from "../../profile-form-components/RadioBtnGroup";
import FileInput from "../../profile-form-components/FileInput";

const MenteeInformationTab = (props) => {
  const {
    currentUser,
    informationData,
    handleInformationData,
    handleInformationNumber,
    handleCheckboxChange,
    putEditMenteeProfile,
    handleRadioChange,
    fileChangeHandler,
    resumeUniqueId,
  } = props;
  const [loading, setLoading] = useState(false);
  const [saved, setSaved] = useState(false);
  const [errorMsg, setErrorMsg] = useState({
    emailError: "",
    myStoryError: "",
    educationError: "",
    gpaError: "",
    interestedIn: "",
    whatMakesMeUniqueError: "",
  });
  const handleLoading = (val) => {
    setLoading(val);
  };

  const pattern =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const validate = () => {
    setErrorMsg({
      ...errorMsg,
      emailError:
        !informationData.preferredEmailForCommunication.match(pattern) &&
        "Either Email is wrong or empty!",
      myStoryError: !informationData.myStory && "My Story is required!",
      gpaError:
        (informationData.gpa === "" ||
          +informationData.gpa > 4 ||
          +informationData.gpa < 0) &&
        "gpa is required!",
      interestedInError:
        (!informationData.interestedIn.value ||
          informationData.interestedIn.value.length === 0) &&
        "What Makes Me Unique is required!",
      educationError: !informationData.education && "Education is required!",
      whatMakesMeUniqueError:
        !informationData.whatMakesMeUnique.value &&
        "What Makes Me Unique is required!",
    });

    return (
      informationData.preferredEmailForCommunication.match(pattern) &&
      informationData.myStory &&
      informationData.education &&
      informationData.gpa !== "" &&
      +informationData.gpa < 4 &&
      +informationData.gpa > 0 &&
      informationData.interestedIn.value &&
      informationData.interestedIn.value.length > 0 &&
      informationData.whatMakesMeUnique.value
    );
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      handleLoading(true);

      var formData = new FormData(); // Currently empty
      formData.append("education", informationData.education);
      if (informationData.fileInput.fileName !== "No file chosen") {
        formData.append(
          "resume",
          informationData.fileInput.value,
          informationData.fileInput.value.name
        );
      }
      formData.append("gpa", informationData.gpa);
      formData.append("interestedIn", informationData.interestedIn.value);
      formData.append("myStory", informationData.myStory);
      formData.append(
        "preferredEmailForCommunication",
        informationData.preferredEmailForCommunication
      );
      formData.append("stage", informationData.stage.value);
      if (informationData.whatMakesMeUnique.value.length === 0) {
        formData.append("whatMakesMeUnique", ["N/A"]);
      } else {
        formData.append(
          "whatMakesMeUnique",
          informationData.whatMakesMeUnique.value
        );
      }
      formData.append("resumeUniqueId", resumeUniqueId);
      await putEditMenteeProfile(formData, handleLoading);
      setSaved(true);
    } else {
      return false;
    }
  };
  const {
    preferredEmailForCommunication,
    myStory,
    education,
    gpa,
    interestedIn,
    stage,
    whatMakesMeUnique,
    fileInput,
    resume,
  } = informationData;

  const [count, setCount] = useState(0);
  const handleCount = (e) => {
    handleInformationData(e);
  };
  useEffect(() => {
    setCount(myStory.length);
  }, [myStory.length]);
  const onKeyDown = (e) => {};

  return (
    <div className="mentee-profile-information">
      <h2>Information</h2>
      <div className="mentee-profile-form-wrapper">
        <form onSubmit={(e) => handleSubmit(e)}>
          <div className="form-line">
            <InlineField>
              <TextField
                id="mentorProfileFirstName"
                label="First Name"
                placeholder="First Name"
                value={currentUser.firstName}
                name="mentorProfileFirstName"
                disabled
                toolTip={
                  <div>
                    First and last name details are uneditable to ensure we
                    maintain a safe community. If you’d like to request a
                    change, please contact our support team.
                  </div>
                }
              />
              <TextField
                id="mentorProfileLastName"
                label="Last Name"
                placeholder="Last Name"
                value={currentUser.lastName}
                name="mentorProfileLastName"
                disabled
                toolTip={
                  <div>
                    First and last name details are uneditable to ensure we
                    maintain a safe community. If you’d like to request a
                    change, please contact our support team.
                  </div>
                }
              />
            </InlineField>
          </div>
          <div className="form-line">
            <TextField
              id="preferredEmailForCommunication"
              label="Preferred Email For Communication"
              placeholder=""
              name="preferredEmailForCommunication"
              value={preferredEmailForCommunication}
              onChange={handleInformationData}
              errorMsg={errorMsg.emailError}
              toolTip={
                <div>
                  Notifications are sent by default to the e-mail address
                  associated with your LinkedIn. If you would like to update
                  your e-mail notification address, you can do so here.
                </div>
              }
            />
          </div>
          <div className="form-line" style={{ position: "relative" }}>
            <TextArea
              id="myStory"
              label="My Story (Your Biography)"
              placeholder=""
              name="myStory"
              errorMsg={errorMsg.myStoryError}
              value={myStory}
              onChange={handleCount}
              onKeyDown={onKeyDown}
              toolTip={
                <div>
                  Share your story with your mentor! Write a brief note on your
                  background and aspirations.
                </div>
              }
            />
            <div
              className="font-Neometric-Regular"
              style={{
                position: "absolute",
                bottom: "15px",
                right: "15px",
                fontSize: "14px",
                color: "rgba(85, 85, 219, 0.4)",
              }}
            >
              {300 - count} characters left
            </div>
          </div>

          <div className="form-line">
            <TextField
              id="education"
              label="Education"
              placeholder=""
              name="education"
              errorMsg={errorMsg.educationError}
              value={education}
              disabled={true}
              onChange={handleInformationData}
              toolTip={
                <div>
                  Education details are provided during application and cannot
                  be changed. If you would like to request a change or update,
                  please contact our support team.
                </div>
              }
            />
          </div>

          <div className="form-line">
            <NumberField
              id="gpa"
              label="GPA"
              placeholder=""
              name="gpa"
              disabled={true}
              errorMsg={errorMsg.gpaError}
              value={gpa}
              onChange={handleInformationNumber}
              step={0.1}
              minValue="0"
              maxValue="4"
              toolTip={
                <div>
                  While GPA details are optional, they can help mentors tailor
                  guidance for you. If you would like to add, remove, or edit
                  your GPA details, please contact our support team.
                </div>
              }
            />
          </div>
          <div className="form-line">
            <RadioBtnGroup
              id="interestedIn"
              label="Interested In"
              name="interestedIn"
              value={interestedIn.value}
              inputValues={interestedIn.inputValues}
              onChange={handleRadioChange}
              toolTip={
                <div>
                  Select what kind of opportunities you are looking for. Mentors
                  can tailor guidance based on your selection.
                </div>
              }
            />
          </div>
          <div className="form-line">
            <RadioBtnGroup
              id="stage"
              label="Stage"
              name="stage"
              value={stage.value}
              inputValues={stage.inputValues}
              onChange={handleRadioChange}
              disabled
              toolTip={
                <div>
                  Details regarding your recruitment stage are provided during
                  application. If you’d like to change or update, please contact
                  our support team.
                </div>
              }
            />
          </div>
          <div className="form-line">
            <Checkbox
              id="whatMakesMeUnique"
              label="What Makes Me Unique"
              name="whatMakesMeUnique"
              value={whatMakesMeUnique.value}
              maxSelected={3}
              inputValues={whatMakesMeUnique.inputValues}
              onChange={handleCheckboxChange}
              errorMsg={errorMsg.whatMakesMeUniqueError}
              toolTip={
                <div>
                  Let people know what makes you unique! Select up to three to
                  share on your profile.
                </div>
              }
            />
          </div>

          <div className="form-line" style={{ marginTop: "30px" }}>
            <FileInput
              id="fileInput"
              label="Resume Upload"
              text="Resume Upload"
              name="fileInput"
              buttonText="Upload"
              fileName={fileInput.fileName}
              onChange={fileChangeHandler}
              toolTip={
                <div>
                  Keep a updated copy of your resume here (in PDF or Word
                  format) so your mentor can access it. Resumes provided during
                  application are set as the default.
                </div>
              }
              resume={
                resume ? (
                  <a className="cardo-link" href={resume} target="_blank">
                    {resume && resume.split("/")[resume.split("/").length - 1]}
                  </a>
                ) : null
              }
            />
          </div>

          <div style={{ display: "flex", alignItems: "center" }}>
            <button
              type="submit"
              className="cardo-btn btn-bg-lightblue save-btn"
              // style={{ margin: "20px 10px 10px 10px" }}
            >
              {!loading ? (
                "Save Changes"
              ) : (
                <div className={"form-loader form-loader-active"}>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              )}
            </button>
            {saved && !loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: "10px",
                }}
              >
                <div
                  className="font-Neometric-Medium"
                  style={{
                    color: "black",
                  }}
                >
                  Changes Saved{" "}
                </div>
                <img style={{ marginLeft: "10px" }} src={aprooved} alt="" />
              </div>
            ) : (
              ""
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default connect(null, { putEditMenteeProfile })(MenteeInformationTab);
