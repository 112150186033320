import React, { Component } from "react";

export class Panel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      height: 0,
    };
  }

  componentDidMount() {
    window.setTimeout(() => {
      const height = 73;
      this.setState({
        height,
      });
    }, 333);
  }

  render() {
    const { label, content, activeTab, index, activateTab } = this.props;
    const { height } = this.state;
    const isActive = activeTab === index;
    const innerStyle = {
      height: `${isActive ? height : 0}px`,
    };

    return (
      <div className="panel" role="tabpanel" aria-expanded={isActive}>
        <button
          className={
            label === "Finance" || label === "Product"
              ? `panel__label_disabled`
              : `panel__label`
          }
          role="tab"
          onClick={activateTab}
          data-area-trail={label}
          disabled={label === "Finance" || label === "Product"}
        >
          <i
            className="fa fa-angle-down"
            style={{ marginRight: "10px", color: "inherit" }}
          ></i>
          {label}
        </button>
        <div
          className="panel__inner"
          style={innerStyle}
          aria-hidden={!isActive}
        >
          <div className="panel__content">{content}</div>
        </div>
      </div>
    );
  }
}

export default Panel;
