import React, { useState } from "react";
import "../../../../../styles/dashboard/admin-dasboard/content-tab/content-tab-cards.css";

const ContentTabCard = (props) => {
  const {
    cardContent,
    handleContentInput,
    index,
    area,
    handleContentSubInput,
  } = props;

  return (
    <div>
      <div className="admin_dashboard_content_cards_container">
        {cardContent &&
          cardContent?.map((info, index1) => {
            if (typeof info !== "undefined") {
              return (
                <div key={index1} className="admin_dashboard_content_card">
                  <div style={{ marginBottom: "20px", position: "relative" }}>
                    <input
                      className="admin_dashboard_content_card_input admin_dashboard_content_card_input_title"
                      onChange={(e) =>
                        e.target.value.length <= 30 &&
                        handleContentInput(index, index1, e)
                      }
                      name={`${area}${index1}`}
                      value={info.title ?? ""}
                      placeholder={"empty"}
                    />
                    <div className="font-eina admin_dashboard_content_input_counter">
                      {`${info.title.length}/${30}`}
                    </div>
                  </div>
                  {info?.column?.map((col, index2) => {
                    return (
                      <div
                        key={index2}
                        className="admin_dashboard_content_card_content"
                      >
                        <div className="admin_dashboard_content_card_content_wrapper">
                          <div style={{ position: "relative" }}>
                            <textarea
                              rows={2}
                              className="admin_dashboard_content_card_input admin_dashboard_content_card_input_link_title"
                              onChange={(e) =>
                                e.target.value.length <= 50 &&
                                handleContentSubInput(
                                  index,
                                  index1,
                                  index2,
                                  e,
                                  "link_title"
                                )
                              }
                              name={`${Object.keys(col)[0]}-${index1}`}
                              value={col.link_title ?? ""}
                              placeholder="empty"
                            ></textarea>
                            <div className="font-eina admin_dashboard_content_input_counter">
                              {`${col.link_title.length}/${50}`}
                            </div>
                          </div>
                          <div>
                            <input
                              className="admin_dashboard_content_card_input admin_dashboard_content_card_input_link"
                              onChange={(e) =>
                                handleContentSubInput(
                                  index,
                                  index1,
                                  index2,
                                  e,
                                  "link"
                                )
                              }
                              name={`${Object.keys(col)[1]}-${index1}`}
                              value={col.link ?? ""}
                              placeholder="empty"
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            } else {
            }
          })}
      </div>
    </div>
  );
};

export default ContentTabCard;
