import React from "react";
import menteeHomeBannerImg from "../../../../../assets/dashboard/mentee-dashboard/mentee-dashboard-home/Frame.svg";

const MentorDashboardHomeBanner = (props) => {
  const { currentUser } = props;
  return (
    <div className="mentee-dashboard-home-welcome-banner">
      <div className="mentee-dashboard-home-welcome-banner-image-container">
        <img src={menteeHomeBannerImg} alt="" />
      </div>
      <div className="mentee-dashboard-home-welcome-banner-heading-container">
        <h3>Welcome,</h3>
        <h3>{currentUser && currentUser.firstName}</h3>
      </div>
    </div>
  );
};

export default MentorDashboardHomeBanner;
