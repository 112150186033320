const validateEmail = email => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export default (fields) => {
  let errors = {};

  for (let field in fields) {
    const currentField = fields[field];
    if (currentField.required && currentField.type === 'select' && currentField.name === "q68_whichCountry" && currentField.value !== 'United States') {
      errors[field] = `Due to a large variance in recruiting processes and approaches, we are
        only accepting mentors who are located in the United States at this
        time.`;
    }
    if (currentField.type === 'number' && currentField.value < currentField.minValue) {
      errors[field] = `Cannot be less than ${currentField.minValue}`;
    }
    if (currentField.type === 'number' && currentField.value > currentField.maxValue) {
      errors[field] = `Cannot be greater than ${currentField.maxValue}`;
    }
    if (currentField.required && currentField.value.length < 0 && currentField.type === 'checkbox') {
      errors[field] = 'This field is required!';
    }
    if (currentField.required && currentField.name === 'q80_withinConsulting80' && currentField.value.length > 2 && currentField.type === 'checkbox') {
      errors[field] = 'The maximum number of selections allowed is 2.';
    }
    if (currentField.required && currentField.name === 'q77_whichAreas' && currentField.value.length > 3 && currentField.type === 'checkbox') {
      errors[field] = 'The maximum number of selections allowed is 3.';
    }

    if (currentField.required && currentField.value === '') {
      errors[field] = 'This field is required!';
    }
    if (currentField.required && currentField.checked === false) {
      errors[field] = 'This field is required!';
    }

    if (currentField.required && currentField.file && !currentField.value.name) {
      errors[field] = 'This field is required!';
    }

    if (!errors[field] && currentField.email && !validateEmail(currentField.value)) {
      errors[field] = 'Invalid email address';
    }

    if (!errors[field] && currentField.minLength && currentField.value.trim().length < currentField.minLength) {
      errors[field] = `This field must have at least ${currentField.minLength} characters`;
    }

    if (!errors[field] && currentField.file && currentField.allowedTypes && !currentField.allowedTypes.includes(currentField.value.name.split('.')[currentField.value.name.split('.').length - 1].toLowerCase())) {
      //  console.log(currentField.value.name.split('.')[currentField.value.name.split('.').length - 1].toLowerCase() === 'mp4')
      errors[field] = 'Invalid file type!';
    }

    if (!errors[field] && currentField.file && currentField.maxFileSize && (currentField.maxFileSize * 1024) < Math.round(currentField.value.size)) {

      errors[field] = `File is too large(${Math.round(currentField.value.size / 1024)}KB), it cannot be larger than ${currentField.maxFileSize}KB`;
    }


  }

  return errors;
}