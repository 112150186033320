import React from "react";
import step1IconActive from "../../../../../assets/sign-up/steps/Group-4.svg";
import step1Icon from "../../../../../assets/sign-up/steps/Group-5.svg";
import step2Icon from "../../../../../assets/sign-up/steps/Group 697.svg";
import step2IconActive from "../../../../../assets/sign-up/steps/Group 463.svg";
import step3Icon from "../../../../../assets/sign-up/steps/Vector-4.svg";
import step3IconActive from "../../../../../assets/sign-up/steps/Group 767.svg";

import step4Icon from "../../../../../assets/sign-up/steps/Vector-5.svg";
import step4IconActive from "../../../../../assets/sign-up/steps/Group 769.svg";

const MentorSteps = (props) => {
  const { children, step, iconClickHandle, data, title } = props;
  return (
    <div>
      <div className="steps-icon-container">
        <div
          className={
            step === 0
              ? `step-icon-container step-icon-container-active`
              : `step-icon-container`
          }
        >
          <div className="step-icon-img-container">
            {step === 0 ? (
              <img src={step1IconActive} alt="" />
            ) : (
              <img src={step1Icon} alt="" />
            )}
          </div>
          <div className="step-icon-title">
            <div className="font-Neometric-Regular step-icon-no">Step 1</div>
            <div className="font-Neometric-Medium step-icon-name">Welcome</div>
          </div>
        </div>
        <div className="divider"></div>
        <div
          className={
            step === 1
              ? `step-icon-container step-icon-container-active`
              : `step-icon-container`
          }
        >
          <div className="step-icon-img-container">
            {step === 1 ? (
              <img src={step2IconActive} alt="" />
            ) : (
              <img src={step2Icon} alt="" />
            )}
          </div>
          <div className="step-icon-title">
            <div className="font-Neometric-Regular step-icon-no">Step 2</div>
            <div className="font-Neometric-Medium step-icon-name">
              Your Background
            </div>
          </div>
        </div>{" "}
        <div className="divider"></div>
        <div
          className={
            step === 2
              ? `step-icon-container step-icon-container-active`
              : `step-icon-container`
          }
        >
          <div className="step-icon-img-container">
            {step === 2 ? (
              <img src={step3IconActive} alt="" />
            ) : (
              <img src={step3Icon} alt="" />
            )}
          </div>
          <div className="step-icon-title">
            <div className="font-Neometric-Regular step-icon-no">Step 3</div>
            <div className="font-Neometric-Medium step-icon-name">
              Your Impact
            </div>
          </div>
        </div>{" "}
        <div className="divider"></div>
        <div
          className={
            step === 3
              ? `step-icon-container step-icon-container-active`
              : `step-icon-container`
          }
        >
          <div className="step-icon-img-container">
            {step === 3 ? (
              <img src={step4IconActive} alt="" />
            ) : (
              <img src={step4Icon} alt="" />
            )}
          </div>
          <div className="step-icon-title">
            <div className="font-Neometric-Regular step-icon-no">Step 4</div>
            <div className="font-Neometric-Medium step-icon-name">Verify</div>
          </div>
        </div>
      </div>
      <div style={{ marginTop: "40px" }}>{children}</div>
    </div>
  );
};

export default MentorSteps;
