import { useCallback, useEffect, useState } from 'react';
import {
    DEFAULT_VIDEO_CONSTRAINTS,
    getDeviceInfo,
    isPermissionDenied,
    SELECTED_AUDIO_INPUT_KEY,
    SELECTED_VIDEO_INPUT_KEY
} from "../helper/videoCall";
import Video from "twilio-video";

export default function useMediaTracks() {
    const [audioTrack, setAudioTrack] = useState();
    const [videoTrack, setVideoTrack] = useState();
    const [localTracks, setLocalTracks] = useState([]);
    const [isAcquiringLocalTracks, setIsAcquiringLocalTracks] = useState(false);

    useEffect(() => {
        setLocalTracks([audioTrack, videoTrack].filter(track => track !== undefined))
    }, [audioTrack, videoTrack]);

    const getLocalAudioTrack = useCallback((deviceId) => {
        const options = {};

        if (deviceId) {
            options.deviceId = { exact: deviceId };
        }

        return Video.createLocalAudioTrack(options).then(newTrack => {
            setAudioTrack(newTrack);
            return newTrack;
        });
    }, []);

    const removeAllTracks = useCallback(() => {
        if (localTracks) {
            localTracks.forEach(t => t && t.disable().stop());
            setLocalTracks([]);
        }
    }, [localTracks]);

    const getLocalVideoTrack = useCallback(async () => {
        const selectedVideoDeviceId = window.localStorage.getItem(SELECTED_VIDEO_INPUT_KEY);

        const { videoInputDevices } = await getDeviceInfo();

        const hasSelectedVideoDevice = videoInputDevices.some(
            device => selectedVideoDeviceId && device.deviceId === selectedVideoDeviceId
        );

        const options = {
            ...(DEFAULT_VIDEO_CONSTRAINTS),
            name: `camera-${Date.now()}`,
            ...(hasSelectedVideoDevice && { deviceId: { exact: selectedVideoDeviceId } }),
        };

        return Video.createLocalVideoTrack(options).then(newTrack => {
            setVideoTrack(newTrack);
            return newTrack;
        });
    }, []);

    const getAudioAndVideoTracks = useCallback(async (setTracks = true) => {
        const {
            audioInputDevices,
            videoInputDevices,
            hasAudioInputDevices,
            hasVideoInputDevices
        } = await getDeviceInfo();
        if (!hasAudioInputDevices && !hasVideoInputDevices) return Promise.resolve();
        if (isAcquiringLocalTracks || audioTrack || videoTrack) return Promise.resolve();

        setIsAcquiringLocalTracks(true);

        const selectedAudioDeviceId = window.localStorage.getItem(SELECTED_AUDIO_INPUT_KEY);
        const selectedVideoDeviceId = window.localStorage.getItem(SELECTED_VIDEO_INPUT_KEY);

        const hasSelectedAudioDevice = audioInputDevices.some(
            device => selectedAudioDeviceId && device.deviceId === selectedAudioDeviceId
        );
        const hasSelectedVideoDevice = videoInputDevices.some(
            device => selectedVideoDeviceId && device.deviceId === selectedVideoDeviceId
        );

        const isCameraPermissionDenied = await isPermissionDenied('camera');
        const isMicrophonePermissionDenied = await isPermissionDenied('microphone');

        const shouldAcquireVideo = hasVideoInputDevices && !isCameraPermissionDenied;
        const shouldAcquireAudio = hasAudioInputDevices && !isMicrophonePermissionDenied;


        const localTrackConstraints = {
            video: shouldAcquireVideo && {
                ...(DEFAULT_VIDEO_CONSTRAINTS),
                name: `camera-${Date.now()}`,
                ...(hasSelectedVideoDevice && { deviceId: { exact: selectedVideoDeviceId } }),
            },
            audio:
                shouldAcquireAudio &&
                (hasSelectedAudioDevice ? { deviceId: { exact: selectedAudioDeviceId } } : hasAudioInputDevices),
        };

        Video.createLocalTracks(localTrackConstraints)
            .then(tracks => {
                const newVideoTrack = tracks.find(track => track.kind === 'video');
                const newAudioTrack = tracks.find(track => track.kind === 'audio');
                if (newVideoTrack) {
                    setVideoTrack(newVideoTrack);
                    // Save the deviceId so it can be picked up by the VideoInputList component. This only matters
                    // in cases where the user's video is disabled.
                    window.localStorage.setItem(
                        SELECTED_VIDEO_INPUT_KEY,
                        newVideoTrack.mediaStreamTrack.getSettings().deviceId ?? ''
                    );
                }
                if (newAudioTrack) {
                    setAudioTrack(newAudioTrack);
                }

                // These custom errors will be picked up by the MediaErrorSnackbar component.
                if (isCameraPermissionDenied && isMicrophonePermissionDenied) {
                    const error = new Error();
                    error.name = 'NotAllowedError';
                    throw error;
                }

                if (isCameraPermissionDenied) {
                    throw new Error('CameraPermissionsDenied');
                }

                if (isMicrophonePermissionDenied) {
                    throw new Error('MicrophonePermissionsDenied');
                }
            }).finally(() => setIsAcquiringLocalTracks(false))
    }, [audioTrack, videoTrack, isAcquiringLocalTracks]);

    const removeLocalAudioTrack = useCallback(() => {
        if (audioTrack) {
            audioTrack.stop();
            setAudioTrack(undefined);
        }
    }, [audioTrack]);

    const removeLocalVideoTrack = useCallback(() => {
        if (videoTrack) {
            videoTrack.stop();
            setVideoTrack(undefined);
        }
    }, [videoTrack]);

    return {
        localTracks,
        audioTrack,
        videoTrack, isAcquiringLocalTracks, getAudioAndVideoTracks,
        getLocalAudioTrack, getLocalVideoTrack, removeLocalAudioTrack,
        removeLocalVideoTrack, removeAllTracks
    }
}
