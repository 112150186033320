import React, { useEffect, useState } from "react";
import "../../../../../../styles/dashboard/mentor-dashboard/mentor-dashboard-invite-modal.css";
import inviteImg from "../../../../../../assets/dashboard/mentor-dashboard/mentor-dashboard-home/Group 876.svg";
import { connect } from "react-redux";
import { sendInviteRequest } from "../../../../../../state-management/actions/dashboard/mentor-dashboard/mentorDashboardActions";
import SendEmailButton from "./invite-popup-components/Button";
const MentorDashboardInviteModal = (props) => {
  function closeModal(e) {
    e.stopPropagation();
    props.closeModal();
  }

  const [emailAddress, setEmailAddress] = useState("");
  const [status, setStatus] = useState();
  const [disableBtn, setDisableBtn] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [error, setError] = useState("");

  const handleEmailAddress = (e) => {
    const { name, value } = e.target;
    setEmailAddress(value);
  };

  useEffect(() => {
    if (props?.error?.errorMsg) {
      setError(props?.error?.errorMsg);
    }
    return;
  }, [props]);

  const onSubmit = (e) => {
    setError("");
    setDisableBtn(true);
    setBtnLoading(true);

    e.preventDefault();
    if (emailAddress !== "") {
      props.sendInviteRequest(
        { emailAddress },
        setStatus,
        setEmailAddress,
        setDisableBtn,
        setBtnLoading
      );
    } else {
      setDisableBtn(false);
      setBtnLoading(false);
      setError("Email is required");
    }
  };
  return (
    <div className="mentor-dashboard-invite-modal" onClick={closeModal}>
      <div
        className="mentor-dashboard-invite-modal-content"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="mentor-dashboard-invite-popup-row">
          <div className="d-flex">
            <div className="mentor-dashboard-invite-popup-leftCol">
              <img src={inviteImg} alt="" />{" "}
            </div>
          </div>
          <div className="mentor-dashboard-invite-popup-rightCol">
            <div
              class="mentor-dashboard-invite-modal-close"
              onClick={closeModal}
            >
              ×
            </div>
            <div className="mentor-dashboard-invite-popup-rightCol-container">
              <h1
                className="font-color-darkblue mb-25 mt-10"
                style={{
                  marginBottom: "6px",
                  fontFamily: "Neometric Medium",
                  fontSize: "38px",
                  lineHeight: "45px",
                }}
              >
                Help us make a difference!
              </h1>
              <p className="font-color-black line-height-20 mb-10 mt-25">
                If you know someone that would be a great mentor or benefit from
                Cardo’s mentorship tools, let them know!
              </p>
              <form onSubmit={onSubmit}>
                <input
                  disabled={disableBtn}
                  type="email"
                  name="email"
                  id="email"
                  value={emailAddress}
                  onChange={(e) => handleEmailAddress(e)}
                  placeholder="hello@cardo.com"
                  style={{ border: "1.5px solid #5555DB", color: "#2C205B" }}
                  className="form-textbox mb-10 mt-10"
                />
                {status && <div class="font-size-14 font-eina ">{status}</div>}
                {error !== "" && (
                  <div class="font-size-14 font-eina err_msg">{error}</div>
                )}
                {/* error, success, componentClass, onclick  */}
                <SendEmailButton
                  componentClass="cardo-btn btn-bg-lightblue font-Neometric-Bold"
                  btnDisabled={disableBtn}
                  loading={btnLoading}
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  error: state.errors,
});
export default connect(mapStateToProps, { sendInviteRequest })(
  MentorDashboardInviteModal
);
