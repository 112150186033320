import React, { useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import "../../../styles/animate.css";

const Section5BtnGroup = ({ buttons }) => {
  const [clickedId, setClickedId] = useState(1);

  return (
    <ScrollAnimation animateIn="fadeIn">
      <div style={{ borderRadius: "10px" }} className="btn-group-section-four">
        <div
          style={{
            borderTopLeftRadius: "8.8px",
            borderBottomLeftRadius: "8.8px",
          }}
          key={1}
          onClick={() => setClickedId(1)}
          className={
            1 === clickedId
              ? "btn-group-item active-btn-group-item"
              : "btn-group-item active-btn-group-item"
          }
        >
          Consulting
        </div>
        <div key={2} className={"btn-group-item"}>
          <span className="soon">coming soon</span>
          Finance
        </div>
        <div
          style={{
            borderTopRightRadius: "8.8px",
            borderBottomRightRadius: "8.8px",
          }}
          key={3}
          className={"btn-group-item"}
        >
          <span className="soon">coming soon</span>
          Product
        </div>
      </div>
    </ScrollAnimation>
  );
};

export default Section5BtnGroup;
