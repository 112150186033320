import React, { useState, useEffect } from "react";
import Review from "./Review";
import moment from "moment-timezone";

export default function Timer(props) {
  const {
    session,
    sessionTimer,
    handleTimeOutEndCall,
    room,
    setIsCallOver,
    setTimer,
    user,
  } = props;

  const time = moment.tz(user.userCreated, moment.tz.guess());
  let today = moment(new Date(Date.now()));

  const [[h, m, s], setTime] = useState([
    today.diff(time, "hours"),
    today.diff(time, "minutes"),
    today.diff(time, "seconds") - today.diff(time, "minutes") * 60,
  ]);
  const tick = () => {
    if (m === 59 && s === 59) {
      setTime([h + 1, 0, 0]);
    } else if (s === 59) {
      setTime([h, m + 1, 0]);
    } else {
      setTime([h, m, s + 1]);
    }
  };

  useEffect(() => {
    const timerID = setInterval(() => tick(), 1000);
    if (m === parseInt(session.interviewLength.split(" ")[0])) {
      clearTimeout(timerID);
      handleTimeOutEndCall(room);
      setIsCallOver(true);
    }
    if (props.stoptimer === true) {
      // clearTimeout(timerID)
      const t = `${h.toString().padStart(2, "0")}:${m
        .toString()
        .padStart(2, "0")}:${s.toString().padStart(2, "0")}`;
      sessionTimer(t);
      setTimer(false);
    }
    return () => clearInterval(timerID);
  });
  return `${h.toString().padStart(2, "0")}:${m.toString().padStart(2, "0")}:${s
    .toString()
    .padStart(2, "0")}`;
}
