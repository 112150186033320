import React, { useEffect } from "react";
import { connect } from "react-redux";
import MenteeSignup from "../../components/auth/sign-up/mentee-sign-up-steps/MenteeSignup";
import {
  innerScript,
  signupMentee,
} from "../../state-management/actions/authActions";
const MenteeSignupRoute = (props) => {
  useEffect(() => {
    props.signupMentee();
    props.innerScript();
  }, []);
  return (
    <div>
      <MenteeSignup />
    </div>
  );
};

export default connect(null, { innerScript, signupMentee })(MenteeSignupRoute);
