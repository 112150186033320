import React from "react";
import Accordian from "../become-mentor/Accordian";
import becomeMentorSection5 from "../../../assets/Become a mentee/findMentorSection7.svg";
import { Link } from "react-router-dom";
import { useIsDesktop } from "../../../helper/useIsDesktop";
import { useMobilePopup } from "../../../hooks/useMobilePopup";

const FindMentorSectionFAQ = () => {
  const { openMobilePopupClick } = useMobilePopup("mobile-modal");
  const { isDesktop } = useIsDesktop();
  return (
    <div className="find-mentor-section-seven">
      <div className="find-mentor-section-seven_container">
        <div className="row find-mentor-section-seven-row">
          <div className="col-6 find-mentor-section-seven-leftCol">
            <img src={becomeMentorSection5} alt="" />
          </div>
          <div className="col-6 find-mentor-section-seven-rightCol">
            <div>
              <h2 className="section-seven-heading">
                Frequently asked questions{" "}
              </h2>
              <Accordian
                data={[
                  {
                    title: (
                      <h3 className="title-text">
                        What is the application process like?
                      </h3>
                    ),
                    content: (
                      <p>
                        Application consists of a form seeking information about
                        career <br className="br_desk" /> goals and background,
                        along with a short video submission.
                      </p>
                    ),
                  },
                  {
                    title: (
                      <h3 className="title-text">
                        How will I know I’ve been accepted?
                      </h3>
                    ),
                    content: (
                      <p>
                        Within 7-14 days of your application, you will recieve
                        an acceptance <br className="br_desk" /> e-mail, along
                        with full access to our platform upon login.{" "}
                      </p>
                    ),
                  },

                  {
                    title: (
                      <h3 className="title-text">
                        Will I have unlimited mentorship{" "}
                        <br className="br_desk" /> sessions?
                      </h3>
                    ),
                    content: (
                      <p>
                        To ensure everyone has an opportunity to receive
                        mentorship, <br className="br_desk" /> we offer 150
                        minutes per mentee. If you need more, just ask!
                      </p>
                    ),
                  },
                  {
                    title: (
                      <h3 className="title-text">
                        What if I have to cancel or reschedule?
                      </h3>
                    ),
                    content: (
                      <p>
                        Not a problem! As long as you cancel 24 hours before
                        your <br className="br_desk" />
                        upcoming session, you’ll keep your minutes.
                      </p>
                    ),
                  },
                  {
                    title: (
                      <h3 className="title-text">
                        What if I don’t get accepted to Cardo?
                      </h3>
                    ),
                    content: (
                      <p>
                        Cardo accepts reapplicants 180 days after initial
                        submission.
                      </p>
                    ),
                  },
                ]}
              />
              <div className="apply-now-container">
                <Link to={isDesktop ? "/sign-up" : "#"}>
                  <button
                    className="pink-btn apply_now_btn font-Mulish find-mentor-btn"
                    onClick={openMobilePopupClick}
                  >
                    Apply Now
                  </button>
                </Link>
              </div>
              <p
                style={{
                  fontWeight: "300",
                  lineHeight: "22px",
                  fontFamily: "Neometric Regular",
                }}
                className="contact-for-help-text"
              >
                Still have questions? Reach out to us{" "}
                <a href="mailto:support@hellocardo.org">
                  support@hellocardo.org
                </a>
                , we’d be happy to help!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FindMentorSectionFAQ;
