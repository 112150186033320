import {useCallback, useState} from 'react';
import useRoom from "./useRoom";

export default function useLocalVideoToggle(localTracks, getLocalVideoTrack, removeLocalVideoTrack, room) {
    const localParticipant = room?.localParticipant;
    const videoTrack = localTracks?.find(track => track.name.includes('camera'));
    const [isPublishing, setIspublishing] = useState(false);

    const toggleVideoEnabled = useCallback(() => {
        if (!isPublishing) {
            if (videoTrack) {
                const localTrackPublication = localParticipant?.unpublishTrack(videoTrack);
                localParticipant?.emit('trackUnpublished', localTrackPublication);
                removeLocalVideoTrack();
            } else {
                setIspublishing(true);
                getLocalVideoTrack()
                    .then((track) => localParticipant?.publishTrack(track, {priority: 'low'}))
                    .catch(error => console.log(error))
                    .finally(() => setIspublishing(false));
            }
        }
    }, [videoTrack, localParticipant, getLocalVideoTrack, isPublishing, removeLocalVideoTrack]);

    return [!!videoTrack, toggleVideoEnabled];
}
