import React from "react";
import Validation from "../../landing-page/common/Validation";
import SignupValidation from "../sign-up/validation/SignupValidation";

const RadioGroup = (props) => {
  const { text, error, style, description } = props;
  return (
    <div style={style} className="position-relative">
      <div className="form-label">
        {text}{" "}
        {description && (
          <div className="tooltip">
            ?<div className="tooltiptext">{description}</div>
          </div>
        )}
      </div>
      {error && <SignupValidation message={error} />}

      <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
        {props.children}
      </div>
    </div>
  );
};

export default RadioGroup;
