import {useState, useEffect} from 'react';
import {getDeviceInfo} from "../helper/videoCall";


export default function useDevices() {
    const [deviceInfo, setDeviceInfo] = useState({
        audioInputDevices: [],
        videoInputDevices: [],
        audioOutputDevices: [],
        hasAudioInputDevices: false,
        hasVideoInputDevices: false,
    });

    useEffect(() => {
        const getDevices = () => getDeviceInfo().then(devices => setDeviceInfo(devices));
        navigator.mediaDevices.addEventListener('devicechange', getDevices);
        if (navigator.permissions) {
            navigator.permissions.query({name: 'camera'}).then(function (permission) {
                permission.onchange = function () {
                    getDevices()
                };
            });
            navigator.permissions.query({name: 'microphone'}).then(function (permission) {
                permission.onchange = function () {
                    getDevices()
                };
            });
        }
        getDevices();

        return () => {
            navigator.mediaDevices.removeEventListener('devicechange', getDevices);
        };
    }, []);

    return deviceInfo;
}
