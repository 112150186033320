import React, {useEffect, useRef} from 'react';
import TextMessage from "./TextMessage";
import MediaMessage from "./MediaMessage";

const getFormattedTime = (message) =>
    message?.dateCreated.toLocaleTimeString('en-us', {hour: 'numeric', minute: 'numeric'}).toLowerCase();

const AlwaysScrollToBottom = () => {
    const elementRef = useRef();
    useEffect(() => elementRef.current.scrollIntoView({behavior: "smooth"}));
    return <div ref={elementRef} />;
};

export default function MessageList({messages, room}) {
    const localParticipant = room?.localParticipant;

    return (
        <>
            {
                messages.map((message, idx) => {
                    const time = getFormattedTime(message);
                    const isLocalParticipant = localParticipant?.identity === message.author;

                    return (
                        <React.Fragment key={message.sid}>
                            {message.type === 'text' &&
                            <TextMessage body={message.body} isLocalParticipant={isLocalParticipant}/>}
                            {message.type === 'media' && <MediaMessage media={message.media}/>}
                        </React.Fragment>
                    );
                })
            }
            <AlwaysScrollToBottom/>
        </>
    );
}
