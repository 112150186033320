import React, { useEffect } from "react";
import useMediaTracks from "../../../../../hooks/useMediaTracks";
import "../../../../../styles/dashboard/mentor-dashboard/pre-join.css";
import VideoInputList from "./video-call-screens/VideoInputList";
import AudioInputList from "./video-call-screens/AudioInputList";
import AudioOutputList from "./video-call-screens/AudioOutputList";
import { withRouter } from "react-router-dom";

function PreJoinScreen(props) {
  let { getAudioAndVideoTracks, removeAllTracks, localTracks } =
    useMediaTracks();

  useEffect(() => {
    getAudioAndVideoTracks()
      .then(() => {})
      .catch((error) => {
        console.log("Error acquiring local media:");
        console.dir(error);
      });
  }, []);

  const handleJoin = () => {
    removeAllTracks();
    props.history.push(`/mentor/videocall/${props.sessionId}`);
  };
  const closeModal = (e) => {
    removeAllTracks();
    e.stopPropagation();
    props.closeModal();
  };
  return (
    <div
      className="mentee-dashboard-find-mentor-modal"
      style={{ display: "block" }}
      onClick={(e) => closeModal(e)}
    >
      <div
        style={{
          width: "860px",
          height: "auto",
          padding: "25px 0 50px 0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        className="mentee-dashboard-find-mentor-modal-content"
        onClick={(e) => e.stopPropagation()}
      >
        <div
          style={{
            position: "absolute",
            right: "10px",
            top: "0",
            cursor: "pointer",
            zIndex: 10,
            padding: "20px",
            fontSize: "35px",
            fontWeight: "700",
            color: "#c5c5c5",
          }}
          onClick={(e) => closeModal(e)}
        >
          {/*<i className="fa fa-times"/>*/}
          &times;
        </div>
        <div
          style={{
            width: "360px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="position-relative">
            <div className="font-Neometric-Bold join-session-title mb-25 mt-25 text-align-center">
              You are about to join a mentorship session
            </div>
            <div className="join-session-step">
              {/*<LocalVideoPreview singleVideoTrack={singleVideoTrack}*/}
              {/*                   singleAudioTrack={singleAudioTrack}/>*/}
              {/*<div style={{*/}
              {/*    display: 'flex',*/}
              {/*    justifyContent: 'center'*/}
              {/*}}>*/}
              {/*    <ToggleAudioButton localTracks={localTracks} disabled={isAcquiringLocalTracks}/>*/}
              {/*    <ToggleVideoButton localTracks={localTracks} getLocalVideoTrack={getLocalVideoTrack}*/}
              {/*                       removeLocalVideoTrack={removeLocalVideoTrack}*/}
              {/*                       disabled={isAcquiringLocalTracks}/>*/}
              {/*</div>*/}
              <VideoInputList localTracks={localTracks} />
              <AudioInputList localTracks={localTracks} />
              <AudioOutputList />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              bottom: 0,
              right: 0,
              left: 0,
            }}
          >
            <div
              className="cardo-btn btn-bg-lightblue"
              style={{
                width: "340px",
                margin: "40px auto 0 auto",
              }}
              onClick={handleJoin}
            >
              Join Session
              <i
                style={{
                  marginLeft: "10px",
                }}
                className="fa fa-arrow-right"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(PreJoinScreen);
