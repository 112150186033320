import React, { useEffect, useState } from "react";
import MentorAccountTab from "./mentor-form/MentorAccountTab";
import MentorInformationTab from "./mentor-form/MentorInformationTab";
import "../../../../styles/dashboard/profile/profile-form.css";
import { editMentorProfile } from "../../../../state-management/actions/dashboard/mentor-dashboard/profileActions";
import { connect } from "react-redux";
import MentorshipTab from "./mentor-form/MentorshipTab";
import MentorCalender from "./mentor-form/MentorCalender";
import { withRouter } from "react-router";

const MentorEditProfile = (props) => {
  const [informationData, setInformationData] = useState({
    preferredEmailForCommunication: "",
    myStory: "",
    education: "",
    gpa: "",
    almaMater: "",
    interestedIn: {
      value: "",
      inputValues: ["Full Time", "Internship"],
    },
    stage: {
      value: "Student",
      inputValues: ["Student", "Experienced"],
    },
    whatMakesMeUnique: {
      value: [],
      inputValues: [
        "BIPOC",
        "Latinx",
        "LGBTQ+",
        "Military Veteran",
        "Non-Target Graduate",
        "First-Gen Graduate",
        "Woman in STEM",
        "Career Switcher",
      ],
    },
    fileInput: {
      name: `fileUpload`,
      value: {},
      file: true,
      fileName: "No file chosen",
      type: "file",
      allowedTypes: ["pdf", "doc", "docx"],
    },
  });
  const [accountData, setAccountData] = useState({
    industry: {
      value: ["Consulting"],
      inputValues: ["Consulting", "Finance", "Product"],
    },
    trail: {
      value: ["Strategy"],
      inputValues: [
        "Strategy",
        "Operations",
        "Technology",
        "Digital",
        "Human Capital",
      ],
    },
    support: {
      value: [],
      inputValues: [
        "Resume Review",
        "Case Interview",
        "Fit Interview",
        "Industry Overview",
        "LinkedIn Support",
      ],
    },
  });

  //Edit profile handleres
  const handleInformationData = (e) => {
    const { name, value } = e.target;
    setInformationData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleInformationNumber = (e) => {
    const { name, value } = e.target;

    setInformationData((prevState) => ({
      ...prevState,
      [name]: value < 0 ? 0 : value >= 4 ? 4 : value,
    }));
  };
  const fileChangeHandler = (name, file) => {
    setInformationData((prev) => ({
      ...prev,
      [name]: {
        ...prev[name],
        value: file,
        fileName: file.name ? file.name : "No file chosen",
      },
    }));
  };
  const handleCheckboxChange = (key, input) => {
    let arr = [...informationData[key].value];

    if (arr.includes("N/A")) {
      let index = arr.indexOf("N/A");
      arr.splice(index, 1);
    }
    if (arr.includes(input)) {
      let index = arr.indexOf(input);
      arr.splice(index, 1);
      if (arr.length === 0) {
        arr.push("N/A");
      }
    } else {
      arr.push(input);
    }
    setInformationData((prevState) => ({
      ...prevState,
      [key]: { ...prevState[key], value: arr.filter((v) => v) },
    }));
  };
  const handleRadioChange = (key, input) => {
    // Change for Account Data Later
    if (currentTab === "mentorship") {
      let prevValues = accountData[key].value;
      if (prevValues.includes(input)) {
        prevValues.splice(
          prevValues.findIndex((i) => i === input),
          1
        );
      } else {
        prevValues.push(input);
      }
      setAccountData((prevState) => ({
        ...prevState,
        [key]: { ...prevState[key], value: prevValues },
      }));
    } else {
      setInformationData((prevState) => ({
        ...prevState,
        [key]: { ...prevState[key], value: input },
      }));
    }
  };

  const { currentUser, editMentorProfile, mentorDashboardEditProfile } = props;
  const [currentTab, setCurrentTab] = useState("Information");

  const changeCurrentTab = (screen) => {
    props.changeScreen(screen);
    props.history.push({
      search: `?tabs=${screen.toLocaleLowerCase()}`,
    });
    setCurrentTab(screen);
    if (currentTab === "calendar") {
      // getInvitationStatus()
    }
  };

  useEffect(() => {
    editMentorProfile();
  }, [currentTab]);

  useEffect(() => {
    const search = props.location.search;
    const tabs = new URLSearchParams(search).get("tabs");
    const newTabs = tabs?.replace(/_/g, " ");
    if (tabs) {
      props.changeScreen(newTabs);
      setCurrentTab(newTabs);
    } else {
      props.changeScreen("information");
      setCurrentTab("information");
    }
  }, [props.location.search]);

  useEffect(() => {
    setInformationData((prevState) => ({
      ...prevState,
      ...mentorDashboardEditProfile.informationTab,
    }));

    setAccountData((prevState) => ({
      ...prevState,
      ...mentorDashboardEditProfile.accountData,
    }));
  }, [mentorDashboardEditProfile]);
  const currentTabClass = (currentTabLabel) => {
    return currentTab.toLocaleLowerCase() ===
      currentTabLabel.toLocaleLowerCase()
      ? "user-profile-edit-profile-tab user-profile-edit-profile-tab-active"
      : "user-profile-edit-profile-tab";
  };

  let editProfileTabContent;
  if (currentTab.toLocaleLowerCase() === "information") {
    editProfileTabContent = (
      <MentorInformationTab
        handleInformationData={handleInformationData}
        handleInformationNumber={handleInformationNumber}
        handleCheckboxChange={handleCheckboxChange}
        handleRadioChange={handleRadioChange}
        informationData={informationData}
        currentUser={currentUser}
        fileChangeHandler={fileChangeHandler}
      />
    );
  } else if (currentTab.toLocaleLowerCase() === "mentorship") {
    editProfileTabContent = (
      <MentorshipTab
        currentUser={currentUser}
        accountData={accountData}
        handleRadioChange={handleRadioChange}
      />
    );
  } else if (currentTab.toLocaleLowerCase() === "calendar") {
    editProfileTabContent = (
      <MentorCalender
        setImagepopup={props.setImagepopup}
        setImgClass={props.setImgClass}
        calendar={mentorDashboardEditProfile.calendar}
        currentUser={currentUser}
        accountData={accountData}
        handleRadioChange={handleRadioChange}
      />
    );
  } else if (currentTab.toLocaleLowerCase() === "account") {
    editProfileTabContent = (
      <MentorAccountTab
        currentUser={currentUser}
        accountData={accountData}
        handleRadioChange={handleRadioChange}
        handleDeletePopup={props.handleDeletePopup}
      />
    );
  }
  return (
    <div className="user-profile-edit-profile">
      <div className="user-profile-edit-profile-tabs">
        <div
          className={currentTabClass("Information")}
          onClick={() => changeCurrentTab("Information")}
        >
          Information
        </div>
        <div
          className={currentTabClass("Mentorship")}
          onClick={() => changeCurrentTab("Mentorship")}
        >
          Mentorship
        </div>
        <div
          className={currentTabClass("Calendar")}
          onClick={() => changeCurrentTab("Calendar")}
        >
          Calendar
        </div>
        <div
          className={currentTabClass("Account")}
          onClick={() => changeCurrentTab("Account")}
        >
          Account
        </div>
      </div>
      <div className="user-profile-edit-profile-content">
        {editProfileTabContent}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  mentorDashboardEditProfile: state.mentorDashboard.mentorDashboardEditProfile,
});

export default connect(mapStateToProps, { editMentorProfile })(
  withRouter(MentorEditProfile)
);
