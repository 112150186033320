import React from 'react';
import useScreenShareParticipant from "../../../../../../hooks/useScreenShareParticipant";
import screen from "../../../../../../assets/mentor/screen.svg";
import shareScreen from "../../../../../../assets/mentor/share-screen.svg";
import useScreenShareToggle from "../../../../../../hooks/useScreenShareToggle";

export default function ToggleScreenShareButton(props) {
    const screenShareParticipant = useScreenShareParticipant();
    const [isSharingScreen, toggleScreenShare] = useScreenShareToggle(props.room);
    const disableScreenShareButton = Boolean(screenShareParticipant);
    const isScreenShareSupported = navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia;
    const isDisabled = props.disabled || disableScreenShareButton || !isScreenShareSupported;

    return (
        <div className="screen-button-group action-button-group">
            <button className={`${!isSharingScreen ? 'active' : 'disabled'}`} onClick={toggleScreenShare} disabled={isDisabled}>
                <img className={`${!isSharingScreen ? 'screen' : 'share-screen'}`} src={!isSharingScreen ? screen : shareScreen}
                     alt='icon'/>
            </button>
            <span>Share</span>
        </div>
    );
}
