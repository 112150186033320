import React, { useState } from "react";

const Checkbox = (props) => {
  const {
    id,
    label,
    placeholder,
    maxSelected,
    toolTip,
    value,
    onChange,
    name,
    disabled,
    errorMsg,
    inputValues,
  } = props;

  const [error, setError] = useState("");

  function getSelectedValue(value, inputValue) {
    return value.includes(inputValue);
  }

  function changeEvent(name, inputValue) {
    setError("");
    if (maxSelected) {
      if (value && value.length < maxSelected) {
        onChange(name, inputValue);
      } else {
        if (value.includes(inputValue)) {
          onChange(name, inputValue);
        } else {
          setError(`You can only select maximum ${maxSelected} values`);
        }
      }
    } else {
      onChange(name, inputValue);
    }
  }

  return (
    <div className="mb-5 position-relative">
      <div className="display-flex">
        <label className="form-label" htmlFor={id}>
          {label}
        </label>
        {toolTip && (
          <div className="tooltip">
            ?<div className="tooltiptext">{toolTip}</div>
          </div>
        )}
      </div>
      <div className="edit-profile-checkbox-container">
        {inputValues.map((inputValue, index) => (
          <div
            key={index}
            style={{ cursor: "pointer" }}
            className={
              getSelectedValue(value, inputValue)
                ? "edit-profile-checkbox edit-profile-checkbox-selected"
                : "edit-profile-checkbox"
            }
            onClick={() => changeEvent(name, inputValue)}
          >
            {inputValue}
          </div>
        ))}
      </div>
      {(errorMsg || error) && (
        <div className="signup-form-validation">{errorMsg || error}</div>
      )}
    </div>
  );
};

export default Checkbox;
