import React, { useEffect } from "react";
import Textbox from "../../../form-component/Textbox";
import Radio from "../../../form-component/Radio";
import RadioGroup from "../../../form-component/RadioGroup";
import InputNumber from "../../../form-component/InputNumber";

const MenteeSignupStep4YourBackground = (props) => {
  const {
    title,
    step,
    onPrevStep,
    onStepChange,
    data,
    onChange,
    stepKey,
    errors,
    onRadioChange,
    handleRequired,
  } = props;
  useEffect(() => {
    if (
      data.q23_whichOf.value ===
        "I am a professional outside of the Consulting industry, but looking to transition in" ||
      data.q23_whichOf.value ===
        "I am currently a professional in Consulting, but I want to transition my focus area (e.g., technology to strategy)"
    ) {
      handleRequired(stepKey, [data.q96_whoIs], [data.q111_wereYou]);
    } else if (
      data.q23_whichOf.value ===
      "I am a professional whose employment has recently been impacted by COVID-19"
    ) {
      handleRequired(stepKey, [data.q96_whoIs], []);
    } else if (
      data.q23_whichOf.value ===
      "I am a student hoping to pursue a career in Consulting"
    ) {
      handleRequired(stepKey, [], [data.q96_whoIs, data.q111_wereYou]);
    }
  }, [data.q23_whichOf.value]);
  return (
    <div>
      <h3 className="sign-up-form-heading">{title}</h3>
      <div className="step-form-content">
        <div className="form-line">
          <RadioGroup
            text={data.q23_whichOf.text}
            error={errors[data.q23_whichOf.name]}
            description={data.q23_whichOf.description}
          >
            {data.q23_whichOf.inputValues.map((inputValue, index) => (
              <Radio
                key={index}
                name={data.q23_whichOf.name}
                value={data.q23_whichOf.value}
                text={data.q23_whichOf.text}
                inputValue={data.q23_whichOf.inputValues[index]}
                label={data.q23_whichOf.inputValues[index]}
                id={`${data.q23_whichOf.name}_radio_${index}`}
                onRadioChange={(e) =>
                  onRadioChange(stepKey, e, data.q23_whichOf.value)
                }
                type={data.q23_whichOf.type}
              />
            ))}
          </RadioGroup>
        </div>
        {(data.q23_whichOf.value ===
          "I am a professional outside of the Consulting industry, but looking to transition in" ||
          data.q23_whichOf.value ===
            "I am currently a professional in Consulting, but I want to transition my focus area (e.g., technology to strategy)") && (
          <div className="form-line">
            <Textbox
              placeholder={data.q96_whoIs.placeholder}
              name={data.q96_whoIs.name}
              description={data.q96_whoIs.description}
              value={data.q96_whoIs.value}
              text={data.q96_whoIs.text}
              id={`${data.q96_whoIs.name}_textbox`}
              onChange={(e) => onChange(stepKey, e)}
              error={errors[data.q96_whoIs.name]}
              type={data.q96_whoIs.type}
            />
          </div>
        )}
        {data.q23_whichOf.value ===
          "I am a professional whose employment has recently been impacted by COVID-19" && (
          <div>
            <div className="form-line">
              <RadioGroup
                text={data.q111_wereYou.text}
                error={errors[data.q111_wereYou.name]}
                description={data.q111_wereYou.description}
              >
                {data.q111_wereYou.inputValues.map((inputValue, index) => (
                  <Radio
                    key={index}
                    name={data.q111_wereYou.name}
                    value={data.q111_wereYou.value}
                    text={data.q111_wereYou.text}
                    inputValue={data.q111_wereYou.inputValues[index]}
                    label={data.q111_wereYou.inputValues[index]}
                    id={`${data.q111_wereYou.name}_radio_${index}`}
                    onRadioChange={(e) =>
                      onRadioChange(stepKey, e, data.q111_wereYou.value)
                    }
                    type={data.q111_wereYou.type}
                  />
                ))}
              </RadioGroup>
            </div>
            <div className="form-line">
              <Textbox
                placeholder={data.q96_whoIs.placeholder}
                name={data.q96_whoIs.name}
                value={data.q96_whoIs.value}
                text={data.q96_whoIs.text}
                description={data.q96_whoIs.description}
                id={`${data.q96_whoIs.name}_textbox`}
                onChange={(e) => onChange(stepKey, e)}
                error={errors[data.q96_whoIs.name]}
                type={data.q96_whoIs.type}
              />
            </div>
          </div>
        )}

        <div className="form-line">
          <Textbox
            placeholder={data.q114_whereDid.placeholder}
            name={data.q114_whereDid.name}
            value={data.q114_whereDid.value}
            description={data.q114_whereDid.description}
            text={data.q114_whereDid.text}
            id={`${data.q114_whereDid.name}_textbox`}
            onChange={(e) => onChange(stepKey, e)}
            error={errors[data.q114_whereDid.name]}
            type={data.q114_whereDid.type}
          />
        </div>
        <div className="form-line">
          <InputNumber
            placeholder={data.q28_whatIswas.hint}
            name={data.q28_whatIswas.name}
            value={data.q28_whatIswas.value}
            text={data.q28_whatIswas.text}
            id={`${data.q28_whatIswas.name}_number`}
            description={data.q28_whatIswas.description}
            onChange={(e) => onChange(stepKey, e)}
            step={data.q28_whatIswas.stepIncrement}
            minValue={data.q28_whatIswas.minValue}
            maxValue={data.q28_whatIswas.maxValue}
            error={errors[data.q28_whatIswas.name]}
            type={data.q28_whatIswas.type}
          />
        </div>
      </div>
      <div className="form-step-btn-group">
        {step > 0 && (
          <button
            type="button"
            className="cardo-btn back-btn"
            onClick={() => onPrevStep(step - 1)}
          >
            Go back
          </button>
        )}
        <button
          type="button"
          className="cardo-btn next-btn"
          onClick={(e) => onStepChange(data, e)}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default MenteeSignupStep4YourBackground;
