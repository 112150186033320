import React, { useEffect, useRef, useState } from "react";
import ProfileHeader from "./profile-components/ProfileHeader";
import "../../../styles/dashboard/profile/profile.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import ProfileBodyHeader from "./profile-components/ProfileBodyHeader";
import ProfileBodyProgress from "./profile-components/ProfileBodyProgress";
import MentorEditProfile from "./mentor-profile-components/MentorEditProfile";
import { getCurrentUser } from "../../../state-management/actions/dashboard/mentor-dashboard/mentorDashboardActions";
import MessagePopup from "./mentor-profile-components/mentor-form/MessagePopup";
import { postDeleteAccount } from "../../../state-management/actions/dashboard/mentor-dashboard/profileActions";

const MentorProfile = (props) => {
  const [screen, setScreen] = useState("Information");
  const [errorPopup, setErrorPopup] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [imagepopup, setImagepopup] = useState(false);

  const image = useRef(null);

  const setImgClass = (img) => {
    image.current.classList.add(img);
  };
  const { currentUser, mentorDashboardEditProfile } = props;
  useEffect(() => {
    props.getCurrentUser();
  }, []);

  const [userImg, setUserImg] = useState();
  useEffect(() => {
    if (!userImg) {
      setUserImg(currentUser.imgUrl);
    }
    return;
  }, [userImg, currentUser]);

  const changeScreen = (screen) => {
    setScreen(screen);
  };
  const handleErrorPopup = () => {
    setErrorPopup(!errorPopup);
  };
  const handleDeletePopup = () => {
    setDeletePopup(!deletePopup);
  };
  return (
    <div className="profile">
      <div
        onClick={() => setImagepopup(false)}
        style={{
          position: "fixed",
          width: "100vw",
          height: "100vh",
          top: "0",
          left: "0",
          backgroundColor: "rgba(0,0,0,0.5)",
          zIndex: "999",
          display: imagepopup ? "flex" : "none",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          cursor: "zoom-out",
        }}
      >
        <div
          ref={image}
          style={{ width: "750px", height: "590px", backgroundSize: "cover" }}
        ></div>
      </div>

      {errorPopup && (
        <MessagePopup
          type="error"
          msg={
            <div>
              Your profile could not be activated. Please ensure your profile{" "}
              <br className="deskBr" /> is at 100% by completing your bio and
              confirming a Calendly
              <br className="deskBr" /> account is connected.{" "}
            </div>
          }
          title="Warning"
          handlePopup={handleErrorPopup}
        />
      )}

      {deletePopup && (
        <MessagePopup
          type="delete"
          msg={
            <div>
              Are you sure you would like you permanently delete <br /> your
              account?
            </div>
          }
          title="Warning"
          handlePopup={handleDeletePopup}
          deleteHandler={postDeleteAccount}
        />
      )}
      <ProfileHeader currentUser={currentUser} userImg={userImg} />
      <div className="profile-body">
        <div>
          <Link
            to="/mentor/dashboard"
            className="profile-back-to-dashboard-btn text-decoration-none"
          >
            <button className="cardo-btn btn-bg-lightblue ">
              Back To Dashboard
            </button>
          </Link>
          <div className="profile-body-container">
            <ProfileBodyHeader
              setUserImg={setUserImg}
              userImg={userImg}
              currentUser={currentUser}
              handleErrorPopup={handleErrorPopup}
            />
            <ProfileBodyProgress
              mentorDashboardEditProfile={mentorDashboardEditProfile}
            />

            <MentorEditProfile
              setImagepopup={setImagepopup}
              setImgClass={setImgClass}
              changeScreen={changeScreen}
              currentUser={currentUser}
              handleErrorPopup={handleErrorPopup}
              handleDeletePopup={handleDeletePopup}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  currentUser: state.auth.currentUser,
  mentorDashboardEditProfile: state.mentorDashboard.mentorDashboardEditProfile,
});
export default connect(mapStateToProps, { getCurrentUser })(MentorProfile);
