import React, { useEffect, useState } from "react";

const MenteeDashboardAccordian = (props) => {
  const [accordionItems, setAccordionItems] = useState([]);
  useEffect(() => {
    let accordion = [];
    if (props.data) {
      props.data[0].columns.forEach((i) => {
        if (i.title !== "") {
          accordion.push({
            tipTitle: i.title,
            tips: i.column,
            open: false,
          });
        }
      });
      setAccordionItems(accordion);
    }
  }, [props.data]);
  const click = (i) => {
    const newAccordion = accordionItems.slice();
    const index = newAccordion.indexOf(i);

    newAccordion[index].open = !newAccordion[index].open;
    setAccordionItems(newAccordion);
  };
  return (
    <div className="mentee-dashboard-home-accordian">
      {accordionItems.length > 0 &&
        accordionItems.map((i) => (
          <div key={accordionItems.indexOf(i)}>
            <div
              className="mentee-dashboard-home-title"
              onClick={click.bind(null, i)}
            >
              <div className="display-flex align-items-center">
                <div className="mentee-dashboard-home-title-before mr-10"></div>
                <div>{i.tipTitle}</div>
              </div>
              <div className="mentee-dashboard-home-arrow-wrapper">
                <i
                  className={
                    i.open
                      ? "fa fa-angle-right fa-rotate-90"
                      : "fa fa-angle-right"
                  }
                ></i>
              </div>
            </div>
            <div
              className={
                i.open
                  ? "mentee-dashboard-home-content mentee-dashboard-home-content-open"
                  : "mentee-dashboard-home-content"
              }
            >
              <div
                className={
                  i.open
                    ? "mentee-dashboard-home-content-text mentee-dashboard-home-content-text-open"
                    : "mentee-dashboard-home-content-text"
                }
              >
                <div>
                  {i.tips &&
                    i.tips.map((tip, index) => (
                      <div
                        className="mentee-dashboard-home-accordian-tip-container"
                        key={index}
                      >
                        <div className="mentee-dashboard-home-accordian-tip-before"></div>
                        <div className="mentee-dashboard-home-accordian-tip-title">
                          <a href={tip.link}>{tip.link_title}</a>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default MenteeDashboardAccordian;
