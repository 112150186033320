import React, { Fragment } from "react";
import { Doughnut } from "@reactchartjs/react-chart.js";

const DoughnutChart = (props) => {
  const { chartTime, chartTitle, chartColor, total } = props;
  const areaTitle = chartTitle;
  const areaData = chartTime;
  const areaColor = chartColor;

  const data = {
    labels: total > 0 ? areaTitle : [""],

    datasets: [
      {
        data: total > 0 ? areaData : [100],
        backgroundColor: total > 0 ? areaColor : ["#E9E9E9"],
        borderWidth: 0,
      },
    ],
  };
  return (
    <Fragment>
      <Doughnut
        data={data}
        options={{
          responsive: true,
          maintainAspectRatio: true,
          cutoutPercentage: 70,
          tooltips: { enabled: total > 0 ? true : false },
          legend: {
            display: false,
          },
        }}
        height={180}
      />
    </Fragment>
  );
};

export default DoughnutChart;
