import React from "react";
import step1IconActive from "../../../../../assets/sign-up/steps/Group-4.svg";
import step1Icon from "../../../../../assets/sign-up/steps/Group-5.svg";
import step3Icon from "../../../../../assets/sign-up/steps/Group 699.svg";
import step3IconActive from "../../../../../assets/sign-up/steps/Group 760.svg";
import step4Icon from "../../../../../assets/sign-up/steps/Group 697.svg";
import step4IconActive from "../../../../../assets/sign-up/steps/Group 463.svg";
import step5Icon from "../../../../../assets/sign-up/steps/Group 692.svg";
import step5IconActive from "../../../../../assets/sign-up/steps/Group 758.svg";
import step6Icon from "../../../../../assets/sign-up/steps/Group 772.svg";
import step6IconActive from "../../../../../assets/sign-up/steps/Group 763.svg";
import step7Icon from "../../../../../assets/sign-up/steps/Vector-5.svg";
import step7IconActive from "../../../../../assets/sign-up/steps/Group 769.svg";
const MenteeSteps = (props) => {
  const { children, step, iconClickHandle, data, title } = props;
  return (
    <div>
      <div className="steps-icon-container">
        <div
          className={
            step === 0 || step === 1
              ? `step-icon-container step-icon-container-active`
              : `step-icon-container`
          }
          onClick={(e) => iconClickHandle(0, e)}
        >
          <div className="step-icon-img-container">
            {step === 0 || step === 1 ? (
              <img src={step1IconActive} alt="" />
            ) : (
              <img src={step1Icon} alt="" />
            )}
          </div>
          <div className="step-icon-title">
            <div className="font-Neometric-Regular step-icon-no">Step 1</div>
            <div className="font-Neometric-Medium step-icon-name">Welcome</div>
          </div>
        </div>
        <div className="divider"></div>
        <div
          className={
            step === 2
              ? `step-icon-container step-icon-container-active`
              : `step-icon-container`
          }
          onClick={(e) => iconClickHandle(2, e)}
        >
          <div className="step-icon-img-container">
            {step === 2 ? (
              <img src={step3IconActive} alt="" />
            ) : (
              <img src={step3Icon} alt="" />
            )}
          </div>
          <div className="step-icon-title">
            <div className="font-Neometric-Regular step-icon-no">Step 2</div>
            <div className="font-Neometric-Medium step-icon-name">
              Your Goals
            </div>
          </div>
        </div>{" "}
        <div className="divider"></div>
        <div
          className={
            step === 3 || step === 4
              ? `step-icon-container step-icon-container-active`
              : `step-icon-container`
          }
          onClick={(e) => iconClickHandle(3, e)}
        >
          <div className="step-icon-img-container">
            {step === 3 || step === 4 ? (
              <img src={step4IconActive} alt="" />
            ) : (
              <img src={step4Icon} alt="" />
            )}
          </div>
          <div className="step-icon-title">
            <div className="font-Neometric-Regular step-icon-no">Step 3</div>
            <div className="font-Neometric-Medium step-icon-name">
              Your Background
            </div>
          </div>
        </div>
        <div className="divider"></div>
        {/* <div
          className={
            step === 4
              ? `step-icon-container step-icon-container-active`
              : `step-icon-container`
          }
          onClick={(e) => iconClickHandle(4, e)}
        >
          <div className="step-icon-img-container">
            {step === 4 ? (
              <img src={step5IconActive} alt="" />
            ) : (
              <img src={step5Icon} alt="" />
            )}
          </div>
          <div className="step-icon-title">
            <div className="font-Neometric-Regular step-icon-no">Step 4</div>
            <div className="font-Neometric-Medium step-icon-name">
              Our Mission
            </div>
          </div>
        </div>
        <div className="divider"></div> */}
        <div
          className={
            step === 5
              ? `step-icon-container step-icon-container-active`
              : `step-icon-container`
          }
          onClick={(e) => iconClickHandle(5, e)}
        >
          <div className="step-icon-img-container">
            {step === 5 ? (
              <img src={step6IconActive} alt="" />
            ) : (
              <img src={step6Icon} alt="" />
            )}
          </div>
          <div className="step-icon-title">
            <div className="font-Neometric-Regular step-icon-no">Step 4</div>
            <div className="font-Neometric-Medium step-icon-name">Video</div>
          </div>
        </div>
        <div className="divider"></div>
        <div
          className={
            step === 6
              ? `step-icon-container step-icon-container-active`
              : `step-icon-container`
          }
          onClick={(e) => iconClickHandle(6, e)}
        >
          <div className="step-icon-img-container">
            {step === 6 ? (
              <img src={step7IconActive} alt="" />
            ) : (
              <img src={step7Icon} alt="" />
            )}
          </div>
          <div className="step-icon-title">
            <div className="font-Neometric-Regular step-icon-no">Step 5</div>
            <div className="font-Neometric-Medium step-icon-name">Verify</div>
          </div>
        </div>
      </div>
      <div style={{ marginTop: "40px" }}>{children}</div>
    </div>
  );
};

export default MenteeSteps;
