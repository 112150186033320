import React, { useState, useEffect } from "react";
import oppVideo from "../../../../../../../assets/mentor/oppvideo.png";
import maskgroup from "../../../../../../../assets/mentor/maskgroup.svg";
import ReactStars from "react-rating-stars-component";
const MenteePopup = (props) => {
  const [errors, setErros] = useState({});
  const {
    handleChangeMentorAnswer,
    isSubmit,
    goback,
    mentorRatingChanged,
    session,
    total,
    errObj,
    isCallOver,
  } = props;

  useEffect(() => {
    setErros(errObj);
  }, [errObj]);
  return (
    <div
      className="mentee-dashboard-find-mentor-modal"
      style={{ display: "block" }}
    >
      <div
        className="mentee-dashboard-find-mentor-modal-content"
        style={{ height: "490px" }}
        onClick={(e) => e.stopPropagation()}
      >
        <div
          className="mentee-dashboard-modal-wrapper"
          style={{ height: "100%" }}
        >
          <div
            className="mentee-dashboard-modal-content"
            style={{
              display: "flex",
              height: "100%",
            }}
          >
            <div
              className="mentee-dashboard-sessiondetail"
              style={{
                width: "35%",
                background: "#5555DB",
                backgroundImage: `url("${maskgroup}")`,
                padding: "67px 0",
              }}
            >
              <div
                className="mentee-dashboard-sessionimgdetails"
                style={{
                  borderRadius: "8px",
                  height: "100%",
                }}
              >
                <div
                  className="mentee-dashboard-userimg"
                  style={{ width: "120px", height: "120px", margin: "0 auto" }}
                >
                  <img
                    src={session.mentee.img_url}
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "50%",
                    }}
                  />
                </div>
                <div className="mentee-dashboard-sessioncontent">
                  <div
                    className="mentee-dashboard-mentorship mentee-dashboard-sessioninfo"
                    style={{
                      margin: "14px 0",
                      textAlign: "center",
                      color: "#fff",
                    }}
                  >
                    <p
                      className="mentorshipwith font-Neometric-Regular"
                      style={{
                        textTransform: "capitalize",
                        fontSize: "12px",
                        lineHeight: "14px",
                        fontWeight: "400",
                      }}
                    >
                      mentorship with
                    </p>
                    <h6
                      style={{
                        margin: "0",
                        fontSize: "18px",
                        fontWeight: "600",
                        lineHeight: "22px",
                        fontFamily: "Neometric Medium",
                      }}
                    >
                      {session.mentee.first_name} {session.mentee.last_name}
                    </h6>
                  </div>
                  <div
                    className="mentee-dashboard-sessionduration mentee-dashboard-sessioninfo"
                    style={{
                      margin: "14px 0",
                      textAlign: "center",
                      color: "#fff",
                    }}
                  >
                    <p
                      className="sessionduration font-Neometric-Regular"
                      style={{
                        textTransform: "capitalize",
                        fontSize: "12px",
                        lineHeight: "14px",
                        fontWeight: "400",
                      }}
                    >
                      duration of session
                    </p>
                    <h6
                      style={{
                        margin: "0",
                        fontSize: "18px",
                        fontWeight: "600",
                        lineHeight: "22px",
                        fontFamily: "Neometric Medium",
                      }}
                    >
                      {total}
                    </h6>
                  </div>
                  <div
                    className="mentee-dashboard-sessiontype mentee-dashboard-sessioninfo"
                    style={{
                      margin: "14px 0",
                      textAlign: "center",
                      color: "#fff",
                    }}
                  >
                    <p
                      className="sessiontype font-Neometric-Regular"
                      style={{
                        textTransform: "capitalize",
                        fontSize: "12px",
                        lineHeight: "14px",
                        fontWeight: "400",
                      }}
                    >
                      session type
                    </p>
                    <h6
                      style={{
                        margin: "0",
                        fontSize: "18px",
                        fontWeight: "600",
                        lineHeight: "22px",
                        fontFamily: "Neometric Medium",
                      }}
                    >
                      {session.interviewTitle}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="mentee-dashboard-sessionrating"
              style={{
                width: "65%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
              }}
            >
              <div
                className="mentee-dashboard-modalclose"
                onClick={props.closeModel}
                style={{
                  position: "absolute",
                  top: "9px",
                  right: "15px",
                  transform: "translate(0px, 0px)",
                }}
              >
                {!isCallOver && (
                  <i
                    className="fa fa-times"
                    style={{
                      fontSize: "20px",
                      padding: "8px",
                      cursor: "pointer",
                    }}
                  />
                )}
              </div>
              <div className="mentee-dashboard-ratingdetails">
                <div
                  className="userfulrating"
                  style={{
                    margin: "0 0 35px 0",
                  }}
                >
                  <label
                    htmlFor="usefulrating"
                    style={{
                      fontSize: "16px",
                      fontFamily: "Neometric Medium",
                      lineHeight: "19px",
                      display: "block",
                      marginBottom: "15px",
                    }}
                  >
                    Was your mentee prepared for their session?
                  </label>
                  <select
                    name="usefulrating"
                    id="usefulrating"
                    onChange={handleChangeMentorAnswer}
                    style={{
                      width: "100%",
                      padding: "12px 8px",
                      outline: "none",
                      border: "1px solid lightgray",
                      background: "#fff",
                      borderRadius: "5px",
                      fontFamily: "Neometric Regular",
                      margin: "6px 0",
                    }}
                  >
                    <option value="">---Please select an option---</option>
                    <option value="Very prepared">Very prepared</option>
                    <option value="Prepared">Prepared</option>
                    <option value="Somewhat prepared">Somewhat prepared</option>
                    <option value="Not prepared">Not prepared</option>
                  </select>
                  <div class="review_err">
                    {errors?.answer && errors?.answer?.msg}
                  </div>
                </div>

                <div
                  className="mentee-dashboard-overallrating"
                  style={{ margin: "24px 0" }}
                >
                  <p
                    style={{
                      fontSize: "16px",
                      fontFamily: "Neometric Medium",
                      lineHeight: "19px",
                      textTransform: "capitalize",
                      paddingBottom: "7px",
                    }}
                  >
                    overall rating
                  </p>
                  <div className="mentee-dashboard-ratingicons">
                    <ReactStars
                      count={5}
                      onChange={mentorRatingChanged}
                      size={40}
                      color="#D3D3D3"
                      activeColor="#ffd700"
                    />
                    {/*<i className="fa fa-star" style={{marginRight: '4px', fontSize: '24px'}} />*/}
                    {/*<i className="fa fa-star" style={{marginRight: '4px', fontSize: '24px'}} />*/}
                    {/*<i className="fa fa-star" style={{marginRight: '4px', fontSize: '24px'}} />*/}
                    {/*<i className="fa fa-star" style={{marginRight: '4px', fontSize: '24px'}} />*/}
                    {/*<i className="fa fa-star" style={{marginRight: '4px', fontSize: '24px'}} />*/}
                    <div class="review_err">
                      {errors?.rating && errors?.rating?.msg}
                    </div>
                  </div>
                </div>
                <div
                  className="mentee-dashboard-modalbutton"
                  style={{ padding: "20px 0" }}
                >
                  <button
                    disabled={isSubmit}
                    className="btn-finish"
                    onClick={goback}
                  >
                    {isSubmit && <i className="fas fa-circle-notch fa-spin" />}
                    finish
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MenteePopup;
