import React, { useEffect } from "react";
import SignUp from "../../components/auth/sign-up/sign-up/SignUp";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { useIsDesktop } from "../../helper/useIsDesktop";
import NotAccessibleForMobile from "../../components/landing-page/error-pages/NotAccessibleForMobile";
const SignupRoute = (props) => {
  const { currentUser, history } = props;
  const { isDesktop } = useIsDesktop();

  useEffect(() => {
    // if user loggedin redirect
    // always redirect status
    if (currentUser.loggedIn) {
      history.push("/sign-up/status");
    }
  }, [currentUser]);
  useEffect(() => {
    document.title = "Cardo | Sign Up";
  }, []);

  return <div>{isDesktop ? <SignUp /> : <NotAccessibleForMobile />}</div>;
};

const mapStateToProps = (state) => ({
  currentUser: state.auth.currentUser,
});
export default connect(mapStateToProps)(withRouter(SignupRoute));
