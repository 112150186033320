import React from "react";

const FindMentorSection6 = ({ mentorData }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        marginTop: "50px",
      }}
    >
      {mentorData &&
        mentorData.map((mentor, index) => {
          return (
            <div
              className="mentor_img_back"
              key={index}
              style={{
                position: "relative",
                backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0) 40%, rgba(0,0,0,1) 100%),url('${mentor.imageUrl}')`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
                height: "502.7px",
                width: "332.6px",
                borderRadius: "16.798px",
                margin: "0px 20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                  textAlign: "center",
                  height: "95%",
                }}
              >
                <span
                  style={{
                    color: "white",
                    fontWeight: "600",
                    fontSize: "19px",
                    lineHeight: "23px",
                    textAlign: "center",
                    fontFamily: "Neometric Medium",
                    marginBottom: "5px",
                  }}
                >
                  {mentor.name}
                </span>
                <span
                  style={{
                    color: "white",
                    fontSize: "14px",
                    lineHeight: "16px",
                    fontFamily: "Neometric Regular",
                  }}
                >
                  {mentor.area}
                  <i
                    className="fas fa-caret-right"
                    style={{ color: "white", margin: "0px 5px" }}
                  ></i>
                  {mentor.company}
                </span>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    fontFamily: "Neometric Regular",
                  }}
                >
                  {mentor.skills.split(",").map((skill, index) => {
                    return (
                      <span
                        key={index}
                        style={{
                          color: "white",
                          backgroundColor: "rgba(255, 255, 255, 0.24)",
                          borderRadius: "11.7857px",
                          fontSize: "12px",
                          lineHeight: "15px",
                          padding: "2px 15px",
                          margin: "4px",
                        }}
                      >
                        {skill}
                      </span>
                    );
                  })}
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default FindMentorSection6;
