import React, { useEffect, useState } from "react";
import notificationIcon from "../../../../assets/dashboard/header/Vector-8.svg";
import { socketManager } from "../../../../helper/socket";
import { connect } from "react-redux";
import {
  getNotifications,
  readNotifications,
} from "../../../../state-management/actions/dashboard/mentor-dashboard/mentorDashboardActions";
import moment from "moment";
import { Link } from "react-router-dom";

const ProfileNotification = (props) => {
  const {
    getNotifications,
    mentorNotifications,
    handleCurrentLink,
    readNotifications,
    currentUser,
  } = props;

  const [isNotify, setIsNotifiy] = useState(false);
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    getNotifications();
    socketManager
      .getSocket()
      .on("newNotfication", (data) => setNotifications([...data]));
  }, [socketManager.socket]);

  useEffect(() => {
    setNotifications([...mentorNotifications]);
  }, [mentorNotifications]);

  const getDateFormat = (date) => {
    return moment(date).calendar(null, {
      lastWeek: "DD MMM,YYYY",
      lastDay: "[Yesterday]",
      sameDay: "[Today]",
      sameElse: function () {
        return "DD MMM,YYYY";
      },
    });
  };

  const readAllNotifications = (dropdownOpen) => {
    setIsNotifiy(dropdownOpen);
    if (!dropdownOpen) {
      let unreadIds = notifications
        .sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        })
        .filter((item) =>
          currentUser.type === "mentor" ? !item.mentor_read : !item.mentee_read
        )
        .map((item) => item._id);
      if (unreadIds.length > 0) {
        readNotifications(unreadIds);
        setNotifications(
          notifications
            .sort((a, b) => {
              return new Date(b.createdAt) - new Date(a.createdAt);
            })
            .map((item) => {
              return currentUser.type === "mentor"
                ? { ...item, mentor_read: true }
                : { ...item, mentee_read: true };
            })
        );
      }
    }
  };

  const getNotficationMessage = (
    title,
    mentorName,
    menteeName,
    sessionDate
  ) => {
    let beginWith = "";
    if (title === "New session") {
      beginWith = "Congrats on scheduling a session for ";
    }
    if (title === "Rescheduled session") {
      beginWith = "You’ve successfully rescheduled your session to ";
    }
    if (title === "Canceled session") {
      beginWith = "You’ve cancelled your session on ";
    }

    return `${beginWith} ${moment(sessionDate).format("MM/DD/YYYY")} with ${
      currentUser.type === "mentor" ? menteeName : mentorName
    }`;
  };

  return (
    <div className="dashboard-header-notification">
      <div className="navbar">
        <div className="navbar_right">
          <div
            className={`${isNotify ? "active" : ""} notifications ${
              notifications
                .sort((a, b) => {
                  return new Date(b.createdAt) - new Date(a.createdAt);
                })
                .filter((item) => {
                  if (currentUser.type === "mentor") {
                    return !item.mentor_read;
                  } else {
                    return !item.mentee_read;
                  }
                }).length > 0
                ? "notifications-unread"
                : ""
            }`}
            onClick={() => readAllNotifications(!isNotify)}
          >
            <div className="icon_wrap dashboard-header-notification">
              <img
                src={notificationIcon}
                alt=""
                style={{ cursor: "pointer" }}
              />
            </div>
            <div className="notification_dd">
              <ul className="notification_ul">
                <li className="starbucks success">
                  <div className="notify_data">
                    {notifications.length > 0 ? (
                      notifications
                        .sort((a, b) => {
                          return new Date(b.createdAt) - new Date(a.createdAt);
                        })
                        .map((item, index) => (
                          <Link
                            key={index}
                            to={{ state: { prevLink: "My Sessions" } }}
                            onClick={() => {
                              handleCurrentLink("My Sessions");
                            }}
                          >
                            <div
                              className={`title font-Neometric-Medium ${
                                currentUser.type === "mentor"
                                  ? !item.mentor_read && "unread"
                                  : !item.mentee_read && "unread"
                              }`}
                            >
                              {item.title}

                              <div className="sub_title">
                                {getNotficationMessage(
                                  item.title,
                                  item.mentor.first_name,
                                  item.mentee.first_name,
                                  item.session.start_time
                                )}
                              </div>
                              <div className="time">
                                {getDateFormat(item.createdAt)}
                              </div>
                            </div>
                          </Link>
                        ))
                    ) : (
                      <div className="title font-Neometric-Medium">
                        No notifications
                        <div className="sub_title" />
                      </div>
                    )}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  mentorNotifications: state.mentorDashboard.notifications,
  currentUser: state.auth.currentUser,
});

export default connect(mapStateToProps, {
  getNotifications,
  readNotifications,
})(ProfileNotification);
