import React, { useState } from "react";
import "../../../styles/mobile-popup.css";
import ModalImage from "../../../assets/modal-content-not-available-on-mobile.svg";
const PopUp = (props) => {
  function closeModal(e) {
    e.stopPropagation();
    document.getElementsByClassName("mobile-modal")[0].style.display = "none";
  }

  return (
    <div className="mobile-modal" id="mobile-modal" onClick={closeModal}>
      <div
        className="mobile-modal-content"
        onClick={(e) => e.stopPropagation()}
      >
        <span className="close" onClick={closeModal}>
          &times;
        </span>
        <div className="mobile-modal-content-text">
          <h2 className="modal-content-title">Oops!</h2>
          <p className="modal-first-paragragh">
            Cardo is not available on mobile. Please log in or apply via your
            web browser on your Mac or PC.
          </p>
          <img
            className="modal_image"
            src={ModalImage}
            alt="not-available-on-mobile"
          />
          <p className="modal-second-paragragh">
            We want to make sure our mentors and mentees have the best
            experience possible. We’ve found that mobile communication is often
            filled with distractions, so we encourage mentorship in environments
            with fewer distractions!
          </p>
        </div>
      </div>
    </div>
  );
};

export default PopUp;
