import React, { useRef } from "react";
import PropTypes from "prop-types";
import SignupValidation from "../../../auth/sign-up/validation/SignupValidation";

const FileInput = ({
  onChange,
  name,
  error,
  fileName,
  buttonText,
  text,
  toolTip,
  id,
  resume,
}) => {
  const fileInput = useRef();

  const openFilePicker = () => {
    fileInput.current.click();
  };

  const fileChangeHandler = (e) => {
    if (e.target.files[0]) {
      onChange(name, e.target.files[0]);
      e.target.value = "";
    } else {
      onChange(name, {});
      e.target.value = "";
    }
  };

  return (
    <div className="mb-5 position-relative">
      <div className="display-flex">
        <label className="form-label" htmlFor={id}>
          {text}
        </label>
        {toolTip && (
          <div className="tooltip">
            ?<div className="tooltiptext">{toolTip}</div>
          </div>
        )}
      </div>
      {error && <SignupValidation message={error} />}

      <input
        id={id}
        type="file"
        name={name}
        ref={fileInput}
        onChange={fileChangeHandler}
        className="is-hidden"
        style={{ display: "none" }}
      />
      <div className="display-flex" style={{ alignItems: "center" }}>
        <button
          style={{
            backgroundColor: "transparent",
            border: "1px solid #5555DB",
            color: "#5555DB",
            fontSize: "16px",

            margin: "20px 20px 20px 0",
          }}
          type="button"
          className="cardo-btn file-btn"
          onClick={openFilePicker}
        >
          {buttonText} ↑
        </button>
        <div
          className="is-flex file-chosen-text"
          style={{ alignItems: "center" }}
        >
          {resume && fileName === "No file chosen" ? (
            resume
          ) : (
            <div>
              {fileName}
              {fileName !== "No file chosen" && (
                <span
                  onClick={(e) => onChange(name, {})}
                  className="remove-file"
                >
                  <i
                    className="fas fa-times ml-10"
                    style={{ color: "red", cursor: "pointer" }}
                  ></i>
                </span>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

FileInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  error: PropTypes.string,
  stepKey: PropTypes.string,
  fileName: PropTypes.string.isRequired,
};

export default FileInput;
