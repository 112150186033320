import { GET_FORM_MENTOR,GET_FORM_MENTEE, FORM_LOADING, GET_CONDITIOS_MENTOR, GET_CURRENT_USER, REMOVE_CURRENT_USER, CURRENT_USER_LOADING } from '../types/types'

const initialState = {
    loading: false,
    mentorForm: {},
    innerScript: {},
    menteeForm : {},
    currentUser:{},
    currentUserLoading: false
}

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case CURRENT_USER_LOADING:
            return {
                ...state,
                loading: true
            }
        case FORM_LOADING:
            return {
                ...state,
                loading: true
            }
        case GET_FORM_MENTOR:
            return {
                ...state,
                mentorForm: action.payload,
                loading: false
            }
            case GET_FORM_MENTEE:
                return {
                    ...state,
                    menteeForm: action.payload,
                    loading: false
                }
        case GET_CONDITIOS_MENTOR:
            return {
                ...state,
                innerScript: action.payload,

            }
        case GET_CURRENT_USER :
            return{
                ...state,
                currentUser: action.payload,
                currentUserLoading: false,
            }
        case REMOVE_CURRENT_USER :
            return {
                ...state,
                currentUser: action.payload,

            }
        default:
            return state;
    }
}
export default authReducer