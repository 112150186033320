import React, { useEffect, useState } from "react";
import RadioBtnGroup from "../../profile-form-components/RadioBtnGroup";
import MessagePopup from "./MessagePopup";
import aprooved from "../../../../../assets/mentee-dashboard-profile-edit/aprooved.png";
import { connect } from "react-redux";
import { putMentorProfileMentorship } from "../../../../../state-management/actions/dashboard/mentor-dashboard/profileActions";
import TextField from "../../profile-form-components/TextField";

const MentorshipTab = (props) => {
  const { accountData, handleRadioChange, putMentorProfileMentorship } = props;
  const [loading, setLoading] = useState(false);
  const [saved, setSaved] = useState(false);
  const [errorMsg, setErrorMsg] = useState({
    industryError: "",
    trailError: "",
    supportAreasError: "",
  });
  const handleLoading = (val) => {
    setLoading(val);
  };
  const resetErros = () => {
    setErrorMsg({
      ...errorMsg,
      industryError: "",
      trailError: "",
      supportAreasError: "",
    });
  };
  const validate = () => {
    setErrorMsg({
      ...errorMsg,
      industryError:
        (!accountData.industry.value ||
          accountData.industry.value.length === 0) &&
        "Please select at least one industry!",
      trailError:
        (!accountData.trail.value || accountData.trail.value.length === 0) &&
        "Please select at least one trail!",
      trailError:
        (!accountData.trail.value || accountData.trail.value.length > 2) &&
        "Please select up to 2 trail!",
      supportAreasError:
        (!accountData.support.value ||
          accountData.support.value.length === 0) &&
        "Please select at least one support area!",
      supportAreasError:
        (!accountData.support.value || accountData.support.value.length > 5) &&
        "Please select up to 5 support area!",
    });

    return (
      accountData.industry.value &&
      accountData.industry.value.length > 0 &&
      accountData.trail.value &&
      accountData.trail.value.length > 0 &&
      accountData.trail.value &&
      accountData.trail.value.length <= 2 &&
      accountData.support.value &&
      accountData.support.value.length > 0 &&
      accountData.support.value &&
      accountData.support.value.length <= 5
    );
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      handleLoading(true);
      resetErros();
      const formData = new FormData();
      formData.append("industry", accountData.industry.value);
      formData.append("trail", accountData.trail.value);
      formData.append("supportArea", accountData.support.value);

      await putMentorProfileMentorship(formData, handleLoading);
      setSaved(true);
    } else {
      return false;
    }
  };

  const { industry, trail, support } = accountData;
  return (
    <div className="mentee-profile-account">
      <h2>Mentorship Focus</h2>
      <div className="mentee-profile-form-wrapper">
        <form onSubmit={(e) => handleSubmit(e)}>
          <div className="form-line">
            <RadioBtnGroup
              id="industry"
              label="Industry"
              name="industry"
              disabled
              value={industry.value}
              inputValues={industry.inputValues}
              onChange={null}
              errorMsg={errorMsg.industryError}
              toolTip={
                <div>
                  We are currently only servicing the Consulting industry. Stay
                  on the lookout for us to launch new industries!
                </div>
              }
            />
          </div>
          <div className="form-line">
            <RadioBtnGroup
              disabled
              id="trail"
              label="Trail"
              name="trail"
              value={trail.value}
              inputValues={trail.inputValues}
              onChange={handleRadioChange}
              errorMsg={errorMsg.trailError}
              toolTip={
                <div>
                  We allow mentors to support up to two Trails at a time. If
                  you’d like to add, remove, or edit your Trails, please reach
                  out to our support team.
                </div>
              }
            />
          </div>
          <div className="form-line">
            <RadioBtnGroup
              id="support"
              label="Support Areas"
              name="support"
              value={support.value}
              inputValues={support.inputValues}
              onChange={handleRadioChange}
              errorMsg={errorMsg.supportAreasError}
              disabled={false}
              toolTip={
                <div>
                  You can add or remove your Support Areas at any time. If you
                  remove a support area, no new sessions can be scheduled.
                  However, any prior scheduled sessions will remain.
                </div>
              }
            />
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <button
              type="submit"
              className="save-btn cardo-btn btn-bg-lightblue"
              // style={{ margin: "20px 10px 10px 10px" }}
            >
              {!loading ? (
                "Save Changes"
              ) : (
                <div className={"form-loader form-loader-active"}>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              )}
            </button>
            {saved && !loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: "10px",
                }}
              >
                <div
                  className="font-Neometric-Medium"
                  style={{
                    color: "black",
                  }}
                >
                  Changes Saved{" "}
                </div>
                <img style={{ marginLeft: "10px" }} src={aprooved} alt="" />
              </div>
            ) : (
              ""
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default connect(null, { putMentorProfileMentorship })(MentorshipTab);
