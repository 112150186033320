import React, { useState } from "react";
import RadioBtnGroup from "../../profile-form-components/RadioBtnGroup";
import MessagePopup from "./MessagePopup";

const MentorAccountTab = (props) => {
  const { accountData, handleRadioChange } = props;
  const [loading, setLoading] = useState(false);
  const handleLoading = (val) => {
    setLoading(val);
  };

  const { industry, trail } = accountData;
  return (
    <div className="mentee-profile-account">
      <div className="mt-25">
        <div className="mt-25 mb-25">
          <h2>Account</h2>
        </div>
        <div className="mt-25 mb-25">
          <p className="delete-account-text">
            If you would like to delete your account, you can do so here.
            Account deletions are <br /> permanent and cannot be reversed.{" "}
            <br /> <br /> If you prefer to turn your profile off, you can do so
            via the toggle above
          </p>
        </div>
        <div>
          <button
            className="cardo-btn delete-account-btn"
            onClick={() => props?.handleDeletePopup()}
          >
            Delete Account
          </button>
        </div>
      </div>
    </div>
  );
};

export default MentorAccountTab;
