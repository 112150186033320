import React from 'react';
import useParticipants from "../../../../../../hooks/useParticipants";
import useScreenShareParticipant from "../../../../../../hooks/useScreenShareParticipant";
import useMainParticipant from "../../../../../../hooks/useMainParticipant";
import useSelectedParticipant from "../../../../../../hooks/useSelectedParticipant";
import Participant from "./Participant";

export default function ParticipantList({room}) {
    const localParticipant = room?.localParticipant;
    const participants = useParticipants(room);
    const [selectedParticipant, setSelectedParticipant] = useSelectedParticipant(room);
    const screenShareParticipant = useScreenShareParticipant(room);
    const mainParticipant = useMainParticipant(room);

    if (participants.length === 0) return null; // Don't render this component if there are no remote participants.

    return (
        <aside
            style={{
                overflowY: 'auto',
                gridArea: '1 / 2 / 1 / 3',
                zIndex: 5,
            }}
        >
            <div style={{
                display: 'flex',
                justifyContent: 'center',
            }}>
                <div style={{
                    width: `100px`,
                }}>
                    <Participant participant={localParticipant} isLocalParticipant={true}/>
                    {participants.map(participant => {
                        const isSelected = participant === selectedParticipant;
                        const hideParticipant =
                            participant === mainParticipant && participant !== screenShareParticipant && !isSelected;
                        return (
                            <Participant
                                key={participant.sid}
                                participant={participant}
                                isSelected={participant === selectedParticipant}
                                onClick={() => setSelectedParticipant(participant)}
                                hideParticipant={hideParticipant}
                            />
                        );
                    })}
                </div>
            </div>
        </aside>
    );
}
