import React, {useCallback, useRef} from 'react';
import useLocalVideoToggle from "../../../../../../hooks/useLocalVideoToggle";
import useDevices from "../../../../../../hooks/useDevices";
import video from "../../../../../../assets/mentor/video.svg";
import videoOff from "../../../../../../assets/mentor/video-off.svg";

export default function ToggleVideoButton(props) {
    const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle(props.localTracks, props.getLocalVideoTrack, props.removeLocalVideoTrack, props.room);
    const lastClickTimeRef = useRef(0);
    const {hasVideoInputDevices} = useDevices();

    const toggleVideo = useCallback(() => {
        if (Date.now() - lastClickTimeRef.current > 500) {
            lastClickTimeRef.current = Date.now();
            toggleVideoEnabled();
        }
    }, [toggleVideoEnabled]);

    return (
        <div className="video-button-group action-button-group">
            <button onClick={toggleVideo} disabled={!hasVideoInputDevices || props.disabled} className={`${isVideoEnabled ? 'active': 'disabled'}`}>
                <img className='video' style={{
                    display: isVideoEnabled ? 'block' : 'none'
                }} src={video} alt='icon'/>
                <img className='video-off' style={{
                    display: !isVideoEnabled ? 'block' : 'none'
                }} src={videoOff} alt='icon' />
            </button>
            <span>{!hasVideoInputDevices ? 'No Video' : 'Video'}</span>
        </div>
    );
}
