import React, { useState, useEffect } from "react";

const MenteeDashboardFindMentorCards = (props) => {
  const { menteeDashboardFindMentor, mentorFilter } = props;
  function popupHandler(mentor) {
    props.selectModal();
    props.selectMentor(mentor);
  }

  return (
    <div className="mentee-dashboard-find-mentor-cards-container">
      {menteeDashboardFindMentor?.length > 0 &&
        menteeDashboardFindMentor
          .filter((mentor) => {
            if (mentorFilter.skillFilter.length === 0) {
              return true;
            } else {
              return mentorFilter.skillFilter.some((v) =>
                mentor.profile_info.support_areas.includes(v)
              );
            }
          })
          .map((item, index) => (
            <div key={index} className="mentee-dashboard-find-mentor-card-item">
              <div
                className="mentee-dashboard-find-mentor-card-item-img-container"
                style={{ backgroundImage: `url(${item.img_url})` }}
              />
              <div className="mentee-dashboard-find-mentor-card-item-name">
                {item.first_name} {item.last_name.substr(0, 1)}.
              </div>
              <div className="mentee-dashboard-find-mentor-card-item-company">
                {item.profile_info.consulting_firm}
              </div>
              <div className="mentee-dashboard-find-mentor-card-item-skills-container">
                {item.profile_info.support_areas.sort().map((skill, index) => (
                  <div
                    key={index}
                    className="mentee-dashboard-find-mentor-card-item-skill"
                  >
                    {skill}
                  </div>
                ))}
              </div>
              <div className="mentee-dashboard-find-mentor-card-item-schedule-btn-container">
                <div
                  className="cardo-btn mentee-dashboard-find-mentor-card-item-schedule-btn"
                  onClick={() => popupHandler(item)}
                >
                  Schedule
                </div>
              </div>
            </div>
          ))}
    </div>
  );
};

export default MenteeDashboardFindMentorCards;
