import { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getCurrentUser,
  postOnboardingFirstTime,
  postOnboardingFirstTimeTemp,
} from "../../../state-management/actions/dashboard/mentor-dashboard/mentorDashboardActions";
import "../../../styles/onboardingPopup.css";
import { onboardingData } from "./onboardingData";

const OnboardingPopup = (props) => {
  const { currentUser, setOpenOnboardingPopup } = props;
  const [step, setStep] = useState(0);
  const [clickable, setClickable] = useState(false);
  const [checked, setChecked] = useState(false);
  let width = -900;

  const dontShowAgain = () => {
    props.postOnboardingFirstTime();
  };
  const dontShowAgainTemp = () => {
    props.postOnboardingFirstTimeTemp();
  };
  useEffect(() => {
    if (props?.onBoarding) {
      props.getCurrentUser();
    }
    if (props?.onBoardingTemp) {
      props.getCurrentUser();
    }
    if (
      !props?.currentUser?.onBoarding ||
      !props?.currentUser?.onBoardingTemp
    ) {
      setOpenOnboardingPopup(false);
    }
  }, [props]);
  return (
    <div className="onboarding_popup_component">
      <div className="onboarding_overlay">
        <div className="onboarding_popup_settings">
          {step === onboardingData.length - 1 && (
            <div
              className="onboarding_close_btn"
              onClick={() => {
                dontShowAgainTemp();
              }}
            >
              ×
            </div>
          )}
          <div className="onboarding_slider_wrapper">
            <div className="onboarding_arrow">
              {step !== 0 && (
                <div
                  onClick={() => {
                    setStep((prev) => prev - 1);
                  }}
                >
                  <i class="fas fa-2x fa-angle-left"></i>
                </div>
              )}
            </div>
            <div className="onboarding_arrow">
              {step !== onboardingData.length - 1 && (
                <div
                  onClick={() => {
                    setStep((prev) => prev + 1);
                  }}
                >
                  <i class="fas fa-2x fa-angle-right"></i>
                </div>
              )}
            </div>
          </div>
          <div className="onboarding_slider_container">
            <div
              style={{
                left: `${step * width}px`,
              }}
              className="onboarding_slider_track"
            >
              {onboardingData.map((item, index) => {
                return (
                  <div key={index} className="onboarding_slide">
                    <div className="onboarding_slide_title">
                      {item.title.replace("{Name}", currentUser.firstName)}
                    </div>
                    <div className="onboarding_slide_image">
                      <img src={item.img} alt="onboarding" />
                    </div>
                    <div className="onboarding_slide_subtitle">
                      {item.subTitle}{" "}
                      {item.link && (
                        <a
                          href={`mailto:${item.link}`}
                          className="popup-subtitle-link"
                        >
                          {item.link}
                        </a>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          {step === onboardingData.length - 1 && (
            <div
              onClick={() => dontShowAgain()}
              className="onboarding_checkbox"
            >
              Don't show this again
            </div>
          )}

          <div className="onboarding_dots">
            {onboardingData.map((item, index) => {
              return (
                <div
                  key={index}
                  id={index}
                  onClick={() => {
                    if (clickable) {
                      setStep(index);
                    }
                  }}
                  className={index === step ? "dot active" : "dot"}
                ></div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  currentUser: state.auth.currentUser,
  onBoarding: state.mentorDashboard.onBoarding,
  onBoardingTemp: state.mentorDashboard.onBoardingTemp,
});
export default connect(mapStateToProps, {
  postOnboardingFirstTime,
  getCurrentUser,
  postOnboardingFirstTimeTemp,
})(OnboardingPopup);
