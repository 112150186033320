import React, { useEffect } from "react";
import EditableRadio from "../../../form-component/EditableRadio";
import FileInput from "../../../form-component/FileInput";
import Textbox from "../../../form-component/Textbox";
import Radio from "../../../form-component/Radio";
import RadioGroup from "../../../form-component/RadioGroup";
import Select from "../../../form-component/Select";
import SignupValidation from "../../validation/SignupValidation";

const MentorSignupStep2YourBackground = (props) => {
  const {
    title,
    step,
    onPrevStep,
    onStepChange,
    data,
    onChange,
    onRadioChange,
    errors,
    onFileChange,
    stepKey,
    handleRequired,
  } = props;
  useEffect(() => {
    if (data.q81_areYou.value === "Yes") {
      handleRequired(
        stepKey,
        [data.q86_whatConsulting],
        [data.q105_whichConsulting, data.q84_whereDid]
      );
    } else if (data.q81_areYou.value === "No") {
      handleRequired(
        stepKey,
        [data.q105_whichConsulting],
        [data.q86_whatConsulting]
      );
    }
  }, [data.q81_areYou.value]);

  return (
    <div>
      <h3 className="sign-up-form-heading">{title}</h3>
      <div className="step-form-content">
        {/* Which Country */}
        <div className="form-line">
          <Select
            name={data.q68_whichCountry.name}
            text={data.q68_whichCountry.text}
            value={data.q68_whichCountry.value}
            description={data.q68_whichCountry.description}
            selected={data.q68_whichCountry.selected}
            onChange={(e) => onChange(stepKey, e)}
            error={errors[data.q68_whichCountry.name]}
            choices={data.q68_whichCountry.choices}
          />
        </div>
        {data.q68_whichCountry.value !== "United States" ? (
          <SignupValidation
            message={` Due to a large variance in recruiting processes and approaches, we
                are only accepting mentors who are located in the United States at
                this time.`}
          />
        ) : (
          <div>
            <div className="form-line">
              <RadioGroup
                text={data.q12_whichIndustry12.text}
                description={data.q12_whichIndustry12.description}
                error={errors[data.q12_whichIndustry12.name]}
              >
                <Radio
                  name={data.q12_whichIndustry12.name}
                  value={data.q12_whichIndustry12.value}
                  text={data.q12_whichIndustry12.text}
                  inputValue={data.q12_whichIndustry12.inputValues[0]}
                  label={data.q12_whichIndustry12.inputValues[0]}
                  id={`${data.q12_whichIndustry12.name}_radio_0`}
                  selected={true}
                  onRadioChange={(e) =>
                    onRadioChange(stepKey, e, data.q12_whichIndustry12.value)
                  }
                  type={data.q12_whichIndustry12.type}
                />
                <Radio
                  name={data.q12_whichIndustry12.name}
                  value={data.q12_whichIndustry12.value}
                  text={data.q12_whichIndustry12.text}
                  inputValue={data.q12_whichIndustry12.inputValues[1]}
                  label={data.q12_whichIndustry12.inputValues[1]}
                  id={`${data.q12_whichIndustry12.name}_radio_1`}
                  onRadioChange={(e) =>
                    onRadioChange(stepKey, e, data.q12_whichIndustry12.value)
                  }
                  disabled
                  type={data.q12_whichIndustry12.type}
                />
                <Radio
                  name={data.q12_whichIndustry12.name}
                  value={data.q12_whichIndustry12.value}
                  text={data.q12_whichIndustry12.text}
                  inputValue={data.q12_whichIndustry12.inputValues[2]}
                  label={data.q12_whichIndustry12.inputValues[2]}
                  id={`${data.q12_whichIndustry12.name}_radio_2`}
                  onRadioChange={(e) =>
                    onRadioChange(stepKey, e, data.q12_whichIndustry12.value)
                  }
                  disabled
                  type={data.q12_whichIndustry12.type}
                />
              </RadioGroup>
            </div>
            <div className="form-line">
              <RadioGroup
                text={data.q81_areYou.text}
                error={errors[data.q81_areYou.name]}
                description={data.q81_areYou.description}
              >
                <Radio
                  name={data.q81_areYou.name}
                  value={data.q81_areYou.value}
                  text={data.q81_areYou.text}
                  inputValue={data.q81_areYou.inputValues[0]}
                  onRadioChange={(e) =>
                    onRadioChange(stepKey, e, data.q81_areYou.value)
                  }
                  type={data.q81_areYou.type}
                  label={data.q81_areYou.inputValues[0]}
                  id={`${data.q81_areYou.name}_radio_0`}
                />
                <Radio
                  name={data.q81_areYou.name}
                  value={data.q81_areYou.value}
                  text={data.q81_areYou.text}
                  inputValue={data.q81_areYou.inputValues[1]}
                  onRadioChange={(e) =>
                    onRadioChange(stepKey, e, data.q81_areYou.value)
                  }
                  type={data.q81_areYou.type}
                  label={data.q81_areYou.inputValues[1]}
                  id={`${data.q81_areYou.name}_radio_1`}
                />
              </RadioGroup>
            </div>
            {data.q81_areYou.value === "No" && (
              <div>
                <div className="form-line">
                  <RadioGroup
                    text={data.q84_whereDid.text}
                    error={errors[data.q84_whereDid.name]}
                    description={data.q84_whereDid.description}
                  >
                    <Radio
                      checked={false}
                      name={data.q84_whereDid.name}
                      value={data.q84_whereDid.value}
                      text={data.q84_whereDid.text}
                      inputValue={data.q84_whereDid.inputValues[0]}
                      onRadioChange={(e) =>
                        onRadioChange(stepKey, e, data.q84_whereDid.value)
                      }
                      type={data.q84_whereDid.type}
                      label={data.q84_whereDid.inputValues[0]}
                      id={`${data.q84_whereDid.name}_radio_0`}
                    />
                    <Radio
                      checked={false}
                      name={data.q84_whereDid.name}
                      value={data.q84_whereDid.value}
                      text={data.q84_whereDid.text}
                      inputValue={data.q84_whereDid.inputValues[1]}
                      onRadioChange={(e) =>
                        onRadioChange(stepKey, e, data.q84_whereDid.value)
                      }
                      type={data.q84_whereDid.type}
                      label={data.q84_whereDid.inputValues[1]}
                      id={`${data.q84_whereDid.name}_radio_1`}
                    />
                    <Radio
                      checked={false}
                      name={data.q84_whereDid.name}
                      value={data.q84_whereDid.value}
                      text={data.q84_whereDid.text}
                      inputValue={data.q84_whereDid.inputValues[2]}
                      onRadioChange={(e) =>
                        onRadioChange(stepKey, e, data.q84_whereDid.value)
                      }
                      type={data.q84_whereDid.type}
                      label={data.q84_whereDid.inputValues[2]}
                      id={`${data.q84_whereDid.name}_radio_2`}
                    />
                    <EditableRadio
                      checked={false}
                      name={data.q84_whereDid.name}
                      value={data.q84_whereDid.value}
                      text={data.q84_whereDid.text}
                      inputValues={data.q84_whereDid.inputValues}
                      inputValue={data.q84_whereDid.inputValues[3]}
                      onRadioChange={onRadioChange}
                      stepKey={stepKey}
                      type={data.q84_whereDid.type}
                      label={data.q84_whereDid.inputValues[3]}
                      id={`${data.q84_whereDid.name}_radio_3`}
                    />
                  </RadioGroup>
                </div>
                <div className="form-line">
                  <Textbox
                    placeholder={data.q105_whichConsulting.placeholder}
                    name={data.q105_whichConsulting.name}
                    value={data.q105_whichConsulting.value}
                    text={data.q105_whichConsulting.text}
                    description={data.q105_whichConsulting.description}
                    id={`${data.q105_whichConsulting.name}_textbox`}
                    onChange={(e) => onChange(stepKey, e)}
                    error={errors[data.q105_whichConsulting.name]}
                    type={data.q105_whichConsulting.type}
                  />
                </div>
              </div>
            )}
            {data.q81_areYou.value === "Yes" && (
              <div>
                <div className="form-line">
                  <Textbox
                    placeholder={data.q86_whatConsulting.placeholder}
                    name={data.q86_whatConsulting.name}
                    value={data.q86_whatConsulting.value}
                    text={data.q86_whatConsulting.text}
                    description={data.q86_whatConsulting.description}
                    id={`${data.q86_whatConsulting.name}_textbox`}
                    onChange={(e) => onChange(stepKey, e)}
                    error={errors[data.q86_whatConsulting.name]}
                    type={data.q86_whatConsulting.type}
                  />
                </div>
              </div>
            )}
            <div>
              <div className="form-line">
                <Textbox
                  placeholder={data.q113_education.placeholder}
                  name={data.q113_education.name}
                  value={data.q113_education.value}
                  text={data.q113_education.text}
                  description={data.q113_education.description}
                  id={`${data.q113_education.name}_textbox`}
                  onChange={(e) => onChange(stepKey, e)}
                  error={errors[data.q113_education.name]}
                  type={data.q113_education.type}
                />
              </div>
            </div>
            <div className="form-line">
              <FileInput
                id={`${data.q25_pleaseUpload25.name}_id1`}
                onChange={onFileChange}
                error={errors[data.q25_pleaseUpload25.name]}
                name={data.q25_pleaseUpload25.name}
                description={data.q25_pleaseUpload25.description}
                stepKey={stepKey}
                fileName={data.q25_pleaseUpload25.fileName}
                text={data.q25_pleaseUpload25.text}
                buttonText={data.q25_pleaseUpload25.buttonText}
              />
            </div>
          </div>
        )}
        {/* Which Industry */}
      </div>

      <div className="form-step-btn-group">
        {step > 0 && (
          <button
            type="button"
            className="cardo-btn back-btn"
            onClick={() => onPrevStep(step - 1)}
          >
            Go back
          </button>
        )}
        <button
          type="button"
          className="cardo-btn next-btn"
          onClick={(e) => onStepChange(data, e)}
          disabled={data.q68_whichCountry.value !== "United States"}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default MentorSignupStep2YourBackground;
