import React, { useEffect, useState } from "react";
import AdminResizableTableContent from "./AdminResizableTableContent";
import "../../../../styles/dashboard/admin-dasboard/ru-admin-dashboard/admin-dashboard-table.css";
import CardoButton from "../../../common/btn/CardoButton";
import chevron from "../../../../assets/icons/chevron.svg";
import EditableSubCell from "./EditableSubCell";

const AdminResizableTable = (props) => {
  const {
    // data,
    headers,
    type,
    lastColumnComponent,
    expanded,
    sortedColumns,
    setCurrentDataIndex,
    hiddenColumns,
    checkPipeline,
    handler,
    editable,
    onRevokeUser,
    onSaveClick,
    onDeleteUser,
  } = props;
  const [initialData, setInitialData] = useState(props?.data);
  const [data, setData] = React.useState(props?.data);
  useEffect(() => {
    setInitialData(props?.data);
    setData(props?.data);
  }, [props?.data]);
  const columns = React.useMemo(() => {
    let temp = [...headers];
    type && temp.push(type);
    expanded && temp.push("expander");
    return temp?.map((header) => {
      if (header === "expander") {
        return {
          // Make an expander cell
          Header: () => null, // No header
          id: "expander", // It needs an ID
          minWidth: 200,
          Cell: ({ row }) => (
            // Use Cell to render an expander for each row.
            // We can use the getToggleRowExpandedProps prop-getter
            // to build the expander.
            <span {...row.getToggleRowExpandedProps()}>
              {row.isExpanded ? (
                <CardoButton
                  text="View"
                  width="90px"
                  height="24px"
                  id="revokeBtn"
                  className="position-relative btn-bg-lightblue admin_dashboard_btn"
                  icon={
                    <img
                      style={{
                        position: "absolute",
                        right: "10px",
                        transform: "rotate(180deg)",
                      }}
                      src={chevron}
                      alt=""
                    />
                  }
                  margin="0"
                />
              ) : (
                <CardoButton
                  text="View"
                  width="90px"
                  height="24px"
                  id="revokeBtn"
                  className="position-relative btn-bg-lightblue admin_dashboard_btn"
                  icon={
                    <img
                      style={{ position: "absolute", right: "10px" }}
                      src={chevron}
                      alt=""
                    />
                  }
                  margin="0"
                />
              )}
            </span>
          ),
        };
      } else {
        return {
          Header: header !== type ? header.replace(/_/g, " ") : () => null,
          accessor: header,
          width: 200,
        };
      }
    });
  }, [type, data]);

  const renderSubItemCard = (row, obj) => {
    let arr = [];
    for (const key in obj) {
      arr.push(
        <div className="expanded_table_sub_component_container_leftCol_item_container">
          <div className="expanded_table_sub_component_container_leftCol_item_key">
            {key}
          </div>
          <div className="expanded_table_sub_component_container_leftCol_item_value">
            {key === "application" ? (
              <a
                href={`https://www.jotform.com/submission/${obj[key]}`}
                target="_blank"
              >
                View Submission
              </a>
            ) : (
              <EditableSubCell
                value={obj[key]}
                upadteData={updateMyDataSubContent}
                name={key}
                rowId={row.id}
              />
            )}
          </div>
        </div>
      );
    }
    return arr;
  };
  const renderRowSubComponent = ({ row }) => {
    if (row.isExpanded) {
      return (
        <div>
          <div className="expanded_table_sub_component_container">
            <div className="expanded_table_sub_component_container_leftCol">
              {renderSubItemCard(row, row.values.subData)}
            </div>
            <div className="expanded_table_sub_component_container_rightCol_handler">
              <div
                onClick={(e) => {
                  onRevokeUser(e, data[row.id].type, data[row.id].id);
                }}
              >
                Revoke User Access
              </div>
              <div
                onClick={(e) => {
                  setInitialData(data);
                  onSaveClick(e, data[row.id]);
                }}
              >
                Save
              </div>
              <div
                onClick={(e) => {
                  onDeleteUser(e, data[row.id].type, data[row.id].id);
                  // setInitialData(data);
                  // onSaveClick(e, data[row.id]);
                }}
              >
                Delete
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  const updateMyData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page

    setData((prev) =>
      prev.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...prev[rowIndex],
            [columnId]: value,
          };
        }

        return row;
      })
    );
  };

  const updateMyDataSubContent = (rowId, inputName, inputValue) => {
    setData((prev) =>
      prev.map((row, index) => {
        if (+rowId === index) {
          return {
            ...prev[rowId],
            subData: {
              ...prev[rowId].subData,
              [inputName]: inputValue,
            },
          };
        }

        return row;
      })
    );
  };

  return (
    <div>
      <AdminResizableTableContent
        columns={columns}
        data={initialData}
        type={type}
        checkPipeline={checkPipeline}
        lastColumnComponent={lastColumnComponent}
        setCurrentDataIndex={setCurrentDataIndex}
        renderRowSubComponent={renderRowSubComponent}
        sortedColumns={sortedColumns}
        hiddenColumns={hiddenColumns}
        expanded
        handler={handler}
        editable={editable}
        updateMyData={updateMyData}
      />
    </div>
  );
};

export default AdminResizableTable;
