import React from "react";
import moment from "moment-timezone";
import { Link } from "react-router-dom";

const MentorDashboardUpcomingSessions = (props) => {
  const {
    mentorDashboardHome,
    handleCurrentLink,
    selectModalDetails,
    handleModalMentorDetails,
  } = props;
  const handleDetailsClick = (upcomingSession) => {
    selectModalDetails();
    handleModalMentorDetails(upcomingSession);
  };
  //check if upcoming sessions not undefined
  const checkUpcomingSessions =
    mentorDashboardHome && mentorDashboardHome.upcomingSessions;
  //upcoming sessions item content
  let upcomingSessionsItemContent;
  //upcoming session empty
  let upcomingSessionsEmpty;

  if (
    checkUpcomingSessions &&
    mentorDashboardHome.upcomingSessions.length > 0
  ) {
    upcomingSessionsItemContent = mentorDashboardHome.upcomingSessions.map(
      (upcomingSession, index) => (
        <div
          className="mentee-dashboard-home-upcoming-sessions-item"
          key={index}
        >
          <div className="mentee-dashboard-home-upcoming-sessions-item-container">
            <div className="display-flex justify-content-between align-items-center">
              <div className="font-Neometric-Medium">
                {`${
                  upcomingSession.mentee?.first_name
                } ${upcomingSession.mentee?.last_name.substr(0, 1)}.`}
                {upcomingSession.mentee?.status === "deleted" && "(Deleted)"}
              </div>
              <button
                onClick={() => handleDetailsClick(upcomingSession)}
                className="cardo-btn cardo-btn-sm m-0"
              >
                Details
              </button>
            </div>
            <div className="mentee-dashboard-home-upcoming-sessions-item-date-time-container">
              <div className="display-flex flex-direction-column mr-20">
                <span className="mentee-dashboard-home-upcoming-sessions-item-date-time">
                  Date
                </span>
                <span>
                  {moment(upcomingSession.start_time)
                    .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
                    .format("MM/DD/YY")}
                </span>
              </div>
              <div className="display-flex flex-direction-column ml-20">
                <span className="mentee-dashboard-home-upcoming-sessions-item-date-time">
                  Time
                </span>
                <span>
                  {moment(upcomingSession.start_time)
                    .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
                    .format("LT")}{" "}
                  to{" "}
                  {moment(upcomingSession.end_time)
                    .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
                    .format("LT")}
                </span>
              </div>{" "}
            </div>
          </div>
        </div>
      )
    );
  }
  if (
    checkUpcomingSessions &&
    mentorDashboardHome.upcomingSessions.length < 2
  ) {
    upcomingSessionsEmpty = (
      <div className="mentee-dashboard-home-upcoming-sessions-empty">
        <div
          className="mentee-dashboard-home-upcoming-sessions-empty-container"
          style={{ flexDirection: "row" }}
        >
          <div className="font-Neometric-Regular">
            {mentorDashboardHome.upcomingSessions.length === 1
              ? "No Additional Sessions."
              : "You have no upcoming sessions."}
          </div>
          <div>
            {/*<button*/}
            {/*  onClick={() => handleCurrentLink("Find Mentor")}*/}
            {/*  className="cardo-btn cardo-btn-sm pink-btn mt-0 mb-0 ml-25 mr-25"*/}
            {/*>*/}
            {/*  Book Now*/}
            {/*</button>*/}
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="mentee-dashboard-home-components-line">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          position: "relative",
        }}
      >
        <div
          className="font-Neometric-Medium mt-25 mb-25"
          style={{ marginTop: "45px" }}
        >
          {" "}
          Upcoming Sessions
        </div>
        {checkUpcomingSessions &&
          mentorDashboardHome.upcomingSessions.length !== 0 && (
            <Link
              to={{
                state: { prevLink: "My Sessions" },
              }}
              onClick={() => handleCurrentLink("My Sessions")}
              className="font-Neometric-Regular mentee-dashboard-home-upcoming-sessions-view-all"
            >
              View all
            </Link>
          )}
      </div>
      <div className="mentee-dashboard-home-upcoming-sessions-container">
        {upcomingSessionsItemContent}
        {upcomingSessionsEmpty}
      </div>
    </div>
  );
};

export default MentorDashboardUpcomingSessions;
