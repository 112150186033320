import React, { useEffect, useState } from "react";
import DoughnutChart from "./DoughnutChart";

const MenteeDashboardMentorshipAreas = (props) => {
  const { menteeDashboardHome } = props;
  const [total, setTotal] = useState(0);
  const [resumeReviewTime, setResumeReviewTime] = useState(0);
  const [caseInterviewTime, setCaseInterviewTime] = useState(0);
  const [fitInterviewTime, setFitInterviewTime] = useState(0);
  const [industryOverviewTime, setIndustryOverviewTime] = useState(0);
  const [linkedInTipsTime, setLinkedInTipsTime] = useState(0);
  const setHours = () => {
    let fifteemMinutes = 1000 * 60 * 15;
    let halfHour = 1000 * 60 * 30;
    menteeDashboardHome?.chartData?.map((item, index) => {
      if (
        +item.areaParameter >= fifteemMinutes &&
        +item.areaParameter < halfHour
      ) {
        if (item.areaTitle === "Resume Review") {
          if (item.areaTitle === "Resume Review") {
            setResumeReviewTime((prev) => prev + 0.5);
          }
          if (item.areaTitle === "Case Interview") {
            setCaseInterviewTime((prev) => prev + 0.5);
          }
          if (item.areaTitle === "Fit Interview") {
            setFitInterviewTime((prev) => prev + 0.5);
          }
          if (item.areaTitle === "Industry Overview") {
            setIndustryOverviewTime((prev) => prev + 0.5);
          }
          if (item.areaTitle === "LinkedIn Tips") {
            setLinkedInTipsTime((prev) => prev + 0.5);
          }
        }
      } else if (+item.areaParameter >= halfHour) {
        if (item.areaTitle === "Resume Review") {
          setResumeReviewTime((prev) => prev + 1);
        }
        if (item.areaTitle === "Case Interview") {
          setCaseInterviewTime((prev) => prev + 1);
        }
        if (item.areaTitle === "Fit Interview") {
          setFitInterviewTime((prev) => prev + 1);
        }
        if (item.areaTitle === "Industry Overview") {
          setIndustryOverviewTime((prev) => prev + 1);
        }
        if (item.areaTitle === "LinkedIn Tips") {
          setLinkedInTipsTime((prev) => prev + 1);
        }
      }
    });
  };
  const totalHoursCalc = () => {
    setTotal(
      resumeReviewTime +
        caseInterviewTime +
        fitInterviewTime +
        industryOverviewTime +
        linkedInTipsTime
    );
  };
  useEffect(() => {
    totalHoursCalc();
  }, [
    resumeReviewTime,
    caseInterviewTime,
    fitInterviewTime,
    industryOverviewTime,
    linkedInTipsTime,
  ]);
  const areasArr = [
    "Resume Review",
    "Case Interview",
    "Fit Interview",
    "Industry Overview",
    "LinkedIn Tips",
  ];

  const getTimeAndColorByArea = (area) => {
    if (area === "Resume Review") {
      return { time: resumeReviewTime, color: "#00AAED" };
    }
    if (area === "Case Interview") {
      return { time: caseInterviewTime, color: "#33BBF1" };
    }
    if (area === "Fit Interview") {
      return { time: fitInterviewTime, color: "#66CCF4" };
    }
    if (area === "Industry Overview") {
      return { time: industryOverviewTime, color: "#A4E4F7" };
    }
    if (area === "LinkedIn Tips") {
      return { time: linkedInTipsTime, color: "#CCEEFB" };
    }
  };
  useEffect(() => {
    if (menteeDashboardHome?.chartData && total === 0) {
      setTotal(0);
      setResumeReviewTime(0);
      setCaseInterviewTime(0);
      setFitInterviewTime(0);
      setIndustryOverviewTime(0);
      setLinkedInTipsTime(0);
      setHours();
    }
  }, [menteeDashboardHome]);
  return (
    <div className="mentee-dashboard-home-mentorship-areas mentee-dashboard-home-components-line">
      <div className="mentee-dashboard-home-mentorship-areas-container">
        <div className="mentee-dashboard-home-mentorship-areas-container-leftCol">
          <div className="font-Neometric-Medium">Mentorship areas</div>
          <div className="mentee-dashboard-home-mentorship-area-label-list">
            {menteeDashboardHome &&
              menteeDashboardHome.chartData &&
              menteeDashboardHome.chartData.map((item) => {
                areasArr.splice(areasArr.indexOf(item.areaTitle), 1);
                return (
                  <>
                    <div className="mentee-dashboard-home-mentorship-area-label-item">
                      <div className="mentee-dashboard-home-mentorship-area-label-title">
                        <div
                          className="mentee-dashboard-home-mentorship-area-label-title-before"
                          style={{
                            background: getTimeAndColorByArea(item.areaTitle)
                              .color,
                          }}
                        ></div>
                        <div>{item.areaTitle}</div>
                      </div>
                      <div>{`${
                        getTimeAndColorByArea(item.areaTitle).time
                      }h`}</div>
                    </div>
                  </>
                );
              })}
            {areasArr.map((item) => (
              <div className="mentee-dashboard-home-mentorship-area-label-item">
                <div className="mentee-dashboard-home-mentorship-area-label-title">
                  <div
                    className="mentee-dashboard-home-mentorship-area-label-title-before"
                    style={{
                      background: getTimeAndColorByArea(item).color,
                    }}
                  ></div>
                  <div>{item}</div>
                </div>
                <div>{`${getTimeAndColorByArea(item).time}h`}</div>
              </div>
            ))}
          </div>
          <div
            style={{
              width: "225px",
              borderRadius: "5px",
              padding: "0 20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              backgroundColor: "#F0F0F0",
              height: "30px",
            }}
          >
            <div
              style={{
                lineHeight: "14px",
                fontSize: "14px",
              }}
              class="font-Neometric-Medium"
            >
              Total
            </div>
            <div
              style={{
                lineHeight: "14px",
                fontSize: "14px",
              }}
              class="font-Neometric-Medium"
            >
              {total}h
            </div>
          </div>
        </div>
        <div>
          <DoughnutChart
            total={total}
            chartTime={[
              resumeReviewTime,
              caseInterviewTime,
              fitInterviewTime,
              industryOverviewTime,
              linkedInTipsTime,
            ]}
            chartTitle={areasArr}
            chartColor={["#00AAED", "#33BBF1", "#66CCF4", "#A4E4F7", "#CCEEFB"]}
          />
        </div>
      </div>
    </div>
  );
};

export default MenteeDashboardMentorshipAreas;
