import { useState } from "react";
import PopUp from "../components/landing-page/popup/PopUp";

export const usePopup = (Component, modalClassName, data) => {
  const openPopup = <Component data={data} />;

  function openPopupClick() {
    let popupModal = document.getElementsByClassName(modalClassName)[0];
    popupModal.style.display = "block";
  }

  return { openPopup, openPopupClick };
};
