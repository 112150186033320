import React from "react";
import bannerImg from "../../../../../assets/dashboard/mentee-dashboard/mentee-dashboard-find-mentor/Group 291-2.svg";

const MenteeDashboardFindMentorBanner = (props) => {
  return (
    <div className="mentee-dashboad-find-mentor-banner">
      <div className="mentee-dashboad-find-mentor-banner-img">
        <img src={bannerImg} alt="" />
      </div>
      <div className="mentee-dashboad-find-mentor-banner-title">
        <div>
          <h3>Find a Mentor</h3>
        </div>
        <div>Search, select and schedule time with a mentor</div>
      </div>
    </div>
  );
};

export default MenteeDashboardFindMentorBanner;
