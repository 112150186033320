import React from "react";
import Checkbox from "../../../form-component/Checkbox";
import ReactHtmlParser from "react-html-parser";
const MentorSignupStep3YourImpact = (props) => {
  const {
    title,
    step,
    onPrevStep,
    onStepChange,
    data,
    onChange,
    onRadioChange,
    errors,
    onFileChange,
    stepKey,
    onCheckboxChange,
    storeValues,
  } = props;
  return (
    <div>
      <h3 className="sign-up-form-heading">{title}</h3>
      <div className="step-form-content">
        <Checkbox
          checked={false}
          name={data.q80_withinConsulting80.name}
          value={data.q80_withinConsulting80.value}
          text={data.q80_withinConsulting80.text}
          description={data.q80_withinConsulting80.description}
          inputValues={data.q80_withinConsulting80.inputValues}
          onCheckboxChange={(e) =>
            onCheckboxChange(stepKey, e, data.q80_withinConsulting80.value)
          }
          error={errors[data.q80_withinConsulting80.name]}
          type={data.q80_withinConsulting80.type}
        />
        <Checkbox
          checked={false}
          name={data.q77_whichAreas77.name}
          text={data.q77_whichAreas77.text}
          value={data.q77_whichAreas77.value}
          description={data.q77_whichAreas77.description}
          storeValues={data.q77_whichAreas77.storeValues}
          inputValues={data.q77_whichAreas77.inputValues}
          onCheckboxChange={(e) =>
            onCheckboxChange(stepKey, e, data.q77_whichAreas77.value)
          }
          error={errors[data.q77_whichAreas77.name]}
          type={data.q77_whichAreas77.type}
        />
      </div>
      <div className="form-step-btn-group">
        {step > 0 && (
          <button
            type="button"
            className="cardo-btn back-btn"
            onClick={() => onPrevStep(step - 1)}
          >
            Go back
          </button>
        )}
        <button
          type="button"
          className="cardo-btn next-btn"
          onClick={(e) => onStepChange(data, e)}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default MentorSignupStep3YourImpact;
