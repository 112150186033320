import React from 'react';
import useDevices from "../../../../../../hooks/useDevices";
import Select from "../../../../../auth/form-component/Select";
import useActiveSinkId from "../../../../../../hooks/useActiveSinkId";

export default function AudioOutputList(props) {
    const {audioOutputDevices} = useDevices();
    const [activeSinkId, setActiveSinkId] = useActiveSinkId();
    const activeOutputLabel = audioOutputDevices.find(device => device.deviceId === activeSinkId)?.label;

    return (
        <div className="inputSelect">
            {audioOutputDevices.length > 1 ? (
                <Select
                    name="audiooutput"
                    text="Sound Output"
                    value={activeSinkId || ''}
                    onChange={(e) => {
                        setActiveSinkId(e.target.value);
                        if (props.closeModel) {
                            props.closeModel();
                        }
                    }}
                    error={''}
                    choices={
                        audioOutputDevices.map(device => (
                            <option value={device.deviceId} key={device.deviceId}>
                                {device.label}
                            </option>
                        ))
                    }/>
            ) : (
                <>
                    <div className="audiovideoinput">
                        {activeOutputLabel || 'System Default Audio Output'}
                    </div>
                </>
            )}
        </div>
    );
}
