import React, { PureComponent } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { connect } from "react-redux";

import { putNewImage } from "../../../../state-management/actions/dashboard/mentee-dashboard/profileActions";

class CropImageBeforUploadPopup extends PureComponent {
  constructor(props) {
    super(props);
  }

  state = {
    error: null,
    blob: null,
    src: null,
    crop: {
      unit: "px",
      aspect: 1 / 1,
      x: 125,
      y: 125,
    },
  };
  componentDidMount() {
    if (this.props.fileLoaded) {
      if (this.props.file.type.split("/")[0] === "image") {
        this.setState({ error: null });
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          this.setState({ src: reader.result });
        });

        reader.readAsDataURL(this.props.file);
      } else {
        this.setState({ src: null });
        this.setState({ error: "Only images are allowed, please try again." });
      }
    }
  }

  // If you setState the crop in here you should return false.
  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );
      this.setState({ croppedImageUrl });
    }
  }
  closeModal(e) {
    e.stopPropagation();
    this.props.selectModalDetails();
  }
  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    canvas.style.width = Math.round(crop?.width ?? 0);
    canvas.style.height = Math.round(crop?.height ?? 0);

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    const ctx = canvas.getContext("2d");
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error("Canvas is empty");
          return;
        }

        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
        this.setState({ blob: blob });
      }, "image/jpeg");
    });
  }

  uploadImage = async () => {
    var formData = new FormData();
    formData.append("file", this.state.blob, "file.jpg");
    // await this.props.putNewImage(formData, () => {});

    // this.props.setUserImg(
    //   this.props.menteeNewImage.imgUrl + "?" + Date.parse(new Date())
    // );
    // this.props.selectModalDetails();
  };
  render() {
    const { crop, croppedImageUrl, src, error } = this.state;

    return (
      <>
        <div
          className="mentee-dashboard-find-mentor-modal"
          style={{ display: this.props.closeModal ? "none" : "block" }}
          onClick={(e) => this.closeModal(e)}
        >
          <div
            style={{ width: "630px" }}
            className="mentee-dashboard-find-mentor-modal-content"
            onClick={(e) => e.stopPropagation()}
          >
            {!this.props.fileLoaded ? (
              <div>Loading...</div>
            ) : (
              <>
                {error ? (
                  <div>{error}</div>
                ) : (
                  <>
                    <div
                      style={{
                        position: "absolute",
                        right: "10px",
                        top: "5px",
                        cursor: "pointer",
                        zIndex: 10,
                        padding: "20px",
                        fontSize: "25px",
                      }}
                      onClick={(e) => this.closeModal(e)}
                    >
                      <i className="fa fa-times" />
                    </div>

                    <div
                      className="position-relative"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "65px",
                      }}
                    >
                      <div className="App">
                        {src && (
                          <ReactCrop
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "100%",
                              maxWidth: "330px",
                              height: "100%",
                            }}
                            src={src}
                            crop={crop}
                            ruleOfThirds
                            onImageLoaded={this.onImageLoaded}
                            onComplete={this.onCropComplete}
                            onChange={this.onCropChange}
                          />
                        )}
                        {croppedImageUrl && (
                          <img
                            alt="Crop"
                            style={{ maxWidth: "100%", display: "none" }}
                            src={croppedImageUrl}
                          />
                        )}
                      </div>
                    </div>

                    <div
                      style={{
                        margin: "20px auto",
                      }}
                      className="cardo-btn btn-bg-lightblue"
                      onClick={this.uploadImage}
                    >
                      Apply
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    menteeNewImage: state.menteeDashboard.menteeNewImage,
  };
};
export default connect(mapStateToProps, { putNewImage })(
  CropImageBeforUploadPopup
);
