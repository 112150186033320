import React from "react";
import "../../../styles/ru_component/msg-toast.css";
const Toast = (props) => {
  const { type, msg } = props;
  return (
    <div className={`msg_toast toast_${type}`}>
      <span className={`msg_toast_type msg_toast_type_${type}`}> {type} </span>
      &nbsp; - &nbsp;{msg}
    </div>
  );
};

export default Toast;
