import React from "react";
import moment from "moment-timezone";
const FirstSession = (props) => {
  const { upcomingSession, selectModalDetails, handleModalMentorDetails } =
    props;
  const handleDetailsClick = () => {
    selectModalDetails();
    handleModalMentorDetails(upcomingSession);
  };
  return (
    <div style={{ display: "flex", marginBottom: "15px" }}>
      <div style={{ width: "40px", marginTop: "5px" }}>
        <div className="font-Neometric-Medium" style={{ fontSize: "18px" }}>
          {moment(upcomingSession.start_time).format("DD")}
        </div>
        <div className="font-Neometric-Regular" style={{ fontSize: "14px" }}>
          {moment(upcomingSession.start_time).format("MMM")}
        </div>
      </div>
      <div
        style={{
          width: "860px",
          height: "120px",
          backgroundColor: "#E3E3E3",
          borderRadius: "10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ width: "785px", height: "82px", display: "flex" }}>
          <div style={{ width: "90px", display: "flex", alignItems: "center" }}>
            <div
              style={{
                width: "64px",
                height: "64px",
                borderRadius: "100%",
                backgroundSize: "cover",
                backgroundImage: `url(${upcomingSession.mentee.img_url})`,
              }}
            />
          </div>
          <div
            style={{
              color: "black",
              width: "210px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div>
              <div
                style={{ color: "black", marginBottom: "4px" }}
                className="font-Neometric-Medium"
              >
                {upcomingSession.mentee.first_name}{" "}
                {upcomingSession.mentee.last_name.substr(0, 1)}.
              </div>
              <div
                style={{ color: "black", fontSize: "14px" }}
                className="font-eina"
              >
                {upcomingSession.mentee?.profile_info?.preferred_industry}
                
                <i
                  className="fas fa-caret-right"
                  style={{ margin: "0px 5px" }}
                ></i>
                {upcomingSession.mentee?.profile_info?.preferred_trail}
              </div>
            </div>
          </div>

          <div
            style={{
              width: "400px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div>
              <div
                style={{
                  color: "black",
                  fontSize: "14px",
                  marginBottom: "8px",
                }}
                className="font-Neometric-Medium"
              >
                Time
              </div>
              <div
                style={{ color: "black", fontSize: "16px" }}
                className="font-Neometric-Regular"
              >
                {moment(upcomingSession.start_time)
                  .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
                  .format("LT")}{" "}
                →{" "}
                {moment(upcomingSession.end_time)
                  .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
                  .format("LT")}
              </div>
            </div>
          </div>
          <div
            style={{
              width: "85px",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              justifyContent: "center",
            }}
          >
            <div
              className="font-Neometric-Regular"
              style={{
                fontSize: "14px",
                color: "black",
                borderRadius: "7px",
                width: "85px",
                height: "46px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "white",
                cursor: "pointer",
              }}
              onClick={handleDetailsClick}
            >
              Details
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FirstSession;
