import { Component, useState } from "react";
import PopUp from "../components/landing-page/popup/PopUp";
import { useIsDesktop } from "../helper/useIsDesktop";

export const useMobilePopup = (modalClassName) => {
  const { isDesktop } = useIsDesktop();
  const openMobilePopup = <PopUp />;

  function openMobilePopupClick() {
    if (!isDesktop) {
      let popupModal = document.getElementsByClassName(modalClassName)[0];
      popupModal.style.display = "block";
    }
  }

  return { openMobilePopup, openMobilePopupClick };
};
