import React from "react";
import sectionTwoBoy from "../../../assets/home/Group 549.svg";
import ScrollAnimation from "react-animate-on-scroll";
import "../../../styles/animate.css";

const HomeSection2 = (props) => {
  return (
    <div className={`home-section-two-body`}>
      <div className="info-container">
        <ScrollAnimation animateIn="fadeInDown">
          <button
            className={`${props.bubbleClass.mentor} bubbles`}
            onClick={props.onClick}
          >
            Hi! I’m a mentor.
          </button>

          <button
            className={`${props.bubbleClass.mentee} bubbles`}
            onClick={props.onClick}
          >
            Hi! I’m a mentee.
          </button>
        </ScrollAnimation>
      </div>
      <div>
        <ScrollAnimation animateIn="fadeIn">
          <div className="rectangle-container rectangle-left rectangle-active leftOne">
            <div className="content-heading">{props.topLeftTitle}</div>
            <div className="content-text">{props.topLeftDesc}</div>
          </div>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeIn">
          <div className="rectangle-container rectangle-right rectangle-active rightOne">
            <div className="content-heading">{props.topRightTitle}</div>
            <div className="content-text">{props.topRightDesc}</div>
          </div>
        </ScrollAnimation>
      </div>
      <ScrollAnimation animateIn="fadeInUp">
        <div
          className="home-section-two-toggle middle-img-section-two"
          alt=""
          style={{
            backgroundImage: `url('${sectionTwoBoy}')`,
            width: "158px",
            height: "278px",
          }}
        ></div>
      </ScrollAnimation>
      <div style={{ marginTop: "-130px" }}>
        <ScrollAnimation animateIn="fadeIn" delay={100}>
          <div className="rectangle-container rectangle-left rectangle-active leftTwo">
            <div className="content-heading">{props.bottomLeftTitle}</div>
            <div className="content-text">{props.bottomLeftDesc}</div>
          </div>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeIn" delay={100}>
          <div className="rectangle-container rectangle-right rectangle-active rightTwo">
            <div className="content-heading">{props.bottomRightTitle}</div>
            <div className="content-text">{props.bottomRightDesc}</div>
          </div>
        </ScrollAnimation>
      </div>
    </div>
  );
};

export default HomeSection2;
