import OnboardingImage from "../../../assets/OnboardingPopup/onboardingPopup1.jpg";
import Step1 from "../../../assets/OnboardingPopup/1.gif";
import Step2 from "../../../assets/OnboardingPopup/2.gif";
import Step3 from "../../../assets/OnboardingPopup/3.gif";
import Step4 from "../../../assets/About us/Group 438 (1).svg";

export const onboardingData = [
  {
    title: "Welcome to Cardo, {Name}! Let's get you setup.",
    img: Step1,
    subTitle:
      "Get started by heading to “Edit Profile” to fill out your bio and set your mentorship preferences.",
  },
  {
    title: "Set up seamless scheduling with Calendly.",
    img: Step2,
    subTitle:
      "Set your availability and sync your calendars in “Edit Profile > Calendar”. Cardo provides all mentors with a free Calendly Pro account to make scheduling seamless.",
  },
  {
    title: "Turn “On” your profile and start mentoring.",
    img: Step3,
    subTitle:
      "Turn your profile to “On” and begin mentoring (you can turn it “Off” when you need a break)! Check your Dashboard for new sessions and to join a video a session.",
  },
  {
    title: "Thanks for all you do!",
    img: Step4,
    subTitle:
      "Thanks for being a part of the Cardo community and helping others reach new heights. We’re always open to your feedback on how to make Cardo better for mentees and mentors at",
    link: "team@hellocardo.org",
  },
];
