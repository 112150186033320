import React from "react";

const ApplicationsInnerTabs = (props) => {
  const { activeTab, handleActiveTab } = props;
  return (
    <div className="admin_dashboard_calendly_tabs">
      <div
        className={
          activeTab === "pipeline" && "admin_dashboard_calendly_tabs_active_tab"
        }
        onClick={() => handleActiveTab("pipeline")}
      >
        Pipeline
      </div>

      <div
        className={
          activeTab === "accepted" && "admin_dashboard_calendly_tabs_active_tab"
        }
        onClick={() => handleActiveTab("accepted")}
      >
        Accepted
      </div>
      <div
        className={
          activeTab === "rejected" && "admin_dashboard_calendly_tabs_active_tab"
        }
        onClick={() => handleActiveTab("rejected")}
      >
        Rejected
      </div>
    </div>
  );
};

export default ApplicationsInnerTabs;
