import React, { useEffect, useState } from "react";
import useParticipantNetworkQualityLevel from "../../../../../../hooks/useParticipantNetworkQualityLevel";

const STEP = 3;
const BARS_ARRAY = [0, 1, 2, 3, 4];

export default function NetworkQualityLevel(props) {
  const [participant, setParticipant] = useState(props.participant);

  useEffect(() => {
    if (props.participant) {
      setParticipant(props.participant);
    }
  }, [props.participant]);

  const networkQualityLevel = useParticipantNetworkQualityLevel(participant);

  if (networkQualityLevel === null) return null;

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "flex-end",
          "& div": {
            width: "2px",
            marginRight: "1px",
            "&:not(:last-child)": {
              borderRight: "none",
            },
          },
        }}
      >
        {BARS_ARRAY.map((level) => (
          <div
            key={level}
            style={{
              height: `${STEP * (level + 1)}px`,
              background: networkQualityLevel > level ? "#5555db" : "grey",
              width: "3px",
              marginRight: "2px",
            }}
          />
        ))}
      </div>
    </div>
  );
}
