import axios from "axios";
import { helper } from "../../../../helper/helper";
import Cookies from "universal-cookie";
import {
  MENTEE_DASHBOARD_EVENT_SCHEDULED,
  GET_SUPPORT_AREAS,
  GET_ERRORS,
  PUT_REVOKE,
  PUT_INVITE,
  GET_SEATS_INFO,
  POST_ACCEPT
} from "../../../types/types";
import { throwCustomError } from "../../errorActions";
import { getCalendlyTabInfo } from "../../admin/adminDashboard";
import { startLoading, stopLoading } from "../../loadingActions";
import { cleanSuccess, handleSuccess } from "../../successActions";
import { clearErrors } from "../../authActions";

const cookies = new Cookies();

export const postEventScheduled = (data) => (dispatch) => {
  console.log("fire" + data);
  axios
    .post(
      `${helper.baseUrl}/calendly/postEventScheduled`,
      {
        data,
        headers: { "x-csrf-token": cookies.get("XSRF-TOKEN") },
      },
      { withCredentials: true }
    )
    .then((res) =>
      dispatch({
        type: MENTEE_DASHBOARD_EVENT_SCHEDULED,
        payload: res.data,
      })
    )
    .catch((err) => console.log(err.response));
};

export const getSupportAreaFromCalendly = (data) => (dispatch) => {
  axios
    .get(`${helper.baseUrl}/calendly/getSupportAreaFromCalendly`, {
      params: data,
      withCredentials: true,
    })
    .then((res) =>
      dispatch({
        type: GET_SUPPORT_AREAS,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch(
        throwCustomError(
          "somethingWentWrong",
          err.response.status,
          err.response.data
        )
      )
    );
};

export const postRevokeReq = (data) => (dispatch) => {
  console.log("fire revoke", data);
  axios
    .put(`${helper.baseUrl}/admin/revokeMentorCalendly`, data, {
      withCredentials: true,
    })
    .then((res) => {
      dispatch(handleSuccess());
      dispatch(getCalendlyTabInfo());
      dispatch({ type: PUT_REVOKE, payload: res.data });
    })
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

export const postInviteReq = (data) => (dispatch) => {
  axios
    .put(`${helper.baseUrl}/admin/inviteMentorToCalendly`, data, {
      withCredentials: true,
    })
    .then((res) => {
      dispatch(handleSuccess());
      dispatch(getCalendlyTabInfo());
      dispatch({ type: PUT_INVITE, payload: res.data });
    })
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

export const getCalendlySeatsInfo = () => (dispatch) => {
  axios
    .get(`${helper.baseUrl}/admin/getCalendlySeatsInfo`, null, {
      headers: { "x-csrf-token": cookies.get("XSRF-TOKEN") },
      withCredentials: true,
    })
    .then((res) => {
      dispatch({
        type: GET_SEATS_INFO,
        payload: res.data,
      });
    })
    .catch((err) => console.log(err));
};

export const putCalendlySeatsInfo = (data) => (dispatch) => {
  dispatch(startLoading());
  dispatch(cleanSuccess());
  dispatch(clearErrors());
  axios
    .put(`${helper.baseUrl}/admin/putCalendlyAdminInfo`, data, {
      withCredentials: true,
    })
    .then((res) => {
      dispatch(handleSuccess());
      dispatch(stopLoading());
      dispatch(getCalendlySeatsInfo());
    })
    .catch((err) => console.log(err));

};

export const postMentorAccpetInvitation = (data) => (dispatch) => {
  axios
    .post(`${helper.baseUrl}/admin/postMentorAccpetInvitation`, data, {
      withCredentials: true,
    })
    .then((res) => {
      dispatch(handleSuccess());
      dispatch(getCalendlyTabInfo());
      dispatch({ type: POST_ACCEPT, payload: res.data });
    })
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};
