import React from "react";
import { Link } from "react-router-dom";
import "../../../styles/dashboard/dashboard-layout/sidebar.css";
import darkLogo from "../../../assets/dashboard/sidebar/Group 774.svg";
import darkLogoSm from "../../../assets/dashboard/sidebar/Group.svg";

import SidebarLinks from "./sidebar-components/SidebarLinks";
import SidebarFaqLink from "./sidebar-components/SidebarFaqLink";
const Sidebar = (props) => {
  const { currentLink, handleCurrentLink, sideBarLinks } = props;
  return (
    <div className="dashboard-sidebar">
      <div className="dashboard-sidebar-inner-container">
        <div className="dashboard-logo">
          <Link to="/">
            <img src={darkLogo} alt="" />
          </Link>
        </div>
        <div className="dashboard-logo-sm">
          <Link to="/">
            <img src={darkLogoSm} alt="" />
          </Link>
        </div>
        <div className="dashboard-sidebar-links-container">
          {sideBarLinks.map((link, index) =>
            link.title === "FAQ" ? (
              <SidebarFaqLink
                key={index}
                currentLink={currentLink}
                handleCurrentLink={handleCurrentLink}
                icon={link.icon}
                link={link.link}
                iconActive={link.activeIcon}
                title={link.title}
              />
            ) : (
              <SidebarLinks
                key={index}
                currentLink={currentLink}
                handleCurrentLink={handleCurrentLink}
                icon={link.icon}
                iconActive={link.activeIcon}
                title={link.title}
              />
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
