import React from "react";
import findMentorSection1RightCol from "../../../assets/Group 558.png";

const FindMentorSection1 = () => {
  return (
    <section className="find-mentor-section-one">
      <div className="container_narrow find-mentor-section-one_container">
        <div className="row find-mentor-section-one-row">
          <div className="col-7 find-mentor-section-one-leftCol">
            <div className="strike-heading">OUR FOCUS</div>
            <div className="heading">
              Professional growth for
              <br className="br_mobile" /> the <br className="br_desk" />
              underserved and
              <br className="br_mobile" /> underrepresented
            </div>
            <p className="desc">
              We are dedicated to people whose social,
              <br className="br_mobile" /> educational,
              <br className="br_desk" /> economic, or familial background
              <br className="br_mobile" /> didn’t offer the conditions to
              <br className="br_desk" /> break into
              <br className="br_mobile" /> traditionally hyper-selective career
              paths. <br /> <br /> Some examples of who we prioritize:
            </p>
            <div className="examples-container">
              <div className="example-item example-pill-container">
                <span>BIPOC</span>
                <span>Latinx</span>
                <span>Women</span>
              </div>
              <div className="example-item">
                Low-income or single-parent households
              </div>
              <div className="example-item">First-gen college students</div>
              <div className="example-item">Non-target school graduates</div>
            </div>
            <p className="desc">
              Given COVID-19, we are also temporarily
              <br className="br_mobile" /> serving those
              <br className="br_desk" /> who have lost employment as a
              <br className="br_mobile" /> result of the pandemic.
            </p>
          </div>

          <div className="col-5 find-mentor-section-one-rightCol">
            <img src={findMentorSection1RightCol} alt="" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default FindMentorSection1;
