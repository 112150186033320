import React from 'react';
import useLocalAudioToggle from "../../../../../../hooks/useLocalAudioToggle"
import speaker from "../../../../../../assets/mentor/speaker.svg";
import mute from "../../../../../../assets/mentor/mute.svg";

export default function ToggleAudioButton(props) {
    const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle(props.localTracks);
    const hasAudioTrack = props.localTracks.some(track => track.kind === 'audio');

    return (
        <div className="speaker-button-group action-button-group">
            <button onClick={toggleAudioEnabled} className={`${!isAudioEnabled ? 'disabled' : ''}`}
                    disabled={!hasAudioTrack || props.disabled} data-cy-audio-toggle>
                <img className='speaker' style={{
                    display: isAudioEnabled ? 'block' : 'none'
                }} src={speaker} alt='speaker icon'/>
                <img className='mute' style={{
                    display: !isAudioEnabled ? 'block' : 'none'
                }} src={mute} alt='icon'/>
            </button>
            <span>{!hasAudioTrack ? 'No Audio' : `Audio`}</span>
        </div>
    );
}
