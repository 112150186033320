import React from "react";
import AdminDashboard from "../../components/admin/dashboard/AdminDashboard";

const AdminDashboardRoute = () => {
  
  return (
    <div>
      <AdminDashboard />
    </div>
  );
};

export default AdminDashboardRoute;
