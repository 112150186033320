import React, { useEffect, useState } from "react";
import "../../../../../styles/dashboard/admin-dasboard/calendly-tab/calendly-seats-input.css";
import {
  getCalendlySeatsInfo,
  putCalendlySeatsInfo,
} from "../../../../../state-management/actions/dashboard/mentee-dashboard/calendlyActions";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

const CalendlySeatsInput = (props) => {
  const [seatLoading, setSeatLoading] = useState(true);
  const handleClickSave = () => {
    props.putCalendlySeatsInfo({
      totalOfSeats: props.noOfSeats,
      pricePerSeat: props.pricePerSeat,
    });
  };
  useEffect(() => {
    setSeatLoading(props.loading);
  }, [props.loading]);
  return (
    <div className="calendly_top_cards_wrapper">
      <div className="calendly_top_cards_wrapper_container">
        <div className="inputWrapper">
          <div className="totalSeats">
            <input
              type="text"
              name="totalSeats"
              id="totalSeats"
              value={props.noOfSeats}
              onChange={(e) => props.handleTotalSeatsChange(e)}
              placeholder={props.noOfSeats}
            />
            <label htmlFor="totalSeats"># of Total Seats</label>
          </div>
          <div className="seatPrice">
            <input
              type="text"
              name="seatPrice"
              id="seatPrice"
              value={props.pricePerSeat}
              onChange={(e) => props.handlePricePerSeatChange(e)}
              placeholder="$27"
            />
            <label htmlFor="seatPrice">Price Per Seat</label>
          </div>
        </div>
        <div
          onClick={handleClickSave}
          className="cardo-btn btn-bg-lightblue admin_dashboard_save_btn calendly_seats_save"
        >
          {seatLoading ? "Loading..." : "Save"}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  getCalendlySeats: state.admin.seatsInfo,
  loading: state.loading.loading,
});
export default connect(mapStateToProps, {
  getCalendlySeatsInfo,
  putCalendlySeatsInfo,
})(withRouter(CalendlySeatsInput));
