import React, { useEffect, useRef, useState } from "react";
import moment from "moment-timezone";
import PreJoinScreen from "../../../mentor-dashboard/mentor-dashboard-components/mentor-dashboard-my-sessions-components/PreJoinScreen";
const FirstSession = (props) => {
  const { upcomingSession, selectModalDetails, handleModalMentorDetails } =
    props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [available, setAvailable] = useState(false);

  useEffect(() => {
    const start = moment.tz(
      upcomingSession.start_time,
      upcomingSession.timezone
    );
    const end = moment.tz(upcomingSession.end_time, upcomingSession.timezone);
    const now = moment.tz(new Date(), upcomingSession.timezone);
    if (
      new Date(start).valueOf() - 1000 * 60 * 11 < new Date(now).valueOf() &&
      new Date(end).valueOf() > new Date(now).valueOf()
    ) {
      setAvailable(true);
    } else {
      setAvailable(false);
    }
  }, []);

  const handleDetailsClick = () => {
    selectModalDetails();
    handleModalMentorDetails(upcomingSession);
  };

  let joinCallBtn = useRef(null);

  const isSessionAvailanle = (session) => {
    const start = moment.tz(session.start_time, session.timezone);
    const end = moment.tz(session.end_time, session.timezone);
    const now = moment.tz(new Date(), session.timezone);
    if (
      new Date(start).valueOf() - 1000 * 60 * 11 < new Date(now).valueOf() &&
      new Date(end).valueOf() > new Date(now).valueOf()
    ) {
      return true;
    } else {
      return false;
    }
    // setIsModalOpen(!isModalOpen);
    // return true;
  };

  return (
    <div style={{ display: "flex" }}>
      <div style={{ width: "40px", marginTop: "5px" }}>
        <div className="font-Neometric-Medium" style={{ fontSize: "18px" }}>
          {moment(upcomingSession.start_time).format("DD")}
        </div>
        <div className="font-Neometric-Regular" style={{ fontSize: "14px" }}>
          {moment(upcomingSession.start_time).format("MMM")}
        </div>
      </div>
      <div
        style={{
          width: "860px",
          height: "245px",
          backgroundColor: "#5555DB",
          borderRadius: "10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ width: "785px", height: "195px", display: "flex" }}>
          <div style={{ width: "90px" }}>
            <div
              style={{
                width: "64px",
                height: "64px",
                borderRadius: "100%",
                backgroundSize: "cover",
                backgroundImage: `url(${upcomingSession.mentor.img_url})`,
              }}
            />
          </div>
          <div
            style={{
              color: "white",
              width: "270px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
            }}
          >
            <div>
              <div
                style={{ color: "white", marginBottom: "4px" }}
                className="font-Neometric-Medium"
              >
                {upcomingSession.mentor.first_name}{" "}
                {upcomingSession.mentor.last_name.substr(0, 1)}.
              </div>
              <div
                style={{ color: "white", fontSize: "14px" }}
                className="font-eina"
              >
                {upcomingSession.mentee.profile_info.preferred_trail}
                <i
                  className="fas fa-caret-right"
                  style={{ margin: "0px 5px" }}
                ></i>
                {upcomingSession.mentor.profile_info.consulting_firm}
              </div>
            </div>

            <div>
              <div
                style={{
                  color: "white",
                  fontSize: "14px",
                  marginBottom: "8px",
                }}
                className="font-Neometric-Medium"
              >
                Time
              </div>
              <div
                style={{ color: "white", fontSize: "16px" }}
                className="font-Neometric-Regular"
              >
                {moment(upcomingSession.start_time)
                  .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
                  .format("LT")}{" "}
                →{" "}
                {moment(upcomingSession.end_time)
                  .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
                  .format("LT")}
              </div>
            </div>

            <div>
              <div
                style={{
                  color: "white",
                  fontSize: "14px",
                  marginBottom: "8px",
                }}
                className="font-Neometric-Medium"
              >
                Session Type
              </div>
              <div
                style={{ color: "white", fontSize: "16px" }}
                className="font-Neometric-Regular"
              >
                {upcomingSession.interviewTitle}
              </div>
            </div>
          </div>

          <div
            style={{
              wordWrap: "break-word",
              width: "230px",
              padding: "20px",
              borderRadius: "6px",
              border: "1px solid rgba(255,255,255,0.2)",
            }}
          >
            <div
              className="font-Neometric-Medium"
              style={{
                color: "white",
                marginBottom: "17px",
                fontSize: "12px",
              }}
            >
              Biography
            </div>
            <div
              className="font-eina"
              style={{ color: "white", fontSize: "14px" }}
            >
              {upcomingSession.mentor.profile_info.my_story.substr(0, 120)}
              {upcomingSession.mentor.profile_info.my_story.length > 120
                ? "..."
                : ""}
            </div>
          </div>
          <div
            style={{
              width: "195px",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              justifyContent: "space-between",
            }}
          >
            <div
              ref={joinCallBtn}
              className="font-Neometric-Regular"
              style={{
                fontSize: "14px",
                borderRadius: "7px",
                width: "142px",
                height: "46px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: available ? "white" : "#f4f4f4",
                cursor: "pointer",
                color: available ? "black" : "rgba(0,0,0,0.8)",
                position: "relative",
              }}
              onMouseEnter={() => {
                if (!isSessionAvailanle(upcomingSession)) {
                  setAvailable(false);
                  joinCallBtn.current.classList.add("join_call_btn");
                } else {
                  setAvailable(true);
                }
              }}
              onMouseLeave={() => {
                !isSessionAvailanle(upcomingSession) &&
                  joinCallBtn.current.classList.remove("join_call_btn");
              }}
              onClick={() =>
                isSessionAvailanle(upcomingSession)
                  ? setIsModalOpen(true)
                  : null
              }
            >
              Join Call
            </div>
            <div
              className="font-Neometric-Regular"
              style={{
                fontSize: "14px",
                color: "white",
                borderRadius: "7px",
                width: "142px",
                height: "46px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#4D4DC6",
                cursor: "pointer",
              }}
              onClick={handleDetailsClick}
            >
              Details
            </div>
            {new Date(
              moment.tz(upcomingSession.start_time, upcomingSession.timezone)
            ).valueOf() >
              moment
                .tz(new Date(Date.now()), upcomingSession.timezone)
                .valueOf() && (
              <>
                <a
                  href={upcomingSession.reschedule_url}
                  target="_blank"
                  className="font-Neometric-Regular"
                  style={{
                    fontSize: "14px",
                    color: "white",
                    borderRadius: "7px",
                    width: "142px",
                    height: "46px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#4D4DC6",
                    textDecoration: "none",
                  }}
                >
                  Reschedule
                </a>
                <a
                  href={upcomingSession.cancel_url}
                  target="_blank"
                  className="font-Neometric-Regular"
                  style={{
                    fontSize: "12px",
                    color: "#BABAF7",
                    width: "142px",
                    height: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textDecoration: "underline",
                  }}
                >
                  Cancel
                </a>
              </>
            )}
          </div>
        </div>
      </div>
      {isModalOpen && (
        <PreJoinScreen
          sessionId={upcomingSession._id}
          closeModal={() => setIsModalOpen(!isModalOpen)}
        />
      )}
    </div>
  );
};

export default FirstSession;
