import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { postLogin } from "../../../state-management/actions/admin/adminDashboard";
import "../../../styles/adminLogin/admin_login.css";
import CardoLogo from "../../../assets/darkLogo.png";

const AdminLogin = (props) => {
  const searchQuery = new URLSearchParams(props.location.search);
  const query = searchQuery.get("q");
  const [access, setAccess] = useState(false);
  const [data, setData] = useState({ user: "", pass: "" });

  const onChange = (e, setState) => {
    setState((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const onSubmit = () => {
    props.postLogin(data);
  };

  useEffect(() => {
    if (props?.login?.status === 200) {
      window.location.href = "/admin/dashboard";
    }
  }, [props]);

  useEffect(() => {
    if (query !== "1!$odracolleh!$1") {
      window.location.href = "/";
    } else {
      setAccess(true);
    }
  }, []);
  return (
    <div>
      {access && (
        <div className="admin_login_page">
          <div className="admin_login_page_logo">
            <img src={CardoLogo} alt="cardologo" />
          </div>

          <div className="admin_login_page_form">
            <div className="admin_login_page_form_control">
              <label htmlFor="user">User</label>
              <input
                id="user"
                className="admin_login_page_input"
                value={data.user}
                name="user"
                onChange={(e) => {
                  onChange(e, setData);
                }}
              />
            </div>
            <div className="admin_login_page_form_control">
              <label htmlFor="password">Password</label>
              <input
                id="password"
                className="admin_login_page_input"
                value={data.pass}
                name="pass"
                type="password"
                onChange={(e) => {
                  onChange(e, setData);
                }}
              />
            </div>
          </div>
          <div
            className="admin_login_page_button"
            onClick={() => {
              onSubmit();
            }}
          >
            Login
          </div>
        </div>
      )}
    </div>
  );
};
const mapStateToProps = (state) => ({
  login: state.admin.login,
});
export default connect(mapStateToProps, { postLogin })(withRouter(AdminLogin));
