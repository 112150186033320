import React, { useState, useCallback, useRef, useEffect } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { connect } from "react-redux";

import { putNewImage } from "../../../../state-management/actions/dashboard/mentee-dashboard/profileActions";

const CropImageTest = (props) => {
  const [upImg, setUpImg] = useState();
  const [error, setError] = useState();
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState({ unit: "%", width: 30, aspect: 1 / 1 });
  const [completedCrop, setCompletedCrop] = useState(null);

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  const closeModal = (e) => {
    e.stopPropagation();
    props.selectModalDetails();
  };

  const handleOnChange = (e) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => setUpImg(reader.result));
    reader.readAsDataURL(e.target.files[0]);
    setError();
  };

  const uploadImage = (canvas, crop) => {
    if (!crop || !canvas) {
      return;
    }

    canvas.toBlob(async (blob) => {
      if (!blob) {
        //reject(new Error('Canvas is empty'));
        console.error("Canvas is empty");
        return;
      }
      var formData = new FormData();
      formData.append("file", blob, "file");
      await props.putNewImage(formData, () => {});

      props.selectModalDetails();
    }, "image/jpeg");
  };

  useEffect(() => {
    if (props.fileLoaded) {
      if (props.file.type.split("/")[0] === "image") {
        setError();
        const reader = new FileReader();
        reader.addEventListener("load", () => setUpImg(reader.result));
        reader.readAsDataURL(props.file);
      } else {
        setUpImg();
        setError("Only images are allowed, please try again.");
      }
    }

    return;
  }, [props.fileLoaded]);

  useEffect(() => {
    if (props?.menteeNewImage?.imgUrl) {
      props.setUserImg(
        props.menteeNewImage.imgUrl + "?" + Date.parse(new Date())
      );
    }
  }, [props]);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
  }, [completedCrop]);

  return (
    <div
      className="mentee-dashboard-find-mentor-modal"
      style={{ display: props.closeModal ? "none" : "block" }}
      onClick={(e) => closeModal(e)}
    >
      <div
        style={{ width: "630px" }}
        className="mentee-dashboard-find-mentor-modal-content"
        onClick={(e) => e.stopPropagation()}
      >
        {!props.fileLoaded ? (
          <div>Loading...</div>
        ) : (
          <>
            {error ? (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div>
                  <label
                    style={{ width: "200px" }}
                    class="cardo-btn btn-bg-lightblue"
                    htmlFor="fileInput"
                  >
                    Upload new image
                  </label>
                  <input
                    id="fileInput"
                    style={{ marginBottom: "15px", display: "none" }}
                    type="file"
                    onChange={(e) => handleOnChange(e)}
                  />
                </div>
                <div style={{ color: "red" }}>{error}</div>
              </div>
            ) : (
              <>
                <div
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "5px",
                    cursor: "pointer",
                    zIndex: 10,
                    padding: "20px",
                    fontSize: "25px",
                  }}
                  onClick={(e) => closeModal(e)}
                >
                  <i className="fa fa-times" />
                </div>
                <div
                  className="position-relative"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "65px",
                  }}
                >
                  <div className="App">
                    {upImg && !error && (
                      <>
                        <ReactCrop
                          style={{ maxHeight: "495px" }}
                          src={upImg}
                          onImageLoaded={onLoad}
                          crop={crop}
                          onChange={(c) => setCrop(c)}
                          onComplete={(c) => setCompletedCrop(c)}
                        />
                        <div>
                          <canvas
                            ref={previewCanvasRef}
                            // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                            style={{
                              display: "none",
                              width: Math.round(completedCrop?.width ?? 0),
                              height: Math.round(completedCrop?.height ?? 0),
                            }}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div
                  style={{
                    margin: "20px auto",
                  }}
                  className="cardo-btn btn-bg-lightblue"
                  onClick={() => {
                    uploadImage(previewCanvasRef.current, completedCrop);
                  }}
                >
                  Apply
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    menteeNewImage: state.menteeDashboard.menteeNewImage,
  };
};
export default connect(mapStateToProps, { putNewImage })(CropImageTest);
