import React, { useEffect, useState } from "react";
import MenteeAccountTab from "./mentee-form/MenteeAccountTab";
import MenteeInformationTab from "./mentee-form/MenteeInformationTab";
import "../../../../styles/dashboard/profile/profile-form.css";
import { editMenteeProfile } from "../../../../state-management/actions/dashboard/mentee-dashboard/profileActions";
import { connect } from "react-redux";
import { withRouter } from "react-router";
const MenteeEditProfile = (props) => {
  const [informationData, setInformationData] = useState({
    preferredEmailForCommunication: "",
    myStory: "",
    education: "",
    gpa: "",
    interestedIn: {
      value: "",
      inputValues: ["Full Time", "Internship"],
    },
    stage: {
      value: "Student",
      inputValues: ["Student", "Experienced"],
    },
    whatMakesMeUnique: {
      value: [],
      inputValues: [
        "BIPOC",
        "Latinx",
        "LGBTQ+",
        "Military Veteran",
        "Non-Target Graduate",
        "First-Gen Graduate",
        "Woman in STEM",
        "Career Switcher",
      ],
    },
    fileInput: {
      name: `fileUpload`,
      value: {},
      file: true,
      fileName: "No file chosen",
      type: "file",
      allowedTypes: ["pdf", "doc", "docx"],
    },
  });
  const [accountData, setAccountData] = useState({
    industry: {
      value: "Consulting",
      inputValues: ["Consulting", "Finance", "Product"],
    },
    trail: {
      value: "Strategy",
      inputValues: [
        "Strategy",
        "Operations",
        "Technology",
        "Digital",
        "Human Capital",
      ],
    },
  });
  //Edit profile handleres
  const handleInformationData = (e) => {
    const { name, value } = e.target;
    setInformationData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleInformationNumber = (e) => {
    const { name, value } = e.target;

    setInformationData((prevState) => ({
      ...prevState,
      [name]: value < 0 ? 0 : value >= 4 ? 4 : value,
    }));
  };
  const fileChangeHandler = (name, file) => {
    setInformationData((prev) => ({
      ...prev,
      [name]: {
        ...prev[name],
        value: file,
        fileName: file.name ? file.name : "No file chosen",
      },
    }));
  };
  const handleCheckboxChange = (key, input) => {
    let arr = [...informationData[key].value];

    if (arr.includes("N/A")) {
      let index = arr.indexOf("N/A");
      arr.splice(index, 1);
    }
    if (arr.includes(input)) {
      let index = arr.indexOf(input);
      arr.splice(index, 1);
      if (arr.length === 0) {
        arr.push("N/A");
      }
    } else {
      arr.push(input);
    }
    setInformationData((prevState) => ({
      ...prevState,
      [key]: { ...prevState[key], value: arr.filter((v) => v) },
    }));
  };
  const handleRadioChange = (key, input) => {
    // Change for Account Data Later
    setInformationData((prevState) => ({
      ...prevState,
      [key]: { ...prevState[key], value: input },
    }));
  };

  const { currentUser, editMenteeProfile, menteeDashboardEditProfile } = props;
  const [currentTab, setCurrentTab] = useState("Information");

  const changeCurrentTab = (screen) => {
    props.changeScreen(screen);
    props.history.push({
      search: `?tabs=${screen.toLocaleLowerCase()}`,
    });
    setCurrentTab(screen);
  };

  useEffect(() => {
    editMenteeProfile();
  }, []);

  useEffect(() => {
    const search = props.location.search;
    const tabs = new URLSearchParams(search).get("tabs");
    const newTabs = tabs?.replace(/_/g, " ");
    if (tabs) {
      props.changeScreen(newTabs);
      setCurrentTab(newTabs);
    } else {
      props.changeScreen("Information");
      setCurrentTab("Information");
    }
  }, [props.location.search]);
  useEffect(() => {
    setInformationData((prevState) => ({
      ...prevState,
      ...menteeDashboardEditProfile.informationTab,
    }));
    setAccountData((prevState) => ({
      ...prevState,
      ...menteeDashboardEditProfile.accountData,
    }));
  }, [menteeDashboardEditProfile.informationTab]);
  const currentTabClass = (currentTabLabel) => {
    return currentTab.toLocaleLowerCase() ===
      currentTabLabel.toLocaleLowerCase()
      ? "user-profile-edit-profile-tab user-profile-edit-profile-tab-active"
      : "user-profile-edit-profile-tab";
  };

  let editProfileTabContent;
  if (currentTab?.toLocaleLowerCase() === "information") {
    editProfileTabContent = (
      <MenteeInformationTab
        resumeUniqueId={menteeDashboardEditProfile.resumeUniqueId}
        handleInformationData={handleInformationData}
        handleInformationNumber={handleInformationNumber}
        handleCheckboxChange={handleCheckboxChange}
        handleRadioChange={handleRadioChange}
        informationData={informationData}
        currentUser={currentUser}
        fileChangeHandler={fileChangeHandler}
      />
    );
  } else if (currentTab?.toLocaleLowerCase() === "account") {
    editProfileTabContent = (
      <MenteeAccountTab
        currentUser={currentUser}
        accountData={accountData}
        handleRadioChange={handleRadioChange}
        handleDeletePopup={props.handleDeletePopup}
      />
    );
  }
  return (
    <div className="user-profile-edit-profile">
      <div className="user-profile-edit-profile-tabs">
        <div
          className={currentTabClass("Information")}
          onClick={() => changeCurrentTab("Information")}
        >
          Information
        </div>
        <div
          className={currentTabClass("Account")}
          onClick={() => changeCurrentTab("Account")}
        >
          Account
        </div>
      </div>
      <div className="user-profile-edit-profile-content">
        {editProfileTabContent}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  menteeDashboardEditProfile: state.menteeDashboard.menteeDashboardEditProfile,
});

export default connect(mapStateToProps, { editMenteeProfile })(
  withRouter(MenteeEditProfile)
);
