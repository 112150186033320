import React from "react";
import Group213 from "../../../assets/lightLogo.svg";
import GroupLogo from "../../../assets/Group.svg";
import "../../../styles/footer.css";
import useScript from "../../../hooks/useScript";
import { Link } from "react-router-dom";
const Footer = (props) => {
  // useScript(
  //   "https://cdnjs.cloudflare.com/ajax/libs/punycode/1.4.1/punycode.min.js"
  // );
  // useScript("https://cdn.jotfor.ms/static/prototype.forms.js");
  // useScript("https://cdn.jotfor.ms/static/jotform.forms.js?3.3.22363");

  // useScript("https://cdn.jotfor.ms//common/FormUserScripts.js");
  return (
    <div>
      {/* Footer */}
      <section
        className="footer_section"
        style={{
          backgroundColor: props.wavebg,
        }}
      >
        {/*'wave shape'  */}
        <div
          style={{
            height: "150px",
            overflow: "hidden",
            marginTop: props.wavebg === "transparent" ? "-120px" : "0px",
          }}
        >
          <svg
            viewBox="0 0 500 150"
            preserveAspectRatio="none"
            style={{ height: "100%", width: "100%" }}
          >
            <path
              className="desktop_wave"
              d="M-0.84,28.13 C65.18,-75.48 416.19,146.53 501.41,106.08 L500.84,151.47 L-0.27,151.47 Z"
              style={{ stroke: "none", fill: props.bgcolor }}
            ></path>
            <path
              className="mobile_wave"
              d="M-1.41,4.45 C112.58,2.47 287.52,37.98 500.27,93.25 L500.00,150.00 L0.00,150.00 Z"
              style={{ stroke: "none", fill: props.bgcolor }}
            ></path>
          </svg>
        </div>
      </section>
      <section className="footer" style={{ backgroundColor: props.bgcolor }}>
        <div className="container_narrow footer_content_container desktop_footer_container">
          <div className="row footer_first_row">
            <div className="col-4 footer_first_col_leftCol">
              <Link to="/">
                <img src={Group213} alt="" />
              </Link>
            </div>
            <div className="col-4 footer_first_col_middleCol">
              <Link to="/">
                <img src={GroupLogo} alt="" />
              </Link>
            </div>
            <div className="col-4 footer_first_col_rightCol">
              <a
                href="https://www.linkedin.com/company/hellocardo/"
                target="_blank"
                className="social-btn"
                rel="noreferrer"
              >
                <i className="fab fa-linkedin-in"></i>
              </a>
              <a
                className="social-btn"
                href="https://www.instagram.com/hellocardo"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-instagram"></i>
              </a>
              <a
                href="https://www.facebook.com/helllocardo"
                target="_blank"
                className="social-btn"
                rel="noreferrer"
              >
                <i className="fab fa-facebook"></i>
              </a>
              <a
                href="https://twitter.com/hellocardo"
                target="_blank"
                className="social-btn"
                rel="noreferrer"
              >
                <i className="fab fa-twitter"></i>
              </a>
            </div>
          </div>

          <div className="row footer_second_row">
            <div className="col-4 footer_second_col_leftCol">
              <a href="mailto:support@hellocardo.org?subject=I have a question about Cardo">
                Contact Us
              </a>
              <a
                href="https://www.guidestar.org/profile/85-2132557"
                target="_blank"
                rel="noreferrer"
              >
                EIN: 85-2132557
              </a>
            </div>
            <div className="col-4 footer_second_col_middleCol">
              <Link to="/about-us">About us</Link>

              <Link to="/find-mentor">Find your mentor</Link>
              <Link to="/become-mentor">Become a mentor</Link>
              <Link to="/donate">Donate</Link>
            </div>
            <div className="col-4 footer_second_col_rightCol">
              <Link to="/terms-of-service">Terms of Service</Link>
              <Link to="/privacy-policy">Privacy Policy</Link>
            </div>
          </div>
        </div>
        <div className="container_narrow mobile_footer_container">
          <div className="row">
            <div className="col-12 footer_mobile_first_col_logo">
              <img src={Group213} alt="" />
            </div>
            <div className="col-12 footer_second_col_middleCol">
              <Link to="/about-us">About us</Link>

              <Link to="/find-mentor">Find your mentor</Link>
              <Link to="/become-mentor">Become a mentor</Link>
              <Link to="/donate">Donate</Link>
            </div>
            <div className="col-12 footer_first_col_rightCol">
              <a
                href="https://www.linkedin.com/company/hellocardo/"
                target="_blank"
                className="social-btn"
                rel="noreferrer"
              >
                <i className="fab fa-linkedin-in"></i>
              </a>
              <a
                className="social-btn"
                href="https://www.instagram.com/hellocardo"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-instagram"></i>
              </a>
              <a
                href="https://www.facebook.com/hellllocardo"
                target="_blank"
                className="social-btn"
                rel="noreferrer"
              >
                <i className="fab fa-facebook"></i>
              </a>
              <a
                href="https://twitter.com/hellocardo"
                target="_blank"
                className="social-btn"
                rel="noreferrer"
              >
                <i className="fab fa-twitter"></i>
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col-12 footer_second_col_top">
              <a href="mailto:support@hellocardo.org?subject=I have a question about Cardo">
                Contact Us
              </a>
              <a
                href="https://www.guidestar.org/profile/85-2132557"
                target="_blank"
                rel="noreferrer"
              >
                EIN: 85-2132557
              </a>
            </div>
            <div className="col-12 footer_second_col_rightCol">
              <Link to="/terms-of-service">Terms of Service</Link>
              <Link to="/privacy-policy">Privacy Policy</Link>
            </div>
          </div>
        </div>
        <div
          className="row copywrite"
          style={{ backgroundColor: props.copywritebg }}
        >
          <div className="col-12 copywrite_text">
            &copy; {new Date().getFullYear()} Cardo. All rights reserved.
          </div>
        </div>
      </section>
    </div>
  );
};

export default Footer;
