import Video from 'twilio-video';
import { useCallback, useEffect, useRef, useState } from 'react';

// @ts-ignore
window.TwilioVideo = Video;

export default function useRoom(localTracks, options) {
    const [room, setRoom] = useState(null);
    const [isConnecting, setIsConnecting] = useState(false);
    const [isConnected, setIsConnected] = useState(false);
    const optionsRef = useRef(options);

    useEffect(() => {
        optionsRef.current = options;
    }, [options]);

    const connect = useCallback(
        (token, tracks = null) => {
            setIsConnecting(true);
            setIsConnected(false);
            return Video.connect(token, { ...optionsRef.current, tracks: tracks || localTracks }).then(
                newRoom => {
                    setRoom(newRoom);
                    const disconnect = (e) => {
                        e.preventDefault()
                        delete e["Are you sure you want to exit?"];

                        newRoom.disconnect()
                        return (e.returnValue = "Are you sure you want to exit?");
                    }
                    newRoom.setMaxListeners(15);
                    newRoom.once('disconnected', () => {
                        setTimeout(() => setRoom(null));

                    });

                    window.twilioRoom = newRoom;

                    newRoom.localParticipant.videoTracks.forEach(publication =>
                        publication.setPriority('low')
                    );

                    setIsConnecting(false);
                    setIsConnected(true);


                },
                error => {
                    console.log(error);
                    setIsConnecting(false);
                    setIsConnected(false);
                }
            );
        },
        [localTracks]
    );

    return { room, isConnecting, connect, isConnected };
}
