import React, { useEffect, useState } from "react";
import validate from "../../../../helper/validate";
import MentorSteps from "./steps/MentorSteps";
import { countries } from "../../../../helper/countries";
import ReactHtmlParser from "react-html-parser";
import MentorSignupStep1Welcome from "./steps/MentorSignupStep1Welcome";
import MentorSignupStep2YourBackground from "./steps/MentorSignupStep2YourBackground";
import MentorSignupStep3YourImpact from "./steps/MentorSignupStep3YourImpact";
import LinkedInLogin from "../login-with-linkedin/LinkedInLogin";
import { connect } from "react-redux";
import { postSignupMentorJotform } from "../../../../state-management/actions/authActions";
import { helper } from "../../../../helper/helper";
import "../../../../styles/loader.css";

const MentorSignupForm = (props) => {
  const { mentorForm } = props;
  const [step, setStep] = useState(0);

  const [loading, setLoading] = useState(false);
  const handleLoading = (loadingValue) => {
    setLoading(loadingValue);
  };

  const [formData, setFormData] = useState({
    stepZero: {
      q95_input95: {
        name: `q${mentorForm[0][3].qid}_${mentorForm[0][3].name}`,
        required: false,
        description: mentorForm[0][3].description,

        text: mentorForm[0][3].text,
        type: "control_text",
      },
    },
    stepOne: {
      q68_whichCountry: {
        name: `q${mentorForm[0][5].qid}_${mentorForm[0][5].name}`,
        value: mentorForm[0][5].selected,
        description: mentorForm[0][5].description,

        required: true,
        type: "select",
        text: mentorForm[0][5].text,
        selected: mentorForm[0][5].selected,
        choices: ReactHtmlParser(countries),
      },

      q12_whichIndustry12: {
        name: `q${mentorForm[0][7].qid}_${mentorForm[0][7].name}`,
        text: mentorForm[0][7].text,
        value: "Consulting",
        inputValues: [
          "Consulting",
          "Finance (Coming Soon)",
          "Product (Coming Soon)",
        ],
        description: mentorForm[0][7].description,
        required: true,
        type: "radio",
        checked: true,
      },
      q81_areYou: {
        name: `q${mentorForm[0][8].qid}_${mentorForm[0][8].name}`,
        text: mentorForm[0][8].text,
        value: "",
        inputValues: ["Yes", "No"],
        description: mentorForm[0][8].description,

        type: "radio",
        checked: false,
        required: true,
      },
      q84_whereDid: {
        name: `q${mentorForm[0][9].qid}_${mentorForm[0][9].name}`,
        text: mentorForm[0][9].text,
        description: mentorForm[0][9].description,

        value: "",
        inputValues: [
          "Graduate School (e.g., MBA)",
          "Industry (e.g., F500 Company)",
          "Entrepreneurship",
          "",
        ],
        type: "radio",
        checked: false,
      },
      q113_education: {
        name: `q${mentorForm[0][10].qid}_${mentorForm[0][10].name}`,
        value: "",
        required: false,
        description: mentorForm[0][10].description,

        type: "input",
        placeholder: "",
        text: mentorForm[0][10].text,
      },
      q86_whatConsulting: {
        name: `q${mentorForm[0][11].qid}_${mentorForm[0][11].name}`,
        value: "",
        required: false,
        description: mentorForm[0][11].description,

        type: "input",
        placeholder: "",
        text: mentorForm[0][11].text,
      },
      q105_whichConsulting: {
        name: `q${mentorForm[0][12].qid}_${mentorForm[0][12].name}`,
        value: "",
        required: false,
        type: "input",
        placeholder: "",
        description: mentorForm[0][12].description,
        text: mentorForm[0][12].text,
      },
      q25_pleaseUpload25: {
        name: `q${mentorForm[0][13].qid}_${mentorForm[0][13].name}`,
        value: {},
        required: true,
        file: true,
        text: mentorForm[0][13].text,
        description: mentorForm[0][13].description,

        fileName: "No file chosen",
        type: "file",
        buttonText: mentorForm[0][13].buttonText,
        allowedTypes: ["pdf", "doc", "docx"],
        maxFileSize: 5000,
      },
    },
    stepTwo: {
      q80_withinConsulting80: {
        checked: false,
        name: `q${mentorForm[0][15].qid}_${mentorForm[0][15].name}`,
        value: [],
        text: mentorForm[0][15].text,
        inputValues: mentorForm[0][15].options.split("|"),
        description: mentorForm[0][15].description,

        required: true,
        type: "checkbox",
      },
      q77_whichAreas77: {
        checked: false,

        name: `q${mentorForm[0][16].qid}_${mentorForm[0][16].name}`,
        value: [],
        text: mentorForm[0][16].text,
        inputValues: mentorForm[0][16].options.split("|"),
        description: mentorForm[0][16].description,

        storeValues: [
          "Industry Overview",
          "Resume Review",
          "LinkedIn Tips",
          "Fit Interview",
          "Case Interview",
        ],
        required: true,
        type: "checkbox",
      },
    },
  });
  const [errors, setErrors] = useState({});

  const radioChange = (step, e, val) => {
    setFormData((prev) => ({
      ...prev,
      ...(prev[step][e.target.name].checked = true),
    }));
    e.persist();
    setFormData((prev) => ({
      ...prev,
      [step]: {
        ...prev[step],

        [e.target.name]: {
          ...prev[step][e.target.name],
          value: e.target.value,
        },
      },
    }));
  };
  const onCheckboxChange = (step, e, val) => {
    let elm = e.target.value;
    let arr = formData[step][e.target.name].value;

    e.persist();

    //delete or add element to the value array
    if (arr.includes(elm)) {
      let index = arr.indexOf(elm);
      arr.splice(index, 1);
    } else {
      arr.push(elm);
    }
    if (arr.length <= 0) {
      setFormData((prev) => ({
        ...prev,
        [step]: {
          ...prev[step],
          [e.target.name]: {
            ...prev[step][e.target.name],
            value: arr,
            checked: false,
          },
        },
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [step]: {
          ...prev[step],
          [e.target.name]: {
            ...prev[step][e.target.name],
            value: arr,
            checked: true,
          },
        },
      }));
    }
  };
  const handleRequired = (step, inputsRequired, inputsNotRequired) => {
    inputsRequired.forEach((input) => {
      setFormData((prev) => ({
        ...prev,
        [step]: {
          ...prev[step],
          [input.name]: {
            ...prev[step][input.name],
            required: true,
          },
        },
      }));
    });
    inputsNotRequired.forEach((input) => {
      setFormData((prev) => ({
        ...prev,
        [step]: {
          ...prev[step],
          [input.name]: {
            ...prev[step][input.name],
            required: false,
            value: "",
          },
        },
      }));
    });
  };
  const changeHandler = (step, e) => {
    e.persist();
    setFormData((prev) => ({
      ...prev,
      [step]: {
        ...prev[step],
        [e.target.name]: {
          ...prev[step][e.target.name],
          value: e.target.value,
        },
      },
    }));
  };

  const fileChangeHandler = (e, name, file, step) => {
    setFormData((prev) => ({
      ...prev,
      [step]: {
        ...prev[step],
        [name]: {
          ...prev[step][name],
          value: file,
          fileName: file.name ? file.name : "No file chosen",
        },
      },
    }));
  };
  const iconClickHandle = (stepNo, e) => {
    e.preventDefault();

    setStep(stepNo);
  };
  const stepChangeHandler = (values, e) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    const newErrors = validate(values);
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      setStep(step + 1);
    }
  };

  const onPrevStep = (step) => {
    window.scrollTo(0, 0);
    setStep(step);
  };

  const [linkedinStatus, setLinkedinStatus] = useState({
    error: "",
    code: "",
  });
  // useEffect(() => {
  //   setLinkedinStatus({
  //     error: "",
  //     code: "",
  //   });
  // }, []);
  const handleLinkedInSuccess = (data) => {
    setLinkedinStatus({
      code: data.code,
      error: "",
    });
    submitHandler(data.code);
  };
  const customLinkedInError = (msg) => {
    setLinkedinStatus({
      code: "",
      error: msg,
    });
    // document.getElementById("login-with-linkedin_id").style.display = "block";
  };
  const handleLinkedInFailure = (error) => {
    setLinkedinStatus({
      code: "",
      error: "Please Login To Submit Form",
    });
    // document.getElementById("login-with-linkedin_id").style.display = "block";
  };

  const submitHandler = (code) => {
    // e.preventDefault();
    handleLoading(true);
    // const { code } = linkedinStatus;
    if (code !== "") {
      const data = new FormData();
      data.append("q34_whatAre", "Mentor");
      data.append("q95_input95", formData.stepZero.q95_input95.value);
      data.append("q68_whichCountry", formData.stepOne.q68_whichCountry.value);

      data.append(
        "q12_whichIndustry12",
        formData.stepOne.q12_whichIndustry12.value
      );

      data.append("q81_areYou", formData.stepOne.q81_areYou.value);

      data.append("q84_whereDid", formData.stepOne.q84_whereDid.value);

      data.append("q113_education", formData.stepOne.q113_education.value);
      data.append(
        "q86_whatConsulting",
        formData.stepOne.q86_whatConsulting.value
      );

      data.append(
        "q105_whichConsulting",
        formData.stepOne.q105_whichConsulting.value
      );

      data.append(
        "q25_pleaseUpload25",
        formData.stepOne.q25_pleaseUpload25.value
      );
      for (
        let i = 0;
        i < formData.stepTwo.q80_withinConsulting80.value.length;
        i++
      ) {
        data.append(
          "q80_withinConsulting80[]",
          formData.stepTwo.q80_withinConsulting80.value[i]
        );
      }
      for (let i = 0; i < formData.stepTwo.q77_whichAreas77.value.length; i++) {
        data.append(
          "q77_whichAreas77[]",
          formData.stepTwo.q77_whichAreas77.value[i]
        );
      }

      props.postSignupMentorJotform(data, code, handleLoading);

      // In here you can send data to some api
      // For example if you have some redux action: sendData(data)
    } else {
      setLinkedinStatus({
        code: "",
        error: "Please Login To Submit Form",
      });
    }
  };

  return (
    <form
      onSubmit={submitHandler}
      encType="multipart/form-data"
      name={`form_${helper.mentorFormId}`}
      id={`form_id_${helper.mentorFormId}`}
    >
      <MentorSteps
        step={step}
        iconClickHandle={iconClickHandle}
        data={formData}
      >
        {step === 0 && (
          <MentorSignupStep1Welcome
            data={formData.stepZero}
            onChange={changeHandler}
            onRadioChange={radioChange}
            onCheckboxChange={onCheckboxChange}
            onStepChange={stepChangeHandler}
            errors={errors}
            stepKey="stepZero"
            title={
              "Welcome: Become a Mentor"
              // mentorForm[step][2].text
            }
            step={0}
          />
        )}
        {step === 1 && (
          <MentorSignupStep2YourBackground
            data={formData.stepOne}
            onChange={changeHandler}
            onRadioChange={radioChange}
            onCheckboxChange={onCheckboxChange}
            onStepChange={stepChangeHandler}
            onFileChange={fileChangeHandler}
            handleRequired={handleRequired}
            errors={errors}
            setErrors={setErrors}
            stepKey="stepOne"
            onPrevStep={onPrevStep}
            title={"Your Background"}
            step={1}
          />
        )}
        {step === 2 && (
          <MentorSignupStep3YourImpact
            data={formData.stepTwo}
            onChange={changeHandler}
            onStepChange={stepChangeHandler}
            onRadioChange={radioChange}
            onCheckboxChange={onCheckboxChange}
            onFileChange={fileChangeHandler}
            errors={errors}
            stepKey="stepTwo"
            title={"Your Impact"}
            onPrevStep={onPrevStep}
            step={2}
          />
        )}

        {step === 3 && (
          <LinkedInLogin
            onPrevStep={() => setStep(step - 1)}
            step={3}
            linkedinStatus={linkedinStatus}
            handleLinkedInSuccess={handleLinkedInSuccess}
            handleLinkedInFailure={handleLinkedInFailure}
            customLinkedInError={customLinkedInError}
            loading={loading}
          />
        )}
      </MentorSteps>
    </form>
  );
};

export default connect(null, { postSignupMentorJotform })(MentorSignupForm);
