import axios from "axios";
import React, { useEffect, useState } from "react";
import SignupStatusApproved from "./SignupStatusApproved";
import SignupStatusDenied from "./SignupStatusDenied";
import SignupStatusPending from "./SignupStatusPending";
import { helper } from "../../../../helper/helper";
import { checkStatus } from "../../../../state-management/actions/authActions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
const SignupStatus = (props) => {
  const [status, setStatus] = useState("");
  const handleStatus = (data) => {
    setStatus(data);
  };
  const { currentUser, history } = props;
  useEffect(() => {
    props.checkStatus(handleStatus);
    if (currentUser.loggedIn && !currentUser.firstTime) {
      history.push(`/${currentUser.type}/dashboard`);
    }
  }, [currentUser]);

  let signupContent;
  if (status === "accepted") {
    signupContent = <SignupStatusApproved currentUser={currentUser} />;
  } else if (status === "pending") {
    signupContent = <SignupStatusPending />;
  } else if (status === "rejected") {
    signupContent = <SignupStatusDenied />;
  }
  return <div>{signupContent}</div>;
};

const mapStateToProps = (state) => ({
  currentUser: state.auth.currentUser,
});
export default connect(mapStateToProps, { checkStatus })(
  withRouter(SignupStatus)
);
