import { helper } from "../../../helper/helper";
import axios from "axios";
import {
  GET_ALL_USERS,
  SAVE_LINK,
  GET_LINKS,
  GET_CALENDLY_TAB,
  ACCEPT_USER,
  REJECT_USER,
  GET_ALL_NEW_USERS,
  LOGIN,
  CHECK
} from "../../types/types";
import Cookies from "universal-cookie";
import { throwCustomError } from "../authActions";
import { startLoading, stopLoading } from "../loadingActions";
import { cleanSuccess, handleSuccess } from "../successActions";
import { clearErrors } from "../errorActions";
const cookies = new Cookies();

export const checkValid = () => (dispatch) => {
  axios
    .post(`${helper.baseUrl}/admin/check`, null, { withCredentials: true })
    .then((res) => {
      res.data.status = res.status
      dispatch({
        type: CHECK,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log('err', err.response);
      if (err.response.status === 401) {
        console.log('err')
        window.location.href = "/"
      }
    });
};

export const postLogin = (data) => (dispatch) => {
  axios
    .post(`${helper.baseUrl}/admin/login`, data, {
      headers: { "x-csrf-token": cookies.get("XSRF-TOKEN") },
      withCredentials: true,
    })
    .then((res) => {
      res.data.status = res.status
      dispatch({
        type: LOGIN,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(
        throwCustomError(
          "somethingWentWrong",
          err.response.status,
          err.response.data
        )
      );
    });
}

export const getAllUsers = () => (dispatch) => {
  axios
    .get(`${helper.baseUrl}/admin/getAllUsers`, { withCredentials: true })
    .then((res) => {
      dispatch({
        type: GET_ALL_USERS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err.response);
    });
};
export const updateUserData = (data) => (dispatch) => {
  dispatch(clearErrors());
  dispatch(cleanSuccess());
  axios
    .put(`${helper.baseUrl}/admin/updateUserData`, data, {
      headers: { "x-csrf-token": cookies.get("XSRF-TOKEN") },

      withCredentials: true,
    })
    .then((res) => {
      dispatch(handleSuccess());
    })
    .catch((err) => {
      dispatch(
        throwCustomError(
          "somethingWentWrong",
          err.response.status,
          err.response.data
        )
      );
    });
};

export const revokeMentee = (id) => (dispatch) => {
  dispatch(clearErrors());
  dispatch(cleanSuccess());
  axios
    .put(`${helper.baseUrl}/admin/revokeMentee`, id, {
      headers: { "x-csrf-token": cookies.get("XSRF-TOKEN") },

      withCredentials: true,
    })
    .then((res) => {
      res.data.status = res.status
      dispatch({
        type: GET_ALL_USERS,
        payload: res.data,
      });
      dispatch(handleSuccess());
    })
    .catch((err) => {
      dispatch(
        throwCustomError(
          "somethingWentWrong",
          err.response.status,
          err.response.data
        )
      );
    });
};
export const deleteMentee = (id) => (dispatch) => {
  dispatch(clearErrors());
  dispatch(cleanSuccess());
  axios
    .delete(`${helper.baseUrl}/admin/deleteMentee/${id}`, {
      headers: { "x-csrf-token": cookies.get("XSRF-TOKEN") },

      withCredentials: true,
    })
    .then((res) => {
      res.data.status = res.status
      dispatch({
        type: GET_ALL_USERS,
        payload: res.data,
      });
      dispatch(handleSuccess());
    })
    .catch((err) => {
      dispatch(
        throwCustomError(
          "somethingWentWrong",
          err.response.status,
          err.response.data
        )
      );
    });
};
export const revokeMentor = (id) => (dispatch) => {
  dispatch(clearErrors());
  dispatch(cleanSuccess());
  axios
    .put(`${helper.baseUrl}/admin/revokeMentor`, id, {
      headers: { "x-csrf-token": cookies.get("XSRF-TOKEN") },

      withCredentials: true,
    })
    .then((res) => {
      dispatch(handleSuccess());
    })
    .catch((err) => {
      dispatch(
        throwCustomError(
          "somethingWentWrong",
          err.response.status,
          err.response.data
        )
      );
    });
};
export const deleteMentor = (id) => (dispatch) => {
  dispatch(clearErrors());
  dispatch(cleanSuccess());
  axios
    .delete(`${helper.baseUrl}/admin/deleteMentor/${id}`, {
      headers: { "x-csrf-token": cookies.get("XSRF-TOKEN") },

      withCredentials: true,
    })
    .then((res) => {
      dispatch({
        type: GET_ALL_USERS,
        payload: res.data,
      });
      dispatch(handleSuccess());
    })
    .catch((err) => {
      dispatch(
        throwCustomError(
          "somethingWentWrong",
          err.response.status,
          err.response.data
        )
      );
    });
};
export const getAllNewUsers = () => (dispatch) => {
  axios
    .get(`${helper.baseUrl}/admin/getNewUsers`, { withCredentials: true })
    .then((res) => {

      dispatch({
        type: GET_ALL_NEW_USERS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log('err', err.response);
      if (err.response.status === 401) {
        console.log('err')
        window.location.href = "/"
      }
    });
};
export const acceptUser = (id, type) => (dispatch) => {
  axios
    .put(`${helper.baseUrl}/admin/postAccept?id=${id}&type=${type}`, null, {
      headers: { "x-csrf-token": cookies.get("XSRF-TOKEN") },
      withCredentials: true,
    })
    .then((res) => {
      dispatch({
        type: ACCEPT_USER,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log('err', err.response);

    });
};
export const rejectUser = (id, type) => (dispatch) => {
  axios
    .put(`${helper.baseUrl}/admin/postReject?id=${id}&type=${type}`, null, {
      headers: { "x-csrf-token": cookies.get("XSRF-TOKEN") },
      withCredentials: true,
    })
    .then((res) => {
      dispatch({
        type: REJECT_USER,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err.response);
    });
};

export const saveLink = (data) => (dispatch) => {
  dispatch(startLoading());
  axios
    .post(`${helper.baseUrl}/admin/saveLink`, data, {
      headers: { "x-csrf-token": cookies.get("XSRF-TOKEN") },
      withCredentials: true,
    })
    .then((res) => {
      dispatch(stopLoading());
      dispatch({
        type: SAVE_LINK,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(
        throwCustomError(
          "somethingWentWrong",
          err.response.status,
          err.response.data
        )
      );
    });
};

export const getLinks = (data) => (dispatch) => {
  axios
    .get(`${helper.baseUrl}/admin/getLinks`, data, {
      headers: { "x-csrf-token": cookies.get("XSRF-TOKEN") },
      withCredentials: true,
    })
    .then((res) => {
      dispatch({
        type: GET_LINKS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(
        throwCustomError(
          "somethingWentWrong",
          err.response.status,
          err.response.data
        )
      );
    });
};

export const getCalendlyTabInfo = () => (dispatch) => {
  axios
    .get(`${helper.baseUrl}/admin/getCalendlyTabInfo`, null, {
      headers: { "x-csrf-token": cookies.get("XSRF-TOKEN") },
      withCredentials: true,
    })
    .then((res) => {
      dispatch({
        type: GET_CALENDLY_TAB,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(
        throwCustomError(
          "somethingWentWrong",
          err.response.status,
          err.response.data
        )
      );
    });
};
