import {useEffect} from 'react';

export default function useHandleRoomDisconnection(
    room = null, removeLocalAudioTrack = () => {
    },
    removeLocalVideoTrack = () => {
    },
    isSharingScreen,
    toggleScreenShare
) {
    useEffect(() => {
        if (room) {
            const onDisconnected = (_, error) => {
                removeLocalAudioTrack();
                removeLocalVideoTrack();
                if (isSharingScreen) {
                    toggleScreenShare();
                }
            };

            room.on('disconnected', onDisconnected);
            return () => {
                room.off('disconnected', onDisconnected);
            };
        }
    }, [room, removeLocalAudioTrack, removeLocalVideoTrack, isSharingScreen, toggleScreenShare]);
}
