import React, { useEffect } from "react";
import SignupThankyou from "../../components/auth/sign-up/sign-up/SignupThankyou";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { useIsDesktop } from "../../helper/useIsDesktop";
import NotAccessibleForMobile from "../../components/landing-page/error-pages/NotAccessibleForMobile";

const SignupThankyouRoute = (props) => {
  const { isDesktop } = useIsDesktop();

  //   useEffect(() => {
  //     console.log(props.currentUser);
  //     if (
  //       props.currentUser.loggedIn === true &&
  //       props.currentUser.type === "mentor"
  //     ) {
  //       props.history.push("/");
  //     } else if (
  //       props.currentUser.loggedIn === true &&
  //       props.currentUser.type === "mentee"
  //     ) {
  //       props.history.push("/");
  //     }
  //   }, [props.currentUser]);
  useEffect(() => {
    document.title = "Cardo | Sign Up";
  }, []);
  return (
    <div>{isDesktop ? <SignupThankyou /> : <NotAccessibleForMobile />}</div>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.auth.currentUser,
});

export default connect(mapStateToProps)(withRouter(SignupThankyouRoute));
