export const DEFAULT_VIDEO_CONSTRAINTS = {
    width: 1280,
    height: 720,
    frameRate: 24,
};
export const SELECTED_AUDIO_INPUT_KEY = 'TwilioVideoApp-selectedAudioInput';
export const SELECTED_AUDIO_OUTPUT_KEY = 'TwilioVideoApp-selectedAudioOutput';
export const SELECTED_VIDEO_INPUT_KEY = 'TwilioVideoApp-selectedVideoInput';

export async function getDeviceInfo() {
    const devices = await navigator?.mediaDevices?.enumerateDevices();

    return {
        audioInputDevices: devices.filter(device => device.kind === 'audioinput'),
        videoInputDevices: devices.filter(device => device.kind === 'videoinput'),
        audioOutputDevices: devices.filter(device => device.kind === 'audiooutput'),
        hasAudioInputDevices: devices.some(device => device.kind === 'audioinput'),
        hasVideoInputDevices: devices.some(device => device.kind === 'videoinput'),
    };
}

export async function isPermissionDenied(name) {
    if (navigator.permissions) {
        try {
            const result = await navigator.permissions.query({ name });
            return result.state === 'denied';
        } catch {
            return false;
        }
    } else {
        return false;
    }
}
