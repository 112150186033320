import React from "react";
import section1cardIcon1 from "../../../assets/Become-a-Mentor/Group 575.svg";
import section1cardIcon2 from "../../../assets/Become-a-Mentor/Group 576.svg";
import section1cardIcon3 from "../../../assets/Become-a-Mentor/Vector-9.svg";
const BecomeMentorSection1Desktop = () => {
  return (
    <div>
      <div className="card-container">
        <div className="card">
          <div>
            <div className="card-icon">
              <img src={section1cardIcon1} alt="" />
            </div>
          </div>
          <div className="card-heading first_card_heading">
            Earn credit for your time
          </div>
          <p className="card-desc">
            Track your mentorship contributions to see your impact over time and
            feel recognized for your efforts.
          </p>
        </div>
        <div className="card">
          <div>
            <div className="card-icon">
              <img src={section1cardIcon2} alt="" />
            </div>
          </div>
          <div className="card-heading">
            Get rid of the hassle around helping
          </div>
          <p className="card-desc">
            Coach pre-vetted mentees and let us handle the burden of messaging
            and scheduling, so you can focus on what matters most.
          </p>
        </div>
        <div className="card">
          <div>
            <div className="card-icon">
              <img src={section1cardIcon3} alt="" />
            </div>
          </div>
          <div className="card-heading">
            Become part of our non-profit community
          </div>
          <p className="card-desc">
            Join our 501(c)(3) non-profit organization. Have the opportunity to
            take leadership roles and grow your network.
          </p>
        </div>
      </div>
    </div>
  );
};

export default BecomeMentorSection1Desktop;
