import React from "react";
import Slider from "react-slick";

const FindMentorSection6Mobile = ({ mentorData, loading }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    arrows: false,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div style={{ marginTop: "60px", width: "100%" }}>
      <Slider {...settings}>
        {mentorData &&
          mentorData.map((mentor, index) => {
            return (
              <div className="mentor-container" key={index}>
                <div
                  style={{
                    backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0) 40%, rgba(0,0,0,1) 100%),url('${mentor.imageUrl}')`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center center",
                    height: "502.7px",
                    width: "100%",
                    borderRadius: "16.798px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                      textAlign: "center",
                      height: "100%",
                    }}
                  >
                    <span
                      style={{
                        color: "white",
                        fontWeight: "600",
                        fontSize: "19px",
                        lineHeight: "23px",
                        textAlign: "center",
                        fontFamily: "Neometric Medium",
                        marginBottom: "5px",
                      }}
                    >
                      {mentor.name}
                    </span>
                    <span
                      style={{
                        color: "white",
                        fontSize: "14px",
                        lineHeight: "16px",
                        fontFamily: "Neometric Regular",
                      }}
                    >
                      {mentor.area}
                      <i
                        className="fas fa-caret-right"
                        style={{ color: "white", margin: "0px 5px" }}
                      ></i>
                      {mentor.company}
                    </span>
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "center",
                        fontFamily: "Neometric Regular",
                      }}
                    >
                      {mentor.skills.split(",").map((skill, index) => {
                        return (
                          <span
                            key={index}
                            style={{
                              color: "white",
                              backgroundColor: "rgba(255, 255, 255, 0.24)",
                              borderRadius: "11.7857px",
                              fontSize: "12px",
                              lineHeight: "15px",
                              padding: "3px 5px",
                              margin: "4px",
                            }}
                          >
                            {skill}
                          </span>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </Slider>
    </div>
  );
};

export default FindMentorSection6Mobile;
