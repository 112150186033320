import {useState, useCallback, useRef} from 'react';

export default function useScreenShareToggle(room) {
    const [isSharing, setIsSharing] = useState(false);
    const stopScreenShareRef = useRef(null);

    const shareScreen = useCallback(() => {
        navigator.mediaDevices
            .getDisplayMedia({
                audio: false,
                video: {
                    frameRate: 10,
                    height: 1080,
                    width: 1920,
                },
            })
            .then(stream => {
                const track = stream.getTracks()[0];
                room?.localParticipant
                    .publishTrack(track, {
                        name: 'screen',
                        priority: 'low',
                    })
                    .then(trackPublication => {
                        stopScreenShareRef.current = () => {
                            room?.localParticipant.unpublishTrack(track);
                            room?.localParticipant.emit('trackUnpublished', trackPublication);
                            track.stop();
                            setIsSharing(false);
                        };

                        track.onended = stopScreenShareRef.current;
                        setIsSharing(true);
                    })
                    .catch(e => console.log(e));
            })
            .catch(error => {
                // Don't display an error if the user closes the screen share dialog
                if (error.name !== 'AbortError' && error.name !== 'NotAllowedError') {
                    console.log(error)
                }
            });
    }, [room]);

    const toggleScreenShare = useCallback(() => {
        if (room) {
            !isSharing ? shareScreen() : stopScreenShareRef.current();
        }
    }, [isSharing, shareScreen, room]);

    return [isSharing, toggleScreenShare];
}
