import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

const ProfileBodyProgress = (props) => {
  const { menteeDashboardEditProfile } = props;
  const [progressPercent, setProgress] = useState();
  useEffect(() => {
    if (props.progress) {
      setProgress(props.progress);
    }
    return;
  }, [props]);
  return (
    <div className="profile-body-user-profile-completion">
      <div>Completed Profile</div>

      <progress
        className="progress-bar"
        value={progressPercent || menteeDashboardEditProfile?.percent || 0}
        max="100"
      />
      <span className="ml-15">
        {progressPercent || menteeDashboardEditProfile?.percent || 0}%
      </span>
    </div>
  );
};
const mapStateToProps = (state) => ({
  progress: state.mentorDashboard.mentorDashboardEditProfile.percent,
});
export default connect(mapStateToProps, {})(ProfileBodyProgress);
