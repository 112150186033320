import React, { useEffect } from "react";
import { LinkedIn } from "react-linkedin-login-oauth2";
import { helper } from "../../../../helper/helper";
import { connect } from "react-redux";
import SignupValidation from "../validation/SignupValidation";
const LinkedInLogin = (props) => {
  const {
    step,
    onPrevStep,
    handleLinkedInFailure,
    handleLinkedInSuccess,
    linkedinStatus,
    errors,
    customLinkedInError,
    loading,
  } = props;
  const { code, error } = linkedinStatus;
  useEffect(() => {
    if (errors.cannotSubmit) {
      customLinkedInError(errors.cannotSubmit.msg);
    } else if (errors.cannotSubmit) {
    }
  }, [errors]);
  // useEffect(() => {
  //   document
  //     .getElementById("login-with-linkedin_id")
  //     .parentNode.addEventListener("click", (e) => {
  //       e.target.style.display = "none";
  //     });
  // }, []);
  return (
    <div>
      <h3 className="sign-up-form-heading">
        Complete your application by validating your identity
      </h3>
      <div className="step-form-content" style={{ marginBottom: "140px" }}>
        <div className="form-line">
          <p className="sign-up-form-paragraph sign-up-verify-step-paragraph">
            Sign in with LinkedIn to validate your professional identity and
            complete your application. Your LinkedIn credentials will be used
            sign into Cardo once you create an account. We will not post on your
            behalf.{" "}
          </p>
        </div>
        <div className="form-line">
          <div className="login-with-linkedin-container">
            <LinkedIn
              clientId={helper.linkedinClientId}
              onFailure={handleLinkedInFailure}
              onSuccess={handleLinkedInSuccess}
              redirectUri={helper.linkedinRedirectUrl}
              scope="r_liteprofile r_emailaddress"
            >
              <div
                id="login-with-linkedin_id"
                className="login-with-linkedin"
              />
            </LinkedIn>
            <div className="login-with-linkedin-error-container">
              {error && <SignupValidation message={error} />}
            </div>
          </div>
        </div>
        <div className="form-line">
          <p className="sign-up-form-paragraph sign-up-verify-step-paragraph sign-up-verify-step-paragraph-note">
            Note: Creation of an account is required to save application
            details. If you exit this screen, your application will not be
            saved.
          </p>
        </div>
      </div>
      <div className="form-step-btn-group">
        {step > 0 && (
          <button
            type="button"
            className="cardo-btn back-btn"
            onClick={() => onPrevStep(step - 2)}
          >
            Go back
          </button>
        )}
        <button
          type="submit"
          id="sign-up_submit-btn"
          className="cardo-btn submit-btn"
          disabled={
            // !code || loading ? true : false
            true
          }
        >
          {!loading ? (
            "Finish"
          ) : (
            <div className={"form-loader form-loader-active"}>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          )}
        </button>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  errors: state.errors,
});

export default connect(mapStateToProps)(LinkedInLogin);
