import React, { useRef } from "react";
import PropTypes from "prop-types";
import Validation from "../../landing-page/common/Validation";
import SignupValidation from "../sign-up/validation/SignupValidation";

const FileInput = ({
  onChange,
  name,
  error,
  stepKey,
  fileName,
  buttonText,
  text,
  id,
}) => {
  const fileInput = useRef();

  const openFilePicker = () => {
    fileInput.current.click();
  };

  const fileChangeHandler = (e) => {
    if (e.target.files[0]) {
      onChange(e, name, e.target.files[0], stepKey);
      e.target.value = "";
    } else {
      onChange(e, name, {}, stepKey);
      e.target.value = "";
    }
  };

  return (
    <div className="mb-5 position-relative">
      <label className="form-label" htmlFor={id}>
        {text}
      </label>
      {error && <SignupValidation message={error} />}

      <input
        id={id}
        type="file"
        name={name}
        ref={fileInput}
        onChange={fileChangeHandler}
        className="is-hidden"
        style={{ display: "none" }}
      />
      <div className="is-flex" style={{ alignItems: "center" }}>
        <button
          type="button"
          className="cardo-btn file-btn"
          onClick={openFilePicker}
        >
          {buttonText}
        </button>
        <p
          className="is-flex file-chosen-text"
          style={{ alignItems: "center" }}
        >
          {fileName}
          {fileName !== "No file chosen" && (
            <span
              onClick={(e) => onChange(e, name, {}, stepKey)}
              className="remove-file"
            >
              <i className="fas fa-times"></i>
            </span>
          )}
        </p>
      </div>
    </div>
  );
};

FileInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  error: PropTypes.string,
  stepKey: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
};

export default FileInput;
