export const helper = {
    mentorFormId: "212982757956373",
    // mentorFormId: '203288684811159',
    menteeFormId: "203287764414156",
    // baseUrl: `http://localhost:8000`,
    // linkedinRedirectUrl: "http://localhost:3000/linkedin",
    // baseUrl: "https://cardoapi.carthagos.com",
    // linkedinRedirectUrl: `https://cardo.carthagos.com/linkedin`,
    baseUrl: window.location.href.includes('www') ? "https://www.hellocardo.org/cardoapi" : "https://hellocardo.org/cardoapi",
    baseUrlSocket: 'https://hellocardo.org/cardoapi',
    linkedinRedirectUrl: window.location.href.includes('www') ? `https://www.hellocardo.org/linkedin` : `https://hellocardo.org/linkedin`,
    // baseUrl: 'http://api.sqya.io',
    linkedinClientId: "86s7hd70v1q0rg",
};
