import React, { useEffect } from "react";
import MenteeDashboard from "../../../components/dashboard/mentee-dashboard/MenteeDashboard";
import { useIsDesktop } from "../../../helper/useIsDesktop";
import NotAccessibleForMobile from "../../../components/landing-page/error-pages/NotAccessibleForMobile";

const MenteeDashboardRoute = () => {
  const { isDesktop } = useIsDesktop();

  useEffect(() => {
    document.title = "Cardo | Dashboard";
    return () => (document.title = "Cardo");
  }, []);
  return (
    <div>{isDesktop ? <MenteeDashboard /> : <NotAccessibleForMobile />}</div>
  );
};

export default MenteeDashboardRoute;
