import React, { useEffect, useState } from "react";
import axios from "axios";
import FindMentorSection6 from "./FindMentorSection6";
import Collapse from "./collapse/Collapse";
import FindMentorSection6Mobile from "./FindMentorSection6Mobile";
import FindMentorSection5Mobile from "./FindMentorSection5Mobile";
import { helper } from "../../../helper/helper";

const FindMentorSection4 = () => {
  const [industyClickedId, setIndustyClickedId] = useState(1);
  const [mentorData, setMentorData] = useState([]);

  function industryClick(x) {
    setIndustyClickedId(x);
    var loc = document.location.toString().split("#")[0];
    document.location = loc + "#step2";
  }
  useEffect(() => {
    onTrailBtnClick("Strategy");
  }, []);
  const onTrailBtnClick = (currentTrail) => {
    axios
      .get(`${helper.baseUrl}/mentors/getmentorbytrail/${currentTrail}`)
      .then((res) => {
        setMentorData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const onTrailCardClick = (e) => {
    let trailCards = document.getElementsByClassName("trail-card");

    for (let card of trailCards) {
      card.classList.remove("trail-card-active");
    }
    e.target.classList.value.split(" ").includes("trail-card")
      ? e.target.classList.add("trail-card-active")
      : e.target.parentElement.classList.add("trail-card-active");

    let currentTrail =
      e.target.getAttribute("data-trail-area") === null
        ? e.target.parentElement.getAttribute("data-trail-area")
        : e.target.getAttribute("data-trail-area");

    onTrailBtnClick(currentTrail);
  };

  return (
    <div>
      <section className="find-mentor-section-four">
        <div className="find-mentor-section-four_container">
          <div className="find-mentor-section-four-desktop">
            <h2 className="section-heading">1. Choose an industry</h2>
            <h4 className="section-desc">
              Where do you want to take your career?
            </h4>
            <div className="cards-container">
              <div className="industry-card-container">
                <div
                  className={
                    1 === industyClickedId
                      ? "industry-card active-card"
                      : "industry-card"
                  }
                >
                  <div>
                    <h4 className="card-heading">Consulting</h4>
                    <div className="card-desc">
                      <span>- Strategy </span>
                      <span>- Operations</span>
                      <span>- Human Capital</span>
                      <span>- Digital</span>
                      <span>- Technology</span>
                    </div>
                  </div>
                  <div className="card-btn-container">
                    <button
                      className="card-btn"
                      onClick={() => industryClick(1)}
                    >
                      Select
                    </button>
                  </div>
                </div>
                <div
                  className={
                    2 === industyClickedId
                      ? "industry-card active-card"
                      : "industry-card"
                  }
                >
                  <div>
                    <h4 className="card-heading">Finance</h4>
                    <div className="card-desc">
                      <span>- Investment Banking</span>
                      <span>- Private Equity</span>
                      <span>- Venture Capital</span>
                      <span>- Corporate Finance / FP&A</span>
                    </div>
                  </div>
                  <div className="card-btn-container">
                    <button
                      className="card-btn"
                      onClick={() => setIndustyClickedId(1)}
                    >
                      Coming Soon
                    </button>
                  </div>
                </div>
                <div
                  className={
                    3 === industyClickedId
                      ? "industry-card active-card"
                      : "industry-card"
                  }
                >
                  <div>
                    <h4 className="card-heading">Product</h4>
                    <div className="card-desc">
                      <span>- Product Management</span>
                      <span>- Product Marketing</span>
                      <span>- Product Design</span>
                    </div>
                  </div>
                  <div className="card-btn-container">
                    <button
                      className="card-btn"
                      onClick={() => setIndustyClickedId(1)}
                    >
                      Coming Soon
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="find-mentor-section-four-mobile">
            <h2 className="section-heading">1. Choose an industry</h2>
            <h4 className="section-desc">
              Where do you want to take your career?
            </h4>
            <FindMentorSection5Mobile />
          </div>
        </div>
      </section>
      <section className="find-mentor-section-five" id="step2">
        <div className="find-mentor-section-five_container">
          <div className="find-mentor-section-five-desktop">
            <h2 className="section-five-heading">2. Select a trail</h2>
            <h4 className="section-five-desc">
              What do you want to specialize in?
            </h4>
            <div className="trail-cards-container">
              <div className="trail-card-container">
                <div
                  data-trail-area="Strategy"
                  className="trail-card trail-card-active"
                  onClick={onTrailCardClick.bind(this)}
                >
                  <div className="trail-card-heading">Strategy</div>
                  <p className="trail-card-desc">
                    Help companies make decisions that shape the future
                  </p>
                </div>
                <div
                  data-trail-area="Operations"
                  className="trail-card"
                  onClick={onTrailCardClick.bind(this)}
                >
                  <div className="trail-card-heading">Operations</div>
                  <p className="trail-card-desc">
                    Help companies operate and run their businesses more
                    efficiently{" "}
                  </p>
                </div>
                <div
                  data-trail-area="Human Capital"
                  className="trail-card"
                  onClick={onTrailCardClick.bind(this)}
                >
                  <div className="trail-card-heading">Human Capital</div>
                  <p className="trail-card-desc">
                    Help companies grow talent and get the best from their
                    people{" "}
                  </p>
                </div>
                <div
                  data-trail-area="Digital"
                  className="trail-card"
                  onClick={onTrailCardClick.bind(this)}
                >
                  <div className="trail-card-heading">Digital</div>
                  <p className="trail-card-desc">
                    Bring creativity and technology together to build new
                    experiences{" "}
                  </p>
                </div>
                <div
                  data-trail-area="Technology"
                  className="trail-card hello"
                  onClick={onTrailCardClick.bind(this)}
                >
                  <div className="trail-card-heading">Technology</div>
                  <p className="trail-card-desc">
                    Help companies power their business with the help of
                    technology{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="find-mentor-section-five-mobile">
            <h2 className="section-five-heading">2. Select a trail</h2>
            <h4 className="section-five-desc">
              What do you want to specialize in?
            </h4>
            <div className="collapse-container">
              <div>
                <Collapse
                  onTrailBtnClick={onTrailBtnClick}
                  panels={[
                    {
                      label: "Strategy",
                      content:
                        "Help companies make decisions that shape the future",
                    },
                    {
                      label: "Operations",
                      content:
                        "Help companies operate and run their businesses more efficiently",
                    },
                    {
                      label: "Human Capital",
                      content:
                        "Help companies grow talent and get the best from their people",
                    },
                    {
                      label: "Digital",
                      content:
                        "Bring creativity and technology together to build new experiences",
                    },
                    {
                      label: "Technology",
                      content:
                        "Help companies power their business with the help of technology",
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="find-mentor-section-six" id="step3">
        <div className="find-mentor-section-six_container">
          <h2 className="find-mentor-section-six-heading">
            3. Find your Mentor
          </h2>
          <h4 className="find-mentor-section-six-desc">
            Get the advice you need to succeed
          </h4>
          <div className="find-mentor-section-six-desktop">
            <FindMentorSection6 mentorData={mentorData} />
          </div>
          <div className="find-mentor-section-six-mobile">
            <FindMentorSection6Mobile mentorData={mentorData} />
          </div>
        </div>
      </section>
    </div>
  );
};

export default FindMentorSection4;
