import axios from "axios";
import { helper } from "../../../../helper/helper";
import {
  MENTEE_DASHBOARD_HOME,
  MENTEE_DASHBOARD_FIND_MENTOR,
  MENTEE_DASHBOARD_MY_SESSIONS,
  GET_CURRENT_USER,
} from "../../../types/types";
import { throwCustomError } from "../../errorActions";

export const getCurrentUser = () => (dispatch) => {
  axios
    .get(`${helper.baseUrl}/users/mentee/dashboard/getUserInfo`, {
      withCredentials: true,
    })
    .then((res) => {
      dispatch({
        type: GET_CURRENT_USER,
        payload: res.data,
      });
    })
    .catch((err) => console.log(err.response));
};
export const getDashboardHomeData = () => (dispatch) => {
  axios
    .get(
      `${helper.baseUrl}/users/mentee/dashboard/getMenteeDashboardHomePage`,
      { withCredentials: true }
    )
    .then((res) =>
      dispatch({
        type: MENTEE_DASHBOARD_HOME,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch(
        throwCustomError(
          "somethingWentWrong",
          err.response.status,
          err.response.data
        )
      )
    );
};
export const getDashboardFindMentorData = () => (dispatch) => {
  axios
    .get(
      `${helper.baseUrl}/users/mentee/dashboard/getMenteeDashboardFindMentorPage`,
      { withCredentials: true }
    )
    .then((res) =>
      dispatch({
        type: MENTEE_DASHBOARD_FIND_MENTOR,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch(
        throwCustomError(
          "somethingWentWrong",
          err.response.status,
          err.response.data
        )
      )
    );
};

export const getDashboardMySessionsData = () => (dispatch) => {
  axios
    .get(
      `${helper.baseUrl}/users/mentee/dashboard/getMenteeDashboardMySessionsPage`,
      { withCredentials: true }
    )
    .then((res) =>
      dispatch({
        type: MENTEE_DASHBOARD_MY_SESSIONS,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch(
        throwCustomError(
          "somethingWentWrong",
          err.response.status,
          err.response.data
        )
      )
    );
};
