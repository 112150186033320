import React, { useEffect } from "react";
import MenteeProfile from "../../../components/dashboard/profile/MenteeProfile";
import Footer from "../../../components/landing-page/layout/Footer";

const MenteeProfileRoute = () => {
  useEffect(() => {
    document.title = "Cardo | Edit Profile";
    return () => (document.title = "Cardo");
  }, []);
  return (
    <div>
      <MenteeProfile />
      <Footer wavebg={"white"} bgcolor={"#5555DB"} copywritebg={"#4D4DC6"} />
    </div>
  );
};

export default MenteeProfileRoute;
