import React, { useEffect, useState } from "react";
//import PanelGroup from "react-panelgroup";
import { adminDashboardCalendly } from "../../../../helper/admin/adminDashboardCalendly";
import CardoButton from "../../../common/btn/CardoButton";
import AdminResizableTable from "../table/AdminResizableTable";
import "../../../../styles/dashboard/admin-dasboard/calendly-tab/calendly-tab.css";
import CalendlyInnerTabs from "./calendly-tab-components/CalendlyInnerTabs";
import CalendlyCardContainer from "./calendly-tab-components/CalendlyCardContainer";
import { getCalendlyTabInfo } from "../../../../state-management/actions/admin/adminDashboard";
import {
  postRevokeReq,
  postInviteReq,
  postMentorAccpetInvitation,
} from "../../../../state-management/actions/dashboard/mentee-dashboard/calendlyActions";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Toast from "../../../common/alerts/Toast";
import { clearErrors } from "../../../../state-management/actions/errorActions";
import { cleanSuccess } from "../../../../state-management/actions/successActions";
const CalendlyTab = (props) => {
  const [calendlyTableData, setCalendlyTableData] = useState();
  const [calendlyRevokeData, setCalendlyRevokeData] = useState([]);
  const [calendlyActiveData, setCalendlyActiveData] = useState([]);
  const [calendlyPipelineData, setCalendlyPipelineData] = useState([]);
  const [activeTab, setActiveTab] = useState("");
  const [currentTableData, setCurrentTableData] = useState([]);
  const [searchFilter, setSearchFilter] = useState("");
  const [revokeStatus, setRevokeStatus] = useState();
  const [inviteStatus, setInviteStatus] = useState();
  const [currentDataIndex, setCurrentDataIndex] = useState();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    //on succes (revoke or invite) fire this function.
    props.getCalendlyTabInfo();
    return () => {
      props.clearErrors();
      props.cleanSuccess();
    };
  }, []);
  useEffect(() => {
    setLoading(false);
    let newData = [];
    if (props.getCalendlyTab) {
      newData = props.getCalendlyTab
        .filter((item, index) => {
          return item.status === "accepted";
        })
        .map((item) => {
          return {
            id: item._id,
            trail: item.profile_info.trail.join(", "),
            first: item.first_name,
            last: item.last_name,
            company: item.profile_info.company,
            email: item.email,
            hours: item.hours, //ms
            last_login: new Date(item.status_time), //new Date('')
            last_call: new Date(item.last_call),
            "#_sessions": item.total_raters || 0,
            status: item.calendly_info.status || "",
            rating: `${item.total_rate / item.total_raters} / 5`,
          };
        });
      return setCalendlyTableData(newData);
    }
  }, [props.getCalendlyTab]);
  useEffect(() => {
    setCalendlyPipelineData([]);
    setCalendlyRevokeData([]);
    setCalendlyActiveData([]);

    if (calendlyTableData) {
      let temp = calendlyTableData;
      temp?.forEach((data) => {
        if (data.status === "" || data.status === "pending") {
          setCalendlyPipelineData((prev) => [...prev, data]);
        } else if (data.status === "accepted") {
          setCalendlyActiveData((prev) => [...prev, data]);
        } else if (data.status === "rejected") {
          setCalendlyRevokeData((prev) => [...prev, data]);
        }
      });
    }
    return () => {
      setCalendlyPipelineData([]);
      setCalendlyRevokeData([]);
      setCalendlyActiveData([]);
    };
  }, [calendlyTableData]);
  useEffect(() => {
    setCurrentTableData([]);
    if (activeTab === "active" && calendlyActiveData.length > 0) {
      return setCurrentTableData(calendlyActiveData);
    } else if (activeTab === "revoke" && calendlyRevokeData.length > 0) {
      return setCurrentTableData(calendlyRevokeData);
    } else if (activeTab === "pipeline" && calendlyPipelineData.length > 0) {
      return setCurrentTableData(calendlyPipelineData);
    }
    if (searchFilter) {
      setCurrentTableData((prev) =>
        prev.filter((val) => {
          for (let key in val) {
            if (
              key !== "last_login" &&
              key !== "last_call" &&
              val[key]
                .toString()
                .toLowerCase()
                .includes(searchFilter.toLowerCase())
            ) {
              return true;
            }
          }
          return false;
        })
      );
    }
  }, [
    activeTab,
    searchFilter,
    calendlyRevokeData,
    calendlyActiveData,
    calendlyPipelineData,
  ]);

  useEffect(() => {
    if (props.putRevoke) {
      setRevokeStatus(props.putRevoke.msg);
    }
    if (props.putInvite) {
      setInviteStatus(props?.putInvite?.msg);
    }
    //add for invite accordingly
    if (props?.putInvite?.msg === "Connected to Calendly" && loading) {
      props.getCalendlyTabInfo();
    }
    if (props?.putRevoke?.msg === "success" && loading) {
      props.getCalendlyTabInfo();
    }
    if (props?.postAccept?.msg === "Mentor accepted" && loading) {
      props.getCalendlyTabInfo();
    }
  }, [props]);

  const handleRevokeAndActive = (e, currentDataIndex) => {
    props.cleanSuccess();
    props.clearErrors();
    let elemenetId = e.target.id;
    if (currentDataIndex) {
      const { id, email } = currentTableData[currentDataIndex];
      if (activeTab === "active" && elemenetId === "revokeBtn") {
        setLoading(true);
        setCalendlyTableData();
        props.postRevokeReq({ id });
      } else if (
        (activeTab === "revoke" || activeTab === "pipeline") &&
        elemenetId === "inviteBtn"
      ) {
        setLoading(true);
        setCalendlyTableData();
        props.postInviteReq({
          id,
          email,
        });
      } else if (
        activeTab === "pipeline" &&
        elemenetId === "acceptCalendlyBtn"
      ) {
        setLoading(true);
        setCalendlyTableData();
        props.postMentorAccpetInvitation({
          id,
        });
      }
    }
  };

  const setBtn = () => {
    if (activeTab === "active" || activeTab === "revoke") {
      return (
        <CardoButton
          text={activeTab === "active" ? "Revoke" : "Invite"}
          width="60px"
          height="24px"
          id={activeTab === "active" ? "revokeBtn" : "inviteBtn"}
          className="transparent_btn_lightblue admin_dashboard_btn"
          margin="0"
        />
      );
    }
  };
  return (
    <div>
      <h3 className="admin_applications_header">Calendly Seat Management</h3>
      {props?.errors?.msg && <Toast type="error" msg={props?.errors?.msg} />}
      {props?.success?.success && <Toast type="success" msg={"Updated!"} />}

      <CalendlyCardContainer />
      <div className="calendly-header-container">
        <h3>Seats</h3>
        <input
          type="text"
          value={searchFilter}
          placeholder="Search"
          onChange={(e) => setSearchFilter(e.target.value)}
        />
      </div>
      <CalendlyInnerTabs activeTab={activeTab} handleActiveTab={setActiveTab} />

      {currentTableData.length > 0 && (
        <AdminResizableTable
          headers={Object.keys(currentTableData[0])}
          minCellWidth={90}
          data={currentTableData}
          type={activeTab}
          setCurrentDataIndex={setCurrentDataIndex}
          hiddenColumns={["id", "status"]}
          lastColumnComponent={setBtn()}
          checkPipeline={true}
          handler={handleRevokeAndActive}
          sortedColumns={["hours", "last_login", "last_call", "#_sessions"]}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  getCalendlyTab: state.admin.getCalendlyTab,
  putRevoke: state.admin.putRevoke,
  putInvite: state.admin.putInvite,
  postAccept: state.admin.postAccept,
  loading: state.loading,
  errors: state.errors,
  success: state.success,
});
export default connect(mapStateToProps, {
  getCalendlyTabInfo,
  postRevokeReq,
  postInviteReq,
  clearErrors,
  cleanSuccess,
  postMentorAccpetInvitation,
})(withRouter(CalendlyTab));
