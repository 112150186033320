import io from 'socket.io-client';
import { helper } from "./helper";

export let socket = null;
let url
if (window.location.href.includes('www')) {
    url = 'https://www.hellocardo.org'
} else {
    url = 'https://hellocardo.org'
}
export const socketManager = {

    connectSocket: () => {
        if (!socket) {
            let opts = {
                withCredentials: true,

            }
            socket = io(url, opts);

            socket.on('connect', (data) => {
                console.log('CONNECTED', socket);
            })


        }
    },
    socket,
    getSocket: () => {
        socketManager.connectSocket();
        return socket;
    },
    disconnectSocket: () => {
        if (socket) {
            socket.disconnect();
        }
    }
}