import React, { Fragment } from "react";

const Radio = (props) => {
  const {
    type = "radio",
    name,
    inputValue,
    onRadioChange,
    error,
    id,
    value,
    disabled,
    label,
  } = props;
  return (
    <Fragment>
      <span className="form-radio-item">
        <span className="dragger-item" />
        <input
          id={id}
          className="form-radio validate[required]"
          type={type}
          name={name}
          value={inputValue}
          onChange={onRadioChange}
          checked={inputValue === value}
          disabled={disabled}
        />
        <label className={error ? "radio is-danger" : "radio"} htmlFor={id}>
          {label}
        </label>{" "}
        <div className="check" />
      </span>
    </Fragment>
  );
};

export default Radio;
