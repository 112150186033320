import React, { useEffect, useState } from "react";

import "../../../../../../styles/dashboard/mentor-dashboard/review.css";
import { postData } from "../../../../../../state-management/actions/dashboard/mentor-dashboard/mentorDashboardActions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import MenteePopup from "./review-popup-components/MenteePopup";
import MentorPopup from "./review-popup-components/MentorPopup";
function Review(props) {
  const [rating, setrating] = useState(0);
  //mentor rating
  const [mentorRating, setMentorRating] = useState(0);

  const [answer1, setanswer1] = useState("");
  const [answer2, setanswer2] = useState("");
  //mentor answer
  const [mentorAnswer, setMentorAnswer] = useState("");
  const [milliseconds, setMilliseconds] = useState(0);
  const [isSubmit, setIsSubmit] = useState(false);

  function handleChangeFirst(e) {
    setanswer1({ value1: e.target.value });
  }
  function handleChangeSecond(e) {
    setanswer2({ value2: e.target.value });
  }

  //mentor change select handler
  function handleChangeMentorAnswer(e) {
    setMentorAnswer(e.target.value);
  }
  //mentor rating change handler
  const mentorRatingChanged = (newRating) => {
    return setMentorRating(newRating);
  };
  const ratingChanged = (newRating) => {
    return setrating(newRating);
  };

  useEffect(() => {
    if (props.postReview && Object.keys(errObj).length === 0) {
      setIsSubmit(true);
      if (props.currentUser.type === "mentor") {
        window.location.href = "/mentor/dashboard";
      } else {
        window.location.href = "/mentee/dashboard";
      }
    }
  }, [props.postReview]);

  useEffect(() => {
    if (props.total) {
      let time = props.total.split(":");
      setMilliseconds(
        (Number(time[0] * 3600) + Number(time[1] * 60) + Number(time[2])) * 1000
      );
    }
  }, [props.total]);

  //validation for the form
  const [errors, setErrors] = useState();
  let errObj = {};
  const validation = () => {
    errObj = {};
    if (props.currentUser.type === "mentor") {
      if (mentorRating === 0) {
        errObj.rating = { ...errObj, msg: "Rating is required." };
      }
      if (mentorAnswer === "") {
        errObj.answer = { ...errObj, msg: "Field is required." };
      }
    } else {
      if (rating === 0) {
        errObj.rating = { ...errObj, msg: "Rating is required." };
      }
      if (answer1 === "") {
        errObj.answer1 = { ...errObj, msg: "Field is required." };
      }
      if (answer2 === "") {
        errObj.answer2 = { ...errObj, msg: "Field is required." };
      }
    }
  };

  function goback() {
    validation();
    //add the mentor data

    if (Object.keys(errObj).length === 0) {
      let saveTime = props.total.split(":");
      let newTime =
        (Number(saveTime[0] * 3600) +
          Number(saveTime[1] * 60) +
          Number(saveTime[2])) *
        1000;

      const data =
        props.currentUser.type === "mentor"
          ? {
              sessionId: props.session._id,
              mentor_rating: mentorRating,
              mentor_call_duration: newTime,
              mentor_qa: [
                {
                  question: "Was your mentee prepared for their session?",
                  answer: mentorAnswer,
                },
              ],
            }
          : {
              sessionId: props.session._id,
              mentee_rating: rating,
              mentee_call_duration: newTime,
              mentee_qa: [
                {
                  question: "Did you find your mentor knowledgeable?",
                  answer: answer1.value1,
                },
                {
                  question: "Did you find this session helpful?",
                  answer: answer2.value2,
                },
              ],
            };
      props.handleTimeOutEndCall(props.room);
      setIsSubmit(true);
      props.postData(data);
    } else {
      setErrors(errObj);
      setIsSubmit(false);
    }
  }

  return (
    <>
      {props?.currentUser?.type === "mentor" ? (
        <MentorPopup
          errObj={errors}
          session={props.session}
          handleChangeMentorAnswer={handleChangeMentorAnswer}
          isSubmit={isSubmit}
          goback={goback}
          total={props.total}
          mentorRatingChanged={mentorRatingChanged}
          closeModel={props.closeModel}
          isCallOver={props.isCallOver}
        />
      ) : (
        <MenteePopup
          session={props.session}
          errObj={errors}
          total={props.total}
          handleChangeFirst={handleChangeFirst}
          handleChangeSecond={handleChangeSecond}
          isSubmit={isSubmit}
          goback={goback}
          ratingChanged={ratingChanged}
          closeModel={props.closeModel}
          isCallOver={props.isCallOver}
        />
      )}
    </>
  );
}
const mapStateToProps = (state) => ({
  postReview: state.mentorDashboard.postReview,
  currentUser: state.auth.currentUser,
});
export default connect(mapStateToProps, { postData })(withRouter(Review));
