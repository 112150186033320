import React, { useEffect } from "react";
import Alert from "../components/landing-page/common/Alert";
import Home from "../components/landing-page/home/Home";
import Footer from "../components/landing-page/layout/Footer";
import Navbar from "../components/landing-page/layout/Navbar";

const HomeRoute = () => {
  useEffect(() => {
    document.title = "Cardo";
  }, []);
  return (
    <div>
      <Alert />

      <Navbar loginColor="#2C205B" />

      <Home />
      <Footer wavebg={"white"} bgcolor={"#5555DB"} copywritebg={"#4D4DC6"} />
    </div>
  );
};

export default HomeRoute;
