import React from "react";

const FindMentorPopUpFirstScreen = (props) => {
  const { mentor, nextStep } = props;
  return (
    <div className="display-flex flex-direction-column justify-content-center align-items-center">
      <div className="mentee-dashboard-find-mentor-modal-mentor-info">
        <div className="mentee-dashboard-find-mentor-modal-mentor-info-about-me">
          <div className="mentee-dashboard-find-mentor-modal-mentor-info-about-me-container">
            <div className="font-Neometric-Medium line-height font-size-16">
              About Me
            </div>
            <br />
            <p className="font-size-16 line-height">
              {mentor.profile_info.my_story}
            </p>
          </div>
        </div>
        <div className="mentee-dashboard-find-mentor-modal-mentor-info-mentorship-container">
          <div
            className="mentee-dashboard-find-mentor-modal-mentor-info-mentorship-focus"
            style={{ minHeight: "290px" }}
          >
            <div className="mt-20 mr-25 ml-25">
              <div
                className="font-Neometric-Medium line-height "
                style={{ marginBottom: "10px" }}
              >
                Mentorship Focus
              </div>
              <div>
                <div className="font-Neometric-Regular line-height font-size-14">
                  Trail
                </div>
                <div className="display-flex">
                  {mentor.profile_info.trail.map((area, index) => (
                    <div
                      key={index}
                      className="  btn-bg-darkblue mentee-dashboard-find-mentor-modal-mentor-info-mentorship-focus-trails "
                    >
                      {area}
                    </div>
                  ))}
                </div>
                <div className="font-Neometric-Regular line-height font-size-14 mt-10">
                  How I can help
                </div>
                <div className="mentee-dashboard-find-mentor-modal-mentor-info-mentorship-focus-help-tags-container">
                  {mentor.profile_info.support_areas.map((skill, index) => (
                    <div
                      key={index}
                      className="mentee-dashboard-find-mentor-modal-mentor-info-mentorship-focus-help-tags"
                    >
                      {skill}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div
            className="mentee-dashboard-find-mentor-modal-mentor-info-uniqueness"
            style={{ padding: "0" }}
          >
            <div className="m-25">
              <div className="font-Neometric-Medium">What Makes Me Unique</div>
              <div className="mentee-dashboard-find-mentor-modal-mentor-info-mentorship-focus-help-tags-container">
                {mentor.profile_info.what_makes_me_unique.map(
                  (whatMakesMeUniqueItem, index) => (
                    <div
                      key={index}
                      className="mentee-dashboard-find-mentor-modal-mentor-info-mentorship-focus-help-tags"
                    >
                      {whatMakesMeUniqueItem}
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="display-flex justify-content-center"
        style={{ marginBottom: "15px" }}
      >
        <button
          className="cardo-btn btn-bg-lightblue"
          style={{ margin: "30px 0 30px 0 " }}
          onClick={() => nextStep(null, null, "730px")}
        >
          Book Now
        </button>
      </div>
    </div>
  );
};

export default FindMentorPopUpFirstScreen;
