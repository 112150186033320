import React from "react";
// import moment from "moment";
import moment from "moment-timezone";
import FirstSession from "./FirstSession";
import NextSessions from "./NextSessions";
import PastSessions from "./PastSessions";

import noUpcomingSessionsImg from "../../../../../assets/dashboard/my-sessions/upcomingSessions.svg";
const MentorDashboardMySessionsComponent = (props) => {
  const {
    events,
    selectModalDetails,
    handleModalMentorDetails,
    handleCurrentLink,
  } = props;

  //check if upcoming sessions not undefined

  let sessionEventContent;

  let upcomingEvents = [];
  let pastEvents = [];
  let sortedEvents;
  if (events && events.length > 0) {
    sortedEvents = events.sort(function (a, b) {
      return a.start_time < b.start_time
        ? -1
        : a.start_time > b.start_time
        ? 1
        : 0;
    });
  }

  if (sortedEvents && sortedEvents.length > 0) {
    sortedEvents.forEach((event) => {
      const start = moment.tz(event.end_time, event.timezone);
      const now = moment.tz(new Date(Date.now()), event.timezone);
      if (new Date(start).valueOf() > new Date(now).valueOf()) {
        upcomingEvents.push(event);
      } else {
        pastEvents.push(event);
      }
    });
  }
  if (events && events.length > 0) {
    sessionEventContent = (
      <div>
        {upcomingEvents.length > 0 && (
          <div>
            <div
              style={{ paddingLeft: "40px" }}
              className="font-Neometric-Medium mt-25 mb-25"
            >
              Upcoming Session
            </div>
            <FirstSession
              selectModalDetails={selectModalDetails}
              upcomingSession={upcomingEvents[0]}
              handleModalMentorDetails={handleModalMentorDetails}
            />
          </div>
        )}
        {upcomingEvents.length > 1 && (
          <div>
            <div
              className="font-Neometric-Medium"
              style={{
                marginTop: "55px",
                marginBottom: "25px",
                paddingLeft: "40px",
                fontSize: "18px",
              }}
            >
              Next Sessions
            </div>
            {upcomingEvents.map(
              (upcomingSession, index) =>
                index > 0 && (
                  <NextSessions
                    key={index}
                    upcomingSession={upcomingSession}
                    selectModalDetails={selectModalDetails}
                    handleModalMentorDetails={handleModalMentorDetails}
                  />
                )
            )}
          </div>
        )}

        {pastEvents.length > 0 && (
          <div>
            <div
              className="font-Neometric-Medium"
              style={{
                marginTop: "40px",
                marginBottom: "25px",
                paddingLeft: "40px",
                fontSize: "18px",
              }}
            >
              Past Sessions
            </div>
            {pastEvents.map((upcomingSession, index) => (
              <PastSessions
                key={index}
                upcomingSession={upcomingSession}
                selectModalDetails={selectModalDetails}
                handleModalMentorDetails={handleModalMentorDetails}
              />
            ))}
          </div>
        )}
      </div>
    );
  } else {
    sessionEventContent = (
      <div className="mentee-dashboard-home-upcoming-sessions-empty">
        <div className="mentee-dashboard-home-upcoming-sessions-empty-container">
          <div>
            <img src={noUpcomingSessionsImg} alt="no upcoming sessions" />
          </div>
          <div className="font-Neometric-Regular">
            You have no upcoming sessions.
          </div>
          <div>
            {/*<button*/}
            {/*  onClick={() => handleCurrentLink("Find Mentor")}*/}
            {/*  className="cardo-btn cardo-btn-sm pink-btn mt-0 mb-0 ml-25 mr-25"*/}
            {/*>*/}
            {/*  Book Now*/}
            {/*</button>*/}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div>{sessionEventContent}</div>
    </div>
  );
};

export default MentorDashboardMySessionsComponent;
