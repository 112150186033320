

import { MENTEE_DASHBOARD_FIND_MENTOR, MENTEE_DASHBOARD_HOME, MENTEE_DASHBOARD_EDIT_PROFILE, MENTEE_DASHBOARD_MY_SESSIONS, MENTEE_DASHBOARD_NEW_IMAGE, MENTEE_DASHBOARD_MENTEE_DELETED, MENTEE_DASHBOARD_EVENT_SCHEDULED, GET_SUPPORT_AREAS } from "../../types/types";

const initialState = {
  loading: false,
  menteeDashboardHome: {},
  menteeDashboardFindMentor: [],
  menteeDashboardEditProfile: {},
  currentUser: {}
}

const menteeDashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case MENTEE_DASHBOARD_HOME:
      return {
        ...state,
        menteeDashboardHome: action.payload,
        loading: false,
      }
    case MENTEE_DASHBOARD_FIND_MENTOR:
      return {
        ...state,
        menteeDashboardFindMentor: action.payload,
        loading: false,
      }
    case MENTEE_DASHBOARD_EDIT_PROFILE:
      return {
        ...state,
        menteeDashboardEditProfile: action.payload,
        loading: false,
      }

    case MENTEE_DASHBOARD_MY_SESSIONS:
      return {
        ...state,
        menteeDashboardMySessions: action.payload,
        loading: false,
      }
    case MENTEE_DASHBOARD_EVENT_SCHEDULED:
      return {
        ...state,
        menteeDashboardEventScheduled: action.payload,
        loading: false,
      }
    case GET_SUPPORT_AREAS:
      return {
        ...state,
        supportAreas: action.payload,
        loading: false,
      }
    case MENTEE_DASHBOARD_NEW_IMAGE:
      return {
        ...state,
        menteeNewImage: action.payload,
        loading: false,
      }
    case MENTEE_DASHBOARD_MENTEE_DELETED:
      return {
        ...state,
        menteeNewImage: action.payload,
        loading: false,
      }

    default:
      return state;
  }


}

export default menteeDashboardReducer