import React, { useEffect, useRef, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import darkLogo from "../../../../../assets/dashboard/sidebar/Group 774.svg";
import setting from "../../../../../assets/mentor/setting.svg";
import end from "../../../../../assets/mentor/end.svg";
import document from "../../../../../assets/mentor/document.svg";
import emoji from "../../../../../assets/mentor/emoji.svg";
import "emoji-mart/css/emoji-mart.css";
import { Picker } from "emoji-mart";
import { Loader } from "rsuite";
import clock from "../../../../../assets/mentor/clock.svg";
import "../../../../../styles/dashboard/mentor-dashboard/mentor-video-call.css";
import useMediaTracks from "../../../../../hooks/useMediaTracks";
import ToggleAudioButton from "./video-call-screens/ToggleAudioButton";
import ToggleVideoButton from "./video-call-screens/ToggleVideoButton";
import useRoom from "../../../../../hooks/useRoom";
import NetworkQualityLevel from "./video-call-screens/NetworkQualityLevel";
import useHandleRoomDisconnection from "../../../../../hooks/useHandleRoomDisconnection";
import MainParticipant from "./video-call-screens/MainParticipant";
import ParticipantList from "./video-call-screens/ParticipantList";
import Timer from "./video-call-screens/Timer";
import { connect } from "react-redux";
import {
  getSingleSession,
  getVideoCallToken,
  resetVideoCallToken,
} from "../../../../../state-management/actions/dashboard/mentor-dashboard/mentorDashboardActions";
import ToggleScreenShareButton from "./video-call-screens/ToggleScreenShareButton";
import useChat from "../../../../../hooks/useChat";
import MessageList from "./video-call-screens/MessageList";
import Settings from "./video-call-screens/Settings";
import moment from "moment-timezone";
import Review from "./video-call-screens/Review";
import useLocalVideoToggle from "../../../../../hooks/useLocalVideoToggle";
import useLocalAudioToggle from "../../../../../hooks/useLocalAudioToggle";

const mapStateToProps = (state) => ({
  currentUser: state.auth.currentUser,
  videoCallToken: state.mentorDashboard.videoCallToken,
  videoCallTokenError: state.mentorDashboard.videoCallTokenError,
  session: state.mentorDashboard.session,
});
const beforeUnloadListener = (event) => {
  event.preventDefault();
  delete event["Are you sure you want to exit?"];
  return (event.returnValue = "Are you sure you want to exit?");
};
function MentorVideoCall(props) {
  const fileInputRef = useRef(null);
  let {
    getAudioAndVideoTracks,
    removeLocalAudioTrack,
    removeLocalVideoTrack,
    getLocalVideoTrack,
    isAcquiringLocalTracks,
    localTracks,
    removeAllTracks,
  } = useMediaTracks();
  let videoCallOptions = {
    bandwidthProfile: {
      video: {
        mode: "collaboration",
        dominantSpeakerPriority: "standard",
        // trackSwitchOffMode: undefined,
        contentPreferencesMode: "auto",
        clientTrackSwitchOffControl: "auto",
      },
    },
    dominantSpeaker: true,
    networkQuality: { local: 1, remote: 1 },
    maxAudioBitrate: 16000,
    preferredAudioCodecs: [{ codec: "VP8", simulcast: true }],
  };
  let {
    room,
    connect: videoConnect,
    isConnected,
  } = useRoom(localTracks, videoCallOptions);
  let localParticipant = room?.localParticipant;
  useHandleRoomDisconnection(
    room,
    removeLocalAudioTrack,
    removeLocalVideoTrack,
    false
  );
  useEffect(() => {
    props.resetVideoCallToken();
  }, [props.resetVideoCallToken]);

  useEffect(() => {
    return () => handleRoomDisconnect(room);
  }, [room]);

  const [messageBody, setMessageBody] = useState("");
  const [settingsModel, setSettingsModel] = useState(false);
  const [session, setSession] = useState(null);
  const [reviewModel, setReviewModel] = useState(false);
  const [isCallOver, setIsCallOver] = useState(false);
  useEffect(() => {
    getAudioAndVideoTracks()
      .then(() => {
        props.getVideoCallToken(props.computedMatch?.params?.session);
        props.getSingleSession(props.computedMatch?.params?.session);
      })
      .catch((error) => {
        console.log("Error acquiring local media:");
        console.dir(error);
      });
  }, [props.resetVideoCallToken]);

  let {
    chatClient,
    messages,
    conversation,
    connect: connectChat,
  } = useChat(room);

  useEffect(() => {
    let token = props.videoCallToken?.token;
    if (
      props.videoCallToken &&
      props.videoCallToken?.token &&
      !isAcquiringLocalTracks &&
      !isConnected
    ) {
      videoConnect(token, localTracks);
    }
    if (!chatClient && token) {
      connectChat(token);
    }
  }, [props.videoCallToken, localTracks]);

  useEffect(() => {
    if (props.session) {
      setSession(props.session);
    }
  }, [props.session, setSession]);

  useEffect(async () => {
    // TODO:- SHOW ERROR MESSAGE
    if (props.videoCallTokenError) {
      removeAllTracks();
      setTimeout(() => {
        window.location.href = "/mentor/dashboard";
      }, 1000);
    }
  }, [props.videoCallTokenError]);

  useEffect(() => {
    return window.addEventListener("beforeunload", beforeUnloadListener);
  }, []);
  useEffect(() => {
    if (reviewModel) {
      return window.removeEventListener("beforeunload", beforeUnloadListener);
    }
  }, [reviewModel]);

  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle(
    localTracks,
    getLocalVideoTrack,
    removeLocalVideoTrack,
    room
  );
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle(localTracks);

  const handleReturnKeyPress = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage(messageBody);
    }
  };
  const [isSendingFile, setIsSendingFile] = useState(false);
  const [fileSendError, setFileSendError] = useState(false);
  const [emojiPicker, setEmojiPicker] = useState(false);
  const handleFileChange = (event) => {
    const file = event.target.files?.[0];
    if (file) {
      var formData = new FormData();
      formData.append("userfile", file);
      setIsSendingFile(true);
      setFileSendError(null);
      conversation
        .sendMessage(formData)
        .catch((e) => {
          if (e.code === 413) {
            setFileSendError(
              "File size is too large. Maximum file size is 150MB."
            );
          } else {
            setFileSendError(
              "There was a problem uploading the file. Please try again."
            );
          }
          console.log("Problem sending file: ", e);
          setTimeout(() => {
            setFileSendError(null);
          }, 5000);
        })
        .finally(() => {
          setIsSendingFile(false);
        });
    }
  };
  const [timer, stoptimer] = useState(false);
  const [totaltime, settotaltime] = useState(null);

  const sessionTimer = (sessiontime) => {
    settotaltime(sessiontime);
  };

  const [trackStateAudio, setTrackStateAudio] = useState();
  const [trackStateVideo, setTrackStateVideo] = useState();

  const handleRoomDisconnect = (finalRoom) => {
    if (finalRoom) {
      // finalRoom.disconnect();
      // removeAllTracks();
      if (props?.videoCallToken?.userExist) {
        if (props.currentUser.type === "mentor") {
          window.location.href = "/mentor/dashboard";
        } else {
          window.location.href = "/mentee/dashboard";
        }
      } else {
        setReviewModel(true);
        //check the last video / audio and store it
        setTrackStateAudio(isAudioEnabled);
        setTrackStateVideo(isVideoEnabled);
        if (isAudioEnabled) {
          toggleAudioEnabled();
        }
        if (isVideoEnabled) {
          toggleVideoEnabled();
        }
      }
      stoptimer(true);
    }
  };
  const handleTimeOutEndCall = (finalRoom) => {
    if (finalRoom) {
      finalRoom.disconnect();
      removeAllTracks();
      setReviewModel(true);
      stoptimer(true);
    }
  };

  const handleChange = (event) => {
    setMessageBody(event.target.value);
  };

  const openSettingsModel = () => {
    setSettingsModel(!settingsModel);
  };

  const handleSendMessage = (message) => {
    if (room && conversation) {
      conversation.sendMessage(message.trim());
      setMessageBody("");
      setEmojiPicker(false);
    }
  };

  function getFormattedTime(time) {
    if (!time) return "";
    return moment(time)
      .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
      .format("hh:mm A");
  }

  return (
    <div className="mentor-video-call">
      {isSendingFile && (
        <div
          className="mentee-dashboard-find-mentor-modal"
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.1)",
          }}
        >
          <div
            className="form-loader form-loader-active"
            style={{
              position: "fixed",
              top: "50%",
            }}
          >
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}
      <div className="container_wide">
        <div className="mentor-video-header-main">
          <div className="mentor-video-call-main-left">
            <div
              className="mentor-video-call-header-row"
              style={{ position: "relative" }}
            >
              <div className="dashboard-logo">
                <div>
                  <img src={darkLogo} alt="" />
                </div>
              </div>
              <div className="mentor-video-call-right-header">
                <div className="start-and-time">
                  start{" "}
                  <b>
                    {" "}
                    {getFormattedTime(
                      session?.start_time,
                      session?.timezone
                    )}{" "}
                  </b>{" "}
                  end{" "}
                  <b>
                    {" "}
                    {getFormattedTime(
                      session?.end_time,
                      session?.timezone
                    )}{" "}
                  </b>
                </div>
                <div className="mentor-setting-button">
                  <button onClick={openSettingsModel}>
                    <img src={setting} alt="icon" />
                  </button>
                  {settingsModel && (
                    <Settings
                      closeModel={() => setSettingsModel(false)}
                      localTracks={localTracks}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="mentor-video-main font-Neometric-Medium">
              {room && session ? (
                <>
                  <MainParticipant room={room} />
                  <div className="mentor-video-time">
                    <img src={clock} alt="icon" />
                    <Timer
                      user={props.videoCallToken}
                      session={session}
                      stoptimer={timer}
                      setTimer={stoptimer}
                      handleTimeOutEndCall={handleTimeOutEndCall}
                      sessionTimer={sessionTimer}
                      room={room}
                      setIsCallOver={setIsCallOver}
                    />
                  </div>
                </>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height:
                      "calc(102px + (620 - 102) * ((100vw - 320px) / (1920 - 320)))",
                  }}
                >
                  <Loader style={{ margin: "0 auto" }} />
                  <div
                    style={{
                      marginTop: "20px",
                      color: "white",
                    }}
                  >
                    Connecting
                  </div>
                </div>
              )}
              {localParticipant && (
                <div className="mentor-video-network ">
                  <NetworkQualityLevel participant={localParticipant} />
                </div>
              )}
              {props.videoCallTokenError}
              <div className="mentor-opposite-video">
                {room && <ParticipantList room={room} />}
              </div>
            </div>
            <div className="mentor-action-button">
              <div className="video-call-button-center">
                <ToggleAudioButton
                  localTracks={localTracks}
                  disabled={isAcquiringLocalTracks}
                />
                <ToggleVideoButton
                  localTracks={localTracks}
                  getLocalVideoTrack={getLocalVideoTrack}
                  room={room}
                  removeLocalVideoTrack={removeLocalVideoTrack}
                  disabled={isAcquiringLocalTracks}
                />
                <ToggleScreenShareButton
                  room={room}
                  disabled={isAcquiringLocalTracks}
                />
              </div>
              <div className="end-button-group action-button-group">
                <button id="btnend" onClick={() => handleRoomDisconnect(room)}>
                  <img className="end" src={end} alt="icon" />
                </button>
                <span>End</span>
              </div>
            </div>
          </div>
          <div className="mentor-video-call-main-right">
            <div className="mentor-video-sidebar">
              <div className="mentorship-name">
                <span>Mentorship Session With</span>
                <h3>
                  {props.currentUser.type === "mentor"
                    ? `${
                        session?.mentee?.first_name
                      } ${session?.mentee?.last_name.substr(0, 1)}.`
                    : `${
                        session?.mentor?.first_name
                      } ${session?.mentor?.last_name.substr(0, 1)}.`}
                </h3>
              </div>

              <div className="mentor-chat-box">
                <div className="content">
                  <div className="chet-box-header">
                    <p style={{ color: "red" }}>{fileSendError}</p>
                    <h3>Chat</h3>
                  </div>
                  <div className="chet-box-body">
                    <div className="conversation-box">
                      <MessageList messages={messages} room={room} />
                    </div>
                  </div>
                </div>
                <div className="chet-box-footer">
                  <div className="footer-content">
                    <div className="message-type-box">
                      {room && conversation && (
                        <input
                          type="text"
                          placeholder="Type your message..."
                          onKeyPress={handleReturnKeyPress}
                          onChange={handleChange}
                          value={messageBody}
                        />
                      )}
                    </div>
                    <div
                      className="type-box-media"
                      style={{ position: "relative" }}
                    >
                      <button
                        onClick={() => fileInputRef?.current.click()}
                        disabled={isSendingFile}
                      >
                        <img src={document} alt="icon" />
                      </button>
                      <input
                        ref={fileInputRef}
                        onChange={(e) => handleFileChange(e)}
                        type="file"
                        style={{ display: "none" }}
                      />
                      <button onClick={() => setEmojiPicker(!emojiPicker)}>
                        <img src={emoji} alt="icon" />
                      </button>
                      {emojiPicker && (
                        <Picker
                          title="Pick your emoji…"
                          style={{
                            position: "absolute",
                            right: "-35px",
                            bottom: "50px",
                            zIndex: "10",
                          }}
                          onSelect={(e) =>
                            setMessageBody(`${messageBody}${e.native}`)
                          }
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {reviewModel && (
        <Review
          closeModel={() => {
            setReviewModel(false);
            if (trackStateAudio) {
              toggleAudioEnabled();
            }
            if (trackStateVideo) {
              toggleVideoEnabled();
            }
          }}
          history={props.history}
          session={session}
          total={totaltime}
          handleTimeOutEndCall={handleTimeOutEndCall}
          room={room}
          isCallOver={isCallOver}
          user={props.videoCallToken}
        />
      )}
    </div>
  );
}

export default connect(mapStateToProps, {
  getVideoCallToken,
  getSingleSession,
  resetVideoCallToken,
})(withRouter(MentorVideoCall));
