import React from "react";
import { Link } from "react-router-dom";
import darkLogo from "../../../../assets/darkLogo.svg";
import leftColMainImg from "../../../../assets/sign-up/Group 459.svg";
import "../../../../styles/signup/sign-up-thankyou.css";
const SignupThankyou = () => {
  return (
    <div className="row sign-up-row">
      <div className="col-6 sign-up-thankyou-leftCol">
        <div className="sign-up-leftCol-container">
          <div className="signup-logo-container">
            <Link to="/">
              <img src={darkLogo} alt="" />
            </Link>
          </div>
          <div className="leftCol-mainImg-container">
            <img src={leftColMainImg} alt="" />
          </div>
        </div>
      </div>
      <div className="col-6 sign-up-rightCol">
        <div className="sign-up-rightCol-container">
          <div className="sign-up-rightCol-thankyou-container">
            <div className="sign-up-rightCol-thankyou-container-desc">
              <h1 className="sign-up-rightCol-thankyou-title">Thank You!</h1>
              <p className="sign-up-rightCol-thankyou-desc">
                Our team is working hard to carefully <br /> review your
                application and get back to <br /> you as soon as possible. If
                you are a <br /> good fit, we will be reaching out to share{" "}
                <br /> next steps. <br /> <br /> Feel free to reach out to us at{" "}
                <br />
                support@hellocardo.org if you have <br /> any questions!
              </p>
              <Link style={{ textDecoration: "none" }} to="/">
                <button
                  style={{ backgroundColor: "#5555DB", color: "white" }}
                  className="cardo-btn"
                >
                  Continue
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupThankyou;
