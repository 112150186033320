import React from "react";

const NumberField = (props) => {
  const {
    id,
    label,
    placeholder,
    value,
    onChange,
    name,
    disabled,
    toolTip,
    step,
    minValue,
    maxValue,
    errorMsg,
  } = props;
  return (
    <div className="mb-5 position-relative">
      <div className="display-flex">
        <label className="form-label" htmlFor={id}>
          {label}
        </label>
        {toolTip && (
          <div className="tooltip">
            ?<div className="tooltiptext">{toolTip}</div>
          </div>
        )}
      </div>
      <input
        id={id}
        className="form-number-field form-textbox"
        type="number"
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        step={step}
        min={minValue}
        max={maxValue}
        autoComplete="off"
        disabled={disabled}
      />
      {errorMsg && <div className="signup-form-validation">{errorMsg}</div>}
    </div>
  );
};

export default NumberField;
