import React, { useEffect } from "react";
import MentorProfile from "../../../components/dashboard/profile/MentorProfile";
import Footer from "../../../components/landing-page/layout/Footer";

const MentorProfileRoute = () => {
  useEffect(() => {
    document.title = "Cardo | Edit Profile";
    return () => (document.title = "Cardo");
  }, []);
  return (
    <div>
      <MentorProfile />
      <Footer wavebg={"white"} bgcolor={"#5555DB"} copywritebg={"#4D4DC6"} />
    </div>
  );
};

export default MentorProfileRoute;
