import React, { Component } from "react";

export class Accordian extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accordionItems: [],
    };
  }
  componentWillMount() {
    let accordion = [];

    this.props.data.forEach((i) => {
      accordion.push({
        title: i.title,
        content: i.content,
        open: false,
      });
    });
    this.setState({
      accordionItems: accordion,
    });
  }
  click = (i) => {
    const newAccordion = this.state.accordionItems.slice();
    const index = newAccordion.indexOf(i);

    newAccordion[index].open = !newAccordion[index].open;
    this.setState({ accordionItems: newAccordion });
  };

  render() {
    return (
      <div className="accordion">
        {this.state.accordionItems.map((i) => (
          <div key={this.state.accordionItems.indexOf(i)}>
            <div className="title" onClick={this.click.bind(null, i)}>
              <div className="arrow-wrapper">
                <h3>
                  <i
                    className={
                      i.open
                        ? "fa fa-angle-right fa-rotate-90"
                        : "fa fa-angle-right"
                    }
                  ></i>
                </h3>
              </div>
              {i.title}
            </div>
            <div className={i.open ? "content content-open" : "content"}>
              <div
                className={
                  i.open ? "content-text content-text-open" : "content-text"
                }
              >
                <div> {i.content}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default Accordian;
