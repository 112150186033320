import React, { useEffect, useState } from "react";
import confirmImage from "../../../../../assets/dashboard/mentee-dashboard/mentee-dashboard-find-mentor/Vector-9.svg";

import moment from "moment-timezone";
const MenteeDashboardConfirmationPopup = (props) => {
  function closeModalDone(e) {
    e.stopPropagation();
    props.closeModalDone();
  }

  return (
    <div
      className="mentee-dashboard-find-mentor-confimation-modal"
      onClick={closeModalDone}
    >
      <div
        className="mentee-dashboard-find-mentor-confirmation-modal-content"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="position-relative">
          <div className="mentee-dashboard-find-mentor-confirmation-modal-header">
            <div className="mentee-dashboard-find-mentor-confirmation-modal-header-container">
              <div className="display-flex align-items-center justify-content-center">
                <div className="display-flex flex-direction-column align-items-center justify-content-center">
                  <div className="mb-10 mt-10">
                    <img src={confirmImage} alt="" />
                  </div>
                  <h3 style={{ color: "white" }}>Confirmed</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="mentee-dashboard-find-mentor-modal-confirmation-popup-content">
            <div
              className="display-flex flex-direction-column align-items-center justify-content-center"
              style={{ margin: "35px 0" }}
            >
              <div className="display-flex align-items-center">
                <div
                  className="mentee-dashboard-find-mentor-modal-header-mentor-avatar mr-25"
                  style={{
                    backgroundImage: `url('${props.mentor.img_url}')`,
                  }}
                />
                <div>
                  <h3> {props.mentor.first_name}</h3>
                  <div
                    className="font-Neometric-Regular font-size-14"
                    style={{ marginTop: "20px" }}
                  >
                    {props.menteeDashboardProfileInfo?.preferred_trail}
                    <i
                      className="fas fa-caret-right"
                      style={{ margin: "0px 5px" }}
                    ></i>{" "}
                    {props.mentor.profile_info.consulting_firm}{" "}
                  </div>
                </div>
              </div>

              <div className="mentee-dashboard-find-mentor-confirmation-modal-schedule">
                <div>
                  <div className="font-Neometric-Medium">Date</div>
                  <div className="font-Neometric-Regular">
                    {typeof props.event !== "undefined"
                      ? moment(props.event.event_time)
                          .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
                          .format("MM/DD/YY")
                      : "Loading..."}
                  </div>
                </div>
                <div>
                  <div className="font-Neometric-Medium">Time</div>
                  <div className="font-Neometric-Regular">
                    {typeof props.event !== "undefined"
                      ? moment(props.event.event_time)
                          .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
                          .format("LT")
                      : "Loading..."}
                  </div>
                </div>
                <div>
                  <div className="font-Neometric-Medium">Session</div>
                  <div className="font-Neometric-Regular">
                    {typeof props.event !== "undefined"
                      ? props.event.type
                      : "Loading..."}
                  </div>
                </div>
              </div>
              <div className="display-flex flex-direction-column justify-content-center align-items-center">
                <a
                  className=" mentee-dashboard-find-mentor-confirmation-modal-cancel-link"
                  href={props?.event?.cancel_url}
                  target="_blank"
                >
                  Cancel
                </a>
                <button
                  className="cardo-btn btn-bg-lightblue "
                  style={{ margin: "25px auto" }}
                  onClick={closeModalDone}
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenteeDashboardConfirmationPopup;
