import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import Sidebar from "../../dashboard/dashboard-layout/Sidebar";
import "../../../styles/dashboard/admin-dasboard/ru-admin-dashboard/ru-admin-dashboard.css";
import applicationIcon from "../../../assets/dashboard/sidebar/adminDashboardSidebar/applicationTab.svg";
import applicationIconActive from "../../../assets/dashboard/sidebar/adminDashboardSidebar/applicationTabActive.svg";
import calendlyIcon from "../../../assets/dashboard/sidebar/adminDashboardSidebar/calendlyTab.svg";
import calendlyIconActive from "../../../assets/dashboard/sidebar/adminDashboardSidebar/calendlyTabActive.svg";
import contentIcon from "../../../assets/dashboard/sidebar/adminDashboardSidebar/contentTab.svg";
import contentIconActive from "../../../assets/dashboard/sidebar/adminDashboardSidebar/contentTabActive.svg";
import userManagementIcon from "../../../assets/dashboard/sidebar/adminDashboardSidebar/userManagementTab.svg";
import userManagementIconActive from "../../../assets/dashboard/sidebar/adminDashboardSidebar/userManagementTabActive.svg";

import RenderDashboardContent from "./RenderDashboardContent";
import { checkValid } from "../../../state-management/actions/admin/adminDashboard";
import { connect } from "react-redux";
const AdminDashboard = (props) => {
  // use the inbuilt methods to get the value for your searchQuery
  const [enable, setEnable] = useState(false);
  useEffect(() => {
    props.checkValid();
    document.body.classList.add("dashboard-body-color");
    return () => document.body.classList.remove("dashboard-body-color");
  }, []);

  useEffect(() => {
    if (props?.check?.status === 200) {
      setEnable(true);
    }
  }, [props]);
  const [currentLink, setCurrentLink] = useState("");

  const sideBarLinks = [
    {
      title: "Applications",
      icon: applicationIcon,
      activeIcon: applicationIconActive,
    },
    {
      title: "User Management",
      icon: userManagementIcon,
      activeIcon: userManagementIconActive,
    },
    {
      title: "Content",
      icon: contentIcon,
      activeIcon: contentIconActive,
    },
    {
      title: "Calendly",
      icon: calendlyIcon,
      activeIcon: calendlyIconActive,
    },
  ];

  useEffect(() => {
    const search = props.location.search;
    const tabs = new URLSearchParams(search).get("tabs");
    const newTabs = tabs?.replace(/_/g, " ");
    if (tabs) {
      setCurrentLink(newTabs);
    } else {
      setCurrentLink("applications");
    }
  }, [props.location.search]);
  useEffect(() => {
    if (props.location.state && props.location.state.prevLink) {
      setCurrentLink(props.location.state.prevLink);
    }
  }, [currentLink]);

  const handleCurrentLink = (linkName) => {
    setCurrentLink(linkName);
  };

  return (
    <div className="dashboard-body">
      {enable && (
        <>
          <Sidebar
            currentLink={currentLink}
            handleCurrentLink={handleCurrentLink}
            sideBarLinks={sideBarLinks}
          />
          <div className="dashboard-main">
            <div className="dashboard-main-container admin_dashboard_container">
              {/* <DashboardHeader
            currentUser={props.currentUser}
            handleCurrentLink={handleCurrentLink}
            currentLink={currentLink}
          /> */}

              <RenderDashboardContent currentLink={currentLink} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};
const mapStateToProps = (state) => ({
  check: state.admin.check,
});

export default connect(mapStateToProps, { checkValid })(
  withRouter(AdminDashboard)
);
