import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const PrivateMenteeRoute = ({ component: Component, currentUser, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        currentUser &&
        currentUser.loggedIn === true &&
        currentUser.type === "mentee" ? (
          <Component {...props} />
        ) : (
          currentUser &&
          currentUser.loggedIn === false && <Redirect to="/sign-in" />
        )
      }
    />
  );
};

PrivateMenteeRoute.propTypes = {
  auth: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  currentUser: state.auth.currentUser,
});

export default connect(mapStateToProps)(PrivateMenteeRoute);
