import React, { Component } from "react";
import Panel from "./Panel";

export class Collapse extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: 0,
    };

    this.activateTab = this.activateTab.bind(this);
  }

  activateTab = (index, e) => {
    if (this.props.onTrailBtnClick !== undefined) {
      let currentBtn = e.target.getAttribute("data-area-trail");
      this.props.onTrailBtnClick(currentBtn);
    }
    this.setState((prev) => ({
      activeTab: (prev.activeTab = index),
    }));
  };

  render() {
    const { panels } = this.props;
    const { activeTab } = this.state;
    return (
      <div className="collapse" role="tablist">
        {panels.map((panel, index) => (
          <Panel
            key={index}
            activeTab={activeTab}
            index={index}
            {...panel}
            activateTab={this.activateTab.bind(null, index)}
          />
        ))}
      </div>
    );
  }
}

export default Collapse;
