import React, { useEffect } from "react";
import Footer from "../components/landing-page/layout/Footer";
import Terms from "../components/terms-of-service/TermsOfService";
import Navbar from "../components/landing-page/layout/Navbar";

const TermsOfServiceRoute = () => {
  useEffect(() => {
    document.title = "Cardo | Terms Of Service";
    return () => (document.title = "Cardo");
  }, []);
  return (
    <div>
      <Navbar loginColor="#2C205B" />
      <Terms />
      <Footer wavebg={"white"} bgcolor={"#5555DB"} copywritebg={"#4D4DC6"} />
    </div>
  );
};

export default TermsOfServiceRoute;
