import axios from 'axios';
import { helper } from '../../../../helper/helper';
import { MENTEE_DASHBOARD_EDIT_PROFILE, MENTEE_DASHBOARD_NEW_IMAGE } from '../../../types/types'
import Cookies from 'universal-cookie';
const cookies = new Cookies();

export const editMenteeProfile = (data) => dispatch => {
    axios.get(`${helper.baseUrl}/users/mentee/dashboard/getMenteeDashboardEditProfile`, { withCredentials: true })
        .then(res => {
            dispatch({
                type: MENTEE_DASHBOARD_EDIT_PROFILE,
                payload: res.data
            })
        })
        .catch(err => console.log(err.response))
}

export const putEditMenteeProfile = (data, handleLoading) => dispatch => {

    axios.put(`${helper.baseUrl}/users/mentee/dashboard/putMenteeDashboardEditProfile?resumeUniqueIdEdit=${data.get('resumeUniqueId')}&resumeName=resume`,
        data,
        {
            headers: { 'x-csrf-token': cookies.get('XSRF-TOKEN'), 'Content-Type': 'multipart/form-data' },
            withCredentials: true
        })
        .then(res => {
            handleLoading(false)
            dispatch({
                type: MENTEE_DASHBOARD_EDIT_PROFILE,
                payload: res.data
            })
        })
        .catch(err => console.log(err.response))
}

export const putNewImage = (data, handleLoading) => async (dispatch) => {

    axios.put(`${helper.baseUrl}/users/mentee/dashboard/putNewImage`,
        data,
        { withCredentials: true })
        .then(res => {

            handleLoading(false)
            dispatch({
                type: MENTEE_DASHBOARD_NEW_IMAGE,
                payload: res.data
            })
        })
        .catch(err => console.log(err.response))
}


export const postDeleteAccount = (handleLoading) => {
    axios.post(`${helper.baseUrl}/users/mentee/dashboard/postDeleteAccount`,
        {
            headers: { 'x-csrf-token': cookies.get('XSRF-TOKEN') }
        },
        { withCredentials: true })
        .then(res => {
            handleLoading(false)
            if (res.status === 200) {

                window.location.href = '/'
            }

        })
        .catch(err => console.log(err.response))
}