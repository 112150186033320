import React from "react";

const TextField = (props) => {
  const {
    id,
    label,
    placeholder,
    value,
    onChange,
    name,
    disabled,
    toolTip,
    errorMsg,
  } = props;

  return (
    <div className="mb-5 position-relative">
      <div className="display-flex">
        <label className="form-label" htmlFor={id}>
          {label}
        </label>
        {toolTip && (
          <div className="tooltip">
            ?<div className="tooltiptext">{toolTip}</div>
          </div>
        )}
      </div>
      <input
        id={id}
        className={"form-textbox"}
        type="text"
        placeholder={placeholder}
        name={name}
        value={value || ""}
        onChange={onChange}
        autoComplete="off"
        disabled={disabled}
      />
      {errorMsg && <div className="signup-form-validation">{errorMsg}</div>}
    </div>
  );
};

export default TextField;
