import React from "react";
import section1cardIcon1 from "../../../assets/Become a mentee/Group 641.svg";
import section1cardIcon2 from "../../../assets/Become a mentee/Vector.svg";
import section1cardIcon3 from "../../../assets/Become a mentee/Group 560.svg";
const FindMentorSection2Desktop = () => {
  return (
    <div>
      <div className="card-container">
        <div className="card">
          <div>
            <div className="card-icon">
              <img src={section1cardIcon1} alt="" />
            </div>
          </div>
          <div className="card-heading">Hold the cash</div>
          <p className="card-desc">
            Don’t waste hundreds of dollars paying for career mentorship. We’ve
            got you covered, for free.
          </p>
        </div>
        <div className="card">
          <div>
            <div className="card-icon">
              <img src={section1cardIcon2} alt="" />
            </div>
          </div>
          <div className="card-heading">People who actually care </div>
          <p className="card-desc">
            Say goodbye to unanswered messages. Mentors on Cardo are here to
            help you achieve your career goals.
          </p>
        </div>
        <div className="card">
          <div>
            <div className="card-icon">
              <img src={section1cardIcon3} alt="" />
            </div>
          </div>
          <div className="card-heading">Skip the formalities </div>
          <p className="card-desc">
            Drop the buzzwords. Have authentic and unbiased conversations that
            matter.
          </p>
        </div>
      </div>
    </div>
  );
};

export default FindMentorSection2Desktop;
