import React, { useEffect } from "react";
import Donate from "../components/landing-page/donate/Donate";
import Footer from "../components/landing-page/layout/Footer";
import Navbar from "../components/landing-page/layout/Navbar";

const DonateRoute = () => {
  useEffect(() => {
    document.title = "Cardo | Donate";
    return () => (document.title = "Cardo");
  }, []);
  return (
    <div>
      <Navbar loginColor="#2C205B" />
      <Donate />
      <Footer
        wavebg={"transparent"}
        bgcolor={"#2c205b"}
        copywritebg={"#211845"}
      />
    </div>
  );
};

export default DonateRoute;
