import React from "react";
import leftColImg from "../../../../assets/sign-up/status/Group 291.svg";
import "../../../../styles/signup/sign-up-status.css";
import darkLogo from "../../../../assets/sign-up/status/Group 396-2.svg";
import { Link } from "react-router-dom";
const SignupStatusDenied = () => {
  return (
    <div className="sign-up-status-denied">
      <div className="position-relative">
        <div className="signup-logo-container">
          <Link to="/">
            <img src={darkLogo} alt="" />
          </Link>
        </div>
      </div>
      <div className="row sign-up-status-row">
        <div className="col-6 sign-up-status-leftCol">
          <div className="sign-up-status-leftCol-container">
            <img src={leftColImg} alt="" />
          </div>
        </div>
        <div className="col-6 sign-up-status-rightCol">
          <div className="sign-up-status-approved-rightCol-container">
            <div className="sign-up-status-desc-container">
              <div className="sign-up-status-desc">
                <h3 className="sign-up-status-rightCol-title">
                  Thank you for your interest in Cardo.
                </h3>
                <br /> <br /> <br />
                <p className="sign-up-status-rightCol-desc">
                  As much as we’d like to invite everyone to join our community,
                  we currently do not have the capacity to serve everyone.{" "}
                  <br /> <br /> We're growing quickly, and we’d hope to welcome
                  you back in the near future - be on the lookout for updates on
                  availabilty. <br /> <br /> Feel free to reach out to us at
                  support@hellocardo.org if you have any questions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupStatusDenied;
