import React from "react";
import PropTypes from "prop-types";
import Validation from "../../landing-page/common/Validation";
import SignupValidation from "../sign-up/validation/SignupValidation";

const Select = ({ name, value, onChange, choices, error, text, selected }) => {
  return (
    <div className="mb-5 position-relative">
      <div
        className={
          error ? "select is-fullwidth is-danger" : "select is-fullwidth"
        }
      >
        <label className="form-label" htmlFor={name}>
          {text}
        </label>
        {error && <SignupValidation message={error} />}

        <select
          name={name}
          value={value}
          onChange={onChange}
          className="form-select"
        >
          {choices}
        </select>
      </div>
    </div>
  );
};

Select.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  choices: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ).isRequired,
  error: PropTypes.string,
};

export default Select;
