import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import "../../../styles/animate.css";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const HomeSection2Mobile = (props) => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    arrows: false,
    variableWidth: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div>
      <div className="info-container">
        <ScrollAnimation animateIn="fadeInUp">
          <button
            className={`bubbles`}
            style={{
              background: props.mentorbg,
              color: props.mentorbg === "#5555DB" && "white",
            }}
            onClick={props.onClick}
          >
            Hi! I’m a mentor.
          </button>

          <button
            className={`bubbles`}
            style={{ background: props.menteebg }}
            onClick={props.onClick}
          >
            Hi! I’m a mentee.
          </button>
        </ScrollAnimation>
      </div>{" "}
      <div className="slider-container">
        <ScrollAnimation animateIn="fadeInDown">
          <Slider {...settings}>
            <div className="rectangle-container-mobile">
              <div className="content-heading">{props.topLeftTitle}</div>
              <div className="content-text">{props.topLeftDesc}</div>
            </div>
            <div className="rectangle-container-mobile">
              <div className="content-heading">{props.topRightTitle}</div>
              <div className="content-text">{props.topRightDesc}</div>
            </div>
            <div className="rectangle-container-mobile">
              <div className="content-heading">{props.bottomLeftTitle}</div>
              <div className="content-text">{props.bottomLeftDesc}</div>
            </div>
            <div className="rectangle-container-mobile">
              <div className="content-heading">{props.bottomRightTitle}</div>
              <div className="content-text">{props.bottomRightDesc}</div>
            </div>
          </Slider>
        </ScrollAnimation>
      </div>
    </div>
  );
};

export default HomeSection2Mobile;
