import React, { useEffect } from "react";
import BecomeMentor from "../components/landing-page/become-mentor/BecomeMentor";
import Footer from "../components/landing-page/layout/Footer";
import Navbar from "../components/landing-page/layout/Navbar";

const BecomeMentorRoute = () => {
  useEffect(() => {
    document.title = "Cardo | Become A Mentor";
    return () => (document.title = "Cardo");
  }, []);
  return (
    <div>
      <Navbar dark loginColor="#5555DB" />
      <BecomeMentor />
      <Footer wavebg={"white"} bgcolor={"#5555DB"} copywritebg={"#4D4DC6"} />
    </div>
  );
};

export default BecomeMentorRoute;
