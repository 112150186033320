import React from "react";
import { string } from "prop-types";

const RadioBtnGroup = (props) => {
  const {
    id,
    name,
    label,
    toolTip,
    inputValues,
    value,
    onChange,
    disabled,
    errorMsg,
  } = props;

  function getSelectedValue(value, inputValue) {
    if (typeof value === "string") {
      return value === inputValue;
    } else if (value instanceof Array) {
      return value.includes(inputValue);
    }
    return false;
  }

  return (
    <div className="mb-5 position-relative">
      <div className="display-flex">
        <label className="form-label" htmlFor={id}>
          {label}
        </label>
        {toolTip && (
          <div className="tooltip">
            ?<div className="tooltiptext">{toolTip}</div>
          </div>
        )}
      </div>
      <div className="edit-profile-radio-btn-container">
        {inputValues.map((inputValue, index) => (
          <div
            key={index}
            style={disabled ? { color: "#929292" } : { color: "" }}
            className={
              getSelectedValue(value, inputValue)
                ? `edit-profile-radio edit-profile-radio-selected ${
                    disabled && "cursor-disable"
                  }`
                : `edit-profile-radio ${disabled && "cursor-disable"}`
            }
            onClick={disabled ? null : () => onChange(name, inputValue)}
          >
            {inputValue}
          </div>
        ))}
      </div>
      {errorMsg && <div className="signup-form-validation">{errorMsg}</div>}
    </div>
  );
};

export default RadioBtnGroup;
