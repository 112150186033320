import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import menteeHomeBannerImg from "../../../../assets/dashboard/mentee-dashboard/mentee-dashboard-home/Frame.svg";
import "../../../../styles/dashboard/mentee-dashboard/mentee-dashboard-home.css";
import { getDashboardHomeData } from "../../../../state-management/actions/dashboard/mentee-dashboard/dashboardActions";
import MenteeDashboardHomeBanner from "./mentee-dashboard-home-components/MenteeDashboardHomeBanner";
import MenteeDashboardUpcomingSessions from "./mentee-dashboard-home-components/MenteeDashboardUpcomingSessions";
import DoughnutChart from "./mentee-dashboard-home-components/DoughnutChart";
import MenteeDashboardMentorshipAreas from "./mentee-dashboard-home-components/MenteeDashboardMentorshipAreas";
import MenteeDashboardAccordian from "./mentee-dashboard-home-components/MenteeDashboardAccordian";
const MenteeDashboardHome = (props) => {
  useEffect(() => {
    props.getDashboardHomeData();
  }, []);
  const {
    menteeDashboardHome,
    currentUser,
    handleCurrentLink,
    handleModalMentorDetails,
    selectModalDetails,
  } = props;

  return (
    <div className="mentee-dashboard-home">
      <div className="mentee-dashboard-home-row">
        <div className="mentee-dashboard-home-leftCol">
          <MenteeDashboardHomeBanner currentUser={currentUser} />
          <MenteeDashboardUpcomingSessions
            handleCurrentLink={handleCurrentLink}
            menteeDashboardHome={menteeDashboardHome}
            handleModalMentorDetails={handleModalMentorDetails}
            selectModalDetails={selectModalDetails}
          />
          <MenteeDashboardMentorshipAreas
            menteeDashboardHome={menteeDashboardHome}
          />
        </div>
        <div className="mentee-dashboard-home-rightCol">
          <div className="mentee-dashboard-home-rightCol-container">
            <div className="font-Neometric-Bold font-size-20 mb-25">
              {" "}
              Helpful Links
            </div>
            <div className="mentee-dashboard-home-accordion-container">
              <MenteeDashboardAccordian
                data={menteeDashboardHome.accordionTips}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  menteeDashboardHome: state.menteeDashboard.menteeDashboardHome,
});
export default connect(mapStateToProps, { getDashboardHomeData })(
  MenteeDashboardHome
);
