import { CLEAR_ERRORS, GET_ERRORS } from "../types/types";

const initialState = {};

const errorReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ERRORS: {
      if (action.payload.errorStatus === 401) {
        return (window.location.href = "/sign-in");
      } else if (action.payload.errorStatus === 500) {
        return {
          errorStatus: 500,
          errorMsg: "Something went wrong, please refresh and try again",
        };
      } else {
        return action.payload;
      }
    }
    case CLEAR_ERRORS: {
      return action.payload;
    }
    default:
      return state;
  }
};
export default errorReducer;
