import React from "react";
import Checkbox from "../../../form-component/Checkbox";
import FileInput from "../../../form-component/FileInput";
import Radio from "../../../form-component/Radio";
import RadioGroup from "../../../form-component/RadioGroup";
import Select from "../../../form-component/Select";
import SignupValidation from "../../validation/SignupValidation";

const MenteeSignupStep3OurGoals = (props) => {
  const {
    title,
    step,
    onPrevStep,
    onStepChange,
    data,
    onChange,
    stepKey,
    errors,
    onRadioChange,
    onCheckboxChange,
    onFileChange,
  } = props;

  return (
    <div>
      <h3 className="sign-up-form-heading">{title}</h3>
      <div className="step-form-content">
        <div className="form-line">
          <Select
            name={data.q68_whichCountry.name}
            text={data.q68_whichCountry.text}
            value={data.q68_whichCountry.value}
            description={data.q68_whichCountry.description}
            selected={data.q68_whichCountry.selected}
            onChange={(e) => onChange(stepKey, e)}
            error={errors[data.q68_whichCountry.name]}
            choices={data.q68_whichCountry.choices}
          />
        </div>
        {data.q68_whichCountry.value !== "United States" ? (
          <SignupValidation
            message={` Due to a large variance in recruiting processes and approaches, we
                are only accepting mentees who are located in the United States at
                this time.`}
          />
        ) : (
          <div>
            <div className="form-line">
              <RadioGroup
                text={data.q12_whichIndustry.text}
                error={errors[data.q12_whichIndustry.name]}
                description={data.q12_whichIndustry.description}
              >
                <Radio
                  name={data.q12_whichIndustry.name}
                  value={data.q12_whichIndustry.value}
                  text={data.q12_whichIndustry.text}
                  inputValue={data.q12_whichIndustry.inputValues[0]}
                  label={data.q12_whichIndustry.inputValues[0]}
                  id={`${data.q12_whichIndustry.name}_radio_0`}
                  onRadioChange={(e) =>
                    onRadioChange(stepKey, e, data.q12_whichIndustry.value)
                  }
                  type={data.q12_whichIndustry.type}
                />
                <Radio
                  name={data.q12_whichIndustry.name}
                  value={data.q12_whichIndustry.value}
                  text={data.q12_whichIndustry.text}
                  inputValue={data.q12_whichIndustry.inputValues[1]}
                  label={data.q12_whichIndustry.inputValues[1]}
                  id={`${data.q12_whichIndustry.name}_radio_1`}
                  onRadioChange={(e) =>
                    onRadioChange(stepKey, e, data.q12_whichIndustry.value)
                  }
                  disabled
                  type={data.q12_whichIndustry.type}
                />
                <Radio
                  name={data.q12_whichIndustry.name}
                  value={data.q12_whichIndustry.value}
                  text={data.q12_whichIndustry.text}
                  inputValue={data.q12_whichIndustry.inputValues[2]}
                  label={data.q12_whichIndustry.inputValues[2]}
                  id={`${data.q12_whichIndustry.name}_radio_2`}
                  onRadioChange={(e) =>
                    onRadioChange(stepKey, e, data.q12_whichIndustry.value)
                  }
                  disabled
                  type={data.q12_whichIndustry.type}
                />
              </RadioGroup>
            </div>
            <div className="form-line">
              <RadioGroup
                text={data.q13_trail.text}
                error={errors[data.q13_trail.name]}
                description={data.q13_trail.description}
              >
                {data.q13_trail.inputValues.map((inputValue, index) => (
                  <Radio
                    key={index}
                    name={data.q13_trail.name}
                    value={data.q13_trail.value}
                    text={data.q13_trail.text}
                    inputValue={data.q13_trail.inputValues[index]}
                    label={data.q13_trail.inputValues[index]}
                    id={`${data.q13_trail.name}_radio_${index}`}
                    onRadioChange={(e) =>
                      onRadioChange(stepKey, e, data.q13_trail.value)
                    }
                    type={data.q13_trail.type}
                  />
                ))}
              </RadioGroup>
            </div>
            <div className="form-line">
              <RadioGroup
                text={data.q79_whatType.text}
                error={errors[data.q79_whatType.name]}
                description={data.q79_whatType.description}
              >
                {data.q79_whatType.inputValues.map((inputValue, index) => (
                  <Radio
                    key={index}
                    name={data.q79_whatType.name}
                    value={data.q79_whatType.value}
                    text={data.q79_whatType.text}
                    inputValue={data.q79_whatType.inputValues[index]
                      .split(" ")[0]
                      .replace("-", " ")}
                    label={data.q79_whatType.inputValues[index]}
                    id={`${data.q79_whatType.name}_radio_${index}`}
                    onRadioChange={(e) =>
                      onRadioChange(stepKey, e, data.q79_whatType.value)
                    }
                    type={data.q79_whatType.type}
                  />
                ))}
              </RadioGroup>
            </div>
            <div className="form-line">
              <Checkbox
                checked={false}
                name={data.q77_whichAreas.name}
                value={data.q77_whichAreas.value}
                displayValues={data.q77_whichAreas.displayValues}
                description={data.q77_whichAreas.description}
                text={data.q77_whichAreas.text}
                inputValues={data.q77_whichAreas.inputValues}
                onCheckboxChange={(e) =>
                  onCheckboxChange(stepKey, e, data.q77_whichAreas.value)
                }
                error={errors[data.q77_whichAreas.name]}
                type={data.q77_whichAreas.type}
              />
            </div>
            <div className="form-line">
              <RadioGroup
                text={data.q24_whereAre24.text}
                error={errors[data.q24_whereAre24.name]}
                description={data.q24_whereAre24.description}
              >
                {data.q24_whereAre24.inputValues.map((inputValue, index) => (
                  <Radio
                    key={index}
                    name={data.q24_whereAre24.name}
                    value={data.q24_whereAre24.value}
                    text={data.q24_whereAre24.text}
                    inputValue={data.q24_whereAre24.inputValues[index]}
                    label={data.q24_whereAre24.inputValues[index]}
                    id={`${data.q24_whereAre24.name}_radio_${index}`}
                    onRadioChange={(e) =>
                      onRadioChange(stepKey, e, data.q24_whereAre24.value)
                    }
                    type={data.q24_whereAre24.type}
                  />
                ))}
              </RadioGroup>
            </div>
            <div className="form-line">
              <FileInput
                id={`${data.q25_pleaseAttach.name}_id1`}
                onChange={onFileChange}
                error={errors[data.q25_pleaseAttach.name]}
                description={data.q25_pleaseAttach.description}
                name={data.q25_pleaseAttach.name}
                stepKey={stepKey}
                fileName={data.q25_pleaseAttach.fileName}
                text={data.q25_pleaseAttach.text}
                buttonText={data.q25_pleaseAttach.buttonText}
              />
            </div>{" "}
          </div>
        )}
      </div>

      <div className="form-step-btn-group">
        {step > 0 && (
          <button
            type="button"
            className="cardo-btn back-btn"
            onClick={() => onPrevStep(step - 1)}
          >
            Go back
          </button>
        )}
        <button
          type="button"
          className="cardo-btn next-btn"
          onClick={(e) => onStepChange(data, e)}
          disabled={data.q68_whichCountry.value !== "United States"}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default MenteeSignupStep3OurGoals;
