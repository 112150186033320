import { useEffect, useState } from 'react'
import { useConstructor } from '../hooks/useConstructor'

export const useIsDesktop = () => {
const [isDesktop, setIsDesktop] = useState(true)
useEffect(()=> {
        setIsDesktop((((typeof window.orientation === "undefined") && (navigator.userAgent.indexOf('IEMobile') === -1) && !(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent))) || (window.innerWidth > 1024) ))
},[])
useEffect(() => {

        window.addEventListener("resize", ()=>{
                setIsDesktop((((typeof window.orientation === "undefined") && (navigator.userAgent.indexOf('IEMobile') === -1) && !(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent))) || (window.innerWidth > 1024) ))
        })
        window.addEventListener("orientationchange", () =>  {
                setIsDesktop((((typeof window.orientation === "undefined") && (navigator.userAgent.indexOf('IEMobile') === -1) && !(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent))) || (window.innerWidth > 1024) ))
        })
})
return {isDesktop};
}


