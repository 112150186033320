import React from "react";
import PropTypes from "prop-types";
import SignupValidation from "../sign-up/validation/SignupValidation";

const Checkbox = ({
  type = "checkbox",
  name,
  value,
  inputValues,
  onCheckboxChange,
  error,
  text,
  storeValues,
  displayValues,
  description,
}) => {
  return (
    <div className="position-relative">
      <label className="form-label" htmlFor={name}>
        {text}
        {description && (
          <div className="tooltip">
            ?<div className="tooltiptext">{description}</div>
          </div>
        )}
      </label>
      {error && <SignupValidation message={error} />}

      <div className="list-checkbox">
        {inputValues.map((input, index) => {
          return (
            <span key={index} className="form-checkbox-item">
              <span className="dragger-item" />{" "}
              <input
                id={`${name}_${index}`}
                className={error ? "checkbox is-danger" : "checkbox"}
                type={type}
                name={name}
                value={
                  storeValues
                    ? storeValues[index]
                    : displayValues
                    ? displayValues[index]
                    : input
                }
                onChange={onCheckboxChange}
                checked={
                  storeValues
                    ? value.includes(storeValues[index])
                    : displayValues
                    ? value.includes(displayValues[index])
                    : value.includes(input)
                }
              />
              <label
                className={error ? "checkbox is-danger" : "checkbox"}
                htmlFor={`${name}_${index}`}
              >
                {input}
              </label>
            </span>
          );
        })}
      </div>
    </div>
  );
};

Checkbox.propTypes = {
  type: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  inputvalues: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onCheckboxChange: PropTypes.func,
};

export default Checkbox;
