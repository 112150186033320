import React from "react";
import "../../../../styles/main/aboutus/team_popup.css";
import linkedIn from "../../../../assets/linkedin-logo.svg";

const TeamDetailPopup = (props) => {
  const { handleTeamPopup, teamMemeber } = props;
  const closeModal = () => {
    handleTeamPopup();
  };
  return (
    <div className="team_details_popup" onClick={closeModal}>
      <div
        className="team_details_pop_content"
        onClick={(e) => e.stopPropagation()}
      >
        <span className="close" onClick={closeModal}>
          &times;
        </span>
        <div className="team_details_pop_content_wrapper">
          <div
            className="team_details_pop_content_image_container"
            style={{
              backgroundImage: `url('${teamMemeber.imageUrl}')`,
              backgroundSize: "cover",
            }}
          ></div>
          <div className="team_details_pop_name_and_linkedin">
            <div className="team_details_pop_content_teamMember_name">
              {teamMemeber.name}
            </div>
            <div className="team_details_pop_content_teamMember_linkedin">
              <a href={teamMemeber.linkedIn} target="_blank">
                <img
                  style={{ width: "75px" }}
                  src={linkedIn}
                  alt="linkedin-logo"
                />
              </a>
            </div>
          </div>
          <div className="team_details_pop_content_teamMember_title">
            {teamMemeber.title}
          </div>
          <div className="team_details_pop_content_teamMember_bio">
            {teamMemeber.bio}
          </div>
        </div>
      </div>
    </div>
  );
};
export default TeamDetailPopup;
