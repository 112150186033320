import React, { useEffect, useState } from "react";
import Checkbox from "../../profile-form-components/Checkbox";
import InlineField from "../../profile-form-components/InlineField";
import TextArea from "../../profile-form-components/TextArea";
import TextField from "../../profile-form-components/TextField";
import aprooved from "../../../../../assets/mentee-dashboard-profile-edit/aprooved.png";
import { putEditMentorProfile } from "../../../../../state-management/actions/dashboard/mentor-dashboard/profileActions";
import { connect } from "react-redux";

const MentorInformationTab = (props) => {
  const {
    currentUser,
    informationData,
    handleInformationData,
    handleCheckboxChange,
    putEditMentorProfile,
  } = props;
  const [loading, setLoading] = useState(false);
  const [saved, setSaved] = useState(false);
  const handleLoading = (val) => {
    setLoading(val);
  };
  const [errorMsg, setErrorMsg] = useState({
    emailError: "",
    myStoryError: "",
    consultingFirmError: "",
    currentEmployerError: "",
    educationError: "",
    whatMakesMeUniqueError: "",
  });

  const handleCount = (e) => {
    handleInformationData(e);
  };
  const pattern =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const validate = () => {
    setErrorMsg({
      ...errorMsg,
      emailError:
        !informationData.preferredEmailForCommunication.match(pattern) &&
        "Either Email is wrong or empty!",
      myStoryError: !informationData.myStory && "My Story is required!",
      consultingFirmError:
        !informationData.consultingFirm && "Consulting Firm is required!",
      currentEmployerError:
        !informationData.currentEmployer && "Current Employer is required!",
      educationError: !informationData.education && "Education is required!",
      whatMakesMeUniqueError:
        !informationData.whatMakesMeUnique.value &&
        "What Makes Me Unique is required!",
    });

    return (
      informationData.preferredEmailForCommunication.match(pattern) &&
      informationData.myStory &&
      informationData.consultingFirm &&
      informationData.currentEmployer &&
      informationData.education &&
      informationData.whatMakesMeUnique.value
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validate()) {
      const formData = new FormData(); // Currently empty
      formData.append("consultingFirm", informationData.consultingFirm);
      formData.append("currentEmployer", informationData.currentEmployer);
      formData.append("education", informationData.education);
      formData.append("gpa", informationData.gpa);
      formData.append("interestedIn", informationData.interestedIn.value);
      formData.append("myStory", informationData.myStory);
      formData.append(
        "preferredEmailForCommunication",
        informationData.preferredEmailForCommunication
      );
      formData.append("stage", informationData.stage.value);
      if (informationData.whatMakesMeUnique.value.length === 0) {
        formData.append("whatMakesMeUnique", ["N/A"]);
      } else {
        informationData.whatMakesMeUnique.value.map((item) => {
          formData.append("whatMakesMeUnique", item);
        });
      }
      putEditMentorProfile(formData, handleLoading);
      handleLoading(true);
      setSaved(true);
    } else {
      return false;
    }
  };
  const {
    preferredEmailForCommunication,
    myStory,
    currentEmployer,
    consultingFirm,
    education,
    whatMakesMeUnique,
  } = informationData;

  return (
    <div className="mentee-profile-information">
      <h2>Information</h2>
      <div className="mentee-profile-form-wrapper">
        <form
          onSubmit={(e) => handleSubmit(e)}
          onChange={(event) => validate(event)}
        >
          <div className="form-line">
            <InlineField>
              <TextField
                id="mentorProfileFirstName"
                label="First Name"
                placeholder="First Name"
                value={currentUser.firstName}
                name="mentorProfileFirstName"
                disabled
                toolTip={
                  <div>
                    First and last name details are uneditable to ensure we
                    maintain a safe community. If you’d like to request a
                    change, please contact our support team.
                  </div>
                }
              />
              <TextField
                id="mentorProfileLastName"
                label="Last Name"
                placeholder="Last Name"
                value={currentUser.lastName}
                name="mentorProfileLastName"
                disabled
                toolTip={
                  <div>
                    First and last name details are uneditable to ensure we
                    maintain a safe community. If you’d like to request a
                    change, please contact our support team.
                  </div>
                }
              />
            </InlineField>
          </div>
          <div className="form-line">
            <TextField
              id="preferredEmailForCommunication"
              label="Preferred Email For Communication"
              placeholder=""
              name="preferredEmailForCommunication"
              value={preferredEmailForCommunication}
              onChange={handleInformationData}
              errorMsg={errorMsg.emailError}
            />
          </div>
          <div className="form-line">
            <TextArea
              id="myStory"
              label="My Story (Your Biography)"
              placeholder=""
              name="myStory"
              value={myStory}
              onChange={handleCount}
              onKeyDown={() => {}}
              style={{ position: "relative" }}
              errorMsg={errorMsg.myStoryError}
            />
          </div>

          <div className="form-line">
            <InlineField>
              <div>
                <TextField
                  id="consultingFirm"
                  label="Consulting Firm"
                  placeholder=""
                  name="consultingFirm"
                  value={consultingFirm}
                  disabled
                  onChange={handleInformationData}
                  errorMsg={errorMsg.consultingFirmError}
                  toolTip={
                    <div>
                      Consulting firm affiliation details are provided during
                      application. If you’d like to update your firm
                      affiliation, please reach out to our support team.
                      <br />
                      <br />
                      If you have been employed at multiple firms, please select
                      the firm you feel most aligned to.
                    </div>
                  }
                />
              </div>

              <div>
                <TextField
                  id="currentEmployer"
                  label="Current Employer"
                  placeholder=""
                  name="currentEmployer"
                  value={currentEmployer}
                  errorMsg={errorMsg.currentEmployerError}
                  onChange={handleInformationData}
                />
              </div>
            </InlineField>
          </div>

          <div className="form-line">
            <TextField
              id="education"
              label="Education"
              placeholder=""
              name="education"
              value={education}
              errorMsg={errorMsg.educationError}
              disabled
              onChange={handleInformationData}
            />
          </div>

          <div className="form-line">
            <Checkbox
              id="whatMakesMeUnique"
              label="What Makes Me Unique"
              name="whatMakesMeUnique"
              value={whatMakesMeUnique.value}
              maxSelected={3}
              inputValues={whatMakesMeUnique.inputValues}
              errorMsg={errorMsg.whatMakesMeUniqueError}
              onChange={handleCheckboxChange}
              toolTip={
                <div>
                  Let people know what makes you unique! Select up to three to
                  share on your profile.
                </div>
              }
            />
          </div>

          <div style={{ display: "flex", alignItems: "center" }}>
            <button
              type={loading ? "button" : "submit"}
              className="save-btn cardo-btn btn-bg-lightblue"
              // style={{ margin: "20px 10px 10px 10px" }}
            >
              {!loading ? (
                "Save Changes"
              ) : (
                <div className={"form-loader form-loader-active"}>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              )}
            </button>
            {saved && !loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: "10px",
                }}
              >
                <div
                  className="font-Neometric-Medium"
                  style={{
                    color: "black",
                  }}
                >
                  Changes Saved{" "}
                </div>
                <img style={{ marginLeft: "10px" }} src={aprooved} alt="" />
              </div>
            ) : (
              ""
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default connect(null, { putEditMentorProfile })(MentorInformationTab);
