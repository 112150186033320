import React, {useState} from 'react';
import useDevices from "../../../../../../hooks/useDevices";
import useMediaStreamTrack from "../../../../../../hooks/useMediaStreamTrack";
import {DEFAULT_VIDEO_CONSTRAINTS, SELECTED_VIDEO_INPUT_KEY} from "../../../../../../helper/videoCall";
import Select from "../../../../../auth/form-component/Select";

function VideoInputList(props) {
    const {videoInputDevices} = useDevices();
    const localVideoTrack = props.localTracks.find(track => track.kind === 'video');
    const mediaStreamTrack = useMediaStreamTrack(localVideoTrack);
    const [storedLocalVideoDeviceId, setStoredLocalVideoDeviceId] = useState(
        window.localStorage.getItem(SELECTED_VIDEO_INPUT_KEY)
    );
    const localVideoInputDeviceId = mediaStreamTrack?.getSettings().deviceId || storedLocalVideoDeviceId;

    function replaceTrack(newDeviceId) {
        setStoredLocalVideoDeviceId(newDeviceId);
        window.localStorage.setItem(SELECTED_VIDEO_INPUT_KEY, newDeviceId);
        localVideoTrack?.restart({
            ...(DEFAULT_VIDEO_CONSTRAINTS),
            deviceId: {exact: newDeviceId},
        });
        if (props.closeModel) {
            props.closeModel();
        }
    }

    return (
        <div>
            {videoInputDevices.length > 0 ? (
                <Select
                    name="videoinput"
                    text="Camera"
                    value={localVideoInputDeviceId || ''}
                    onChange={(e) => replaceTrack(e.target.value)}
                    error={''}
                    choices={
                        videoInputDevices.map(device => (
                            <option value={device.deviceId} key={device.deviceId}>
                                {device.label}
                            </option>
                        ))
                    }
                />
            ) : (
                <>
                    <label className="audiovideolabel">
                        Video Input
                    </label>
                    <div className="audiovideoinput">
                        {localVideoTrack?.mediaStreamTrack.label || 'No Local Video'}
                    </div>
                </>
            )}
        </div>
    );
}

export default VideoInputList
