import React, { useEffect } from "react";
import FindMentorMain from "../components/landing-page/find-mentor/FindMentorMain";
import Footer from "../components/landing-page/layout/Footer";
import Navbar from "../components/landing-page/layout/Navbar";

const FindMentorRoute = () => {
  useEffect(() => {
    document.title = "Cardo | Find a Mentor";
    return () => (document.title = "Cardo");
  }, []);
  return (
    <div>
      <Navbar dark loginColor="#2C205B" />
      <FindMentorMain />
      <Footer wavebg={"white"} bgcolor={"#5555DB"} copywritebg={"#4D4DC6"} />
    </div>
  );
};

export default FindMentorRoute;
