import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Link } from "react-router-dom";
import "../../../../styles/main/aboutus/team-card.css";
const TeamCardsDesktop = ({ handleTeamPopup, teamData, handleTeamMemeber }) => {
  const handleCardClick = (data) => {
    handleTeamPopup();
    handleTeamMemeber(data);
  };
  return (
    <div
      className="container_narrow mentor_card_container team_card_container "
      style={{ justifyContent: "flex-start" }}
    >
      {teamData.team &&
        teamData.team.map((teamMember, index) => {
          return (
            <ScrollAnimation
              animateIn="fadeInUp"
              delay={100 * index}
              key={index}
            >
              <div
                className="mentor_card mentor_img_back team_card"
                onClick={() => handleCardClick(teamMember)}
                style={{
                  backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0) 40%, rgba(0,0,0,1) 100%),url('${teamMember.imageUrl}')`,
                }}
              >
                <div className="team_card_data_wrapper">
                  <div className="team_card_inner_bio">
                    {/* {teamMember.bio} */}
                    {teamMember.bio.length < 104 ? (
                      teamMember.bio
                    ) : (
                      <>{teamMember.bio.substring(0, 104)}... </>
                    )}
                  </div>
                </div>
              </div>
              <div style={{ margin: "0 20px" }}>
                <div className="team_card_teamMemberName">
                  {teamMember.name}
                </div>
                <div className="team_card_teamMemberTitle">
                  {teamMember.title}
                </div>
              </div>
            </ScrollAnimation>
          );
        })}
    </div>
  );
};

export default TeamCardsDesktop;
