import React, { useState } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import notificationIcon from "../../../../assets/dashboard/header/Vector-8.svg";
import { logoutUser } from "../../../../state-management/actions/authActions";
import whiteLogo from "../../../../assets/dashboard/profile/Group 799.svg";
import ProfileNotification from "./ProfileNotification";

const ProfileHeader = (props) => {
  const { currentUser, userImg } = props;
  const [isOpen, setOpen] = useState(false);

  const toggleDropdown = () => setOpen(!isOpen);
  const onLogout = () => {
    props.logoutUser();
    props.history.push("/");
  };

  return (
    <div className="profile-header">
      <div className="profile-logo">
        <img src={whiteLogo} alt="" />
      </div>
      <div className="dashboard-header" style={{ padding: "40px" }}>
        <div
          className="dashboard-header-container"
          style={{ margin: "0 50px" }}
        >
          <ProfileNotification currentUser={currentUser} />

          <div
            className="dashboard-header-current-user-dropdown"
            onClick={toggleDropdown}
          >
            <div
              className="dashboard-header-current-user"
              style={{ background: isOpen ? "#fafafa" : "white" }}
            >
              <div
                className="dashboard-header-user-avatar mr-10"
                style={{
                  backgroundImage: `url(${userImg})`,
                }}
              />
              <span className="font-Neometric-Regular">
                {currentUser.lastName
                  ? `${currentUser.firstName} ${currentUser.lastName
                      .substring(0, 1)
                      .toUpperCase()}.`
                  : ""}
              </span>
              <i className="fas fa-angle-down ml-10"></i>
            </div>
            <div
              className={`dashboard-header-current-user-dropdown-body ${
                isOpen && "open"
              }`}
            >
              <Link
                to={`/${currentUser.type}/profile`}
                style={{ textDecoration: "none", color: "black" }}
              >
                <div className="dashboard-header-current-user-dropdown-item">
                  <div className="dashboard-header-current-user-dropdown-item-before"></div>
                  <div>Edit Profile</div>
                </div>
              </Link>
              <div
                className="dashboard-header-current-user-dropdown-item"
                onClick={onLogout}
              >
                <div className="dashboard-header-current-user-dropdown-item-before"></div>
                <div>Logout</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  currentUser: state.auth.currentUser,
});
export default connect(mapStateToProps, { logoutUser })(
  withRouter(ProfileHeader)
);
