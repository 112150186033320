import React from "react";
import findMentorSection3step1 from "../../../assets/Become a mentee/Group (1).svg";
import findMentorSection3step2 from "../../../assets/Become a mentee/Group 533.svg";
import findMentorSection3step3 from "../../../assets/Become a mentee/Group 530.svg";
import findMentorSection3step4 from "../../../assets/Become a mentee/Group 531.svg";
import findMentorSection3step5 from "../../../assets/Become a mentee/Group 187.svg";
import findMentorSection3step6 from "../../../assets/Become a mentee/Group 534.svg";
const FindMentorSection3 = () => {
  return (
    <section className="find-mentor-section-three">
      <div className="container_narrow find-mentor-section-three_container">
        <h2 className="heading">How it works</h2>
        <div className="find-mentor-section-three-body">
          <div className="steps-container">
            <div className="steps">
              <div className="step">
                <div className="step-icon">
                  <img src={findMentorSection3step1} alt="" />
                </div>
                <div className="step-content">
                  <div className="step-no">Step 1</div>
                  <p className="step-desc">
                    Complete an application for your industry and trail{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="steps">
              <div className="step">
                <div className="step-icon">
                  <img src={findMentorSection3step2} alt="" />
                </div>
                <div className="step-content">
                  <div className="step-no">Step 2</div>
                  <p className="step-desc">
                    Once approved, onboard and complete your profile
                  </p>
                </div>
              </div>
            </div>
            <div className="steps">
              <div className="step">
                <div className="step-icon">
                  <img src={findMentorSection3step3} alt="" />
                </div>
                <div className="step-content">
                  <div className="step-no">Step 3</div>
                  <p className="step-desc">
                    Find mentors and view their availability directly from your
                    dashboard
                  </p>
                </div>
              </div>
            </div>
            <div className="steps">
              <div className="step">
                <div className="step-icon">
                  <img src={findMentorSection3step4} alt="" />
                </div>
                <div className="step-content">
                  <div className="step-no">Step 4</div>
                  <p className="step-desc">
                    Select a mentor, pick a time slot, and set the session
                    agenda (e.g., Resume Review)
                  </p>
                </div>
              </div>
            </div>
            <div className="steps">
              <div className="step">
                <div className="step-icon">
                  <img src={findMentorSection3step5} alt="" />
                </div>
                <div className="step-content">
                  <div className="step-no">Step 5</div>
                  <p className="step-desc">
                    Use your credits to finalize your booking and notify your
                    mentor
                  </p>
                </div>
              </div>
            </div>
            <div className="steps">
              <div className="step">
                <div className="step-icon">
                  <img src={findMentorSection3step6} alt="" />
                </div>
                <div className="step-content">
                  <div className="step-no">Step 6</div>
                  <p className="step-desc">
                    Join your 1:1 session directly from your dashboard and leave
                    feedback after
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FindMentorSection3;
