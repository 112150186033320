import React, { useState } from "react";
import "../../../../styles/signup/sign-up.css";
import lightLogo from "../../../../assets/sign-up/Group 396.svg";
import leftColMainImg from "../../../../assets/sign-up/Group.svg";
import mentorCardImg from "../../../../assets/sign-up/Group-2.svg";
import menteeCardImg from "../../../../assets/sign-up/Group-3.svg";

import { Link } from "react-router-dom";
import MentorSignup from "../mentor-sign-up-steps/MentorSignup";
import MenteeSignup from "../mentee-sign-up-steps/MenteeSignup";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
  signupMentor,
  innerScript,
  signupMentee,
} from "../../../../state-management/actions/authActions";

const SignUp = (props) => {
  const [role, setRole] = useState("");
  const handleRoleClick = (currentRole) => {
    setRole(currentRole);
    if (currentRole === "mentor") {
      props.signupMentor();
    } else if (currentRole === "mentee") {
      props.signupMentee();
    }
    props.innerScript();
  };
  let signupContent;
  if (role === "mentor") {
    props.history.push("/sign-up/mentor");
  } else if (role === "mentee") {
    props.history.push("/sign-up/mentee");
  } else {
    signupContent = (
      <div className="row sign-up-row">
        <div className="col-6 sign-up-leftCol">
          <div className="sign-up-leftCol-container">
            <div className="signup-logo-container ">
              <Link to="/">
                <img src={lightLogo} alt="" />
              </Link>
            </div>
            <div className="leftCol-mainImg-container">
              <img src={leftColMainImg} alt="" />
            </div>
          </div>
        </div>
        <div className="col-6 sign-up-rightCol">
          <div className="sign-up-rightCol-container">
            <h1 className="sign-up-rightCol-heading">Welcome to Cardo</h1>
            <p className="sign-up-rightCol-desc">
              Cardo makes career mentorship accessible for all. <br />
              Apply today for free.
            </p>
            <div className="sign-up-role-cards-container">
              <div
                className={
                  role === "mentor"
                    ? `sign-up-role-card-item sign-up-role-card-item-active`
                    : `sign-up-role-card-item`
                }
                onClick={() => handleRoleClick("mentor")}
              >
                <div>
                  <img src={mentorCardImg} alt="" />
                </div>
                <div className="font-Neometric-Medium sign-up-role-card-item-title">
                  Apply to be a mentor
                </div>
              </div>
              <div
                className={
                  role === "mentee"
                    ? `sign-up-role-card-item sign-up-role-card-item-active`
                    : `sign-up-role-card-item`
                }
                onClick={() => handleRoleClick("mentee")}
              >
                <div>
                  <img src={menteeCardImg} alt="" />
                </div>
                <div className="font-Neometric-Medium sign-up-role-card-item-title">
                  Apply to be a mentee
                </div>
              </div>
            </div>
            <div className="font-eina already-have-account-text">
              Already have an account?{" "}
              <Link className="sign-up-link" to="sign-in">
                Sign in
              </Link>
              .
            </div>
            <div className="font-eina sign-up-terms-of-agreement">
              By using Cardo, you agree to our{" "}
              <Link className="sign-up-link" to="terms-of-service">
                Terms of Use
              </Link>{" "}
              and <br />
              <Link className="sign-up-link" to="privacy-policy">
                Privacy Policy
              </Link>
              .
            </div>
          </div>
        </div>
      </div>
    );
  }

  return <div>{signupContent}</div>;
};

const mapStateToProps = (state) => ({
  errors: state.errors,
});

export default connect(mapStateToProps, {
  signupMentor,
  innerScript,
  signupMentee,
})(withRouter(SignUp));
