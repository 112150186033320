import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getMentorDashboardMySessionsData } from "../../../../state-management/actions/dashboard/mentor-dashboard/mentorDashboardActions";
import MentorDashboardMySessionsComponent from "../../mentor-dashboard/mentor-dashboard-components/mentor-dashboard-my-sessions-components/MentorDashboardMySessionsComponent";

const MentorSessions = (props) => {
  useEffect(() => {
    props.getMentorDashboardMySessionsData();
  }, []);
  const {
    mentorDashboardMySessions,
    selectModalDetails,
    handleModalMentorDetails,
    handleCurrentLink,
  } = props;

  return (
    <div style={{ margin: "0", width: "880px" }}>
      <MentorDashboardMySessionsComponent
        handleCurrentLink={handleCurrentLink}
        events={mentorDashboardMySessions}
        selectModalDetails={selectModalDetails}
        handleModalMentorDetails={handleModalMentorDetails}
      />
    </div>
  );
};
const mapStateToProps = (state) => ({
  mentorDashboardMySessions: state.mentorDashboard.mentorDashboardMySessions,
});
export default connect(mapStateToProps, { getMentorDashboardMySessionsData })(
  MentorSessions
);
