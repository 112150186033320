import React from "react";
import editpen from "../../../../assets/icons/editpen.svg";
const EditableCell = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateMyData, // This is a custom function that we supplied to our table instance
}) => {
  const [value, setValue] = React.useState(initialValue);

  const onChange = (e) => {
    setValue(e.target.value);
    // updateMyData(index, id, e.target.value);
  };

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateMyData(index, id, value);
  };

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <div>
      <input
        className="editable_table_input"
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
      <img
        src={editpen}
        alt=""
        style={{ marginLeft: "5px", cursor: "pointer" }}
        onClick={(e) => false}
      />
    </div>
  );
};

export default EditableCell;
