import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "../../../../styles/dashboard/mentee-dashboard/mentee-dashboard-find-mentor.css";
import MenteeDashboardFindMentorBanner from "./mentee-dashboard-find-mentor-components/MenteeDashboardFindMentorBanner";
import MenteeDashboardFindMentorFilter from "./mentee-dashboard-find-mentor-components/MenteeDashboardFindMentorFilter";
import { getDashboardFindMentorData } from "../../../../state-management/actions/dashboard/mentee-dashboard/dashboardActions";

import { postEventScheduled } from "../../../../state-management/actions/dashboard/mentee-dashboard/calendlyActions";

import MenteeDashboardFindMentorCards from "./mentee-dashboard-find-mentor-components/MenteeDashboardFindMentorCards";
import MenteeDashboardFindMentorPopup from "./mentee-dashboard-find-mentor-components/MenteeDashboardFindMentorPopup";

import MenteeDashboardConfirmationPopup from "./mentee-dashboard-booking-details/MenteeDashboardConfirmationPopup";

const MenteeDashboardFindMentor = (props) => {
  useEffect(() => {
    props.getDashboardFindMentorData();
  }, []);

  const [confirmationModal, setConfirmationModal] = useState(false);
  const selectConfirmationModal = () => {
    setConfirmationModal(!confirmationModal); // true/false toggle
  };

  const [data, setData] = useState({});
  useEffect(() => {
    setData(props.menteeDashboardEventScheduled);
  });

  const { menteeDashboardFindMentor, currentUser } = props;
  const [modal, setModal] = useState(false);
  const selectModal = () => {
    setModal(!modal); // true/false toggle
  };

  const [mentor, setMentor] = useState({});
  const selectMentor = (mentor) => {
    setMentor(mentor);
  };

  const [mentorFilter, setMentorFilter] = useState({ skillFilter: [] });
  const handleMentorFilter = (mentorSkillItem) => {
    setMentorFilter((prev) => ({
      ...prev,
      skillFilter: mentorSkillItem,
    }));
  };
  return (
    <div>
      {modal && (
        <MenteeDashboardFindMentorPopup
          displayModal={modal}
          closeModalSchedule={selectModal}
          closeModalDone={selectConfirmationModal}
          menteeDashboardProfileInfo={menteeDashboardFindMentor.profile_info}
          mentor={mentor}
          currentUser={currentUser}
        />
      )}

      <MenteeDashboardFindMentorBanner />
      <MenteeDashboardFindMentorFilter
        handleMentorFilter={handleMentorFilter}
        menteeDashboardProfileInfo={menteeDashboardFindMentor.profile_info}
        mentorFilter={mentorFilter}
      />
      <MenteeDashboardFindMentorCards
        menteeDashboardFindMentor={menteeDashboardFindMentor.mentors}
        selectModal={selectModal}
        mentor={mentor}
        selectMentor={selectMentor}
        currentUser={currentUser}
        mentorFilter={mentorFilter}
      />

      {confirmationModal && (
        <MenteeDashboardConfirmationPopup
          displayModal={confirmationModal}
          closeModalDone={selectConfirmationModal}
          menteeDashboardProfileInfo={menteeDashboardFindMentor.profile_info}
          mentor={mentor}
          currentUser={currentUser}
          event={data}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  menteeDashboardFindMentor: state.menteeDashboard.menteeDashboardFindMentor,
  menteeDashboardEventScheduled:
    state.menteeDashboard.menteeDashboardEventScheduled,
});
export default connect(mapStateToProps, {
  getDashboardFindMentorData,
  postEventScheduled,
})(MenteeDashboardFindMentor);
