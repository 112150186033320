import {
  GET_ALL_USERS,
  GET_ALL_NEW_USERS,
  USERS_LOADING,
  SAVE_LINK,
  GET_LINKS,
  GET_CALENDLY_TAB,
  PUT_REVOKE,
  PUT_INVITE,
  GET_SEATS_INFO,
  ACCEPT_USER,
  REJECT_USER,
  POST_ACCEPT,
  LOGIN,
  CHECK
} from "../types/types";

const initialState = {
  newUsersLoading: false,
  newUsers: {},
  allUsers: {},
  saveLink: null,
  links: null,
  getCalendlyTab: null,
  putRevoke: null,
  putInvite: null,
  seatsInfo: null,
  acceptedUser: null,
  rejectedUser: null,
  postAccept: null,
  login: null,
  check: null
};

const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    case USERS_LOADING:
      return {
        ...state,
        newUsersLoading: true,
      };
    case GET_ALL_NEW_USERS:
      return {
        ...state,
        newUsers: action.payload,
        newUsersLoading: false,
      };
    case GET_ALL_USERS:
      return {
        ...state,
        allUsers: action.payload,
        newUsersLoading: false,
      };
    case SAVE_LINK:
      return {
        ...state,
        saveLink: action.payload,
      };
    case GET_LINKS:
      return {
        ...state,
        links: action.payload,
      };
    case GET_CALENDLY_TAB:
      return {
        ...state,
        getCalendlyTab: action.payload,
      };
    case PUT_REVOKE:
      return {
        ...state,
        putRevoke: action.payload,
      };
    case PUT_INVITE:
      return {
        ...state,
        putInvite: action.payload,
      };
    case GET_SEATS_INFO:
      return {
        ...state,
        seatsInfo: action.payload,
      };
    case ACCEPT_USER:
      return {
        ...state,
        acceptedUser: action.payload,
      };
    case REJECT_USER:
      return {
        ...state,
        rejectedUser: action.payload,
      };
    case POST_ACCEPT:
      return {
        ...state,
        postAccept: action.payload
      }
    case LOGIN:
      return {
        ...state,
        login: action.payload
      }
    case CHECK:
      return {
        ...state,
        check: action.payload
      }


    default:
      return state;
  }
};
export default adminReducer;
