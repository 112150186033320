import React from "react";
import lightLogo from "../../../../assets/sign-up/Group 396.svg";
import { connect } from "react-redux";

import MentorSignupForm from "./MentorSignupForm";
import { Link } from "react-router-dom";

const MentorSignup = (props) => {
  const { mentorForm, loading } = props.auth;
  return (
    <div className="sign-up-body">
      <div className="sign-up-container">
        <div className="sign-in-logo-container">
          <Link to="/">
            <img src={lightLogo} alt="" />
          </Link>
        </div>
        <div>
          <div className="sign-up-panel">
            {loading && loading ? (
              <h1 style={{ textAlign: "center" }}>Loading...</h1>
            ) : (
              <div className="form-wrapper">
                <MentorSignupForm mentorForm={mentorForm} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(MentorSignup);
