import React, { useEffect } from "react";
import SignIn from "../../components/auth/sign-in/SignIn";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import { useIsDesktop } from "../../helper/useIsDesktop";
import NotAccessibleForMobile from "../../components/landing-page/error-pages/NotAccessibleForMobile";

const SignInRoute = (props) => {
  const { currentUser, history } = props;
  const { isDesktop } = useIsDesktop();

  useEffect(() => {
    //user loggedin ? redirect to status page.

    if (currentUser.loggedIn) {
      history.push("/sign-up/status");
    }
  }, [currentUser.loggedIn]);
  useEffect(() => {
    document.title = "Cardo | Sign In";
  }, []);
  return <div>{isDesktop ? <SignIn /> : <NotAccessibleForMobile />}</div>;
};

const mapStateToProps = (state) => ({
  currentUser: state.auth.currentUser,
});
export default connect(mapStateToProps)(withRouter(SignInRoute));
