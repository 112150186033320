import React from "react";
import AdminLogin from "../../components/admin/dashboard/AdminLogin";

const AdminLoginRoute = () => {
  return (
    <div>
      <AdminLogin />
    </div>
  );
};

export default AdminLoginRoute;
