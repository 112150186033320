import React from "react";
import { useMobilePopup } from "../../../hooks/useMobilePopup";
import "../../../styles/donate.css";
const Donate = () => {
  const { openMobilePopup } = useMobilePopup("mobile-modal");

  return (
    <div>
      {openMobilePopup}

      <div className="donate-header">
        <div className="donate-container">
          <div className="frame-container">
            <iframe
              title="donateFrame"
              src="https://givebutter.com/embed/c/hellocardo"
              width="100%"
              style={{ borderRadius: "10px" }}
              name="givebutter"
              frameBorder={0}
              scrolling="auto"
              seamless
              allowpaymentrequest="true"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Donate;
