import React from "react";

const CalendlyInnerTabs = (props) => {
  const { activeTab, handleActiveTab } = props;
  return (
    <div className="admin_dashboard_calendly_tabs">
      <div
        className={
          activeTab === "pipeline" && "admin_dashboard_calendly_tabs_active_tab"
        }
        onClick={() => handleActiveTab("pipeline")}
      >
        Pipeline
      </div>
      <div
        className={
          activeTab === "active" && "admin_dashboard_calendly_tabs_active_tab"
        }
        onClick={() => handleActiveTab("active")}
      >
        Active
      </div>
      <div
        className={
          activeTab === "revoke" && "admin_dashboard_calendly_tabs_active_tab"
        }
        onClick={() => handleActiveTab("revoke")}
      >
        Revoked
      </div>
    </div>
  );
};

export default CalendlyInnerTabs;
