import React, { useEffect, useState } from "react";
import Sidebar from "../dashboard-layout/Sidebar";
import "../../../styles/dashboard/dashboard.css";
import { withRouter } from "react-router-dom";
import DashboardHeader from "../dashboard-layout/DashboardHeader";
import MenteeDashboardHome from "./mentee-dashboard-components/MenteeDashboardHome";
import MenteeDashboardFindMentor from "./mentee-dashboard-components/MenteeDashboardFindMentor";
import MenteeDashboardMySessions from "./mentee-dashboard-components/MenteeDashboardMySessions";
import { connect } from "react-redux";
import MenteeDashboardBookingDetails from "./mentee-dashboard-components/mentee-dashboard-booking-details/MenteeDashboardBookingDetails";
// Sidebar Icons
import homeIcon from "../../../assets/dashboard/sidebar/homeIcon.svg";
import findMentorIcon from "../../../assets/dashboard/sidebar/findMentorIcon.svg";
import sessionsIcon from "../../../assets/dashboard/sidebar/sessionsIcon.svg";
import faqIcon from "../../../assets/dashboard/sidebar/faqIcon.svg";

import homeIconActive from "../../../assets/dashboard/sidebar/homeIconActive.svg";
import findMentorIconActive from "../../../assets/dashboard/sidebar/findMentorIconActive.svg";
import sessionsIconActive from "../../../assets/dashboard/sidebar/sessionsIconActive.svg";

const MenteeDashboard = (props) => {
  useEffect(() => {
    document.body.classList.add("dashboard-body-color");
    return () => document.body.classList.remove("dashboard-body-color");
  }, []);
  const [sideBarLinks, setSideBarLinks] = useState([
    {
      title: "Home",
      icon: homeIcon,
      activeIcon: homeIconActive,
    },
    {
      title: "Find a Mentor",
      icon: findMentorIcon,
      activeIcon: findMentorIconActive,
    },
    {
      title: "My Sessions",
      icon: sessionsIcon,
      activeIcon: sessionsIconActive,
    },
    {
      title: "FAQ",
      icon: faqIcon,
      activeIcon: homeIconActive,
      link: "https://support.hellocardo.org/",
    },
  ]);
  const [currentLink, setCurrentLink] = useState("");
  const [modalDetails, setModalDetails] = useState(false);

  const selectModalDetails = () => {
    setModalDetails(!modalDetails); // true/false toggle
  };

  const [modalMentorDetails, setModalMentorDetails] = useState({});

  const handleModalMentorDetails = (mentorData) => {
    setModalMentorDetails(mentorData);
  };
  useEffect(() => {
    const search = props.location.search;
    const tabs = new URLSearchParams(search).get("tabs");
    const newTabs = tabs?.replace(/_/g, " ");
    if (tabs) {
      setCurrentLink(newTabs);
    } else {
      setCurrentLink("home");
    }
  }, [props.location.search]);
  useEffect(() => {
    if (props.location.state && props.location.state.prevLink) {
      setCurrentLink(props.location.state.prevLink);
    }
  }, [currentLink]);
  const handleCurrentLink = (linkName) => {
    setCurrentLink(linkName);
  };
  const renderMenteeDashboardMainContent = () => {
    switch (currentLink.toLocaleLowerCase()) {
      case "home":
        return (
          <MenteeDashboardHome
            currentUser={props.currentUser}
            handleCurrentLink={handleCurrentLink}
            selectModalDetails={selectModalDetails}
            handleModalMentorDetails={handleModalMentorDetails}
          />
        );
      case "find a mentor":
        return <MenteeDashboardFindMentor currentUser={props.currentUser} />;
      case "my sessions":
        return (
          <MenteeDashboardMySessions
            currentUser={props.currentUser}
            handleCurrentLink={setCurrentLink}
            selectModalDetails={selectModalDetails}
            handleModalMentorDetails={handleModalMentorDetails}
          />
        );
    }
  };
  return (
    <div className="dashboard-body">
      {modalDetails && (
        <MenteeDashboardBookingDetails
          displayModal={modalDetails}
          closeModal={selectModalDetails}
          modalMentorDetails={modalMentorDetails}
          // currentUser={currentUser}
        />
      )}
      <Sidebar
        currentLink={currentLink}
        handleCurrentLink={handleCurrentLink}
        sideBarLinks={sideBarLinks}
      />
      <div className="dashboard-main">
        <div className="dashboard-main-container">
          <DashboardHeader
            currentUser={props.currentUser}
            handleCurrentLink={handleCurrentLink}
            currentLink={currentLink}
          />

          {renderMenteeDashboardMainContent()}
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  currentUser: state.auth.currentUser,
});
export default connect(mapStateToProps)(withRouter(MenteeDashboard));
