import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getDashboardMySessionsData } from "../../../../state-management/actions/dashboard/mentee-dashboard/dashboardActions";

import MenteeDashboardMySessionsComponent from "./mentee-dashboard-my-sessions-components/MySessions";

import "../../../../styles/dashboard/mentee-dashboard/mentee-dashboard-home.css";

const MenteeDashboardMySessions = (props) => {
  useEffect(() => {
    props.getDashboardMySessionsData();
  }, []);
  const {
    handleCurrentLink,
    menteeDashboardMySessions,
    selectModalDetails,
    handleModalMentorDetails,
  } = props;

  return (
    <div style={{ margin: "0", width: "880px" }}>
      <MenteeDashboardMySessionsComponent
        handleCurrentLink={handleCurrentLink}
        events={menteeDashboardMySessions}
        selectModalDetails={selectModalDetails}
        handleModalMentorDetails={handleModalMentorDetails}
      />
    </div>
  );
};
const mapStateToProps = (state) => ({
  menteeDashboardMySessions: state.menteeDashboard.menteeDashboardMySessions,
});
export default connect(mapStateToProps, { getDashboardMySessionsData })(
  MenteeDashboardMySessions
);
