import React, { useEffect } from "react";
import { connect } from "react-redux";
import MentorSignup from "../../components/auth/sign-up/mentor-sign-up-steps/MentorSignup";
import {
  innerScript,
  signupMentor,
} from "../../state-management/actions/authActions";
const MentorSignupRoute = (props) => {
  useEffect(() => {
    props.signupMentor();
    props.innerScript();
  }, []);
  return (
    <div>
      <MentorSignup />
    </div>
  );
};

export default connect(null, { innerScript, signupMentor })(MentorSignupRoute);
