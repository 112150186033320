import React from "react";
import Slider from "react-slick";

const TeamCardsMobile = ({ teamData, handleTeamPopup, handleTeamMemeber }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    arrows: false,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const handleCardClick = (data) => {
    handleTeamPopup();
    handleTeamMemeber(data);
  };
  return (
    <div style={{ marginTop: "40px", width: "100%" }}>
      <Slider {...settings}>
        {teamData.team &&
          teamData.team.map((teamMember, index) => {
            return (
              <div className="mentor-container" key={index}>
                <div
                  style={{
                    backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0) 40%, rgba(0,0,0,1) 100%),url('${teamMember.imageUrl}')`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center center",
                    height: "502.7px",
                    width: "100%",
                    borderRadius: "16.798px",
                  }}
                  onClick={() => handleCardClick(teamMember)}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                      textAlign: "center",
                      height: "100%",
                    }}
                  >
                    <span
                      style={{
                        color: "white",
                        fontWeight: "600",
                        fontSize: "24px",
                        lineHeight: "50px",
                        textAlign: "center",
                        fontFamily: "Neometric Medium",
                      }}
                    >
                      {teamMember.name}
                    </span>
                    <span
                      style={{
                        color: "white",
                        fontWeight: "600",
                        fontSize: "19px",
                        lineHeight: "23px",
                        textAlign: "center",
                        fontFamily: "Neometric Medium",
                        marginBottom: "20px",
                      }}
                    >
                      {teamMember.title}
                    </span>
                    {/* <span
                      style={{
                        color: "white",
                        fontSize: "14px",
                        lineHeight: "16px",
                        fontFamily: "eina",
                      }}
                    >
                      {teamMember.area}
                      <i
                        className="fas fa-caret-right"
                        style={{ color: "white", margin: "0px 5px" }}
                      ></i>
                      {teamMember.company}
                    </span> */}
                  </div>
                </div>
              </div>
            );
          })}
      </Slider>
    </div>
  );
};
export default TeamCardsMobile;
