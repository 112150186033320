import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import SignupStatus from "../../components/auth/sign-up/sign-up-status/SignupStatus";
import NotAccessibleForMobile from "../../components/landing-page/error-pages/NotAccessibleForMobile";
import { useIsDesktop } from "../../helper/useIsDesktop";

const SignupStatusRoute = () => {
  const { isDesktop } = useIsDesktop();

  useEffect(() => {
    document.title = "Cardo | Status";
  }, []);
  return <div>{isDesktop ? <SignupStatus /> : <NotAccessibleForMobile />}</div>;
};

export default withRouter(SignupStatusRoute);
