import "./App.css";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  withRouter,
} from "react-router-dom";
import AboutUsRoute from "./routes/AboutUsRoute";
import DonateRoute from "./routes/DonateRoute";
import HomeRoute from "./routes/HomeRoute";
import BecomeMentorRoute from "./routes/BecomeMentorRoute";
import FindMentorRoute from "./routes/FindMentorRoute";

//error pages

import ScrollToTop from "./hooks/scrollToTop";
import PrivacyPolicyRoute from "./routes/PrivacyPolicyRoute";
import TermsOfServiceRoute from "./routes/TermsOfServiceRoute";
import SignupRoute from "./routes/signup/SignupRoute";
import { LinkedInPopUp } from "react-linkedin-login-oauth2";
import SignupThankyouRoute from "./routes/signup/SignupThankyouRoute";
import AdminDashboardRoute from "./routes/admin/AdminDashboardRoute";
import SignInRoute from "./routes/signin/SignInRoute";
import { useEffect, useState } from "react";
import { signinStatus } from "./state-management/actions/authActions";
import { getCsurfToken } from "./state-management/actions/getCsrfToken";

import { connect } from "react-redux";
import SignupStatusRoute from "./routes/signup/SignupStatusRoute";
import PrivateMenteeRoute from "./routes/private-route/PrivateMenteeRoute";
import PrivateMentorRoute from "./routes/private-route/PrivateMentorRoute";

import MenteeDashboardRoute from "./routes/dashboard/mentee-dashboard/MenteeDashboardRoute";
import MenteeProfileRoute from "./routes/dashboard/mentee-dashboard/MenteeProfileRoute";
import MentorDashboard from "./components/dashboard/mentor-dashboard/MentorDashboard";
import MentorProfileRoute from "./routes/dashboard/mentor-dashboard/MentorProfileRoute";
import MenteeSignupRoute from "./routes/signup/MenteeSignupRoute";
import MentorVideoCall from "./components/dashboard/mentor-dashboard/mentor-dashboard-components/mentor-dashboard-my-sessions-components/MentorVideoCall";
import MentorSignupRoute from "./routes/signup/MentorSignupRoute";
import { cleanSuccess } from "./state-management/actions/successActions";
import { clearErrors } from "./state-management/actions/errorActions";
import AdminLoginRoute from "./routes/admin/AdminLoginRoute";

function App(props) {
  // try later
  // useConstructor( async () => {
  //   await props.signinStatus()
  // })
  const [refresh, setRefresh] = useState(false);
  const handleRefresh = () => {
    setRefresh(true);
  };
  useEffect(() => {
    props.signinStatus(handleRefresh);
  }, []);

  return (
    <Router basename="/">
      <ScrollToTop />
      {refresh ? (
        <div className="page_wrapper" style={{ overflowX: "auto" }}>
          <Switch>
            {/* Landing Page Route */}
            <Route exact path="/" component={withRouter(HomeRoute)} />
            <Route
              exact
              path="/about-us"
              component={withRouter(AboutUsRoute)}
            />
            <Route exact path="/donate" component={withRouter(DonateRoute)} />
            <Route
              exact
              path="/become-mentor"
              component={withRouter(BecomeMentorRoute)}
            />
            <Route
              exact
              path="/find-mentor"
              component={withRouter(FindMentorRoute)}
            />
            <Route
              exact
              path="/privacy-policy"
              component={withRouter(PrivacyPolicyRoute)}
            />
            <Route
              exact
              path="/terms-of-service"
              component={withRouter(TermsOfServiceRoute)}
            />
            {/* Signup Routes */}
            <Route exact path="/sign-up" component={withRouter(SignupRoute)} />
            <Route
              exact
              path="/sign-up/mentee"
              component={withRouter(MenteeSignupRoute)}
            />
            <Route
              exact
              path="/sign-up/mentor"
              component={withRouter(MentorSignupRoute)}
            />

            <Route
              exact
              path="/sign-up/status"
              component={withRouter(SignupStatusRoute)}
            />
            <Route
              exact
              path="/sign-up/thankyou"
              component={SignupThankyouRoute}
            />
            {/* LinkedIn Route */}
            <Route exact path="/linkedin" component={LinkedInPopUp} />

            {/* Later Private Route */}
            <Route
              exact
              path="/admin/dashboard"
              component={withRouter(AdminDashboardRoute)}
            />
            {/* Later Private Route */}
            <Route
              exact
              path="/admin/login"
              component={withRouter(AdminLoginRoute)}
            />
            {/* Signin Routes */}
            <Route exact path="/sign-in" component={withRouter(SignInRoute)} />

            {/* Mentee Dashboard */}
            <PrivateMenteeRoute
              exact
              path="/mentee/dashboard"
              component={withRouter(MenteeDashboardRoute)}
            />
            <PrivateMenteeRoute
              exact
              path="/mentee/profile"
              component={withRouter(MenteeProfileRoute)}
            />

            {/* Mentor Dashboard */}
            <PrivateMentorRoute
              exact
              path="/mentor/dashboard"
              component={withRouter(MentorDashboard)}
            />
            <PrivateMentorRoute
              exact
              path="/mentor/profile"
              component={withRouter(MentorProfileRoute)}
            />

            {/*Videocall route*/}
            <MentorVideoCall
              exact
              path="/mentor/videocall/:session"
              component={withRouter(MentorVideoCall)}
            />

            {/* error route */}
          </Switch>
        </div>
      ) : null}
    </Router>
  );
}

export default connect(null, {
  signinStatus,
  getCsurfToken,
  clearErrors,
  cleanSuccess,
})(withRouter(App));
