import React from "react";
import { Link, withRouter } from "react-router-dom";

const SidebarLinks = (props) => {
  const { currentLink, handleCurrentLink, iconActive, icon, title } = props;
  const setQueryTitle = (queryTitle) => {
    const queryTitleLow = queryTitle?.toLowerCase();
    const query = queryTitleLow?.replace(/ /g, "_");
    return query;
  };
  return (
    <div className="dashboard-sidebar-link-container">
      <Link
        to={{
          search: `?tabs=${setQueryTitle(title)}`,
          state: { prevLink: title },
        }}
        className={
          currentLink.toLowerCase() === title.toLowerCase()
            ? "sidebar-link sidebar-link-active"
            : "sidebar-link"
        }
        onClick={() => {
          handleCurrentLink(title);
        }}
      >
        <span style={{ marginRight: "18px" }}>
          {currentLink.toLowerCase() === title.toLowerCase() ? (
            <img src={iconActive} alt="" />
          ) : (
            <img src={icon} alt="" />
          )}
        </span>{" "}
        {title}
      </Link>
      <Link
        to={{
          state: { prevLink: title },
        }}
        className={
          currentLink.toLowerCase() === title.toLowerCase()
            ? "sidebar-link-sm sidebar-link-active"
            : "sidebar-link-sm"
        }
        onClick={() => handleCurrentLink(title)}
      >
        <span>
          {currentLink === title ? (
            <img src={iconActive} alt="" />
          ) : (
            <img src={icon} alt="" />
          )}
        </span>{" "}
        <div className="sidebar-link-sm-tooltip">
          <div>{title}</div>
        </div>
      </Link>
    </div>
  );
};

export default withRouter(SidebarLinks);
