import React from "react";

const CardoButton = (props) => {
  const { width, text, margin, className, handler, height, style, id, icon } =
    props;
  return (
    <button
      className={`cardo-btn ${className}`}
      style={{
        ...style,
        width: width && width,
        height: height && height,
        margin: margin && margin,
      }}
      id={id && id}
      onClick={(e) => handler && handler(e)}
    >
      {text}
      {icon && icon}
    </button>
  );
};

export default CardoButton;
