import React from "react";
import ReactHtmlParser from "react-html-parser";

const MenteeSignupStep1Welcome = (props) => {
  const { title, step, onPrevStep, onStepChange, data } = props;
  return (
    <div>
      <h3 className="sign-up-form-heading">Welcome: Become a Mentee</h3>
      <div className="step-form-content">
        <div className="form-line">
          <div className="sign-up-form-paragraph">
            {ReactHtmlParser(data.q73_ltpgtthanksFor.text)}
          </div>
        </div>
      </div>
      <div className="form-step-btn-group">
        {step > 0 && (
          <button
            type="button"
            className="cardo-btn back-btn"
            onClick={() => onPrevStep(step - 1)}
          >
            Go back
          </button>
        )}
        <button
          type="button"
          className="cardo-btn next-btn"
          onClick={(e) => onStepChange(data, e)}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default MenteeSignupStep1Welcome;
