import React from "react";
import ReactHtmlParser from "react-html-parser";
import FileInput from "../../../form-component/FileInput";

const MenteeSignupStep6Video = (props) => {
  const {
    title,
    step,
    onPrevStep,
    onStepChange,
    data,
    onChange,
    stepKey,
    errors,
    onRadioChange,
    onCheckboxChange,
    onFileChange,
  } = props;
  return (
    <div>
      <h3 className="sign-up-form-heading">{title}</h3>
      <div className="step-form-content">
        <div className="form-line">
          <div className="sign-up-form-paragraph">
            {ReactHtmlParser(data.q90_input90.text)}
          </div>
        </div>
        <div className="form-line">
          <FileInput
            id={`${data.q6_uploadYour.name}_id1`}
            onChange={onFileChange}
            error={errors[data.q6_uploadYour.name]}
            description={data.q6_uploadYour.description}
            name={data.q6_uploadYour.name}
            stepKey={stepKey}
            fileName={data.q6_uploadYour.fileName}
            text={data.q6_uploadYour.text}
            buttonText={data.q6_uploadYour.buttonText}
          />
        </div>
      </div>
      <div className="form-step-btn-group">
        {step > 0 && (
          <button
            type="button"
            className="cardo-btn back-btn"
            onClick={() => onPrevStep(step - 1)}
          >
            Go back
          </button>
        )}
        <button
          type="button"
          className="cardo-btn next-btn"
          onClick={(e) => onStepChange(data, e)}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default MenteeSignupStep6Video;
