import React, { useEffect, useState } from "react";

const TextArea = (props) => {
  const {
    id,
    label,
    placeholder,
    value,
    onChange,
    onKeyDown,
    name,
    disabled,
    toolTip,
    style,
    errorMsg,
  } = props;
  const [count, setCount] = useState(0);

  useEffect(() => {
    setCount(value.length);
  }, [value.length]);

  const onKeyDownHandle = (e) => {
    if (e.keyCode == 13) {
      return e.preventDefault();
    }
    if (count === 0 && (e.keyCode == 32 || e.keyCode == 13)) {
      return e.preventDefault();
    }
    if (count < 300) {
      return onChange(e);
    } else {
      if (e.keyCode == 8) {
        return onChange(e);
      } else {
        return e.preventDefault();
      }
    }
  };

  return (
    <div className="mb-5 position-relative">
      <div style={style}>
        <div className="display-flex">
          <label className="form-label" htmlFor={id}>
            {label}
          </label>
          {toolTip && (
            <div className="tooltip">
              ?<div className="tooltiptext">{toolTip}</div>
            </div>
          )}
        </div>
        <textarea
          onPaste={(e) => {
            e.preventDefault();
            return false;
          }}
          id={id}
          className={"form-textarea"}
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={onChange}
          onKeyDown={(e) => onKeyDownHandle(e)}
          autoComplete="off"
          disabled={disabled}
        />
        <div
          className="font-Neometric-Regular"
          style={{
            position: "absolute",
            bottom: "15px",
            right: "15px",
            fontSize: "14px",
            color: "rgba(85, 85, 219, 0.4)",
          }}
        >
          {300 - count} characters left
        </div>
      </div>
      {errorMsg && <div className="signup-form-validation">{errorMsg}</div>}
    </div>
  );
};

export default TextArea;
