import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getMentorDashboardHomeData } from "../../../../state-management/actions/dashboard/mentor-dashboard/mentorDashboardActions";

import MentorDashboardAccordian from "./mentor-dashboard-home-components/MentorDashboardAccordian";
import MentorDashboardHomeBanner from "./mentor-dashboard-home-components/MentorDashboardHomeBanner";
import MentorDashboardMentorshipAreas from "./mentor-dashboard-home-components/MentorDashboardMentorshipAreas";
import MentorDashboardUpcomingSessions from "./mentor-dashboard-home-components/MentorDashboardUpcomingSessions";

import "../../../../styles/dashboard/mentor-dashboard/mentor-dashboard-home.css";
import mentorRightColImg from "../../../../assets/dashboard/mentor-dashboard/mentor-dashboard-home/Group 875.svg";
const MentorDashboardHome = (props) => {
  useEffect(() => {
    props.getMentorDashboardHomeData();
  }, []);
  const {
    mentorDashboardHome,
    currentUser,
    handleCurrentLink,
    handleModalMentorDetails,
    selectModalDetails,
    selectInviteModals,
  } = props;
  return (
    <div className="mentee-dashboard-home">
      <div className="mentee-dashboard-home-row">
        <div className="mentee-dashboard-home-leftCol">
          <MentorDashboardHomeBanner currentUser={currentUser} />
          <MentorDashboardUpcomingSessions
            handleCurrentLink={handleCurrentLink}
            mentorDashboardHome={mentorDashboardHome}
            handleModalMentorDetails={handleModalMentorDetails}
            selectModalDetails={selectModalDetails}
          />
          <MentorDashboardMentorshipAreas
            mentorDashboardHome={mentorDashboardHome}
          />
        </div>
        <div>
          <div className="mentor-dashboard-home-rightCol">
            <div className="mentor-dashboard-home-rightCol-row-1">
              <div className="mentor-dashboard-home-rightCol-row-1-container">
                <div className="font-Neometric-Bold font-size-20 mb-25">
                  Helpful Links
                </div>
                <div className="mentor-dashboard-home-accordion-container">
                  <MentorDashboardAccordian
                    data={mentorDashboardHome.accordionTips}
                  />
                </div>
              </div>
            </div>
            <div className="mentor-dashboard-home-rightCol-row2">
              <div className="mentor-dashboard-home-rightCol-row2-container">
                <div>
                  <img src={mentorRightColImg} alt="" />
                </div>
                <div>
                  <div className="font-Neometric-Bold font-size-20 mb-10 line-height-20">
                    Spread the word!
                  </div>
                  <div className="font-eina font-size-12 line-height-14">
                    Know someone that would be a great mentor or mentee on
                    Cardo?
                  </div>
                  <div
                    className="cardo-btn cardo-btn-sm  btn-bg-lightblue font-size-14"
                    style={{
                      margin: "10px 0px",
                      width: "70px",
                      height: "25px",
                      fontFamily: "Neometric Medium",
                      lineHeight: "14px",
                    }}
                    onClick={() => {
                      selectInviteModals();
                    }}
                  >
                    Invite
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  mentorDashboardHome: state.mentorDashboard.mentorDashboardHome,
});
export default connect(mapStateToProps, { getMentorDashboardHomeData })(
  MentorDashboardHome
);
