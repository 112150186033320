import React from "react";
import leftColImg from "../../../../assets/sign-up/status/Group 459.svg";
import "../../../../styles/signup/sign-up-status.css";
import lightLogo from "../../../../assets/sign-up/Group 396.svg";
import { connect } from "react-redux";
import { changeStatusOfFirstTime } from "../../../../state-management/actions/authActions";
import { Link } from "react-router-dom";

const SignupStatusApproved = (props) => {
  const handleStartNow = () => {
    // function here
    props.changeStatusOfFirstTime();
  };
  return (
    <div className="sign-up-status-approved">
      <div className="position-relative">
        <div className="signup-logo-container">
          <Link to="/">
            <img src={lightLogo} alt="" />
          </Link>
        </div>{" "}
      </div>
      <div className="row sign-up-status-row">
        <div className="col-6 sign-up-status-leftCol">
          <div className="sign-up-status-leftCol-container">
            <img src={leftColImg} alt="" />
          </div>
        </div>
        <div className="col-6 sign-up-status-rightCol">
          <div className="sign-up-status-approved-rightCol-container">
            <div className="sign-up-status-desc-container">
              <div className="sign-up-status-desc">
                <h3 className="sign-up-status-rightCol-title">
                  Welcome to the Cardo Community!
                </h3>
                <br /> <br /> <br />
                {props.currentUser.type === "mentee" ? (
                  <p className="sign-up-status-rightCol-desc">
                    Welcome! We’re thrilled to have you join our community and
                    hope you will enjoy your journey with Cardo. <br /> <br />{" "}
                    To get started, please make sure your profile is complete.
                    Once complete, find a mentor and get started! <br /> <br />{" "}
                    Feel free to reach out to us at support@hellocardo.org if
                    you have any questions!
                  </p>
                ) : (
                  <p className="sign-up-status-rightCol-desc">
                    Welcome! We’re thrilled to have you join our community and
                    hope you will enjoy your journey with Cardo. <br /> <br />{" "}
                    To get started, please follow the upcoming prompts to make
                    sure your profile is set up correctly. Once complete,
                    mentees can start booking sessions with you! <br /> <br />{" "}
                    Feel free to reach out to us at support@hellocardo.org if
                    you have any questions!
                  </p>
                )}
              </div>
            </div>
            <button
              className="cardo-btn start-now-btn"
              onClick={handleStartNow}
            >
              Start Now
              <i
                class="fas fa-long-arrow-alt-right"
                style={{ marginLeft: "5px" }}
              ></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, { changeStatusOfFirstTime })(SignupStatusApproved);
