import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import CropImageBeforUploadPopup from "./CropImageBeforUploadPopup";
import CropImageTest from "./CropImageTest";
import {
  putProfileToggle,
  getProfileToggleState,
} from "../../../../state-management/actions/dashboard/mentor-dashboard/profileActions";

const ProfileBodyHeader = (props) => {
  const inputRef = useRef();
  const { currentUser, setUserImg, userImg } = props;
  const [file, setFile] = useState({});
  const [fileLoaded, setFileLoaded] = useState(false);
  const [closeModal, setCloseModal] = useState(true);
  const [toggleLoading, setToggleLoading] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (currentUser.type === "mentor") {
      props.getProfileToggleState();
    }
  }, []);
  useEffect(() => {
    console.log(props);
    if (props?.isProfileActive?.isProfileActive) {
      setToggle(props.isProfileActive.isProfileActive);
    }
    if (props.progress) {
      setProgress(props.progress);
    }
  }, [props]);

  const selectModalDetails = () => {
    inputRef.current.value = "";
    setFile(null);
    setFileLoaded(false);
    setCloseModal(!closeModal); // true/false toggle
  };
  // console.log(value);
  const imageOnChange = (e) => {
    setFile(e.target.files[0]);
    setFileLoaded(true);
    setCloseModal(!closeModal);
  };

  const onClickProfileToggle = (e) => {
    setToggleLoading(true);
    props.putProfileToggle(
      { profileToggle: e.target.checked },
      setToggleLoading,
      setToggle
    );
  };

  return (
    <div className="profile-body-user-header">
      {selectModalDetails && (
        <CropImageTest
          selectModalDetails={selectModalDetails}
          closeModal={closeModal}
          currentUser={currentUser}
          file={file}
          fileLoaded={fileLoaded}
          setUserImg={setUserImg}
        />
      )}
      <div className="display-flex profile-body-user-avatar-img-mentor">
        <div className="profile-body-user-image-container hover-overlay">
          <img
            className="profile-body-user-avatar-img mr-10"
            src={userImg}
            alt=""
          />

          <a href="#!">
            <label htmlFor="imageInput" className="mask" />
            <input
              ref={inputRef}
              type="file"
              id="imageInput"
              name="imageInput"
              style={{ display: "none" }}
              onChange={(e) => imageOnChange(e)}
            />
          </a>
        </div>
        <div className="profile-body-user-header-name-type">
          <h3 className="profile-body-user-header-name">
            {" "}
            {`${currentUser.firstName} ${currentUser.lastName}`}
          </h3>
          <div className="profile-body-user-header-type ">
            {currentUser.type}
          </div>
        </div>
      </div>
      {currentUser.type === "mentor" && (
        <div className="on-off-btn">
          <input
            type="checkbox"
            onClick={
              progress === 100 &&
              (props?.status?.status === "accepted" ||
                props?.invitationStatus?.status === "accepted" ||
                props?.mentorEmailInvitation?.status === "accepted")
                ? (e) => {
                    onClickProfileToggle(e);
                  }
                : () => {
                    props.handleErrorPopup();
                  }
            }
            disabled={
              toggleLoading &&
              progress === 100 &&
              (props?.status?.status === "accepted" ||
                props?.invitationStatus?.status === "accepted" ||
                props?.mentorEmailInvitation?.status === "accepted")
                ? true
                : false
            }
            checked={toggle}
            className="switch"
          />
          <div className="btn-text">
            <label>Off</label>
            <label>On</label>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  status: state.mentorDashboard.mentorDashboardEditProfile.calendar,
  mentorEmailInvitation: state.mentorDashboard.mentorEmailInvitation,
  invitationStatus: state.mentorDashboard.invitationStatus,
  isProfileActive: state.mentorDashboard.isProfileActive,
  progress: state.mentorDashboard.mentorDashboardEditProfile.percent,
});

export default connect(mapStateToProps, {
  putProfileToggle,
  getProfileToggleState,
})(ProfileBodyHeader);
