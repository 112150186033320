import React, { useState, useEffect } from "react";
import "../../../styles/navbar.css";
import darkLogo from "../../../assets/lightLogo.png";
import lightLogo from "../../../assets/darkLogo.png";
import "../../../styles/popup.css";
import "../../../styles/popup.css";
import { Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { logoutUser } from "../../../state-management/actions/authActions";
import { getCsurfToken } from "../../../state-management/actions/getCsrfToken";
import { useIsDesktop } from "../../../helper/useIsDesktop";
import { useMobilePopup } from "../../../hooks/useMobilePopup";
import PopUp from "../popup/PopUp";

const Navbar = (props) => {
  const { dark, loginColor } = props;
  let style = {
    navBar_a: {
      color: dark ? "white" : "black",
      textDecoration: "none",
    },
  };
  // const [isLogged, setIsLogged] = useState(false);
  // useEffect(() => {
  //   setIsLogged(props.currentUser.loggedIn);
  // }, [props.currentUser.loggedIn]);
  const { isDesktop } = useIsDesktop();

  const [isExpended, setIsExpended] = useState(false);
  const [navBar, setNavBar] = useState(false);

  const handleToggle = () => {
    setIsExpended(!isExpended);
    if (window.scrollY === 0) {
      setNavBar(!navBar);
    }
  };

  useEffect(() => {
    props.getCsurfToken();
  }, []);

  const onLogout = () => {
    props.logoutUser();
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const changeNavBarBg = () => {
    var sticky =
      document.querySelector(".alert") ||
      document.querySelector(".navBar_container");

    var origOffsetY = 0;
    if (sticky.classList.contains("alert")) {
      origOffsetY = sticky.clientHeight;
    }

    if (window.scrollY <= origOffsetY) {
      if (!isExpended) {
        setNavBar(false);
      } else {
        setNavBar(true);
      }
    } else {
      setNavBar(true);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", changeNavBarBg);
    return () => window.removeEventListener("scroll", changeNavBarBg);
  });
  const { openMobilePopupClick } = useMobilePopup("mobile-modal");

  return (
    <div>
      <div className={navBar ? "navBar_container active" : "navBar_container"}>
        <div className="container_wide nav_container">
          <div className="row">
            <div className="col-3 navBar_logo">
              <NavLink onClick={scrollToTop} to="/" className="brand-link">
                {navBar ? (
                  <img src={lightLogo}></img>
                ) : dark ? (
                  <img src={darkLogo}></img>
                ) : (
                  <img src={lightLogo}></img>
                )}
              </NavLink>
            </div>
            <div className="col-9 navBar_ul_wrapper">
              <i
                className={isExpended ? "fa fa-times" : "fa fa-bars"}
                aria-hidden="true"
                style={{ color: dark ? "white" : "black" }}
                onClick={(e) => handleToggle(e)}
              />
              <ul className={`navBar_ul ${isExpended ? "is-expanded" : ""}`}>
                <div>
                  <li className={dark ? "navBar_li white" : "navBar_li"}>
                    {" "}
                    <NavLink
                      onClick={scrollToTop}
                      activeClassName="selected-link"
                      className="nav-link"
                      to="/about-us"
                      style={style.navBar_a}
                    >
                      About Us
                    </NavLink>
                  </li>
                  <li className={dark ? "navBar_li white" : "navBar_li"}>
                    <NavLink
                      onClick={scrollToTop}
                      activeClassName="selected-link"
                      to="/find-mentor"
                      style={style.navBar_a}
                    >
                      Find your mentor
                    </NavLink>
                  </li>
                  <li className={dark ? "navBar_li white" : "navBar_li"}>
                    <NavLink
                      activeClassName="selected-link"
                      to="/become-mentor"
                      style={style.navBar_a}
                    >
                      Become a mentor
                    </NavLink>
                  </li>
                  <li className={dark ? "navBar_li white" : "navBar_li"}>
                    {" "}
                    <NavLink
                      activeClassName="selected-link"
                      to="/donate"
                      style={style.navBar_a}
                    >
                      Donate
                    </NavLink>
                  </li>
                </div>
                {props.currentUser.loggedIn ? (
                  <div className="navBar-btns-div">
                    <li className="navBar-btn">
                      <button
                        className="menu_btn login_menu_btn"
                        onClick={onLogout}
                        style={{ background: loginColor }}
                      >
                        Log Out
                      </button>
                    </li>
                    <li className="navBar-btn">
                      <Link
                        to={
                          props.currentUser.firstTime
                            ? `/sign-up/status`
                            : `/${props.currentUser.type}/dashboard`
                        }
                      >
                        <button
                          onClick={openMobilePopupClick}
                          className="menu_btn apply_menu_btn"
                        >
                          Dashboard
                        </button>
                      </Link>
                    </li>
                  </div>
                ) : (
                  <div className="navBar-btns-div">
                    <li className="navBar-btn">
                      <Link to={isDesktop ? "/sign-in" : "#"}>
                        <button
                          className="menu_btn login_menu_btn"
                          onClick={openMobilePopupClick}
                          style={{ background: loginColor }}
                        >
                          Log In
                        </button>
                      </Link>
                    </li>
                    <li className="navBar-btn">
                      <Link to={isDesktop ? "/sign-up" : "#"}>
                        <button
                          onClick={openMobilePopupClick}
                          className="menu_btn apply_menu_btn"
                        >
                          Apply Now
                        </button>
                      </Link>
                    </li>
                  </div>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  currentUser: state.auth.currentUser,
});
export default connect(mapStateToProps, { logoutUser, getCsurfToken })(Navbar);
