import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getAllUsers,
  updateUserData,
  revokeMentee,
  revokeMentor,
  deleteMentee,
  deleteMentor,
} from "../../../../state-management/actions/admin/adminDashboard";
import { clearErrors } from "../../../../state-management/actions/authActions";
import { cleanSuccess } from "../../../../state-management/actions/successActions";
import Toast from "../../../common/alerts/Toast";
import MenteeMentorBtnContainer from "../ru-admin-components/MenteeMentorBtnContainer";
import AdminResizableTable from "../table/AdminResizableTable";

const UserManagementTab = (props) => {
  const [activeBtn, setActiveBtn] = useState("mentee");
  const [menteeData, setMenteeData] = useState();
  const [mentorData, setMentorData] = useState();

  const [currentTableData, setCurrentTableData] = useState();

  const [allUsers, setAllUsers] = useState();
  useEffect(() => {
    props.getAllUsers();

    return () => {
      props.cleanSuccess();
      props.clearErrors();
    };
  }, []);

  useEffect(() => {
    if (props?.allUsers) {
      setAllUsers(props?.allUsers);
    }
  }, [props?.allUsers]);
  useEffect(() => {
    if (allUsers?.mentees?.length > 0) {
      let menteeTableData = [];
      allUsers?.mentees?.forEach((mentee) => {
        let obj = {};
        if (mentee.status !== "rejected") {
          obj.id = mentee._id;
          obj.trails = mentee.profile_info.preferred_trail;
          obj.first_name = mentee.first_name;
          obj.last_name = mentee.last_name;
          obj.support_areas = mentee.profile_info.support_areas.join(", ");
          obj.email = mentee.email;
          obj.education = mentee.profile_info.education;
          obj.industry = mentee.profile_info.preferred_industry;
          obj.subData = {};
          obj.subData.application = mentee.submission_id;
          obj.subData.credits = mentee.credits;
          obj.subData.trails = mentee.profile_info.preferred_trail;
          obj.type = mentee.type;
          menteeTableData.push(obj);
        }
      });
      setMenteeData(menteeTableData);
    }
    if (allUsers?.mentors?.length > 0) {
      let mentorTableData = [];
      allUsers?.mentors?.forEach((mentor) => {
        let obj = {};
        if (mentor.status !== "rejected") {
          obj.id = mentor._id;

          obj.trails = mentor.profile_info.trail.join(", ");
          obj.first_name = mentor.first_name;
          obj.last_name = mentor.last_name;
          obj.consulting_firm = mentor.profile_info.consulting_firm;
          obj.email = mentor.email;
          obj.education = mentor.profile_info.education;
          obj.industry = mentor.profile_info.industry.join(", ");
          obj.type = mentor.type;

          obj.subData = {};
          obj.subData.application = mentor.submission_id;
          obj.subData.trails = mentor.profile_info.trail.join(", ");
          mentorTableData.push(obj);
        }
      });
      setMentorData(mentorTableData);
    }
  }, [allUsers]);

  useEffect(() => {
    if (activeBtn === "mentee" && menteeData) {
      setCurrentTableData(menteeData);
    } else if (activeBtn === "mentor" && mentorData) {
      setCurrentTableData(mentorData);
    }
  }, [activeBtn, menteeData, mentorData]);

  const onSaveClick = (e, data) => {
    let updateData = { profile_info: {} };

    if (data.type === "mentee") {
      updateData._id = data.id;
      updateData.profile_info.preferred_trail = data.trails;
      updateData.first_name = data.first_name;
      updateData.last_name = data.last_name;
      updateData.profile_info.support_areas = data.support_areas.split(", ");
      updateData.email = data.email;
      updateData.profile_info.education = data.education;
      updateData.profile_info.preferred_industry = data.industry;
      updateData.submission_id = data.subData.application;
      updateData.credits = data.subData.credits;
      updateData.profile_info.preferred_trail = data.subData.trails;
      updateData.type = data.type;
    }
    if (data.type === "mentor") {
      updateData._id = data.id;

      updateData.profile_info.trails = data.trails.split(", ");
      updateData.first_name = data.first_name;
      updateData.last_name = data.last_name;
      updateData.profile_info.consulting_firm = data.consulting_firm;
      updateData.email = data.email;
      updateData.profile_info.education = data.education;
      updateData.profile_info.industry = data.industry.split(", ");
      updateData.type = data.type;
      updateData.submission_id = data.subData.application;
      updateData.profile_info.trail = data.subData.trails.split(", ");
    }
    // console.log("onSave ", updateData);
    props.updateUserData(updateData);
  };

  const onRevokeUser = (e, type, id) => {
    if (type === "mentee") {
      props.revokeMentee({ _id: id });
    }
    if (type === "mentor") {
      props.revokeMentor({ _id: id });
    }
  };
  const onDeleteUser = (e, type, id) => {
    if (type === "mentee") {
      props.deleteMentee(id);
    }
    if (type === "mentor") {
      props.deleteMentor(id);
    }
  };
  return (
    <div>
      {props?.errors?.errorMsg && (
        <Toast type="error" msg={props?.errors?.errorMsg} />
      )}
      {props?.success.success && <Toast type="success" msg={"Updated!"} />}

      <h3 className="admin_applications_header">User Management</h3>
      <MenteeMentorBtnContainer
        activeBtn={activeBtn}
        handleActiveBtn={setActiveBtn}
      />

      {currentTableData && currentTableData?.length > 0 && (
        <AdminResizableTable
          headers={Object.keys(currentTableData[0])}
          minCellWidth={90}
          data={currentTableData}
          hiddenColumns={["id", "subData", "type"]}
          expanded
          editable
          activeType={activeBtn}
          onRevokeUser={onRevokeUser}
          handler={(e) => {}}
          onSaveClick={onSaveClick}
          onDeleteUser={onDeleteUser}
        />
      )}
    </div>
  );
};
const mapStateToProps = (state) => ({
  allUsers: state.admin.allUsers,
  errors: state.errors,
  success: state.success,
});
export default connect(mapStateToProps, {
  getAllUsers,
  updateUserData,
  cleanSuccess,
  clearErrors,
  revokeMentee,
  revokeMentor,
  deleteMentee,
  deleteMentor,
})(UserManagementTab);
