const CalendlyMonthlyExpenseCard = ({ expense }) => {
  return (
    <div className="calendly_top_cards_wrapper">
      <div className="calendly_top_cards_wrapper_container">
        <h1 className="calendly_seats_wrapper calendly_seats_expenses">{`$${expense}`}</h1>
        <p className="calendly_seats_wrapper ">Monthly Expense</p>
      </div>
    </div>
  );
};

export default CalendlyMonthlyExpenseCard;
