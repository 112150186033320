import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getAllNewUsers,
  acceptUser,
  rejectUser,
} from "../../../../state-management/actions/admin/adminDashboard";
import CardoButton from "../../../common/btn/CardoButton";
import MenteeMentorBtnContainer from "../ru-admin-components/MenteeMentorBtnContainer";
import AdminResizableTable from "../table/AdminResizableTable";
import "../../../../styles/dashboard/admin-dasboard/application-tab/application-tab.css";
import ApplicationsInnerTabs from "./application-teb-components/ApplicationsInnerTabs";
const ApplicationsTab = (props) => {
  const { newUsers } = props.admin;
  const [allUsers, setAllUsers] = useState();
  const [activeTab, setActiveTab] = useState("mentee");
  const [activeInnerTab, setActiveInnerTab] = useState("pipeline");
  const [currentTableData, setCurrentTableData] = useState();
  const [searchFilter, setSearchFilter] = useState("");
  useEffect(() => {
    props.getAllNewUsers();
  }, []);
  const acceptUser = (id, type) => {
    setAllUsers();
    props.acceptUser(id, type);
  };
  const rejectUser = (id, type) => {
    setAllUsers();
    props.rejectUser(id, type);
  };

  useEffect(() => {
    if (props.admin?.acceptedUser?.success) {
      props.getAllNewUsers();
    }
    if (props.admin?.rejectedUser?.success) {
      props.getAllNewUsers();
    }
  }, [props]);

  useEffect(() => {
    if (Object.keys(newUsers).length > 0 && !allUsers) {
      setAllUsers(newUsers);
    }
  }, [newUsers]);

  useEffect(() => {
    if (activeTab === "mentee") {
      setCurrentTableData(
        allUsers?.mentees.filter((item) => {
          return item.status === "pending";
        })
      );
    } else {
      setCurrentTableData(
        allUsers?.mentors.filter((item) => {
          return item.status === "pending";
        })
      );
    }
  }, [activeTab, allUsers]);

  useEffect(() => {
    if (searchFilter === "") {
      if (activeTab === "mentee") {
        setCurrentTableData(allUsers?.mentees);
      } else {
        setCurrentTableData(allUsers?.mentors);
      }
    } else {
      setCurrentTableData((prev) =>
        prev.filter((val) => {
          for (let key in val) {
            if (
              key !== "last_login" &&
              key !== "last_call" &&
              val[key]
                .toString()
                .toLowerCase()
                .includes(searchFilter.toLowerCase())
            ) {
              return true;
            }
          }
          return false;
        })
      );
    }
  }, [searchFilter]);
  let setMentee = (status) => {
    return setCurrentTableData((prev) =>
      allUsers?.mentees.filter((item) => {
        return item?.status === status;
      })
    );
  };

  let setMentor = (status) => {
    return setCurrentTableData((prev) =>
      allUsers?.mentors.filter((item) => {
        return item?.status === status;
      })
    );
  };

  useEffect(() => {
    if (currentTableData) {
      if (activeInnerTab === "pipeline") {
        if (activeTab === "mentee") {
          setMentee("pending");
        } else {
          setMentor("pending");
        }
      }
      if (activeInnerTab === "accepted") {
        if (activeTab === "mentee") {
          setMentee("accepted");
        } else {
          setMentor("accepted");
        }
      }
      if (activeInnerTab === "rejected") {
        if (activeTab === "mentee") {
          setMentee("rejected");
        } else {
          setMentor("rejected");
        }
      }
    }
  }, [activeInnerTab]);

  const handleAcceptOrReject = (e, currentDataIndex) => {
    let elemenetId = e.target.id;
    if (currentDataIndex) {
      let { _id } = currentTableData[currentDataIndex];
      if (elemenetId === "acceptBtn") {
        acceptUser(_id, activeTab);
      } else if (elemenetId === "rejectBtn") {
        rejectUser(_id, activeTab);
      }
    }
  };

  return (
    <div>
      <h3 className="admin_applications_header">Applications</h3>
      <div className="calendly-header-container">
        <MenteeMentorBtnContainer
          activeBtn={activeTab}
          handleActiveBtn={setActiveTab}
          setActiveInnerTab={setActiveInnerTab}
        />
        <input
          type="text"
          value={searchFilter}
          placeholder="Search"
          onChange={(e) => setSearchFilter(e.target.value)}
        />
      </div>
      <ApplicationsInnerTabs
        activeTab={activeInnerTab}
        handleActiveTab={setActiveInnerTab}
      />

      {currentTableData?.length > 0 && (
        <AdminResizableTable
          headers={Object.keys(currentTableData[0])}
          minCellWidth={90}
          data={currentTableData}
          type={"approveOrReject"}
          hiddenColumns={["_id", "status"]}
          checkPipeline={false}
          lastColumnComponent={
            activeInnerTab === "pipeline" ? (
              <div className="display-flex">
                <CardoButton
                  text="Reject"
                  width="90px"
                  height="24px"
                  className="admin_dashboard_btn admin_dashboard_btn_reject"
                  margin="0"
                  id="rejectBtn"
                />
                <CardoButton
                  text="Accept"
                  width="90px"
                  height="24px"
                  className="admin_dashboard_btn admin_dashboard_btn_accept"
                  margin="0"
                  id="acceptBtn"
                />
              </div>
            ) : (
              activeInnerTab === "rejected" && (
                <CardoButton
                  text="Accept"
                  width="90px"
                  height="24px"
                  className="admin_dashboard_btn admin_dashboard_btn_accept"
                  margin="0"
                  id="acceptBtn"
                />
              )
            )
          }
          handler={handleAcceptOrReject}
          sortedColumns={["hours", "last_login", "last_call", "#_sessions"]}
        />
      )}

      {/* {isClicked === "mentee" && (
        <div>
          <h2>Mentee</h2>{" "}
          {newUsers &&
            newUsers.mentees &&
            newUsers.mentees.map((mentee, index) => {
              return (
                <div style={{ display: "flex" }} key={index}>
                  <div>id: {mentee._id}</div>
                  <div>name: {mentee.name}</div>
                  <div>email: {mentee.email}</div>
                  <div>stauts: {mentee.status}</div>
                  <div>
                    <a
                      href={`https://www.jotform.com/submission/${mentee.submission_id}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Submission
                    </a>
                  </div>
                  <button onClick={(e) => acceptUser(mentee._id, "mentee", e)}>
                    Accept
                  </button>
                  <button onClick={(e) => rejectUser(mentee._id, "mentee", e)}>
                    Reject
                  </button>
                </div>
              );
            })}
        </div>
      )}
      {isClicked === "mentor" && (
        <div>
          <h2>Mentor</h2>
        </div>
      )} */}
    </div>
  );
};

const mapStateToProps = (state) => ({
  admin: state.admin,
});
export default connect(mapStateToProps, {
  getAllNewUsers,
  acceptUser,
  rejectUser,
})(ApplicationsTab);
