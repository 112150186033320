import React from "react";
import { useMobilePopup } from "../../../hooks/useMobilePopup";
import FindMentorHeader from "./FindMentorHeader";
import FindMentorSection1 from "./FindMentorSection1";
import FindMentorSection2 from "./FindMentorSection2";
import FindMentorSection3 from "./FindMentorSection3";
import FindMentorSection4 from "./FindMentorSection4";
import FindMentorSectionFAQ from "./FindMentorSectionFAQ";

const FindMentorMain = () => {
  const { openMobilePopup } = useMobilePopup("mobile-modal");

  return (
    <div>
      {openMobilePopup}

      <FindMentorHeader />
      <FindMentorSection1 />
      <FindMentorSection2 />
      <FindMentorSection3 />
      <FindMentorSection4 />
      <FindMentorSectionFAQ />
    </div>
  );
};

export default FindMentorMain;
