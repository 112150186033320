import React from 'react';
import usePublications from "../../../../../../hooks/usePublications";
import useTrack from "../../../../../../hooks/useTrack";
import useIsTrackSwitchedOff from "../../../../../../hooks/useIsTrackSwitchedOff";
import useParticipantIsReconnecting from "../../../../../../hooks/useParticipantIsReconnecting";
// import NetworkQualityLevel from "./NetworkQualityLevel";
// import AudioLevelIndicator from "./AudioLevelIndicator";
import AvatarIcon from "../../../../../../icons/AvatarIcon";

export default function ParticipantInfo({
                                            participant,
                                            onClick,
                                            isSelected,
                                            children,
                                            isLocalParticipant,
                                            hideParticipant
                                        }) {
    const publications = usePublications(participant);

    const audioPublication = publications.find(p => p.kind === 'audio');
    const videoPublication = publications.find(p => p.trackName.includes('camera'));

    const isVideoEnabled = Boolean(videoPublication);
    // const isScreenShareEnabled = publications.find(p => p.trackName.includes('screen'));

    const videoTrack = useTrack(videoPublication);
    const isVideoSwitchedOff = useIsTrackSwitchedOff(videoTrack);

    // const audioTrack = useTrack(audioPublication);
    const isParticipantReconnecting = useParticipantIsReconnecting(participant);

    return (
        <div
            style={{
                position: 'relative',
                display: hideParticipant ? 'none' : 'flex',
                alignItems: 'center',
                height: '100px',
                overflow: 'hidden',
                marginBottom: '0.5em',
                '& video': {
                    filter: 'none',
                    objectFit: 'contain !important',
                },
                borderRadius: '15px',
                // paddingTop: `100px`,
                background: 'black',
                cursor: Boolean(onClick) ? 'pointer' : '',
            }}
            onClick={onClick}
            data-cy-participant={participant?.identity}
        >
            {/*<div style={{*/}
            {/*    position: 'absolute',*/}
            {/*    zIndex: 2,*/}
            {/*    display: 'flex',*/}
            {/*    flexDirection: 'column',*/}
            {/*    justifyContent: 'space-between',*/}
            {/*    height: '100%',*/}
            {/*    width: '100%',*/}
            {/*    background: 'transparent',*/}
            {/*    top: 0,*/}
            {/*}}>*/}
            {/*<NetworkQualityLevel participant={participant}/>*/}
            {/*            <div style={{*/}
            {/*                display: 'flex',*/}
            {/*                justifyContent: 'space-between',*/}
            {/*                position: 'absolute',*/}
            {/*                bottom: 0,*/}
            {/*                left: 0,*/}
            {/*            }}>*/}
            {/*                {isScreenShareEnabled && (*/}
            {/*                    <span style={{*/}
            {/*                        background: 'rgba(0, 0, 0, 0.5)',*/}
            {/*                        padding: '0.18em 0.3em',*/}
            {/*                        marginRight: '0.3em',*/}
            {/*                        display: 'flex',*/}
            {/*                        '& path': {*/}
            {/*                            fill: 'white',*/}
            {/*                        },*/}
            {/*                    }}>*/}
            {/*          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">*/}
            {/*  <path*/}
            {/*      fill="#707578"*/}
            {/*      fillRule="evenodd"*/}
            {/*      d="M17.657 6.343c3.124 3.124 3.124 8.19 0 11.314-3.124 3.124-8.19 3.124-11.314 0-3.124-3.124-3.124-8.19 0-11.314 3.124-3.124 8.19-3.124 11.314 0zm-10.444.87c-2.643 2.644-2.643 6.93 0 9.574 2.644 2.643 6.93 2.643 9.574 0 2.57-2.57 2.641-6.693.214-9.35l-.214-.224L16.563 7c-2.657-2.427-6.78-2.356-9.35.214zm4.865.484l.02.003.026.005.041.01c.025.007.045.014.063.021l.043.019c.018.009.036.019.053.03.01.005.02.012.03.02.027.019.053.04.077.063l2.773 2.774c.24.24.24.63 0 .87-.213.214-.545.237-.785.071l-.085-.07-1.719-1.72v5.9c0 .34-.275.615-.615.615-.302 0-.553-.218-.605-.505l-.01-.11-.001-5.9-1.718 1.72c-.24.24-.63.24-.87 0-.214-.215-.238-.546-.072-.786l.072-.085 2.769-2.77.015-.014c.02-.018.04-.035.062-.05l.005-.004.008-.006c.027-.018.055-.034.085-.048l.034-.015.059-.02.032-.008c.021-.005.044-.008.068-.011l.038-.003H12.025l.027.002.026.002z"*/}
            {/*  />*/}
            {/*</svg>*/}
            {/*        </span>*/}
            {/*                )}*/}
            {/*                /!*          <span style={{*!/*/}
            {/*                /!*              background: 'rgba(0, 0, 0, 0.5)',*!/*/}
            {/*                /!*              color: 'white',*!/*/}
            {/*                /!*              padding: '0.18em 0.3em',*!/*/}
            {/*                /!*              margin: 0,*!/*/}
            {/*                /!*              display: 'flex',*!/*/}
            {/*                /!*              alignItems: 'center',*!/*/}
            {/*                /!*          }}>*!/*/}
            {/*                /!*  <AudioLevelIndicator audioTrack={audioTrack}/>*!/*/}
            {/*                /!*              {participant?.identity}*!/*/}
            {/*                /!*              {isLocalParticipant && ' (You)'}*!/*/}
            {/*                /!*</span>*!/*/}
            {/*            </div>*/}
            {/*<div>{isSelected && <PinIcon/>}</div>*/}
            {/*</div>*/}
            <div style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
            }}>
                {(!isVideoEnabled || isVideoSwitchedOff) && (
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        background: 'black',
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                        zIndex: 1,
                    }}>
                        <AvatarIcon/>
                    </div>
                )}
                {isParticipantReconnecting && (
                    <div style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        background: 'rgba(40, 42, 43, 0.75)',
                        zIndex: 1,
                    }}>
                        Reconnecting...
                    </div>
                )}
                {children}
            </div>
        </div>
    );
}
