import React, { useEffect, useState } from "react";
import coinsIcon from "../../../../../../assets/mentee-dashboard/popup/coinsIcon.svg";
import "../../../../../../styles/rsuite.css";
import { getSupportAreaFromCalendly } from "../../../../../../state-management/actions/dashboard/mentee-dashboard/calendlyActions";
import { Loader } from "rsuite";
import { connect } from "react-redux";

const FindMentorPopUpSecondScreen = (props) => {
  const { prevStep, nextStep, mentor } = props;

  useEffect(() => {
    props.getSupportAreaFromCalendly({
      mentorUUID: mentor.calendly_info.userUUID,
      mentorId: mentor._id,
    });
  }, []);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const interViewTypeArr = [
    {
      interviewTitle: "Industry Overview",
      interviewDesc:
        "Learn about the industry, the players, explore career progression, & exit opportunities",
      interviewLength: "30 minutes",
      duration: 30,
      interviewCost: "2",
      slug: "/industry-overview",
    },
    {
      interviewTitle: "Resume Review",
      interviewDesc:
        "Get another set of eyes on your resume & get help refining your resume for future opportunities ",
      interviewLength: "30 minutes",
      duration: 30,
      interviewCost: "2",
      slug: "/resume",
    },
    {
      interviewTitle: "LinkedIn Tips",
      interviewDesc:
        "Learn how to make the most of your LinkedIn for career opportunities & growth",
      interviewLength: "30 minutes",
      duration: 30,
      interviewCost: "2",
      slug: "/linkedin",
    },
    {
      interviewTitle: "Fit Interview",
      interviewDesc:
        "Learn more about the fit interview, practice potential questions & refine answers together",
      interviewLength: "60 minutes",
      duration: 60,
      interviewCost: "4",
      slug: "/fit-interview",
    },
    {
      interviewTitle: "Case Interview",
      interviewDesc:
        "Learn more about the case interview, run a mock, & gather feedback",
      interviewLength: "60 minutes",
      duration: 60,
      interviewCost: "4",
      slug: "/case-interview",
    },
  ];

  let count = 0;
  const [newSupportArea, setNewSupportArea] = useState([]);
  const [coins, setCoins] = useState(0);
  useEffect(() => {
    if (props?.supportAreas?.supportAreas) {
      setNewSupportArea([]);
      setCoins(parseInt(props?.supportAreas?.menteeCoins));
      let supportAreas = props?.supportAreas?.supportAreas;

      for (let interViewType of mentor.profile_info.support_areas) {
        if (count === mentor.profile_info.support_areas.length) {
          break;
        }
        for (let supportArea of supportAreas) {
          if (
            supportArea.name.toLowerCase().includes(interViewType.toLowerCase())
          ) {
            count++;
            interViewTypeArr.filter((obj) => {
              if (
                obj.interviewTitle
                  .toLowerCase()
                  .includes(interViewType.toLowerCase())
              ) {
                obj.slug = supportArea.scheduling_url;
                setNewSupportArea((prev) => [...prev, obj]);
              }
            });
            if (count === mentor.profile_info.support_areas.length) {
              setLoading(false);
              break;
            }
          }
        }
      }
    }
    if (props?.error?.somethingWentWrong?.msg) {
      setLoading(false);
      setError(props.error.somethingWentWrong.msg);
    }
  }, [props]);

  const [choosenInterviewType, setCoosenInterviewType] = useState({});
  const [choosenInterviewCoins, setChoosenInterviewCoins] = useState(0);
  const [currentInterviewType, setCurrentInterviewType] = useState("");
  const [calendlySlug, setCalendlySlug] = useState("");
  return (
    <div>
      {loading ? (
        <div
          style={{
            position: "absolute",
            top: "285px",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          <Loader size="md" />
        </div>
      ) : (
        <div className="display-flex flex-direction-column justify-content-center align-items-center mt-25">
          <h2>How can your mentor help?</h2>
          {error ? (
            <div>{error}</div>
          ) : (
            <>
              <div className="mentor-dashboard-find-mentor-modal-interview-type-container">
                {newSupportArea.map((interview, index) => (
                  <div
                    key={index}
                    className={
                      currentInterviewType === interview.interviewTitle
                        ? "mentor-dashboard-find-mentor-modal-interview-type mentor-dashboard-find-mentor-modal-interview-type-active"
                        : "mentor-dashboard-find-mentor-modal-interview-type"
                    }
                    onClick={() => {
                      setCalendlySlug(interview.slug);
                      setCurrentInterviewType(interview.interviewTitle);
                      setCoosenInterviewType(interview);
                      setChoosenInterviewCoins(
                        parseInt(interview.interviewCost)
                      );
                    }}
                  >
                    <div className="m-15">
                      <div className="font-Neometric-Medium mb-10 ">
                        {interview.interviewTitle}
                      </div>
                      <div className="font-eina line-height-16 font-size-14 mb-10">
                        {interview.interviewDesc}
                      </div>
                      <div className="mentor-dashboard-find-mentor-modal-interview-type-cost-container">
                        <div className="mentor-dashboard-find-mentor-modal-interview-type-time">
                          <i className="far fa-clock mr-5"></i>{" "}
                          {interview.interviewLength}
                        </div>
                        <div className="mentor-dashboard-find-mentor-modal-interview-type-coins">
                          <div>
                            <img
                              src={coinsIcon}
                              alt="coins"
                              style={{ marginRight: "4px" }}
                            />
                          </div>{" "}
                          {interview.interviewCost}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div style={{ paddingBottom: "15px", bottom: "30px" }}>
                <div className="display-flex">
                  <button
                    style={{ fontFamily: "Neometric Medium" }}
                    className="cardo-btn btn-bg-lightindigo popupBtn"
                    onClick={() => prevStep("730px")}
                  >
                    Back
                  </button>
                  <button
                    className="cardo-btn btn-bg-lightindigo popupBtn"
                    style={{
                      fontFamily: "Neometric Medium",
                      backgroundColor:
                        calendlySlug !== "" ? "#5555DB" : "#EEEEFB",
                      color: calendlySlug !== "" ? "white" : "#5555DB",
                    }}
                    onClick={() => {
                      if (
                        calendlySlug !== "" &&
                        coins >= choosenInterviewCoins
                      ) {
                        nextStep(calendlySlug, choosenInterviewType, "730px");
                      }
                    }}
                  >
                    Next
                  </button>
                </div>
                {coins < choosenInterviewCoins && (
                  <div style={{ fontFamily: "Neometric Medium" }}>
                    not enough coins
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  supportAreas: state.menteeDashboard.supportAreas,
  error: state.errors,
});
export default connect(mapStateToProps, { getSupportAreaFromCalendly })(
  FindMentorPopUpSecondScreen
);
