import React from "react";
import PropTypes from "prop-types";
import Validation from "../../landing-page/common/Validation";
import SignupValidation from "../sign-up/validation/SignupValidation";

const InputNumber = ({
  type = "text",
  placeholder,
  name,
  value,
  onChange,
  error,
  id,
  text,
  step,
  minValue,
  maxValue,
}) => {
  return (
    <div className="mb-5 position-relative">
      <label className="form-label" htmlFor={id}>
        {text}
      </label>
      {error && <SignupValidation message={error} />}

      <input
        id={id}
        className={"form-number"}
        type={type}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        step={step}
        min={minValue}
        max={maxValue}
        autoComplete="off"
      />
    </div>
  );
};

InputNumber.propTypes = {
  type: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func.isRequired,
};

export default InputNumber;
