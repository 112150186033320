import React from "react";
import Slider from "react-slick";
import section1cardIcon1 from "../../../assets/Become-a-Mentor/Group 575.svg";
import section1cardIcon2 from "../../../assets/Become-a-Mentor/Group 576.svg";
import section1cardIcon3 from "../../../assets/Become-a-Mentor/Vector-9.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const BecomeMentorSection1Mobile = () => {
  const settings = {
    dots: false,
    speed: 500,
    arrows: false,
    // centerMode: true,
    infinite: false,
    variableWidth: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div>
      <Slider {...settings}>
        <div>
          <div className="card-mobile">
            <div style={{ display: "flex", alignItems: "center" }}>
              <div className="card-icon-mobile">
                <img src={section1cardIcon1} alt="" />
              </div>
              <div className="card-heading-mobile">
                Earn credit for your time
              </div>
            </div>
            <p className="card-desc-mobile">
              Track your mentorship contributions to see your impact over time
              and feel recognized for your efforts.
            </p>
          </div>
        </div>
        <div>
          <div className="card-mobile">
            <div style={{ display: "flex", alignItems: "center" }}>
              <div className="card-icon-mobile">
                <img src={section1cardIcon2} alt="" />
              </div>
              <div className="card-heading-mobile">
                Get rid of the hassle around helping
              </div>
            </div>

            <p className="card-desc-mobile">
              Coach pre-vetted mentees and let us handle the burden of messaging
              and scheduling, so you can focus on what matters most.
            </p>
          </div>
        </div>
        <div>
          <div className="card-mobile">
            <div style={{ display: "flex", alignItems: "center" }}>
              <div className="card-icon-mobile">
                <img src={section1cardIcon3} alt="" />
              </div>
              <div className="card-heading-mobile">
                Become part of our non-profit community
              </div>
            </div>

            <p className="card-desc-mobile">
              Join our 501(c)(3) non-profit organization. Have the opportunity
              to take leadership roles and grow your network.
            </p>
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default BecomeMentorSection1Mobile;
