import React from "react";
import "../../../styles/find-mentor.css";
import brand1 from "../../../assets/Become a mentee/homeBrand1.svg";
import brand2 from "../../../assets/Become a mentee/homeBrand2.svg";
import brand3 from "../../../assets/Become a mentee/homeBrand3.svg";
import brand4 from "../../../assets/Become a mentee/homeBrand4.svg";
import brand5 from "../../../assets/Become a mentee/homeBrand5.svg";
import brand6 from "../../../assets/Become a mentee/homeBrand6.svg";
// import brandsMobImg from "../../assets/Become a mentee/Group 636.svg";

import findMentorRightCol from "../../../assets/Become a mentee/Group 686.svg";
import findMentorRightColMobile from "../../../assets/Become a mentee/Group 686.svg";
import { Link } from "react-router-dom";
import { useMobilePopup } from "../../../hooks/useMobilePopup";
import { useIsDesktop } from "../../../helper/useIsDesktop";

const FindMentorHeader = () => {
  const { openMobilePopupClick } = useMobilePopup("mobile-modal");
  const { isDesktop } = useIsDesktop();
  return (
    <section className="find-mentor-header">
      <div className="find-mentor-header_container">
        <div className="row find-mentor-header-row">
          <div className="col-6 find-mentor-header-leftCol">
            <div className="find-mentor-header-leftCol-container">
              <div className="find-your-mentor-text">Find your mentor </div>
              <h2 className="get-tailored-text">
                Get tailored advice
                <br className="br_mobile" /> to <br className="br_desk" /> reach
                your career
                <br className="br_mobile" /> goals
              </h2>
              <p className="easily-access-text">
                Easily access industry-specific mentors who{" "}
                <br className="br_mobile" /> can help
                <br className="br_desk" /> prepare you to make the most out of
                <br className="br_mobile" /> your next opportunity
              </p>
              <div className="apply-now-container">
                <Link
                  className="pink-btn apply_now_btn font-Mulish find-mentor-btn"
                  to={isDesktop ? "/sign-up" : "#"}
                  onClick={openMobilePopupClick}
                >
                  Apply Now
                </Link>
              </div>
              <div className="brands-container">
                <div className="brands">
                  <div>
                    <img src={brand1} alt="" className="brands-img" />
                  </div>
                  <div>
                    <img src={brand2} alt="" className="brands-img" />
                  </div>
                  <div>
                    <img src={brand3} alt="" className="brands-img" />
                  </div>
                  <div>
                    <img src={brand4} alt="" className="brands-img" />
                  </div>
                  <div>
                    <img src={brand5} alt="" className="brands-img" />
                  </div>
                  <div>
                    <img src={brand6} alt="" className="brands-img" />
                  </div>

                  {/* <img src={brandsDeskImg} alt="" className="img_desk" />
                  <img src={brandsDeskImg} alt="" className="img_mobile" /> */}
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 find-mentor-header-rightCol">
            <img
              src={findMentorRightCol}
              alt=""
              className="find-mentor-header-desktop-img"
            />
            <img
              src={findMentorRightColMobile}
              alt=""
              className="find-mentor-header-mobile-img"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default FindMentorHeader;
