import React, { useEffect, useState } from "react";
import DashboardHeader from "../dashboard-layout/DashboardHeader";
import Sidebar from "../dashboard-layout/Sidebar";

// Sidebar Icons
import homeIcon from "../../../assets/dashboard/sidebar/homeIcon.svg";
import sessionsIcon from "../../../assets/dashboard/sidebar/sessionsIcon.svg";
import faqIcon from "../../../assets/dashboard/sidebar/faqIcon.svg";

import homeIconActive from "../../../assets/dashboard/sidebar/homeIconActive.svg";
import sessionsIconActive from "../../../assets/dashboard/sidebar/sessionsIconActive.svg";
import MentorDashboardHome from "./mentor-dashboard-components/MentorDashboardHome";
import MentorSessions from "./mentor-dashboard-components/MentorSessions";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import MentorDashboardBookingDetails from "./mentor-dashboard-components/mentor-dashboard-booking-details/MentorDashboardBookingDetails";
import MentorDashboardInviteModal from "./mentor-dashboard-components/mentor-dashboard-home-components/invite-popup/MentorDashboardInviteModal";
import OnboardingPopup from "../onboarding/OnboardingPopup";

const MentorDashboard = (props) => {
  const [openOnboardingPopup, setOpenOnboardingPopup] = useState(false);
  useEffect(() => {
    document.body.classList.add("dashboard-body-color");
    return () => document.body.classList.remove("dashboard-body-color");
  }, []);
  useEffect(() => {
    if (props?.currentUser?.onBoarding && props?.currentUser?.onBoardingTemp) {
      setOpenOnboardingPopup(props?.currentUser?.onBoarding);
    }
  }, [props]);

  const [sideBarLinks, setSideBarLinks] = useState([
    {
      title: "Home",
      icon: homeIcon,
      activeIcon: homeIconActive,
    },
    {
      title: "My Sessions",
      icon: sessionsIcon,
      activeIcon: sessionsIconActive,
    },
    {
      title: "FAQ",
      icon: faqIcon,
      activeIcon: homeIconActive,
      link: "https://support.hellocardo.org",
    },
  ]);
  const [currentLink, setCurrentLink] = useState("");
  const [modalDetails, setModalDetails] = useState(false);

  const selectModalDetails = () => {
    setModalDetails(!modalDetails); // true/false toggle
  };

  const [modalMentorDetails, setModalMentorDetails] = useState({});

  const handleModalMentorDetails = (mentorData) => {
    setModalMentorDetails(mentorData);
  };

  const handleCurrentLink = (linkName) => {
    setCurrentLink(linkName);
  };

  const [inviteModal, setInviteModals] = useState(false);

  const selectInviteModals = () => {
    setInviteModals(!inviteModal); // true/false toggle
  };
  useEffect(() => {
    const search = props.location.search;
    const tabs = new URLSearchParams(search).get("tabs");
    const newTabs = tabs?.replace(/_/g, " ");
    if (tabs) {
      setCurrentLink(newTabs);
    } else {
      setCurrentLink("home");
    }
  }, [props.location.search]);
  useEffect(() => {
    if (props.location.state && props.location.state.prevLink) {
      setCurrentLink(props.location.state.prevLink);
    }
  }, [currentLink]);

  const renderMenteeDashboardMainContent = () => {
    switch (currentLink.toLocaleLowerCase()) {
      case "home":
        return (
          <MentorDashboardHome
            currentUser={props.currentUser}
            handleCurrentLink={handleCurrentLink}
            selectModalDetails={selectModalDetails}
            selectInviteModals={selectInviteModals}
            handleModalMentorDetails={handleModalMentorDetails}
          />
        );

      case "my sessions":
        return (
          <MentorSessions
            currentUser={props.currentUser}
            handleCurrentLink={handleCurrentLink}
            selectModalDetails={selectModalDetails}
            handleModalMentorDetails={handleModalMentorDetails}
          />
        );
    }
  };
  return (
    <div className="dashboard-body">
      {openOnboardingPopup && (
        <OnboardingPopup
          currentUser={props.currentUser}
          setOpenOnboardingPopup={setOpenOnboardingPopup}
        />
      )}
      {modalDetails && (
        <MentorDashboardBookingDetails
          displayModal={modalDetails}
          closeModal={selectModalDetails}
          modalMentorDetails={modalMentorDetails}
          // currentUser={currentUser}
        />
      )}

      {inviteModal && (
        <MentorDashboardInviteModal
          displayModal={inviteModal}
          closeModal={selectInviteModals}
          // currentUser={currentUser}
        />
      )}
      <Sidebar
        currentLink={currentLink}
        handleCurrentLink={handleCurrentLink}
        sideBarLinks={sideBarLinks}
      />
      <div className="dashboard-main">
        <div className="dashboard-main-container">
          <DashboardHeader
            currentUser={props.currentUser}
            handleCurrentLink={handleCurrentLink}
            currentLink={currentLink}
          />

          {renderMenteeDashboardMainContent()}
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  currentUser: state.auth.currentUser,
});
export default connect(mapStateToProps)(withRouter(MentorDashboard));
