import React from "react";
import FindMentorSection2Desktop from "./FindMentorSection2Desktop";
import FindMentorSection2Mobile from "./FindMentorSection2Mobile";

const FindMentorSection2 = () => {
  return (
    <section className="find-mentor-section-two">
      <div className="container_narrow find-mentor-section-two_container">
        <h2 className="heading">What makes us different</h2>
        <div className="find-mentor-section-two-body">
          <div className="find-mentor-section-two-body-desktop">
            <FindMentorSection2Desktop />
          </div>
          <div className="find-mentor-section-two-body-mobile">
            <FindMentorSection2Mobile />
          </div>
          <div></div>
        </div>
      </div>
    </section>
  );
};

export default FindMentorSection2;
