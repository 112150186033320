import React from 'react';
import useMainParticipant from "../../../../../../hooks/useMainParticipant";
import useSelectedParticipant from "../../../../../../hooks/useSelectedParticipant";
import useScreenShareParticipant from "../../../../../../hooks/useScreenShareParticipant";
import MainParticipantInfo from "./MainParticipantInfo";
import ParticipantTracks from "./ParticipantTracks";

export default function MainParticipant({room}) {
    const mainParticipant = useMainParticipant(room);
    const localParticipant = room?.localParticipant;
    const [selectedParticipant] = useSelectedParticipant(room);
    const screenShareParticipant = useScreenShareParticipant(room);

    const videoPriority =
        (mainParticipant === selectedParticipant || mainParticipant === screenShareParticipant) &&
        mainParticipant !== localParticipant
            ? 'high'
            : null;
    return (
        <MainParticipantInfo participant={mainParticipant} room={room}>
            <ParticipantTracks
                participant={mainParticipant}
                videoOnly
                enableScreenShare={mainParticipant !== localParticipant}
                videoPriority={videoPriority}
                isLocalParticipant={mainParticipant === localParticipant}
            />
        </MainParticipantInfo>
    );
}
