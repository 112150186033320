import React from "react";
import Radio from "../../../form-component/Radio";
import RadioGroup from "../../../form-component/RadioGroup";

const MenteeSignupStep5OurMission = (props) => {
  const {
    title,
    step,
    onPrevStep,
    onStepChange,
    data,
    stepKey,
    errors,
    onRadioChange,
  } = props;
  return (
    <div>
      <h3 className="sign-up-form-heading">{title}</h3>
      <div className="step-form-content">
        <div className="form-line">
          <RadioGroup
            text={data.q80_areYou80.text}
            error={errors[data.q80_areYou80.name]}
            description={data.q80_areYou80.description}
          >
            {data.q80_areYou80.inputValues.map((inputValue, index) => (
              <Radio
                key={index}
                name={data.q80_areYou80.name}
                value={data.q80_areYou80.value}
                text={data.q80_areYou80.text}
                inputValue={data.q80_areYou80.inputValues[index]}
                label={data.q80_areYou80.inputValues[index]}
                id={`${data.q80_areYou80.name}_radio_${index}`}
                onRadioChange={(e) =>
                  onRadioChange(stepKey, e, data.q80_areYou80.value)
                }
                type={data.q80_areYou80.type}
              />
            ))}
          </RadioGroup>
        </div>
        <div className="form-line">
          <RadioGroup
            text={data.q81_doYou81.text}
            error={errors[data.q81_doYou81.name]}
            description={data.q81_doYou81.description}
          >
            {data.q81_doYou81.inputValues.map((inputValue, index) => (
              <Radio
                key={index}
                name={data.q81_doYou81.name}
                value={data.q81_doYou81.value}
                text={data.q81_doYou81.text}
                inputValue={data.q81_doYou81.inputValues[index]}
                label={data.q81_doYou81.inputValues[index]}
                id={`${data.q81_doYou81.name}_radio_${index}`}
                onRadioChange={(e) =>
                  onRadioChange(stepKey, e, data.q81_doYou81.value)
                }
                type={data.q81_doYou81.type}
              />
            ))}
          </RadioGroup>
        </div>
        <div className="form-line">
          <RadioGroup
            text={data.q116_areYou116.text}
            error={errors[data.q116_areYou116.name]}
            description={data.q116_areYou116.description}
          >
            {data.q116_areYou116.inputValues.map((inputValue, index) => (
              <Radio
                key={index}
                name={data.q116_areYou116.name}
                value={data.q116_areYou116.value}
                text={data.q116_areYou116.text}
                inputValue={data.q116_areYou116.inputValues[index]}
                label={data.q116_areYou116.inputValues[index]}
                id={`${data.q116_areYou116.name}_radio_${index}`}
                onRadioChange={(e) =>
                  onRadioChange(stepKey, e, data.q116_areYou116.value)
                }
                type={data.q116_areYou116.type}
              />
            ))}
          </RadioGroup>
        </div>
        <div className="form-line">
          <RadioGroup
            text={data.q117_areYou117.text}
            error={errors[data.q117_areYou117.name]}
            description={data.q117_areYou117.description}
          >
            {data.q117_areYou117.inputValues.map((inputValue, index) => (
              <Radio
                key={index}
                name={data.q117_areYou117.name}
                value={data.q117_areYou117.value}
                text={data.q117_areYou117.text}
                inputValue={data.q117_areYou117.inputValues[index]}
                label={data.q117_areYou117.inputValues[index]}
                id={`${data.q117_areYou117.name}_radio_${index}`}
                onRadioChange={(e) =>
                  onRadioChange(stepKey, e, data.q117_areYou117.value)
                }
                type={data.q117_areYou117.type}
              />
            ))}
          </RadioGroup>
        </div>
        <div className="form-line">
          <RadioGroup
            text={data.q115_doYou115.text}
            error={errors[data.q115_doYou115.name]}
            description={data.q115_doYou115.description}
          >
            {data.q115_doYou115.inputValues.map((inputValue, index) => (
              <Radio
                key={index}
                name={data.q115_doYou115.name}
                value={data.q115_doYou115.value}
                text={data.q115_doYou115.text}
                inputValue={data.q115_doYou115.inputValues[index]}
                label={data.q115_doYou115.inputValues[index]}
                id={`${data.q115_doYou115.name}_radio_${index}`}
                onRadioChange={(e) =>
                  onRadioChange(stepKey, e, data.q115_doYou115.value)
                }
                type={data.q115_doYou115.type}
              />
            ))}
          </RadioGroup>
        </div>
      </div>
      <div className="form-step-btn-group">
        {step > 0 && (
          <button
            type="button"
            className="cardo-btn back-btn"
            onClick={() => onPrevStep(step - 1)}
          >
            Go back
          </button>
        )}
        <button
          type="button"
          className="cardo-btn next-btn"
          onClick={(e) => onStepChange(data, e)}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default MenteeSignupStep5OurMission;
