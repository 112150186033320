import React from "react";
import linkify from "linkify-it";
import { Link } from "react-router-dom";
import oppVideo from "../../../../../../assets/mentor/oppvideo.png";

function addLinks(text) {
  const matches = linkify().match(text);
  if (!matches) return text;

  const results = [];
  let lastIndex = 0;

  matches.forEach((match, i) => {
    results.push(text.slice(lastIndex, match.index));
    results.push(
      <Link target="_blank" rel="noreferrer" href={match.url} key={i}>
        {match.text}
      </Link>
    );
    lastIndex = match.lastIndex;
  });

  results.push(text.slice(lastIndex, text.length));

  return results;
}

export default function TextMessage({ body, isLocalParticipant }) {
  return (
    <div>
      <div className={isLocalParticipant ? "right-message" : "left-message"}>
        <div className="message-box">
          {/*<div className='img-wrapper'>*/}
          {/*    <img src={oppVideo} alt='icon'/>*/}
          {/*</div>*/}
          <div className="message">
            <span>{addLinks(body)}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
