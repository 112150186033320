import React from "react";
import "../../../styles/mentor-list.css";
import ScrollAnimation from "react-animate-on-scroll";
import "../../../styles/animate.css";
const HomeSection4MentorsDesktop = ({ mentorData }) => {
  return (
    <div className="mentor_card_container">
      {mentorData.mentors &&
        mentorData.mentors.map((mentor, index) => {
          return (
            <ScrollAnimation
              animateIn="fadeInUp"
              delay={100 * index}
              key={index}
            >
              <div
                className="mentor_card mentor_img_back"
                style={{
                  backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0) 40%, rgba(0,0,0,1) 100%),url('${mentor.imageUrl}')`,
                }}
              >
                <div className="mentor_card_data_wrapper">
                  <span className="mentor_card_name">{mentor.name}</span>
                  <span className="mentor_card_area">
                    {mentor.area}
                    <i
                      className="fas fa-caret-right"
                      style={{ color: "white", margin: "0px 5px" }}
                    ></i>
                    {mentor.company}
                  </span>
                  <div className="mentor_card_skills_container">
                    {mentor.skills.split(",").map((skill, index) => {
                      return (
                        <span
                          key={index}
                          style={{
                            color: "white",
                            fontFamily: "eina",
                            backgroundColor: "rgba(255, 255, 255, 0.24)",
                            borderRadius: "11.7857px",
                            fontSize: "12px",
                            padding: "4px 15px",
                            margin: "5px 2px",
                          }}
                        >
                          {skill}
                        </span>
                      );
                    })}
                  </div>
                </div>
              </div>
            </ScrollAnimation>
          );
        })}
    </div>
  );
};

export default HomeSection4MentorsDesktop;
