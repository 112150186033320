import React, { useEffect } from "react";
import AboutUs from "../components/landing-page/about-us/AboutUs";
import Footer from "../components/landing-page/layout/Footer";
import Navbar from "../components/landing-page/layout/Navbar";

const AboutUsRoute = () => {
  useEffect(() => {
    document.title = "Cardo | About Us";
    return () => (document.title = "Cardo");
  }, []);
  return (
    <div>
      <Navbar dark loginColor="#2C205B" />
      <AboutUs />
      <Footer wavebg={"white"} bgcolor={"#2c205b"} copywritebg={"#211845"} />
    </div>
  );
};

export default AboutUsRoute;
