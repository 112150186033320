import { combineReducers } from "redux";
import authReducer from "./authReducer";
import errorReducer from "./errorReducer";
import adminReducer from "./adminReducer";
import loadingReducer from "./loadingReducer";

import menteeDashboardReducer from "./dashboard/menteeDashboardReducer";
import mentorDashboardReducer from "./dashboard/mentorDashboardReducer";
import successReducer from "./successReducer";
export default combineReducers({
  auth: authReducer,
  errors: errorReducer,
  admin: adminReducer,
  menteeDashboard: menteeDashboardReducer,
  mentorDashboard: mentorDashboardReducer,
  loading: loadingReducer,
  success: successReducer,
});
