import React from "react";
import warning from "../../../../../assets/mentee-dashboard-profile-edit/warning.svg";

const MessagePopup = (props) => {
  const { title, msg, type, handlePopup, deleteHandler } = props;
  function closeModal(e) {
    e.stopPropagation();
    handlePopup();
  }

  function deleteAccound() {
    if (type === "delete") {
      deleteHandler(() => {});
    }
  }

  return (
    <div>
      <div className="delete-account-confirmation-popup" onClick={closeModal}>
        <div
          className="delete-account-confirmation-popup-content"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="font-Neometric-Medium" style={{ color: "red" }}>
            {" "}
            <img
              src={warning}
              alt="warning"
              style={{ marginRight: "5px" }}
            />{" "}
            {title}
          </div>
          <div className="font-eina text-align-center line-height">{msg}</div>
          <div className="display-flex">
            {type === "error" && (
              <>
                <button
                  style={{
                    width: "125px",
                    height: "47px",
                  }}
                  className="cardo-btn cardo-btn-sm btn-bg-lightblue"
                  onClick={closeModal}
                >
                  Continue
                </button>
              </>
            )}
            {type === "delete" && (
              <>
                <button
                  style={{
                    width: "125px",
                    height: "47px",
                  }}
                  className="cardo-btn cardo-btn-sm btn-bg-lightindigo"
                  onClick={closeModal}
                >
                  Nevermind
                </button>
                <button
                  style={{
                    width: "125px",
                    height: "47px",
                  }}
                  className="cardo-btn cardo-btn-sm btn-bg-lightblue"
                  onClick={deleteAccound}
                >
                  Continue
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessagePopup;
