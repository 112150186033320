import {useState} from "react";
import {useEffect} from "react";
import useRoom from "./useRoom";

export default function useSelectedParticipant(room) {
    const [selectedParticipant, _setSelectedParticipant] = useState(null);
    const setSelectedParticipant = (participant) =>
        _setSelectedParticipant(prevParticipant => (prevParticipant === participant ? null : participant));

    useEffect(() => {
        if (room) {
            const onDisconnect = () => _setSelectedParticipant(null);
            const handleParticipantDisconnected = (participant) =>
                _setSelectedParticipant(prevParticipant => (prevParticipant === participant ? null : prevParticipant));

            room.on('disconnected', onDisconnect);
            room.on('participantDisconnected', handleParticipantDisconnected);
            return () => {
                room.off('disconnected', onDisconnect);
                room.off('participantDisconnected', handleParticipantDisconnected);
            };
        }
    }, [room]);

    return [selectedParticipant, setSelectedParticipant]
}
