import React from "react";
import ApplicationsTab from "./applications-tab/ApplicationsTab";
import CalendlyTab from "./calendly-tab/CalendlyTab";
import ContentTab from "./content-tab/ContentTab";
import UserManagementTab from "./user-management-tab/UserManagementTab";

const RenderDashboardContent = (props) => {
  const { currentLink } = props;
  const renderMenteeDashboardMainContent = () => {
    switch (currentLink.toLocaleLowerCase()) {
      case "applications":
        return <ApplicationsTab />;

      case "user management":
        return <UserManagementTab />;

      case "content":
        return (
          <>
            <h3 className="admin_applications_header">Content Management</h3>
            <ContentTab />
          </>
        );
      case "calendly":
        return <CalendlyTab />;
    }
  };
  return <div>{renderMenteeDashboardMainContent()}</div>;
};

export default RenderDashboardContent;
