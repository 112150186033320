import axios from 'axios'

import {
    FORM_LOADING,
    GET_FORM_MENTOR,
    GET_CONDITIOS_MENTOR,
    GET_FORM_MENTEE,
    GET_ERRORS,
    GET_CURRENT_USER,
    REMOVE_CURRENT_USER,
    CURRENT_USER_LOADING,
    CLEAR_ERRORS
} from '../types/types'
import { helper } from '../../helper/helper'
import Cookies from 'universal-cookie';

const cookies = new Cookies();

//get csurf token
//get('/')
//function()
export const signupMentor = () => dispatch => {
    dispatch(formLoading())
    axios.get(`${helper.baseUrl}/signup/getFormQuestions/?formId=${helper.mentorFormId}`,
        { withCredentials: true })
        .then(res => {
            let start = 0;
            let steps = [];
            let questionsArray = res.data;
            for (let i = 0; i < questionsArray.length; i++) {
                if (questionsArray[i].type === "control_pagebreak") {
                    steps.push(questionsArray.slice(start, i));
                    start = i + 1;
                }
            }
            dispatch({
                type: GET_FORM_MENTOR,
                payload: steps,
            })
        })
        .catch(err => {
            console.log(err)
        })
}

export const signupMentee = () => dispatch => {
    dispatch(formLoading())
    axios.get(`${helper.baseUrl}/signup/getFormQuestions/?formId=${helper.menteeFormId}`,
        { withCredentials: true })
        .then(res => {
            dispatch({
                type: GET_FORM_MENTEE,
                payload: res.data,
            })
        })
        .catch(err => {
            console.log(err)
        })
}

export const postSignupMenteeJotform = (data, code, handleLoading, file, formData) => dispatch => {
    axios
        .post(
            `https://submit.jotform.com/submit/${helper.menteeFormId}/`,
            data,
            {
                headers: { "Content-Type": "multipart/form-data" },
            }
        )
        .then((res) => {
            let reg = /(submittionId:)(\d{5,30})+/;
            let x = res.data.match(reg);

            let submittionId = x[0].split(":")[1];
            // if  typeof x is 'undefined' throw error
            // else continue
            if (typeof x === "undefined") {
                handleLoading(false);

                dispatch(throwCustomError('cannotSubmit', 400, { msg: 'Form Cannot be submitted, Please Refresh and try again' }))

            } else {
                // let menteeData = {
                //     industry: data.get('q12_whichIndustry'),
                //     trail: data.get('q13_trail'),
                //     interestedIn: data.get('q79_whatType'),
                //     areas: data.getAll('q77_whichAreas[]'),
                //     resume: data.get('q25_pleaseAttach'),
                //     education: data.get('q114_whereDid'),
                //     gpa: data.get('q28_whatIswas')
                // }
                const newData = new FormData();


                newData.append(
                    "q12_whichIndustry",
                    formData.stepTwo.q12_whichIndustry.value
                );
                newData.append("q13_trail", formData.stepTwo.q13_trail.value);
                newData.append("q79_whatType", formData.stepTwo.q79_whatType.value);
                for (let i = 0; i < formData.stepTwo.q77_whichAreas.value.length; i++) {
                    newData.append(
                        "q77_whichAreas[]",
                        formData.stepTwo.q77_whichAreas.value[i]
                    );
                }
                newData.append("q25_pleaseAttach", formData.stepTwo.q25_pleaseAttach.value);
                newData.append("q114_whereDid", formData.stepThree.q114_whereDid.value);
                newData.append("q28_whatIswas", formData.stepThree.q28_whatIswas.value);

                dispatch(submitFormIdAndCode(newData, submittionId, code, 'mentee', handleLoading));
            }
            // resetForm()
        })
        .catch((err) => {
            console.log(err)
            handleLoading(false);

            dispatch(throwCustomError('cannotSubmit', 400, { msg: 'Form Cannot be submitted, Please Refresh and try again' }))
        });
}

export const postSignupMentorJotform = (data, code, handleLoading) => dispatch => {
    axios
        .post(
            `https://submit.jotform.com/submit/${helper.mentorFormId}/`,
            data,
            {
                headers: { "Content-Type": "multipart/form-data" },
            }
        )
        .then((res) => {
            let reg = /(submittionId:)(\d{5,30})+/;
            let x = res.data.match(reg);

            let submittionId = x[0].split(":")[1];
            // if  typeof x is 'undefined' throw error
            // else continue
            if (typeof x === "undefined") {
                handleLoading(false);
                dispatch(throwCustomError('cannotSubmit', 400, { msg: 'Form Cannot be submitted, Please Refresh and try again' }))

            } else {

                const areYou = data.get('q81_areYou');
                const mentorData = {
                    industry: data.get('q12_whichIndustry12'),
                    trail: data.getAll('q80_withinConsulting80[]'),


                    where_did_you_exit: data.get('q84_whereDid'),
                    education: data.get('q113_education'),
                    country: data.get('q68_whichCountry'),
                    consulting_firm: areYou === 'Yes' ? data.get('q86_whatConsulting') : data.get('q105_whichConsulting'),
                    areas: data.getAll('q77_whichAreas77[]'),
                }

                dispatch(submitFormIdAndCode(mentorData, submittionId, code, 'mentor', handleLoading));
            }
        })
        .catch((err) => {
            console.log(err);
            handleLoading(false);

            dispatch(throwCustomError('cannotSubmit', 400, { msg: 'Form Cannot be submitted, Please Refresh and try again' }))
        });
}
export const submitFormIdAndCode = (data, submittionId, code, type, handleLoading) => dispatch => {
    //load === true
    //disable btn
    axios
        .post(
            `${helper.baseUrl}/auth/user?submittionId=${submittionId}&linkedinCode=${code}&type=${type}`, data,
            {
                headers: {
                    'x-csrf-token': cookies.get('XSRF-TOKEN')
                },
                withCredentials: true
            }

        )
        .then((res) => {
            if (res.status === 200) {
                window.location.href = '/sign-up/thankyou'
            }
        })
        .catch((err) => {
            console.log(err)
            handleLoading(false);

            dispatch(throwCustomError('cannotSubmit', err.response.status, err.response.data))

            //load false
            //not disable
        });
};

export const checkStatus = (handleStatus) => dispatch => {
    axios
        .get(`${helper.baseUrl}/auth/user/status`,

            { withCredentials: true })
        .then((res) => handleStatus(res.data.status))
        .catch((err) => {
            console.log(err.response)
            dispatch(throwCustomError('somethingWentWrong', err.response.status, err.response.data))
        });
}
export const signin = (code) => dispatch => {
    dispatch(currentUserLoading)
    axios.post(`${helper.baseUrl}/auth/user/login?linkedinCode=${code}`,
        {
            headers: { 'x-csrf-token': cookies.get('XSRF-TOKEN') }
        },
        { withCredentials: true }
    )
        .then(res => {

            dispatch({
                type: GET_CURRENT_USER,
                payload: res.data
            })
        }).catch(err => {
            if (err.response.status === 404) {

                return dispatch(throwCustomError('userNotExists', err.response.status, err.response.data))
            }
            if (err.response.status === 409) {
                return dispatch(throwCustomError('userRejected', err.response.status, err.response.data))
            }
        })
}
export const signinStatus = (handleRefresh) => dispatch => {
    dispatch(currentUserLoading())
    axios.get(`${helper.baseUrl}/auth/user/loggedInStatus`,
        { withCredentials: true }
    ).then(res => {
        handleRefresh()
        dispatch({
            type: GET_CURRENT_USER,
            payload: res.data
        })
    }).catch(err => {
        // console.log(err.response)
        dispatch(throwCustomError('somethingWentWrong', err?.response?.status, err.response.data))
    })
}
export const logoutUser = () => dispatch => {
    axios.post(`${helper.baseUrl}/auth/user/logout`,
        {
            headers: { 'x-csrf-token': cookies.get('XSRF-TOKEN') }
        },
        { withCredentials: true })
        .then(res => {
            dispatch({
                type: REMOVE_CURRENT_USER,
                payload: res.data
            })
        })
        .catch(err => {
            dispatch(throwCustomError('somethingWentWrong', err.response.status, err.response.data))
        })
}

// change first time status
export const changeStatusOfFirstTime = () => dispatch => {
    axios.put(`${helper.baseUrl}/auth/user/changeFirstTime`, {
        headers: { 'x-csrf-token': cookies.get('XSRF-TOKEN') }
    },
        { withCredentials: true })
        .then((res) => {
            if (res.status === 200) {
                dispatch({
                    type: GET_CURRENT_USER,
                    payload: res.data
                })
            }
        })
        .catch((err) => {
            dispatch(throwCustomError('errorMsg', err.response.status, err.response.data))
        })
}
export const innerScript = () => dispatch => {
    axios
        .get(
            "https://api.jotform.com/form/203288684811159/properties?apiKey=282066e094574a9326db66d32485b78c"
        )
        .then((res) => {

            console.log(JSON.parse(res.data.content.conditions[0].action)[0].field)


            let innerScript = res.data.content.conditions;

            dispatch({
                type: GET_CONDITIOS_MENTOR,
                payload: innerScript,
            })
        }).catch(err => {
            console.log(err)
        })
}

export const formLoading = () => dispatch => {
    dispatch({
        type: FORM_LOADING,
    })
}
export const currentUserLoading = () => dispatch => {
    dispatch({
        type: CURRENT_USER_LOADING,
    })
}
export const throwCustomError = (errorName, status, msg) => dispatch => {
    dispatch({
        type: GET_ERRORS,
        payload: {
            errorStatus: status,
            [errorName]: msg
        }
    })
}
export const clearErrors = () => dispatch => {
    dispatch({
        type: CLEAR_ERRORS,
        payload: {}
    })
}
