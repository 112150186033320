import moment from "moment-timezone";
import React from "react";
import {
  useTable,
  useBlockLayout,
  useResizeColumns,
  useExpanded,
  useSortBy,
} from "react-table";
import arrowDown from "../../../../assets/icons/ArrowDown.svg";
import CardoButton from "../../../common/btn/CardoButton";
import DefaultCell from "./DefaultCell";
import EditableCell from "./EditableCell";
const AdminResizableTableContent = (props) => {
  const {
    columns,
    data,
    type,
    lastColumnComponent,
    renderRowSubComponent,
    sortedColumns,
    hiddenColumns,
    handler,
    editable,
    updateMyData,
    checkPipeline,
  } = props;
  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 60,
      width: 100,
      maxWidth: 400,
      Cell: editable ? EditableCell : DefaultCell,
    }),
    []
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    // state,

    visibleColumns,
    state: { expanded },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      updateMyData,
      initialState: {
        hiddenColumns: hiddenColumns,
      },
    },
    useBlockLayout,
    useResizeColumns,
    useSortBy,
    useExpanded
  );

  const renderTableData = (cell) => {
    if (
      cell.column.id !== type &&
      !(cell.value instanceof Date) &&
      cell.column.id !== "submission_id" &&
      cell.column.id !== "date"
    ) {
      return cell.render("Cell");
    } else if (cell.column.id === type && !(cell.value instanceof Date)) {
      //status condition
      if (cell.row.values.status === "" && checkPipeline) {
        return (
          <CardoButton
            text="Invite"
            width="60px"
            height="24px"
            id="inviteBtn"
            className="transparent_btn_lightblue admin_dashboard_btn"
            margin="0"
            id="inviteBtn"
          />
        );
      }
      if (cell.row.values.status === "pending" && checkPipeline) {
        return (
          <CardoButton
            text="Accept"
            width="60px"
            height="24px"
            id="acceptCalendlyBtn"
            className="transparent_btn_lightblue admin_dashboard_btn"
            margin="0"
          />
        );
      }

      return lastColumnComponent;
    } else if (cell.value instanceof Date || cell.column.id === "date") {
      if (
        cell.column.id === "last_login" ||
        cell.column.id === "last_call" ||
        cell.column.id === "date"
      ) {
        return moment(cell.value)
          .tz(moment.tz.guess())
          .startOf("hours")
          .fromNow();
      }
    } else if (cell.column.id === "submission_id") {
      return cell.render(
        <a
          href={`https://www.jotform.com/submission/${cell.value}`}
          target="_blank"
        >
          View Submission
        </a>
      );
    }
  };
  return (
    <>
      <div>
        <div {...getTableProps()} className="resizeable_table">
          <div className="resizeable_table_head">
            {headerGroups.map((headerGroup) => (
              <div
                {...headerGroup.getHeaderGroupProps()}
                className="resizeable_table_row"
              >
                {headerGroup.headers.map((column) => (
                  <div
                    {...column.getHeaderProps(
                      sortedColumns?.includes(column.id) &&
                        column.getSortByToggleProps()
                    )}
                    className="resizeable_table_header"
                  >
                    {column.render("Header")}
                    {/* Use column.getResizerProps to hook up the events correctly */}
                    <span>
                      {" "}
                      {sortedColumns?.includes(column.id) &&
                        !column.isSortedDesc && <img src={arrowDown} alt="" />}
                      {sortedColumns?.includes(column.id) &&
                        column.isSortedDesc && (
                          <img
                            src={arrowDown}
                            alt=""
                            style={{ transform: "rotate(180deg)" }}
                          />
                        )}
                    </span>
                    <div
                      {...column.getResizerProps()}
                      className={`resizer ${
                        column.isResizing ? "isResizing" : ""
                      }`}
                    />
                  </div>
                ))}
              </div>
            ))}
          </div>

          <div {...getTableBodyProps()} className="resizeable_table_body">
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <React.Fragment key={i}>
                  <div {...row.getRowProps()} className="resizeable_table_row">
                    {row.cells.map((cell, i) => {
                      return (
                        <div
                          key={i}
                          {...cell.getCellProps(
                            row.cells.length - 1 === i && {
                              onClick: (e) => {
                                handler(e, row.id);
                              },
                            }
                          )}
                          className="resizeable_table_data"
                        >
                          {cell.column.id !== "id" && renderTableData(cell)}
                        </div>
                      );
                    })}
                  </div>
                  {row.isExpanded ? (
                    <div className="resizeable_table_row">
                      <div className="resizeable_table_data">
                        {renderRowSubComponent({ row })}
                      </div>
                    </div>
                  ) : null}
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminResizableTableContent;
