import {
  MENTOR_DASHBOARD_HOME,
  MENTOR_DASHBOARD_EDIT_PROFILE,
  MENTOR_DASHBOARD_MY_SESSIONS,
  MENTOR_DASHBOARD_NEW_IMAGE,
  MENTOR_DASHBOARD_MENTOR_DELETED,
  MENTOR_DASHBOARD_EVENT_SCHEDULED,
  MENTOR_DASHBOARD_NOTIFICATIONS,
  MENTOR_VIDEOCALL_TOKEN,
  MENTOR_PROFILE_TOGGLE,
  MENTOR_DASHBOARD_POST_EMAIL_INVITATION,
  MENTOR_DASHBOARD_GET_INVITATION_STATUS,
  RESET_MENTOR_VIDEOCALL_TOKEN,
  MENTOR_SINGLE_SESSION,
  POST_REVIEW_DATA,
  MENTOR_VIDEOCALL_TOKEN_ERROR,
  POST_ONBOARDING_FIRST,
  POST_ONBOARDING_FIRST_TEMP
} from "../../types/types";



const initialState = {
  loading: false,
  mentorDashboardHome: {},
  mentorDashboardEditProfile: {},
  mentorDashboardMySessions: {},
  currentUser: {},
  notifications: [],
  videoCallToken: null,
  session: null,
  postReview: null,
  onBoarding: null,
  onBoardingTemp: null,
}


const mentorDashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case MENTOR_DASHBOARD_HOME:
      return {
        ...state,
        mentorDashboardHome: action.payload,
        loading: false,
      }

    case MENTOR_DASHBOARD_EDIT_PROFILE:
      return {
        ...state,
        mentorDashboardEditProfile: action.payload,
        loading: false,
      }

    case MENTOR_DASHBOARD_MY_SESSIONS:
      return {
        ...state,
        mentorDashboardMySessions: action.payload,
        loading: false,
      }
    case MENTOR_DASHBOARD_EVENT_SCHEDULED:
      return {
        ...state,
        mentorDashboardEventScheduled: action.payload,
        loading: false,
      }
    case MENTOR_DASHBOARD_NEW_IMAGE:
      return {
        ...state,
        mentorNewImage: action.payload,
        loading: false,
      }
    case MENTOR_DASHBOARD_MENTOR_DELETED:
      return {
        ...state,
        mentorNewImage: action.payload,
        loading: false,
      }
    case MENTOR_DASHBOARD_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload,
        loading: false,
      }
    case MENTOR_DASHBOARD_POST_EMAIL_INVITATION:
      return {
        ...state,
        mentorEmailInvitation: action.payload,
        loading: false,
      }
    case MENTOR_DASHBOARD_GET_INVITATION_STATUS:
      return {
        ...state,
        invitationStatus: action.payload,
        loading: false,
      }
    case MENTOR_PROFILE_TOGGLE:
      return {
        ...state,
        isProfileActive: action.payload,
        loading: false,
      }
    case MENTOR_VIDEOCALL_TOKEN:
      return {
        ...state,
        videoCallToken: action.payload,
        loading: false,
      }
    case MENTOR_SINGLE_SESSION:
      return {
        ...state,
        session: action.payload,
        loading: false,
      }
    case RESET_MENTOR_VIDEOCALL_TOKEN:
      return {
        ...state,
        videoCallToken: null,
        session: null,
        loading: false,
      }
    case POST_REVIEW_DATA:
      return {
        ...state,
        postReview: action.payload,
        loading: false,
      }
    case MENTOR_VIDEOCALL_TOKEN_ERROR:
      return {
        ...state,
        videoCallTokenError: action.payload,
        loading: false,
      }
    case POST_ONBOARDING_FIRST:
      return {
        ...state,
        onBoarding: action.payload,
        loading: false,
      }
    case POST_ONBOARDING_FIRST_TEMP:
      return {
        ...state,
        onBoardingTemp: action.payload,
        loading: false,
      }


    default:
      return state;
  }


}

export default mentorDashboardReducer
