import React from 'react';
import useScreenShareParticipant from "../../../../../../hooks/useScreenShareParticipant";
import usePublications from "../../../../../../hooks/usePublications";
import useTrack from "../../../../../../hooks/useTrack";
import useIsTrackSwitchedOff from "../../../../../../hooks/useIsTrackSwitchedOff";
import useParticipantIsReconnecting from "../../../../../../hooks/useParticipantIsReconnecting";
import AudioLevelIndicator from "./AudioLevelIndicator";
import NetworkQualityLevel from "./NetworkQualityLevel";
import AvatarIcon from "../../../../../../icons/AvatarIcon";

export default function MainParticipantInfo({participant, children, room}) {
    const localParticipant = room?.localParticipant;
    const isLocal = localParticipant === participant;

    const screenShareParticipant = useScreenShareParticipant();
    const isRemoteParticipantScreenSharing = screenShareParticipant && screenShareParticipant !== localParticipant;

    const publications = usePublications(participant);
    const videoPublication = publications.find(p => p.trackName.includes('camera'));
    const screenSharePublication = publications.find(p => p.trackName.includes('screen'));

    const videoTrack = useTrack(screenSharePublication || videoPublication);
    const isVideoEnabled = Boolean(videoTrack);

    const audioPublication = publications.find(p => p.kind === 'audio');
    const audioTrack = useTrack(audioPublication);

    const isVideoSwitchedOff = useIsTrackSwitchedOff(videoTrack);
    const isParticipantReconnecting = useParticipantIsReconnecting(participant);

    return (
        <div
            data-cy-main-participant
            data-cy-participant={participant?.identity}
            style={{
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                gridArea: isRemoteParticipantScreenSharing ? '1 / 1 / 2 / 3' : ''
            }}
        >
            {/*<div style={{*/}
            {/*    position: 'absolute',*/}
            {/*    zIndex: 2,*/}
            {/*    height: '100%',*/}
            {/*    width: '100%',*/}
            {/*}}>*/}
                {/*<div style={{display: 'flex'}}>*/}
                {/*    <div style={{*/}
                {/*        background: 'rgba(0, 0, 0, 0.5)',*/}
                {/*        color: 'white',*/}
                {/*        padding: '0.1em 0.3em 0.1em 0',*/}
                {/*        display: 'inline-flex',*/}
                {/*        '& svg': {*/}
                {/*            marginLeft: '0.3em',*/}
                {/*        },*/}
                {/*        marginRight: '0.4em',*/}
                {/*        alignItems: 'center',*/}
                {/*    }}>*/}
                {/*        <AudioLevelIndicator audioTrack={audioTrack}/>*/}
                {/*        {participant?.identity}*/}
                {/*        {isLocal && ' (You)'}*/}
                {/*        {screenSharePublication && ' - Screen'}*/}
                {/*    </div>*/}
                {/*    <NetworkQualityLevel participant={localParticipant}/>*/}
                {/*</div>*/}
            {/*</div>*/}
            {(!isVideoEnabled || isVideoSwitchedOff) && (
                <div className='video-call-camera-off-setting' style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: 'black',
                    // height: '345px',
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                    '& svg': {
                        transform: 'scale(2)',
                    },
                }}>
                    <AvatarIcon/>
                </div>
            )}
            {isParticipantReconnecting && (
                <div style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: 'rgba(40, 42, 43, 0.75)',
                    zIndex: 1,
                }}>
                    Reconnecting...
                </div>
            )}
            {children}
        </div>
    );
}
