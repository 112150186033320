import React, { useEffect, useState } from "react";
import editpen from "../../../../assets/icons/editpen.svg";

const EditableSubCell = (props) => {
  const { value, upadteData, rowId, name } = props;
  useEffect(() => {
    setInputValue(value);
  }, [value]);
  const [inputValue, setInputValue] = useState(value);

  const onBlur = (e) => {
    const { name, value } = e.target;
    upadteData(rowId, name, value);
  };

  return (
    <div>
      <input
        className="editable_table_input"
        value={inputValue}
        name={name}
        onChange={(e) => setInputValue(e.target.value)}
        onBlur={onBlur}
      />
      <img
        src={editpen}
        alt=""
        style={{ marginLeft: "5px", cursor: "pointer" }}
        onClick={(e) => false}
      />
    </div>
  );
};

export default EditableSubCell;
